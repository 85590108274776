<template>
    <div class="shipping-provider">
        <SfHeading
            :level="4"
            :title="$t('Delivery terms')"
            class="sf-heading--left sf-heading--no-underline title"
        />
        <div class="form">
            <SfLoader :loading="isLoading">
                <div v-if="errorShippingProvider.save">
                    {{ $t('There was some error while trying to select this shipping term. We are sorry, please try with a different shipping term.') }}
                </div>
                <div v-else-if="shippingMethods.length === 0">
                    {{ $t('There are no shipping terms available for this country. We are sorry, please try with a different country.') }}
                </div>
            </SfLoader>
            <div class="form__radio-group">
                <v-select
                    key="method_code"
                    label="carrier_title"
                    :value="selected.method"
                    :placeholder="$t('Select delivery term')"
                    :options="shippingMethods"
                    @input="selectShippingMethod($event)"
                    :selected="selected.method"
                />
            </div>
        </div>
        <div class="text-right">
            <Button
                class="button button-primary"
                type="button"
                :disabled="!isShippingMethodStepCompleted || isLoading.save"
                @click="$emit('submit')"
            >
                {{ $t('Continue to billing') }}
            </Button>
        </div>
    </div>
</template>

<script>
import {
	SfHeading, SfRadio, SfLoader,
} from '@storefront-ui/vue';

import { computed, defineComponent, ref, reactive, onMounted } from '@nuxtjs/composition-api';
import { cartGetters } from '~/getters';
import { useCart, useShippingProvider } from '~/composables';
import Button from '~/components/Common/Button.vue';

export default defineComponent({
	name: 'VsfShippingProvider',
	components: {
		SfHeading,
		Button,
		SfRadio,
		SfLoader,
	},
	props: {
		shippingMethods: {
			type: Array,
			default: () => [],
		},
	},
	setup(props) {
		const { cart } = useCart();
		const state = ref({});
		const selected = reactive({
			method: props.shippingMethods.find(e => e.method_code == 'monitor1')
		});

		const {
			save: saveShippingProvider,
			error: errorShippingProvider,
			loading: isLoading,
		} = useShippingProvider();

		const isShippingMethodStepCompleted = computed(
			() => state.value?.method_code && !isLoading.value,
		);

		const selectShippingMethod = async (method) => {
			selected.method = method;

			const shippingData = {
				carrier_code: method.carrier_code,
				method_code: method.method_code,
			};

			state.value = await saveShippingProvider({
				shippingMethod: shippingData,
			});
		};

		// auto select method
		onMounted(async () => {
			// await selectShippingMethod(selected.method);
		});

		return {
			errorShippingProvider,
			isLoading,
			isShippingMethodStepCompleted,
			selectShippingMethod,
			state,
			selected
		};
	},
});
</script>

<style lang="scss" scoped>
.title {
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;
    --heading-title-font-weight: var(--font-weight--bold);
}

.shipping-provider {
    .sf-radio {
        &__label {
            display: flex;
            justify-content: space-between;
        }

        &__description {
            --radio-description-margin: 0;
            --radio-description-font-size: var(--font-xs);
        }
    }
}

.form {
    @include for-desktop {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__action {
        @include for-desktop {
            flex: 0 0 100%;
            display: flex;
        }
    }

    &__action-button {
            --button-width: 100%;

            @include for-desktop {
                --button-width: 25rem;
            }
    }

    &__radio-group {
        flex: 0 0 100%;
        margin: 0 0 var(--spacer-xl) 0;

        @include for-desktop {
            margin: 0 0 var(--spacer-2xl) 0;
        }
    }
}
</style>
