<template>
    <div>
        <h5 class="m-0" v-if="userAddress.company">
            {{ userAddress.company }}
        </h5>
        <p v-if="userAddress.street">{{ userAddress.street }},</p>
        <p v-if="userAddress.city">{{ userAddress.city }},</p>
        <p v-if="userAddress.province">{{ userAddress.province }}</p>
        <p v-if="userAddress.postalCode">{{ userAddress.postalCode }}</p>
        <p v-if="userAddress.country">{{ userAddress.country }}</p>
        <p v-if="userAddress.phone" class="phone">
            {{ userAddress.phone }}
        </p>
        <div
            v-if="isDefaultShippingText || isDefaultBillingText"
            class="badge-container"
        >
            <span
                v-if="isDefaultShippingText"
                class="badge-container__badge"
                v-html="$t(isDefaultShippingText)"
            />
            <span
                v-if="isDefaultBillingText"
                class="badge-container__badge"
                v-html="$t(isDefaultBillingText)"
            />
        </div>
    </div>
</template>

<script>
import { toRef, computed, defineComponent } from '@nuxtjs/composition-api';
import { userAddressesGetters } from '~/getters';

export default defineComponent({
	name: 'UserAddressDetails',
	props: {
		address: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const address = toRef(props, 'address');

		const userAddress = computed(() => ({
			company: userAddressesGetters.getCompanyName(address.value),
			firstName: userAddressesGetters.getFirstName(address.value),
			lastName: userAddressesGetters.getLastName(address.value),
			street: userAddressesGetters.getStreetName(address.value),
			postalCode: userAddressesGetters.getPostCode(address.value),
			city: userAddressesGetters.getCity(address.value),
			province: userAddressesGetters.getProvince(address.value) || '',
			country: userAddressesGetters.getCountry(address.value),
			phone: userAddressesGetters.getPhone(address.value),
			isDefault: userAddressesGetters.isDefault(address.value),
			isDefaultShipping: userAddressesGetters.isDefaultShipping(address.value),
			isDefaultBilling: userAddressesGetters.isDefaultBilling(address.value),
		}));

		const isDefaultShippingText = computed(() => (userAddress.value.isDefaultShipping ? 'Default Delivery Address' : ''));

		const isDefaultBillingText = computed(() => (userAddress.value.isDefaultBilling ? 'Default Billing Address' : ''));

		return {
			userAddress,
			isDefaultShippingText,
			isDefaultBillingText,
		};
	},
});
</script>

<style lang="scss" scoped>
p {
    margin: 0;
    line-height: 1.2;
}

.phone {
    margin-top: var(--spacer-base);
}

.badge-container {
    margin-top: var(--spacer-sm);
    display: flex;
    gap: var(--spacer-xs);
    flex-wrap: wrap;

    &__badge {
        display: inline-block; /* have to reset due to style spill from Storefront UI */
        flex-grow: 1;
        font-size: 12px;
        font-style: italic;
    }
}
</style>
