<template>
    <div class="min-height-base flex-center" :style="componentStyle">
        <div class="loader"></div> 
    </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
	name: 'SkeletonLoader',
	props: {
		isLoading: {
			type: Boolean,
			default: true,
		},
		animation: {
			type: [String, Boolean],
			default: 'shimmer',
		},
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: '1em',
		},
		minHeight: {
			type: String,
			default: '50vh',
		},
		margin: {
			type: String,
			default: '0 0 1em 0',
		},
		radius: {
			type: String,
			default: '4px',
		},
		skeletonClass: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const componentClass = computed(() => [
			'skeleton',
			props.skeletonClass,
			props.animation ? `skeleton--${props.animation}` : null,
		]);

		const componentStyle = computed(() => ({
			width: props.width,
			height: props.height,
			minHeight: props.minHeight,
			borderRadius: props.radius,
			margin: props.margin,
		}));

		return {
			componentClass,
			componentStyle,
		};
	},
});

</script>

<style lang="scss">

</style>
