var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('SfHeading',{staticClass:"sf-heading--left sf-heading--no-underline title",attrs:{"level":3,"title":_vm.$t('Billing address')}}),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleAddressSubmit(reset))}}},[(_vm.currentAddressId)?_c('div',{staticClass:"copy__shipping__addresses"},[_c('div',{staticClass:"copy__shipping__address"},[_c('div',{staticClass:"sf-address"},[_c('UserAddressDetails',{attrs:{"address":Object.assign({}, _vm.billingDetails,
                            {region: { region_code: _vm.billingDetails.region }})}})],1)])]):_vm._e(),_vm._v(" "),(!_vm.currentAddressId && _vm.canEditBillingAddress)?_c('div',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"name":"company","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"value":_vm.billingDetails.company,"label":_vm.$t('Company'),"name":"company","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function (company) { return _vm.changeBillingDetails('company', company); }}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"street","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"value":_vm.billingDetails.street,"label":_vm.$t('Street Name'),"name":"streetName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function (street) { return _vm.changeBillingDetails('street', street); }}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"city","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.city,"label":_vm.$t('City'),"name":"city","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function (city) { return _vm.changeBillingDetails('city', city); }}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"zipCode","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element form__element--half form__element--half-even",attrs:{"value":_vm.billingDetails.postcode,"label":_vm.$t('Zip-code'),"name":"zipCode","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function (postcode) { return _vm.changeBillingDetails('postcode', postcode); }}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"country","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('SfSelect',{staticClass:"form__element form__element--half form__select sf-select--underlined",attrs:{"value":_vm.billingDetails.country_code,"label":_vm.$t('Country'),"name":"country","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":_vm.changeCountry}},_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n                            "+_vm._s(countryOption.label)+"\n                        ")])}),1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"region","rules":!_vm.billingDetails.country_code || _vm.regionInformation.length === 0
                        ? null
                        : 'required|min:2',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(!_vm.billingDetails.country_code || _vm.regionInformation.length === 0)?_c('SfInput',{staticClass:"form__element form__element--half form__element--half-even",attrs:{"value":_vm.billingDetails.region,"label":_vm.$t('State/Province'),"disabled":!_vm.billingDetails.country_code,"name":"state","valid":!!_vm.billingDetails.country_code,"error-message":!_vm.billingDetails.country_code
                        ? _vm.$t('Please select a country first')
                        : ''},on:{"input":function (region) { return _vm.changeBillingDetails('region', region); }}}):_c('SfSelect',{staticClass:"form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"value":_vm.billingDetails.region,"label":_vm.$t('State/Province'),"name":"state","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function (region) { return _vm.changeBillingDetails('region', region); }}},_vm._l((_vm.regionInformation),function(regionOption){return _c('SfSelectOption',{key:regionOption.id,attrs:{"value":regionOption.abbreviation}},[_vm._v("\n                        "+_vm._s(regionOption.label)+"\n                    ")])}),1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element form__element--half",attrs:{"label":_vm.$t('Phone number'),"name":"phone","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function (telephone) { return _vm.changeBillingDetails('telephone', telephone); }},model:{value:(_vm.billingDetails.telephone),callback:function ($$v) {_vm.$set(_vm.billingDetails, "telephone", $$v)},expression:"billingDetails.telephone"}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),(!_vm.currentAddressId && _vm.canEditBillingAddress)?_c('Button',{staticClass:"button button-secondary medium mb-3"},[_vm._v("\n            "+_vm._s(_vm.$t('Save'))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.currentAddressId)?_c('VsfPaymentProvider',{on:{"status":_vm.setPaymentReady}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-right"},[(_vm.isPaymentReady)?_c('Button',{staticClass:"button button-primary",attrs:{"type":"submit","disabled":!_vm.canMoveForward}},[_vm._v("\n                "+_vm._s(_vm.$t('Continue to summary'))+"\n            ")]):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"button button-secondary smartphone-only",attrs:{"to":"localePath('/checkout/delivery')"}},[_vm._v("\n                "+_vm._s(_vm.$t('Go back'))+"\n            ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }