<template>
    <div>
        <div
            v-if="title && showTitle"
            class="sf-heading"
        >
            <h3 class="sf-heading__title h3" v-html="title" />
        </div>
        <div
            v-if="subtitle"
            class="sf-heading"
        >
            <h5 class="sf-heading__title h5" v-html="subtitle" />
        </div>
        <HTMLContent :content="content" />
    </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import HTMLContent from '~/components/HTMLContent';

export default defineComponent({
    name: 'ContentBlock',
    components: { HTMLContent },
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        subtitle: {
            type: String,
            required: false,
            default: '',
        },
        content: {
            type: String,
            required: false,
            default: '',
        },
        showTitle: {
            default: false
        }
    },
});
</script>

<style lang="scss" scoped>
::v-deep .social_media {
    justify-content: left;

    li:nth-child(1) {
        padding-left: 0;
        margin-left: 0;
    }

    li:nth-child(2) {
        @media (min-width: 992px) {
            padding-left: 22px;
            margin-left: 22px;
        }
    }

    @media (max-width: 991px) {
        margin-top: 15px;
    }

    @media (min-width: 992px) {
        justify-content: right;
    }
}
</style>
