<template>
    <div class="vacancy-form" v-if="finished == false">
        <ValidationObserver v-slot="{ handleSubmit }" key="vacancies-cv">
            <form @submit.prevent="handleSubmit(submitVacancy)" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                            >
                                <SfInput
                                    v-model="form.name"
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    name="name"
                                    :label="$t('Name')"
                                    class="form__element"
                                />
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                            >
                                <SfInput
                                    v-model="form.email"
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    name="email"
                                    :label="$t('Email')"
                                    class="form__element"
                                />
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                            >
                                <SfInput
                                    v-model="form.phone"
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    name="phone"
                                    :label="$t('Phone')"
                                    class="form__element"
                                />
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                            >
                                <SfTextarea
                                    v-model="form.message"
                                    :label="$t('Message')"
                                    name="message"
                                    :cols="60"
                                    :rows="5"
                                    wrap="soft"
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                />
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="cv" class="custom-file-upload" :class="{'selected': filename.length > 1 }">
                                <img src="/icons/upload.png" alt=""> {{ $t('Upload file') }}
                            </label>
                            <span class="cv-filename" v-html="filename"></span>
                            <input
                                id="cv"
                                name="cv"
                                type="file"
                                @change="onFileChange($event)"
                            />
                            <div class="sf-input form__element invalid" :class="{ 'hidden': filename == '' || filename.length > 1 }">
                                <div id="cv-error" class="sf-input__error-message">{{ $t('This field is required') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group checkboxes">
                            <div class="form-check">

                                <ValidationProvider
                                    v-slot="{ errors }"
                                >
                                    <SfCheckbox
                                        v-model="form.terms"
                                        name="terms"
                                        :label="$t('Apstiprinu, ka esmu iepazinies ar darbinieku privātuma politiku un tās saturs man ir saprotams.')"
                                        class="form__element"
                                    />
                                </ValidationProvider>
                                <span>{{ $t('Privātuma politika pieejama') }} <nuxt-link :to="localePath('/sales-terms-and-conditions')">{{ $t('šeit') }}</nuxt-link></span>
                            </div>
                            <div class="form-check">
                                <ValidationProvider
                                    v-slot="{ errors }"
                                >
                                    <SfCheckbox
                                        v-model="form.agree"
                                        name="agree"
                                        :label="$t('Piekrītu, ka personas datus saglabāt un izmantot citos amata konkursos divus gadus pēc to iesniegšanas. Šo piekrišanu Jūs varat nedot un tās nedošana neierobežos Jūsu iespēju piedalīties konkursā uz konkrēto amatu.')"
                                        class="form__element"
                                    />
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    type="submit"
                    class="btn secondary"
                >
                    <div>{{ $t('Send') }}</div>
                </Button>
            </form>
        </ValidationObserver>
    </div>
    <div class="finished" v-else>
        <h2>{{ $t('Your application form submitted!') }}</h2>
    </div>
</template>
<script>
import {
    ref,
    reactive,
    defineComponent,
    useContext,
} from '@nuxtjs/composition-api';
import {
    SfInput,
    SfTextarea,
    SfCheckbox,
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import Button from '~/components/Common/Button.vue';
import { useVacancies } from '~/modules/vizulo/vacancies'
import { event } from 'vue-gtag';

extend('required', {
    ...required,
    message: 'This field is required',
});

export default defineComponent({
    name: 'VacancyForm',
    components: {
        SfInput,
        SfTextarea,
        SfCheckbox,
        ValidationProvider,
        ValidationObserver,
        Button,
        useVacancies,
    },
    props: {
        vacancy_id: {
            type: Number,
            required: false,
        },
    },    
    setup(props, context) {
        const form = ref({});
        const cv = ref('');
        const filename = ref('');
        const finished = ref(false);

        const {
            submitForm,
            loadingVacancyForm,
        } = useVacancies();

        const onFileChange = async (e) => {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            let result = createFile(files[0]);

            result.then(value => {
                cv.value = value;
                filename.value = files[0].name;
            });
        };

        const createFile = async (file) => {
            return new Promise(function (resolve, reject) {
                let f = new FileReader();

                f.onload = function (e) { 
                    resolve(f.result);
                };

                f.onerror = function (e) { 
                    reject(e); 
                };

                f.readAsDataURL(file);
            });
        };

        const submitVacancy = async () => {
            event('vacancy_form', {
                value: form.value.name + ' ' + form.value.email,
            });

            if (filename.value == '' || filename.value == ' ') {
                filename.value = ' ';
                return;
            } else {
                await submitForm({
                    name: form.value.name,
                    email: form.value.email,
                    phone:  form.value.phone,
                    message: form.value.message,
                    filename: filename.value,
                    document: cv.value,
                    vacancy_id: props.vacancy_id ?? 0,
                });

                finished.value = true;
            }
        };

        return {
            form,
            submitVacancy,
            loadingVacancyForm,
            onFileChange,
            finished,
            filename,
        };
    },
});
</script>

<style lang="scss" scoped>

    .vacancy {
        &-form {
            position: relative;
            padding: 35px 0 50px;

            @media (max-width:991px) {
                padding-top: 0;
                margin-bottom: 10px;
            }

            input:not(.btn):not(#cv):not(.form-check-input ) {
                color: #0d0d0d;
                font-size: 20px;
                line-height: 28px;
                border: none;
                border-bottom: 1px solid #0d0d0d;
                width: 100%;
                background: none;

                @media (max-width:991px) {
                    font-size: 16px;
                    line-height: 24px;
                }

                &:focus, &:active {
                    outline: none;
                }

                &::placeholder {
                    color: #868686;
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    color: #868686;
                }

                &::-ms-input-placeholder {
                    color: #868686;
                }

            }

            textarea {
                width: 100%;
                margin: 15px 0;
                color: #0d0d0d;
                font-size: 20px;
                line-height: 28px;
                resize: none;
            }

            #cv {
                margin-top: 15px;
            }

            input[type="file"] {
                display: none;
            }

            .custom-file-upload {
                border: 1px solid #0d0d0d;
                display: inline-flex;
                align-items: center;
                padding: 6px 12px;
                cursor: pointer;
                background: white;
                margin-bottom: 15px;
                z-index: 1;

                img {
                    float: left;
                    height: 30px;
                    margin-right: 10px;
                }

                &.selected {
                    background: #e8e8e8;
                }
            }

            .cv-filename {
                position: relative;
                top: -9px;
                left: 10px;
            }

            .button {
                margin-top: 35px;
                float: right;
                color: #0d0d0d;
                font-size: 20px;
                line-height: 28px;
                background: none;
                cursor: pointer;

                @media (max-width:991px) {
                    font-size: 16px;
                    line-height: 24px;
                }

                &:focus, &:active {
                    outline: none;
                }

                @media (min-width:991px) {
                    &:hover {
                        opacity: 0.2;
                    }
                }
            }

            .checkboxes {
                margin: 15px 0;
                width: 100%;

                .form-check {
                    margin: 8px 0;
                }
            }

            .action {
                width: 100%;
                border-top: 1px solid #0d0d0d;
            }

            #terms-alert {
                padding-top: 15px;
                color: red;
            }

            .form-group {
                margin: 15px 0;

                .error {
                    color: red;
                }
            }
        }
    }

    .finished {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #0d0d0d;
        border-bottom: 1px solid #0d0d0d;
        margin: 35px 0;
    }
</style>
