import { render, staticRenderFns } from "./PhotometryStructuresBreadcrumbs.vue?vue&type=template&id=40bad9fd&scoped=true&"
import script from "./PhotometryStructuresBreadcrumbs.vue?vue&type=script&lang=ts&"
export * from "./PhotometryStructuresBreadcrumbs.vue?vue&type=script&lang=ts&"
import style0 from "./PhotometryStructuresBreadcrumbs.vue?vue&type=style&index=0&id=40bad9fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bad9fd",
  null
  
)

export default component.exports