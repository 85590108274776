<template>
    <div class="payment-methods mt-2 mb-5">
        <SfHeading
            :level="4"
            title="Payment terms"
            class="sf-heading--left sf-heading--no-underline title"
        />
        <div v-for="method in paymentMethods">
            <v-select
                v-if="method.options.length"
                :value="selectedMethod || method.selected"
                key="code"
                label="title"
                :placeholder="$t('Select...')"
                :options="method.options"
                :reduce="option => option.code"
                @input="definePaymentMethods(method.value, $event)"
            />
            <SfRadio
                v-else
                :key="method.value"
                :label="method.label"
                :value="method.value"
                :selected="selectedMethod"
                name="paymentMethod"
                class="form__radio payment"
                @input="definePaymentMethods(method.value, $event)"
            >
                <div class="payment__label">
                    {{ method.label }}
                </div>
            </SfRadio>
        </div>
    </div>
</template>

<script>
import { SfRadio, SfHeading } from '@storefront-ui/vue';
import {
	ref,
	useAsync,
	computed,
	defineComponent,
} from '@nuxtjs/composition-api';
import { usePaymentProvider } from '~/composables';

export default defineComponent({
	name: 'VsfPaymentProvider',
	components: {
		SfRadio,
        SfHeading,
	},
	emits: ['status'],
	setup(props, { emit }) {
		const state = ref(null);
		const { load, save } = usePaymentProvider();
        const selectedMethod = ref(null);

		useAsync(async () => {
			state.value = await load();

			state.value = state.value ? state.value.map((item) => ({
				...item,
				selected: item.options.find(e => e.status)
			})) : state.value;

			const selected = state.value?.filter((item) => {
				return item.options.filter(e => e.status);
			});

			if (selected.length) {
                await save({
                    paymentMethod: {
                        code: selected[0].code,
                        option: selected[0].selected.code,
                        name: selected[0].selected.title,
                    },
                });

				emit('status');
			}
		});

		const paymentMethods = computed(() => (Array.isArray(state.value) ? state.value.map((p) => ({
			label: p.title,
			value: p.code,
			options: p.options,
			selected: p.selected ? p.selected.code : null,
		})) : []));

		const definePaymentMethods = async (paymentMethod, option) => {
			try {
                const selected = state.value.length ? state.value[0].options.find(e => e.code == option) : null;

				await save({
					paymentMethod: {
						code: paymentMethod,
                        option: option,
						name: selected ? selected.title : '',
					},
				});

				selectedMethod.value = option;

				emit('status');
			} catch (e) {
				console.error(e);
			}
		};

		return {
			state,
			paymentMethods,
			selectedMethod,
			definePaymentMethods,
		};
	},
});
</script>

<style lang="scss" scoped>
.payment {
    &__label {
        display: flex;
        justify-content: space-between;
    }

    &__description {
        --radio-description-margin: 0;
        --radio-description-font-size: var(--font-xs);
    }
}

.payment-methods {
    min-height: 35px;
}

.title {
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;
    --heading-title-font-weight: var(--font-weight--bold);
}
</style>
