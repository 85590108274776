<template>
    <div class="left-navigation" v-click-outside="closeSidebar">
        <nuxt-link
            class="logo"
            :to="localePath('/')"
             @click.native="closeSidebar"
        >
            <svg-image
                icon="logo"
                :label="$t('Vizulo')"
                width="38"
                height="48"
            />
        </nuxt-link>
        <div
            class="menu"
            :class="{ open: isNavigationSidebarOpen || isNavigationSearchOpen || isNavigationStoreSwitcherOpen }"
            aria-label="Open menu"
            @click="toggleNavigationSidebar"
        >
            <div>
                <div><span /><span /><span /><span /></div>
            </div>
            <div>Menu</div>
        </div>

        <div 
            v-if="hasPermission('add edit order')"
            :class="{ hidden: isNavigationSidebarOpen || isNavigationSearchOpen || isNavigationStoreSwitcherOpen }"
            class="cart"
        >
            <nuxt-link :to="localePath('/checkout/cart')">
                <svg-image
                    icon="add_to_cart"
                    width="28"
                    height="28"
                />
            </nuxt-link>
        </div>

        <div
            class="search"
            :class="{ hidden: isNavigationSidebarOpen || isNavigationSearchOpen || isNavigationStoreSwitcherOpen }"
            aria-label="Open search"
            @click="toggleNavigationSearch"
        >
            <svg-image
                icon="search"
                :label="$t('Search')"
                width="23"
                height="23"
            />
        </div>

        <div class="store-switcher">
            <div
              class="container__lang"
              @click="toggleNavigationStoreSwitcher"
            >
                <span>{{ storeConfig.store_code }}</span>
                <svg-image
                    icon="chevron_down"
                    width="12"
                    height="12"
                />
            </div>
        </div>

        <LeftNavigationSidebar />
        <LeftNavigationStoreSwitcher v-if="isNavigationStoreSwitcherOpen" />
        <LeftNavigationSearch v-if="isNavigationSearchOpen" />
    </div>
</template>

<script>

import { useUiState, useConfig, useUser } from '~/composables';
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { clickOutside } from '~/utilities/directives/click-outside/click-outside-directive';
import LeftNavigationSidebar from '~/components/LeftNavigation/LeftNavigationSidebar.vue';
import LeftNavigationSearch from '~/components/LeftNavigation/LeftNavigationSearch.vue';
import LeftNavigationStoreSwitcher from '~/components/LeftNavigation/LeftNavigationStoreSwitcher.vue';

export default defineComponent({
	components: {
		LeftNavigationSidebar,
		LeftNavigationSearch,
		LeftNavigationStoreSwitcher,
	},
	directives: { clickOutside },
	setup() {
		const { config } = useConfig();
        const { hasPermission } = useUser();

		const { 
			toggleNavigationSidebar,
			toggleNavigationSearch,
			toggleNavigationStoreSwitcher,
			isNavigationSidebarOpen,
			isNavigationSearchOpen,
			isNavigationStoreSwitcherOpen,
		} = useUiState();

		const closeSidebar = () => {
			if (isNavigationSidebarOpen.value) {
				toggleNavigationSidebar();
			}

			if (isNavigationSearchOpen.value) {
				toggleNavigationSearch();
			}

			if (isNavigationStoreSwitcherOpen.value) {
				toggleNavigationStoreSwitcher();
			}
		}

		return {
			storeConfig: config,
			toggleNavigationSidebar,
			toggleNavigationSearch,
			toggleNavigationStoreSwitcher,
			isNavigationSidebarOpen,
			isNavigationSearchOpen,
			isNavigationStoreSwitcherOpen,
			closeSidebar,
			hasPermission,
		};
	},
});
</script>

<style lang="scss" scoped>
.left-navigation {
    position: fixed;
    z-index: 110;
    left: 0;
    top: 0;
    width: 120px;
    height: 100%;
    background-color: var(--_c-dark-primary);

    @media (max-width:991px) {
        width: 100%;
        height: 70px;
    }
}

.logo {
    position: absolute;
    top: 53px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--c-white);
    transition: all .2s ease-in-out;

    @media (max-width:991px) {
        top: 11px;
    }

    &:hover {
        opacity: .2;
        transition: all .2s ease-in-out;
    }
}

.menu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width:991px) {
        left: 15px;
        transform: translate(0%, -50%);
    }
}

.search {
    position: absolute;
    cursor: pointer;
    color: var(--c-white);

    @media (max-width:991px) {
        right: 25px;
        top: 25px;
    }

    @media (min-width:992px) {
        bottom: 105px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.hidden {
        display: none;
    }
}

.cart {
    position: absolute;
    bottom: 165px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    color: var(--c-white);

    @media (max-width:991px) {
        display: none;
    }

    .cart-badge {
        position: absolute;
        bottom: 50%;
        left: 50%;
    }
}

.store-switcher {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    color: var(--c-white);
    text-transform: capitalize;
    font-family: var(--font-family--primary);
    text-transform: uppercase;

    @media (max-width:991px) {
        display: none;
    }
}
</style>
