<template>
    <div class="news-opened textdoc">
        <client-only>
            <div class="container top">
                <div class="row">
                    <div class="col-12">
                        <img v-if="news.image" :src="news.image" alt="">
                        <h1 class="text-center" v-html="news.name"></h1>
                        <p class="text-right" v-html="formatDate(news.created_at)"></p>
                    </div>
                    <div class="col-12">
                        <div class="text" v-html="news.content"></div>
                    </div>
                </div>
            </div>
        </client-only>
    </div>
</template>

<script>
import {
    defineComponent, 
    useAsync,
    useRoute,
    ref,
} from '@nuxtjs/composition-api';
import { useNews } from '~/modules/vizulo/news'
import moment from 'moment';

export default defineComponent({
    name: 'NewsItem',
    components: {
        useNews,
    },
    head() {
        if (!this.news) {
            return null;
        }

        const title = this.news?.name ? this.news.name + ' - Vizulo' : 'Vizulo';
        const meta = [];

        return {
            title,
            meta,
        };
    },
    methods: { 
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('ll')
            }
        },
    },
    setup() {
        const { getItem, loadingItem } = useNews();
        const route = useRoute();
        const { params: { slug } } = route.value;
        
        const slugSplitted = slug.split('-');
        const news = ref({});

        useAsync(async () => {
            const newsData = await getItem({
                filter: {
                    entity_id: {
                        "eq": slugSplitted.pop()
                    },
                }
            });
            
            news.value = newsData;
        });

        return {
            loadingItem,
            news,
        };        
    },
});
</script>

<style lang="scss" scoped>
    .news-opened {
        padding-top: 35px;

        @media (min-width:992px) {
            padding-top: 70px;
        }

        .top {
            img {
                margin-bottom: 35px;
            }
        }
    }

</style>
