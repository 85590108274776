<template>
	<div class="row">
		<div
			v-for="(productGroup, family) in productGroups"
			:key="family"
			class="col-12"
            :class="family"
		>
			<h2 :class="{'hide': isProductConfigurator}" v-if="family">{{ family }}</h2>
			<div class="row">
				<nuxt-link
					v-for="product in productGroup"
					:key="getSlug(product)"
					class="col-6 col-lg-4 col-xl-3 product"
					:to="localePath(
						isProductConfigurator
							? getProductConfigurationLink(product, getCategory(product, $route.path, true))
							: getProductLink(product, getCategory(product, $route.path))
					)"
				>
					<div class="flex flex-column">
						<div class="product__image">
							<nuxt-img
								placeholder="/media/catalog-placeholder.png"
								:loading="productGroup.lazy ? 'lazy' : 'eager'"
								:src="getMagentoImage(getProductSmallImage(product))"
								quality="100"
								width="480"
								height="384"
								:alt="product.name"
								class="w-100"
							/>

                            <img
                                v-if="getProductUlLabel(product)"
                                class="product__image-is-ul"
                                src="/icons/ul.png"
                                alt="UL"
                            >
							<h3
								v-if="getProductLabel(product)"
								class="product__image-new-label"
							>
								{{ getProductLabel(product) }}
							</h3>
						</div>
						<div class="w-100">
							<h5 v-if="!isProductConfigurator">{{ product.name }}</h5>
                            <h5 v-else>{{ product.bee_product_name ? product.bee_product_name.toUpperCase() : product.name }}</h5>
							<span v-html="getProductInfo(product)"></span>
						</div>
					</div>
				</nuxt-link>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { productGetters } from '~/getters';
import { useImage } from '~/composables';

export default defineComponent({
	name: 'ProductList',
	props: {
		productGroups: Object,
		isProductConfigurator: Boolean
	},
	setup(props) {
		const total = ref(0);
        const { getMagentoImage } = useImage();

		for (let key in props.productGroups) {
			props.productGroups[key].lazy = false;
			total.value = total.value + Math.ceil((props.productGroups[key].length / 4));

			if (total.value > 3) {
				props.productGroups[key].lazy = true;
			}
		}

		return {
			...productGetters,
			getMagentoImage
		}
	}
})
</script>

<style lang="scss" scoped>
.product {
	margin-bottom: 30px;
	top: 0;
	transition: all .2s ease-in-out;
	text-decoration: none;
	color: inherit;


	h5 {
		margin: 15px 0 4px 0;
		font-size: 14px;
        line-height: 14px;

        @media(max-width: 991px) {
            margin-bottom: 0px;
        }
	}

	span {
		display: block;
		font-size: 12px;
        color: var(--c-gray);
        letter-spacing: -0.4px;
	}

	&:hover {
		top: -6px;
	}

	@media(max-width: 991px) {
		max-width: inherit;
	}

	&__image {
		position: relative;

		img {
			background: var(--c-light-gray);

			@media(max-width: 991px) {
				max-width: inherit;
			}
		}

        &-is-ul {
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 1;
            width: 20px;
        }

		&-new-label {
			position: absolute;
			bottom: 0;
			font-size: 12px;
			right: 10px;
			z-index: 1;
            text-transform: uppercase;

            @media(min-width: 992px) {
                font-size: 14px;
            }
		}
	}
}

.hide {
    display: none;
}
</style>
