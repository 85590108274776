<template>
    <transition-group
        tag="div"
        class="notifications"
        name="slide-fade"
    >
        <SfNotification
            v-for="notification in notifications"
            :key="notification.id"
            :message="notification.message"
            :action="notification.action && notification.action.text"
            :type="notification.type"
            visible
            @click:close="notification.dismiss"
            @click:action="notification.action && notification.action.onClick()"
        >
            <template v-if="notification.icon" #icon>
                <svg-image
                    :icon="notification.icon"
                    width="25"
                    height="25"
                />
            </template>
        </SfNotification>
    </transition-group>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import SfNotification from '~/components/Common/SfNotification';
import { useUiNotification } from '~/composables';

export default defineComponent({
	name: 'NotificationBar',
	components: {
		SfNotification,
	},
	setup() {
		const { notifications } = useUiNotification();

		return {
			notifications,
		};
	},
});
</script>

<style scoped lang="scss">
.notifications {
    position: fixed !important;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    opacity: 0.9;

    @include for-desktop {
        left: 120px;
    }
}

.sf-notification {
    &:first-child {
        margin-top: 0;
    }
}

.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.3s;

    @include for-desktop {
        transition: opacity 0.25s linear;
    }
}

.slide-fade-enter {
    @include for-desktop {
        opacity: 0;
    }
}

.slide-fade-leave-to {
    @include for-desktop {
        opacity: 0;
    }
}
</style>
