<template>
    <div class="loading" v-if="loadingVacancy">
        <SfLoader />
    </div>
    <div class="vacancy-opened" v-else>
        <div class="vacancy-top">
            <img 
                :src="vacancy.image" 
                :alt="vacancy.name"
            >
            <h1>{{ $t('Join our team') }}</h1>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-9">
                    <p 
                        class="vacancy-top-date" 
                        v-html="formatDate(vacancy.created_at)" />
                    
                </div>
                <div class="col-6 col-lg-3" v-if="vacancy.enabled == 1">
                    <Button
                        type="submit"
                        class="btn secondary send-cv"
                        :disabled="loadingVacancy"
                    >
                        <div
                            v-scroll-to="{
                                el: '#form-cv'
                            }"
                        >{{ $t('Send CV') }}</div>
                    </Button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="vacancy-opened__content">
                        <div class="head">
                            <div class="row">
                                <div class="col-12">
                                    <h1>{{ vacancy.name }}</h1>
                                </div>
                                <div class="col-12 old" v-if="vacancy.enabled == 2">
                                    {{ $t('Vakance vairs nav aktuāla') }}
                                </div>
                            </div>
                        </div>
                        <div class="text textdoc" v-html="vacancy.content"></div>
                    </div>
                </div>
            </div>
            <div id="form-cv" class="row" v-if="vacancy.enabled == 1">
                <div class="col-12">
                    <h2>{{ $t('Submit your CV') }}</h2>
                    <Form :vacancy_id="vacancy.vacancy_id" />
                </div>
            </div>
            <div class="row vacancy-share">
                <div class="col-12">
                    <h2>{{ $t('Varbūt pazīsti kādu, kurš būtu ideāls kandidāts? Informē viņu!') }}</h2>
                    <ul>
                        <li>
                            <a 
                            target="_blank" 
                            class="twitter-share-button" 
                            :href="'https://twitter.com/intent/tweet?text=' + vacanciesGetters.getShareText(vacancy)"
                            >
                                <img src="/icons/twitter.png" alt="Share on Twitter" title="Share on Twitter">
                            </a>
                        </li>
                        <li>
                            <a 
                            target="_blank" 
                            class="linkedin-share-button" 
                            :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + vacanciesGetters.getEncodedSlug(vacancy) + '&title=' + vacanciesGetters.getEncodedName(vacancy)"
                            >
                                <img src="/icons/linkedin.png" alt="Share on LinkedIn" title="Share on LinkedIn">
                            </a>
                        </li>
                        <li>
                            <a 
                            target="_blank" 
                            class="facebook-share-button" 
                            :href="'https://facebook.com/sharer/sharer.php?u=' + vacanciesGetters.getEncodedSlug(vacancy) + '&quote=' + vacanciesGetters.getEncodedName(vacancy)"
                            >
                                <img src="/icons/facebook.png" alt="Share on Facebook" title="Share on Facebook">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row" v-if="otherVacancies.length > 0">
                <div class="col-12">
                    <h2>{{ $t('Other vacancies') }}</h2>
                    <div 
                        class="related-vacancies" 
                        v-for="item in otherVacancies" 
                        v-bind:key="item.vacancy_id"
                        >
                        <div class="item" v-if="vacancy.vacancy_id != item.vacancy_id">
                            <nuxt-link :to="localePath(vacanciesGetters.getSlug(item))">
                                <div class="data">
                                    <h5>{{ item.name }}</h5>
                                    <svg-image
                                      class="arrow-link"
                                      height="24"
                                      icon="arrow-link"
                                      width="24"
                                    />
                                </div>
                            </nuxt-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {
  SfLoader,
} from '@storefront-ui/vue';

import {
    defineComponent, 
    useAsync,
    useRoute,
    computed,
    ref,
    onMounted,
} from '@nuxtjs/composition-api';
import { useVacancies } from '~/modules/vizulo/vacancies'
import { vacanciesGetters } from '~/getters';
import Form from './Form.vue';
import Button from '~/components/Common/Button.vue';
import moment from 'moment';

export default defineComponent({
    name: 'VacancyItem',
    components: {
        useVacancies,
        SfLoader,
        Form,
        Button,
    },
    head() {
        if (!this.vacancy) {
            return null;
        }

        const title = this.vacancy?.name ? this.vacancy.name + ' - Vizulo' : 'Vizulo';
        const meta = [];

        return {
            title,
            meta,
        };
    },
    methods: { 
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('ll');
            }
        },
    },
    setup() {
        const { getVacancy, getVacancies, loadingVacancies, loadingVacancy } = useVacancies();
        const route = useRoute();

        const { params: { slug } } = route.value;

        const vacancy = ref({});
        const otherVacancies = ref({});

        onMounted(async () => {
            const vacancyData = await getVacancy({
                filter: {
                    url_key: {
                        "eq": slug
                    },
                }
            });

            vacancy.value = vacancyData;
        });

        onMounted(async () => {
            const vacanciesData = await getVacancies({
                // filter: {
                //     location: {
                //         "eq": vacancy.location
                //     },
                // },
                currentPage: 1,
                pageSize: 1000,
            });

            otherVacancies.value = vacanciesData.items.filter(item => item.enabled == 1);
        });

        return {
            loadingVacancy,
            loadingVacancies,
            otherVacancies,
            vacancy,
            vacanciesGetters,
        };        
    },
});

</script>

<style lang="scss" scoped>
    .loading {
        height: 500px;
    }

    .vacancy-opened {
        .vacancy-top {
            width: calc(100vw - 120px);
            position: relative;
            text-align: center;

            @media (max-width:991px) {
                width: 100vw;
            }

            img {
                width: calc(100vw - 120px);

                @media (max-width:991px) {
                    width: 100vw;
                }
            }

            h1 {
                position: absolute;
                top: 50%;
                left: calc(50% - 120px);
                transform: translate(-50%, -50%);
                font-size: 32px;
                color: white;
                font-weight: bold;
                z-index: 7;

                @media (max-width:991px) {
                    left: 50%;
                    font-size: 24px;
                }
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 6;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }

        .vacancy-top-date {
            font-size: 18px;
            padding-top: 35px;
        }

        .vacancy-top-text {
            font-size: 18px;
            padding-top: 35px;
        }

        .send-cv {
            margin-top: 130px;

            @media (max-width:991px) {
                margin-top: 45px;
            }
        }

        &__content {
            .head {
                padding-bottom: 44px;

                @media (max-width:991px) {
                    padding-top: 44px;
                }

                span {
                    display: block;
                    margin-bottom: 8px;
                }

                h1 {
                    font-size: 28px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 32px;
                    margin: 35px 0 0 0;
                    padding-right: 30px;
                    text-transform: uppercase;

                    @media (max-width:991px) {
                        font-size: 16px;
                        letter-spacing: 0.2px;
                        line-height: 24px;
                        padding-right: 0;
                        margin: 0;
                    }
                }

                .old {
                    color: red;
                    font-style: italic;
                }
            }

            .text {
                padding: 0 120px;
                margin-bottom: 75px;

                @media (max-width:991px) {
                    padding: 0;
                    margin: 0 0 35px 0;
                }

                p {
                    font-size: 18px;
                    margin: 0 0 10px 0;
                    padding: 0;
                    min-height: 25px;

                    @media (max-width:991px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .vacancy-share {
        margin-bottom: 70px;
        text-align: center;

        ul {
            padding: 0;
            margin: 0;
        }
        
        li {
            display: inline-block;
            margin: 10px;
            cursor: pointer;
        }

        h2 {
            @media (max-width:991px) {
                font-size: 16px;
            }
        }
    }

    .related-vacancies {
        .item {
            border-top: 1px solid #0d0d0d;
            display: flex;
            flex-flow: column;

            a {
                padding: 15px 0;

                @media (max-width:991px) {
                    padding: 8px 0;
                }
            }

            h5 {
                float: left;
                font-size: 18px;
                padding: 0;
                margin: 0;

                @media (max-width:991px) {
                    font-size: 14px;
                }
            }

            .arrow-link {
                float: right;
            }
        }

        &:last-child {
            margin-bottom: 120px;

            @media (max-width:991px) {
                margin-bottom: 35px;
            }
        }
    }

</style>
