<template>
    <div v-if="ifWide()" class="col-12 references-list__item">
        <div class="row">
            <div v-bind:class="getClassText()">
                <country-flag :country='item.country_id' size='normal'/>
                <h6>{{ referencesGetters.getFullName(item) }}</h6>
                <p class="related-products list">
                    <template v-for="product in item.products">
                        <Link
                            :icon="true"
                            :link="productGetters.getProductLink(product)"
                            :text="product.name"
                        />
                    </template>
                </p>
                <ul class="d-none d-md-block">
                    <li>
                        <Link
                            :icon="false"
                            :link="localePath(referencesGetters.getSlug(item))"
                            :text="$t('About project')"
                        />
                    </li>
                </ul>
            </div>
            <div v-bind:class="getClassImage()">
                <img :src="referencesGetters.getHorizontalImage(item)" class="js-open-popup-gallery" :data-url="referencesGetters.getSlug(item)" :alt="item.name">
                <ul class="d-md-none">
                    <li>
                        <Link
                            :icon="false"
                            :link="localePath(referencesGetters.getSlug(item))"
                            :text="$t('About project')"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div v-else class="col-12 col-lg-6 references-list__item">
        <country-flag :country='item.country_id' size='normal'/>
        <h6>{{ referencesGetters.getFullName(item) }}</h6>
        <p class="related-products list">
            <template v-for="product in item.products">
                <Link
                    :icon="true"
                    :link="productGetters.getProductLink(product)"
                    :text="product.name"
                />
            </template>
        </p>
        <img :src="referencesGetters.getHorizontalImage(item)" class="js-open-popup-gallery" :data-url="referencesGetters.getSlug(item)" :alt="item.name">
        <ul>
            <li>
                <Link
                    :icon="false"
                    :link="localePath(referencesGetters.getSlug(item))"
                    :text="$t('About project')"
                />
            </li>
        </ul>
    </div>
</template>
<script>
import {
	defineComponent,
	useContext,
	useAsync,
	useRoute,
} from '@nuxtjs/composition-api';
import { useReferences } from '~/composables';
import { referencesGetters, productGetters } from '~/getters';
import Link from '~/components/Common/Link.vue';
import CountryFlag from 'vue-country-flag';

export default defineComponent({
	name: 'ReferencesItemTop',
	components: {
		useReferences,
		Link,
		CountryFlag,
	},
	props: {
		index: {
			type: Number,
			default: 0,
		},
		item: {
			type: Object,
			default: null,
		},
		total: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		ifWide() {
			return (this.index % 6 == 0 || this.index % 6 == 3);
		},
		ifReversed() {
			return (this.index % 6 == 0) ? true : false;
		},
		ifStartRow() {
			return (this.index % 6 == 2 || this.index % 6 == 5) ? false : true;;
		},
		ifEndRow() {
			return ((this.index % 6 == 1 || this.index % 6 == 4) && this.index != this.total) ? false : true;
		},
		getClassText() {
			if (this.ifWide() && this.ifReversed()) {
				return 'col-12 col-lg-4 order-lg-2';
			} else if (this.ifWide()) {
				return 'col-12 col-lg-4';
			}

			return 'col-12 col-lg-6';
		},
		getClassImage() {
			if (this.ifWide()) {
				return 'col-12 col-lg-8 img';
			}

			return 'col-12 col-lg-6 img';
		},
	},
	setup() {
		return {
			referencesGetters,
			productGetters,
		};        
	},
});
</script>

<style lang="scss" scoped>
    .references-list {
        &__item {
            margin-bottom: 120px;

            @media (max-width: 991px) {
                margin-bottom: 35px;

                .row {
                    & > div.col-lg-6:first-child:not(.img) {
                        margin-bottom: 35px;
                    }
                }
            }

            img {
                width: 100%;

                @media (max-width: 991px) {
                    margin-top: 16px;
                }

                &.flag {
                    width: auto;
                    margin-bottom: 2px;
                    margin-top: 0;
                    height: 20px;

                    img {
                        height: 20px;
                        box-shadow: 1px 1px 4px #cecece;
                    }
                }
            }

            h6 {
                font-size: 20px;
                letter-spacing: 0.25px;
                line-height: 28px;
                margin: 0 0 8px;

                @media (max-width: 991px) {
                    font-size: 16px;
                    letter-spacing: 0.2px;
                    line-height: 24px;
                }
            }

            p {
                font-size: 20px;
                line-height: 28px;
                margin: 0 0 16px;

                @media (max-width: 991px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .related-products {
                .link-with-arrow {
                    display: inline-block;
                    position: relative;
                    padding-right: 20px;
                    margin-right: 5px;

                    &:after {
                        content: "|";
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }

                a {
                    font-size: 16px;
                    line-height: 16px;
                    padding-left: 20px;

                    &:before {
                        top: 0px;
                        height: 14px;
                        width: 14px;
                    }
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 16px 0 0 0;

                li {
                    margin: 8px 0;

                    ::v-deep a {
                        font-size: 14px;
                        line-height: 20px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
</style>
