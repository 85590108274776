<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="cite">
                    <svg-image
                        class="cite-icon"
                        icon="cite"
                        width="34"
                        height="28"
                    />
                    {{ text }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
	name: 'Cite',
	props: {
		text: {
			type: String,
			default: '',
		},
	},
};
</script>
<style lang="scss" scoped>
    .cite {
        font-size: 24px;
        position: relative;
        padding: 16px 0 0 62px;
        font-family: var(--font-family--primary-bold);

        @media (max-width:992px) {
            font-size: 18px;
            line-height: 22px;
            padding: 40px 0 0 0;
            margin-top: 16px;
        }
    }

    ::v-deep .svg-image {
        position: absolute;
        top: 22px;
        left: 0;

        @media (max-width: 992px) {
            top: 0;
            left: 0;
        }
    }

</style>