<template>
    <div class="row mt-3 mb-3" v-if="deliveryMethod && paymentTerm">
        <div class="col-6">
            <h4 class="mb-2">{{ $t("Delivery term") }}</h4>
            <p class="mt-0">{{ deliveryMethod.carrier_title }}</p>
        </div>
        <div class="col-6">
            <h4 class="mb-2">{{ $t("Payment term") }}</h4>
            <p class="mt-0">{{ paymentTerm.title }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        deliveryMethod: {
            type: Object,
            default: () => {},
        },
        paymentTerm: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
<style lang="scss" scoped>
p {
    font-size: 14px;
}
</style>
