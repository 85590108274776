<template>
    <div>
        <content-block
            v-for="(block, index) in blocks"
			v-if="block"
            :key="index"
            :title="block.title"
            :content="block.content"
            :showTitle="showTitle"
        />
    </div>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
import ContentBlock from './ContentBlock';

export default defineComponent({
	name: 'ContentBlocks',
	components: {
		ContentBlock,
	},
	props: {
		identifiers: {
			type: Array,
			required: true,
		},
		showTitle: {
			required: false,
			default: true,
		}
	},
	setup(props) {
		const {
			loadBlocks,
		} = useContent();

		const blocks = ref([]);

		useFetch(async () => {
			if (props) {
				blocks.value = await loadBlocks(props);
			}
		});

		return {
			blocks,
		};
	},
});
</script>
