<template functional>
	<component
		:is="link ? injections.components.Link : 'button'"
		:class="[
            data.class,
            data.staticClass,
            {
                'is-disabled--button': $options.buttonActive(
                    link,
                    disabled
                ),
                'is-disabled--link': $options.linkActive(link, disabled),
            },
        ]"
		:style="[data.style, data.staticStyle]"
		:aria-disabled="disabled"
		:link="link"
		:type="type"
		:text="text"
		:aria-label="ariaLabel"
		v-bind="data.attrs"
		v-on="!disabled ? listeners : {}"
	>
		<slot/>
	</component>
</template>

<script>
import Link from './Link.vue';

export default {
	name: 'Button',
	inject: {
		components: {
			default: { Link },
		},
	},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'button',
			validator: (value) => ['button', 'submit', 'reset'].includes(value),
		},
		link: {
			type: [String, Object],
			default: null,
		},
		ariaLabel: {
			type: String,
			default: 'button',
		},
		text: {
			type: String,
			default: '',
			required: false
		}
	},
	linkActive(link, disabled) {
		return link && disabled;
	},
	buttonActive(link, disabled) {
		return !link && disabled;
	},
};
</script>