var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.finished == false)?_c('div',{staticClass:"vacancy-form"},[_c('ValidationObserver',{key:"vacancies-cv",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitVacancy)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"name","label":_vm.$t('Name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"phone","label":_vm.$t('Phone')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)})],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfTextarea',{attrs:{"label":_vm.$t('Message'),"name":"message","cols":60,"rows":5,"wrap":"soft","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})]}}],null,true)})],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"custom-file-upload",class:{'selected': _vm.filename.length > 1 },attrs:{"for":"cv"}},[_c('img',{attrs:{"src":"/icons/upload.png","alt":""}}),_vm._v(" "+_vm._s(_vm.$t('Upload file'))+"\n                        ")]),_vm._v(" "),_c('span',{staticClass:"cv-filename",domProps:{"innerHTML":_vm._s(_vm.filename)}}),_vm._v(" "),_c('input',{attrs:{"id":"cv","name":"cv","type":"file"},on:{"change":function($event){return _vm.onFileChange($event)}}}),_vm._v(" "),_c('div',{staticClass:"sf-input form__element invalid",class:{ 'hidden': _vm.filename == '' || _vm.filename.length > 1 }},[_c('div',{staticClass:"sf-input__error-message",attrs:{"id":"cv-error"}},[_vm._v(_vm._s(_vm.$t('This field is required')))])])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group checkboxes"},[_c('div',{staticClass:"form-check"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{staticClass:"form__element",attrs:{"name":"terms","label":_vm.$t('Apstiprinu, ka esmu iepazinies ar darbinieku privātuma politiku un tās saturs man ir saprotams.')},model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}})]}}],null,true)}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Privātuma politika pieejama'))+" "),_c('nuxt-link',{attrs:{"to":_vm.localePath('/sales-terms-and-conditions')}},[_vm._v(_vm._s(_vm.$t('šeit')))])],1)],1),_vm._v(" "),_c('div',{staticClass:"form-check"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{staticClass:"form__element",attrs:{"name":"agree","label":_vm.$t('Piekrītu, ka personas datus saglabāt un izmantot citos amata konkursos divus gadus pēc to iesniegšanas. Šo piekrišanu Jūs varat nedot un tās nedošana neierobežos Jūsu iespēju piedalīties konkursā uz konkrēto amatu.')},model:{value:(_vm.form.agree),callback:function ($$v) {_vm.$set(_vm.form, "agree", $$v)},expression:"form.agree"}})]}}],null,true)})],1)])])]),_vm._v(" "),_c('Button',{staticClass:"btn secondary",attrs:{"type":"submit"}},[_c('div',[_vm._v(_vm._s(_vm.$t('Send')))])])],1)]}}],null,false,4223941974)})],1):_c('div',{staticClass:"finished"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Your application form submitted!')))])])}
var staticRenderFns = []

export { render, staticRenderFns }