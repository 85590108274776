<template>
    <SfSidebar
        :button="false"
        :visible="isNavigationStoreSwitcherOpen"
        class="navigation-sidebar sf-sidebar--left"
        @close="toggleNavigationStoreSwitcher"
    >
        <nuxt-link :to="localePath('/')">
            <svg-image
                icon="full_logo"
                :label="$t('Vizulo')"
                width="200"
                height="50"
            />
        </nuxt-link>

        <h2>{{ $t('Select language') }}</h2>

        <SfList v-if="availableStores.length > 1">
            <SfListItem
                v-for="store in availableStores"
                :key="store.id"
            >
                <a
                    href="/"
                    class="container__store--link"
                    :class="storeGetters.getSelected(storeConfig, store)
                        ? 'container__store--selected'
                        : ''
                    "
                    @click.prevent="changeStore(store)"
                >
                    <SfCharacteristic class="language">
                        <template #icon>
                            <img class="globe" v-if="store.code == 'en'" src="/icons/globe-white.png" alt="en">
                            <country-flag v-else :country='getStoreCode(store)' size='normal'/>
                        </template>
                        <template #title>
                            <span class="title country">{{ storeConfigGetters.getName(store) }}</span>
                        </template>
                    </SfCharacteristic>
                    <svg-image
                        v-if="storeGetters.getSelected(storeConfig, store)"
                        icon="check"
                        class="checkbox"
                    />
                </a>
            </SfListItem>
        </SfList>
    </SfSidebar>
</template>
<script>

import { SfList, SfCharacteristic } from '@storefront-ui/vue';
import { storeGetters, storeConfigGetters } from '~/getters';
import SfSidebar from '~/components/LeftNavigation/SfSidebar';
import { defineComponent, computed, useAsync } from '@nuxtjs/composition-api';
import { useUiState, useStore, useConfig } from '~/composables';
import CountryFlag from 'vue-country-flag';

export default defineComponent({
	name: 'LeftNavigationStoreSwitcher',
	components: {
		SfSidebar,
		SfList,
		SfCharacteristic,
		CountryFlag
	},
	methods: {
		getStoreCode(store) {
			const code = storeConfigGetters.getCode(store)
			if (code == 'en') {
				return 'gb';
			}

            if (code == 'he') {
                return 'il';
            }

			return code;
		}
	},
	setup() {

		const {
			stores,
			change: changeStore,
			load: loadStores,
		} = useStore();

		const {
			isNavigationStoreSwitcherOpen, 
			toggleNavigationStoreSwitcher,
		} = useUiState();

		const availableStores = computed(() => stores.value ?? []);
		const { config } = useConfig();

		useAsync(async () => {
			await loadStores();
		});

		return {
			storeConfig: config,
			storeGetters,
			storeConfigGetters,
			availableStores,
			changeStore,
			isNavigationStoreSwitcherOpen,
			toggleNavigationStoreSwitcher,
		};
	},
});
</script>

<style lang="scss" scoped>
    ::v-deep .svg-image {
        color: var(--c-white);
        opacity: .2;

        &.checkbox {
            opacity: 1;
        }
    }

    ::v-deep .sf-sidebar {
        &__aside {
            width: 45vw;
            max-width: 840px;

            @media (max-width:991px) {
                width: 100vw;
                max-width: 100%;      
                left: 0;
				top: 70px;
            }
        }

        &__content {
            padding-left: 20px;
            padding-top: 35px;

            @media (min-width:992px) {
                padding-left: var(--sidebar-left);
                padding-top: 55px;
            }

            h2 {
                color: var(--c-white);
            }
        }
    }

    .sf-list {
        height: 60%;
        max-height: 550px;
        @media (min-width:992px) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;            
        }

        &__item {
            margin-bottom: 3px;

            .title.country {
                font-size: 18px;
                line-height: 26px;
            }

            a {
                text-decoration: none;
                display: inline-flex;
                position: relative;

                &.container__store--selected {
                    .title {
                        opacity: 1;
                    }
                }

                &:hover {
                    .title {
                        opacity: 1;
                    }
                }
            }

            .normal-flag {
                margin: 0 5px 0 -10px;
                transform: scale(.46);
                -ms-transform: scale(.46);
                -webkit-transform: scale(.46);
                -moz-transform: scale(.46);
            }

            .globe {
                height: 39px;
                display: inline-block;
                margin: 0 10px 0 -2px;
                transform: scale(0.46);
            }

            .title {
                font-size: 20px;
                line-height: 28px;
                color: var(--c-white);
                opacity: .5;
            }

            .checkbox {
                position: absolute;
                right: -30px;
                top: 7px;
            }
        }
    }
</style>
