var render = function (_h,_vm) {var _c=_vm._c;return _c(_vm.link ? _vm.injections.components.Link : 'button',_vm._g(_vm._b({tag:"component",class:[
            _vm.data.class,
            _vm.data.staticClass,
            {
                'is-disabled--button': _vm.$options.buttonActive(
                    _vm.link,
                    _vm.disabled
                ),
                'is-disabled--link': _vm.$options.linkActive(_vm.link, _vm.disabled),
            } ],style:([_vm.data.style, _vm.data.staticStyle]),attrs:{"aria-disabled":_vm.disabled,"link":_vm.link,"type":_vm.type,"text":_vm.text,"aria-label":_vm.ariaLabel}},'component',_vm.data.attrs,false),!_vm.disabled ? _vm.listeners : {}),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }