<template>
    <SfLoader :loading="$fetchState.pending || loading">
        <div class="textdoc" :class="{notFound: page.identifier == 'no-route' }">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <HTMLContent
                            v-if="page && page.content"
                            :content="page.content"
                        />
                    </div>
                </div>
            </div>
        </div>
    </SfLoader>
</template>
<script type="module">
import {
    SfLoader,
    SfHeading,
} from '@storefront-ui/vue';
import {
    defineComponent, ref, useContext, useFetch, useRoute,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { useContent } from '~/composables';
import HTMLContent from '~/components/HTMLContent';

export default defineComponent({
    name: 'Application',
    components: {
        HTMLContent,
        SfLoader,
        SfHeading,
    },
    head() {
        const title = 'App - Vizulo';
        const meta = [];

        return {
            title,
            meta,
        };
    },
    setup() {
        const { addTags } = useCache();
        const {
            loadPage,
            error,
            loading,
        } = useContent('cmsPage');

        const route = useRoute();
        const { error: nuxtError, app } = useContext();
        const { params } = route.value;
        const page = ref({});

        useFetch(async () => {
            page.value = await loadPage({ identifier: 'app' });

            if (error?.value?.page || !page.value) {
                return nuxtError({ statusCode: 404, message: app.i18n.t('Page not found') });
            }

            addTags([{ prefix: CacheTagPrefix.View, value: page.value.identifier }]);
        });

        return {
            page,
            loading,
        };
    }
});

</script>

<style lang="scss" scoped>
.contacts {
    padding-top: 128px;
    margin-bottom: 120px;

    @media (max-width:991px) {
        padding-top: 35px;
        margin-bottom: 35px;
    }

    &-image {
        img {
            width: 100%;
        }

        @media (max-width: 992px) {
            margin-top: 35px;
        }
    }

    h1 {
        font-size: 28px;
        line-height: 38px;
        font-weight: 400;
        padding: 0 15px;
    }
}
</style>
