<template>
	<div class="container">
		<div class="row mt-6 mb-6">
			<div class="col-12">
				<h1 class="text-center">
					{{ $t('Members') }}
				</h1>
			</div>
			<div class="col-12">
				<ValidationObserver
					v-if="isLogin"
					v-slot="{ handleSubmit, invalid }"
					key="log-in"
				>
					<div class="row justify-content-center">
						<div class="col-12 col-lg-6 mb-2">
							<form
								@submit.prevent="handleSubmit(handleLogin)"
                                class="form"
							>
								<ValidationProvider
									v-slot="{ errors }"
									rules="required|email"
								>
									<SfInput
										v-model="form.username"
										:valid="!errors[0]"
										:error-message="$t(errors[0])"
										name="email"
										:label="$t('Email')"
										class="form__element"
                                        type="email"
									/>
								</ValidationProvider>

								<ValidationProvider
									v-slot="{ errors }"
									rules="required"
								>
									<SfInput
										v-model="form.password"
										:valid="!errors[0]"
										:error-message="$t(errors[0])"
										name="password"
										:label="$t('Password')"
										type="password"
										has-show-password
										class="form__element mb-2"
									/>
								</ValidationProvider>

                                <ValidationProvider
                                    v-slot="{ errors }"
                                >
                                    <SfCheckbox
                                        v-model="form.rememberMe"
                                        name="remember_me"
                                        :label="$t('Remember me')"
                                        class="form__element float-left"
                                    />
                                </ValidationProvider>

								<p class="text-danger" v-if="error.login">
									{{ $t(error.login) }}
								</p>
								<Button
									type="submit"
									class="button button-primary w-100"
									:disabled="loading"
								>
									{{ $t('Login') }}
								</Button>
							</form>
						</div>
						<div class="col-12 text-center mt-3">
							<Button
								class="button button-text"
								@click="setIsForgottenValue(true)"
							>
								{{ $t('Forgot password?') }}
							</Button>
							<p>
								{{ $t('Don\'t have an account yet?') }}
							</p>
							<Button
								class="button button-text"
								@click="setIsRegisterValue(true)"
							>
								{{ $t('Register') }}
							</Button>
						</div>
					</div>
				</ValidationObserver>
				<ValidationObserver
					v-else-if="isForgotten"
					v-slot="{ handleSubmit }"
					key="forgotten-password"
				>
					<div class="row justify-content-center">
						<div class="col-9 col-lg-6">
							<p class="mb-4">
								{{ $t('Forgot Password') }}
							</p>
							<form
								@submit.prevent="handleSubmit(handleForgotten)"
							>
								<ValidationProvider
									v-slot="{ errors }"
									rules="required|email"
								>
									<SfInput
										v-model="form.username"
										:valid="!errors[0]"
										:error-message="$t(errors[0])"
										name="email"
										:label="$t('Forgot Password Modal Email')"
										class="form__element"
									/>
								</ValidationProvider>
								<p class="text-danger" v-if="forgotPasswordError.request">
									{{
										$t('It was not possible to request a new password, please check the entered email address.')
									}}
								</p>
								<div>
									<Button
										type="submit"
										class="button button-primary w-100"
										:disabled="forgotPasswordLoading"
									>
										{{ $t('Reset Password') }}
									</Button>
									<div class="text-center mt-2">
										{{ $t('or') }}
										<Button
											class="button button-text"
											@click="setIsLoginValue(true)"
										>
											{{ $t('go to login page') }}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</ValidationObserver>
				<div
					v-else-if="isThankYouAfterForgotten"
					class="thank-you"
				>
					<i18n
						tag="p"
						class="thank-you__paragraph text-center"
						path="forgotPasswordConfirmation"
					>
						<span class="thank-you__paragraph--bold">{{ userEmail }}</span>
					</i18n>
					<p class="thank-you__paragraph text-center">
						{{ $t('Thank You Inbox') }}
					</p>
					<div class="text-center mt-2">
						<Button
							class="button button-text"
							@click="setIsLoginValue(true)"
						>
							{{ $t('Go to login page') }}
						</Button>
					</div>
				</div>
				<ValidationObserver
					v-else-if="isRegister"
					v-slot="{ handleSubmit, invalid }"
					key="sign-up"
				>
					<div class="row">
                        <div class="col-12 col-xl-6">
                            <h2>{{ $t('Fill the form and become a member of vizulo.com') }}</h2>
                            <p>{{ $t('To become a member you must fill this form. Our team will review it and give positive or negative answer to your request. All fields are required.') }}</p>
                        </div>
						<div class="col-12 col-xl-6">
							<form
								autocomplete="off"
								@submit.prevent="handleSubmit(handleRegister)"
							>
								<ValidationProvider
									v-slot="{ errors }"
									rules="required|email"
                                    class="form__element"
								>
									<SfInput
										v-model="form.email"
										:valid="!errors[0]"
										:error-message="$t(errors[0])"
										name="email"
										:label="$t('Your email')"
									/>
								</ValidationProvider>

                                <div class="form__horizontal">
    								<ValidationProvider
    									v-slot="{ errors }"
    									rules="required"
                                        class="form__element"
    								>
    									<SfInput
    										v-model="form.first_name"
    										:valid="!errors[0]"
    										:error-message="$t(errors[0])"
    										name="first_name"
    										:label="$t('First Name')"
    									/>
    								</ValidationProvider>

    								<ValidationProvider
    									v-slot="{ errors }"
    									rules="required"
                                        class="form__element"
    								>
    									<SfInput
    										v-model="form.last_name"
    										:valid="!errors[0]"
    										:error-message="$t(errors[0])"
    										name="last_name"
    										:label="$t('Last Name')"
    									/>
    								</ValidationProvider>
                                </div>

								<ValidationProvider
									v-slot="{ errors }"
									rules="required|password"
								>
									<SfInput
										v-model="form.password"
										:valid="!errors[0]"
										:error-message="$t(errors[0])"
										name="password"
										:label="$t('Password')"
										type="password"
										has-show-password
										class="form__element"
									/>
								</ValidationProvider>

                                <div class="form__horizontal">
    								<ValidationProvider
    									v-slot="{ errors }"
    									rules="required"
                                        class="form__element"
    								>
    									<SfInput
    										v-model="form.company_name"
    										:valid="!errors[0]"
    										:error-message="$t(errors[0])"
    										name="company_name"
    										:label="$t('Company name')"
    									/>
    								</ValidationProvider>
    								<ValidationProvider
    									v-slot="{ errors }"
    									rules="required"
                                        class="form__element"
    								>
    									<SfInput
    										v-model="form.taxvat"
    										:valid="!errors[0]"
    										:error-message="$t(errors[0])"
    										name="taxvat"
    										:label="$t('Company VAT number')"
    									/>
    								</ValidationProvider>
                                </div>
								<ValidationProvider
									v-slot="{ errors }"
									rules="required"
								>
									<SfInput
										v-model="form.company_address"
										:valid="!errors[0]"
										:error-message="$t(errors[0])"
										name="company_address"
										:label="$t('Company address')"
										class="form__element"
									/>
								</ValidationProvider>
                                <div class="form__horizontal">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        rules="required"
                                        class="form__element"
                                    >
                                        <SfInput
                                            v-model="form.company_position"
                                            :valid="!errors[0]"
                                            :error-message="$t(errors[0])"
                                            name="company_position"
                                            :label="$t('Your position in company')"
                                        />
                                    </ValidationProvider>
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        rules="required"
                                        class="form__element"
                                    >
                                        <SfInput
                                            v-model="form.telephone"
                                            :valid="!errors[0]"
                                            :error-message="$t(errors[0])"
                                            name="phone-number"
                                            :label="$t('Phone number')"
                                        />
                                    </ValidationProvider>
                                </div>

								<SfCheckbox
									v-model="isSubscribed"
									:label="$t('Sign Up for Newsletter')"
									name="signupNewsletter"
									class="form__element"
								/>
								<p class="text-danger" v-if="error.register">
									{{ $t(error.register) }}
								</p>
								<Button
									type="submit"
									class="button button-primary w-100"
									:disabled="loading || invalid"
								>
									<div>{{ $t('Request access') }}</div>
								</Button>
							</form>
							<div class="text-center mt-2">
								{{ $t('or') }}
								<Button
									class="button button-text"
									@click="setIsLoginValue(true)"
								>
									{{ $t('go to login page') }}
								</Button>
							</div>
						</div>
					</div>
				</ValidationObserver>
				<div
					v-else-if="isThankYouAfterRegister"
					class="thank-you text-center"
				>
					<p class="thank-you__paragraph">
						{{ $t('Thank you for registering! However, our team will review your request and give positive or negative answer to it via email.') }}
					</p>
					<div class="text-center mt-2">
						<Button
							class="button button-text"
							@click="setIsLoginValue(true)"
						>
							{{ $t('Go to login page') }}
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	ref,
	watch,
	reactive,
	defineComponent,
	computed,
	useContext,
	useRouter,
	onMounted,
} from '@nuxtjs/composition-api';
import {
	SfModal,
	SfInput,
	SfCheckbox,
	SfBar,
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import {
	useUser, useForgotPassword
} from '~/composables';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/helpers/customer/regex';
import Button from '~/components/Common/Button.vue';

extend('email', {
	...email,
	message: 'Invalid email',
});

extend('required', {
	...required,
	message: 'This field is required',
});

extend('password', {
	message: invalidPasswordMsg,
	validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
	name: 'LoginModal',
	components: {
		SfModal,
		SfInput,
		Button,
		SfCheckbox,
		ValidationProvider,
		ValidationObserver,
		SfBar,
	},
	head() {
		const title = 'Members - Vizulo';
		const meta = [];

		return {
			title,
			meta,
		};
	},
	setup() {
		const { app, $config, localePath, from } = useContext();

		const {
			register,
			login,
			loading,
			isAuthenticated,
			error: userError,
		} = useUser();

		const router = useRouter();
		const { request, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();

		const isSubscribed = ref(false);
		const isLogin = ref(true);
		const isForgotten = ref(false);
		const isThankYouAfterForgotten = ref(false);
		const isRegister = ref(false);
		const isThankYouAfterRegister = ref(false);
		const userEmail = ref('');
		const cameFromLocation = ref('');
		const productConfiguratorRoute = 'product-configurator';

		const form = ref({
			rememberMe: app.$vsf.$magento.config.state.getIsRememberMe() ?? false
		});

		const barTitle = computed(() => {
			if (isLogin.value) {
				return 'Sign in';
			}
			if (isForgotten.value || isThankYouAfterForgotten.value) {
				return 'Reset Password';
			}
			return 'Register';
		});

		const error = reactive({
			login: null,
			register: null,
		});

		const resetErrorValues = () => {
			error.login = null;
			error.register = null;
		};

		const goNext = () => {
			router.go(-1);
		};

		watch(isAuthenticated, async () => {
			if (isAuthenticated.value) {
				if (cameFromLocation.value && cameFromLocation.value.includes(productConfiguratorRoute)){
					let redirectReason = window.localStorage.getItem('rediectedToLoginForAdvanceMode');

					if (redirectReason && redirectReason === 'started') {
						window.localStorage.setItem('rediectedToLoginForAdvanceMode', 'done');
					}
					router.push(localePath(cameFromLocation.value));
				} else {
					router.push(localePath('/members'));
				}
			}
		});

		onMounted(() => {
			cameFromLocation.value = from?.value?.path;
		});

		const setIsLoginValue = (value) => {
			resetErrorValues();
			isLogin.value = value;

			if (isForgotten.value) {
				isForgotten.value = !value;
			}

			if (isThankYouAfterForgotten.value) {
				isThankYouAfterForgotten.value = !value;
			}

			if (isRegister.value) {
				isRegister.value = !value;
			}

			if (isThankYouAfterRegister.value) {
				isThankYouAfterRegister.value = !value;
			}
		};

		const setIsRegisterValue = (value) => {
			resetErrorValues();
			isRegister.value = value;

			if (isForgotten.value) {
				isForgotten.value = !value;
			}

			if (isThankYouAfterForgotten.value) {
				isThankYouAfterForgotten.value = !value;
			}

			if (isLogin.value) {
				isLogin.value = !value;
			}

			if (isThankYouAfterRegister.value) {
				isThankYouAfterRegister.value = !value;
			}
		}

		const setIsForgottenValue = (value) => {
			resetErrorValues();
			isForgotten.value = value;
			if (isLogin.value) {
				isLogin.value = !value;
			}
		};

		const handleForm = (fn) => async () => {
			resetErrorValues();

			await fn({
				user: {
					...form.value,
					is_subscribed: isSubscribed.value,
				},
			});

			const hasUserErrors = userError.value.register || userError.value.login;
			if (hasUserErrors) {
				error.login = userError.value.login?.message;
				error.register = userError.value.register?.message;
				return;
			}

			if (fn === register) {
				isThankYouAfterRegister.value = true;
				isRegister.value = false;
			}
		};

		const handleRegister = async () => handleForm(register)();

		const handleLogin = async () => {
			const expiryDate = new Date();
			expiryDate.setMonth(expiryDate.getMonth() + 1);

			app.$vsf.$magento.config.state.setIsRememberMe(
				form.value.rememberMe,
				{ expires: expiryDate, path: '/' }
			);

			await handleForm(login)();
		};

		const handleForgotten = async () => {
			userEmail.value = form.value.username;

			await request({ email: userEmail.value });

			if (!forgotPasswordError.value.request) {
				isThankYouAfterForgotten.value = true;
				isForgotten.value = false;
			}
		};

		return {
			barTitle,
			error,
			forgotPasswordError,
			forgotPasswordLoading,
			form,
			handleForgotten,
			handleLogin,
			handleRegister,
			isForgotten,
			isLogin,
			isSubscribed,
			isThankYouAfterForgotten,
			isRegister,
			isThankYouAfterRegister,
			loading,
			setIsForgottenValue,
			setIsLoginValue,
			setIsRegisterValue,
			userEmail,
			userError,
			isAuthenticated,
			router,
			goNext,
		};
	}
});
</script>

<style lang="scss" scoped>
    .modal {
    	--modal-index: 3;
    	--overlay-z-index: 3;
    }

    .form {
    	margin-top: var(--spacer-sm);

    	&__element {
    		margin: 0 0 var(--spacer-lg) 0;
    	}

        &__horizontal {
            @include for-desktop {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .form__element {
                @include for-desktop {
                    flex: 1;
                    margin-right: var(--spacer-lg);
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .action {
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
    	font: var(--font-weight--light) var(--font-size--base) / 1.6 var(--font-family--secondary);

    	& > * {
    		margin: 0 0 0 var(--spacer-xs);
    	}
    }

    .action {
    	margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
    }

    .checkbox {
    	margin-bottom: var(--spacer-2xl);
    }

    .bottom {
    	text-align: center;
    	margin-bottom: var(--spacer-lg);
    	font-size: var(--h3-font-size);
    	font-weight: 700;
    	font-family: var(--font-family--secondary);

    	&__paragraph {
    		color: var(--c-primary);
    		margin: 0 0 var(--spacer-base) 0;
    		@include for-desktop {
    			margin: 0;
    		}
    	}
    }

    .thank-you {
    	&__paragraph {
    		&--bold {
    			font-weight: var(--font-weight--semibold);
    		}
    	}
    }

    span {
    	width: 100%;
    }

    .button-text {
        font-size: 16px;
    }
</style>
