<template>
    <div class="globe globe--inside">
        <div class="container top">
            <div class="row">
                <div class="col-12 col-lg-5 col-xl-4 sidebar-container" style="z-index:1;">
                    <h2>{{ $t('References') }}</h2>
                    <div class="js-references-slider">
                        <div class="options">
                            <a v-if="state.type || state.product || state.application || state.country" class="see-all" @click="resetForm()">{{ $t('Reset filter') }}</a>
                            <form action="" method="GET" ref="filter">
                                <div>
                                    <select name="type" class="form-control select2" v-model="state.type">
                                        <option value="0">{{ $t('Filter by type') }}</option>
                                        <option v-for="type in types" v-bind:value="type">
                                            {{ type }}
                                        </option>
                                    </select>
                                    <div 
                                        class="reset" 
                                        v-if="state.type"
                                        @click="resetFormField('type')"
                                    >
                                        <svg-image
                                            height="10"
                                            width="10"
                                            icon="cross"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <select name="application" class="form-control select2" v-model="state.application">
                                        <option value="0">{{ $t('Filter by application') }}</option>
                                        <option v-for="item in applications" v-bind:value="item">
                                            {{ item }}
                                        </option>
                                    </select>
                                    <div 
                                        class="reset" 
                                        v-if="state.application"
                                        @click="resetFormField('application')"
                                    >
                                        <svg-image
                                            height="10"
                                            width="10"
                                            icon="cross"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <select name="product" class="form-control select2" v-model="state.product">
                                        <option value="0">{{ $t('Show all products') }}</option>
                                        <option v-for="item in products" v-bind:value="item">
                                            {{ item }}
                                        </option>
                                    </select>
                                    <div 
                                        class="reset" 
                                        v-if="state.product"
                                        @click="resetFormField('product')"
                                    >
                                        <svg-image
                                            height="10"
                                            width="10"
                                            icon="cross"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <select name="country" class="form-control select2" v-model="state.country">
                                        <option value="0">{{ $t('Show all countries') }}</option>
                                        <option v-for="item in countries" v-bind:value="item">
                                            {{ item }}
                                        </option>
                                    </select>
                                    <div 
                                        class="reset" 
                                        v-if="state.country"
                                        @click="resetFormField('country')"
                                    >
                                        <svg-image
                                            height="10"
                                            width="10"
                                            icon="cross"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="container js-all-references-block">
                            <a @click="showAllReferences" class="link-with-arrow d-lg-none d-xl-none js-all-references">
                                <svg-image
                                    class="arrow-link"
                                    height="24"
                                    icon="arrow-link"
                                    width="24"
                                />
                                {{ $t('Show references') }}
                            </a>

                            <SfModal
                                :visible="allReferencesModal"
                                class="modal"
                                @close="showAllReferences"
                            >
                                <template #modal-bar>
                                  <SfBar
                                    class="sf-modal__bar smartphone-only"
                                    :close="true"
                                    @click:close="showAllReferences"
                                  />
                                </template>

                                <div class="content">
                                    <ul>
                                        <element v-for="(items, index) in chunkedReferences">
                                            <li 
                                                v-for="item in items"
                                                :key="item.entity_id"
                                            >
                                                <nuxt-link :to="localePath(referencesGetters.getSlug(item))" :data-country="item.country_id">
                                                    <svg-image
                                                        class="arrow-link"
                                                        height="24"
                                                        icon="arrow-link"
                                                        width="24"
                                                    />
                                                    {{ item.name }}
                                                </nuxt-link>
                                            </li>
                                        </element>
                                    </ul>
                                </div>
                            </SfModal>
                        </div>
                        <vue-slick-carousel
                            v-if="chunkedReferences.length"
                            v-bind="globeReferencesSettings"
                            class="references-slider"
                            @beforeChange="onSlideBeforeChange"
                        >
                            <div
                                v-for="(items, index) in chunkedReferences"
                                :key="index"
                                class="slide"
                            >
                                <ul>
                                    <li 
                                        v-for="item in items"
                                        :key="item.entity_id"
                                    >
                                        <nuxt-link :to="localePath(referencesGetters.getSlug(item))" :data-country="item.country_id">
                                            <svg-image
                                                class="arrow-link"
                                                height="24"
                                                icon="arrow-link"
                                                width="24"
                                            />
                                            {{ item.name }}
                                        </nuxt-link>
                                    </li>
                                </ul>
                            </div>
                            <template #prevArrow>
                                <div class="project-nav-slider-arrows prev">
                                    <svg-image
                                        width="24"
                                        height="24"
                                        class="icon pointer"
                                        icon="arrow_left"
                                    />
                                </div>
                            </template>
                            <template #nextArrow>
                                <div class="project-nav-slider-arrows next">
                                    <svg-image
                                        class="icon pointer arrow"
                                        icon="arrow_right"
                                        width="24"
                                        height="24"
                                    />
                                </div>
                            </template>
                        </vue-slick-carousel>
                    </div>
                    <div class="paging" v-if="chunkedReferences.length">
                        <div class="paging__counter">
                            <span class="js-slide-current">{{ page }}</span> / <span class="js-slides-total">{{ chunkedReferences.length }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 col-xl-4">
                    <div class="globe__map">
                        <Map :references="filteredReferences" id="chartdiv" />
                    </div>
                </div>
            </div>
            <div class="numbers">
                <span>{{ totalCountries }}</span>
                <h6>{{ $t('Exported to Countries') }}</h6>
                <p>{{ $t('Our lighting products are exported to 43 countries all over the world.') }}</p>
            </div>
            <div class="fixed-bottom">
                <Scroll />
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    computed,
    reactive,
    watch,
    ref,
} from '@nuxtjs/composition-api';
import { storeConfigGetters, referencesGetters } from '~/getters';
import { useConfig, useUiHelpers } from '~/composables';
import Scroll from '~/components/Common/Scroll.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import Map from '~/modules/vizulo/references/Map.vue';
import { SfModal, SfBar } from '@storefront-ui/vue';
import chunk from 'lodash/chunk';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';

export default defineComponent({
    name: 'ReferencesGlobe',
    components: {
        Scroll,
        VueSlickCarousel,
        Map,
        SfModal,
        SfBar
    },
    props: {
        references: [Object, Array],
    },
    data: () => ({
        types: ['Indoor', 'Outdoor'],
        page: 1,
        all_references: 0,
    }),
    methods: {
        onSlideBeforeChange: function(currentIndex, nextIndex) {
            this.page = nextIndex + 1;
        },
        resetForm() {            
            this.$refs.filter.reset();

            this.state.application = 0;
            this.state.country = 0;
            this.state.product = 0;
            this.state.type = 0;
        },
        resetFormField(field) {
            this.state[field] = 0;
        },
        showAllReferences() {
            this.allReferencesModal = !this.allReferencesModal;
        },
    },
    setup(props) {
        const { config } = useConfig();

        const totalCountries = computed(
            () => storeConfigGetters.getTotalCountries(config.value) || '',
        );

        const allReferencesModal = ref(false);
        const globeReferencesSettings = reactive({
            arrows: true,
            draggable: false,
            swipe: false,
            slidesToShow: 1,
        });

        const state = reactive({
            application: 0,
            country: 0,
            product: 0,
            type: 0,
        });

        onMounted(async () => {
            const referencesState = await getItem('references');

            if (referencesState) {
                for (let key in referencesState) {
                    state[key] = referencesState[key];
                }
            }
        });

        const chunkedReferences = computed(()=> {
            return chunk(filteredReferences.value, 8);
        });

        const filteredReferences = computed(()=> {
            let filtered = props.references;

            if (state.application) {
                filtered = filtered.filter((item) => item.application == state.application);
            }

            if (state.country) {
                filtered = filtered.filter((item) => item.country == state.country);
            }

            if (state.product) {
                filtered = filtered.map(item => {
                    const products = item.products.filter(product => product.name == state.product);

                    return {
                        ...item,
                        products
                    }
                });

                filtered = filtered.filter((item) => item.products.length > 0);
            }

            if (state.type) {
                filtered = filtered.map(item => {
                    const products = item.products.filter(product => product.product_type == state.type);

                    return {
                        ...item,
                        products
                    }
                });

                filtered = filtered.filter((item) => item.products.length > 0);
            }

            return filtered;
        });

        const applications = computed(()=> {
            return [...new Set(filteredReferences.value.map((item) => item.application))]
            .sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: false }));
        });

        const countries = computed(()=> {
            return [...new Set(filteredReferences.value.map((item) => item.country))]
            .sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: false }));
        });

        const products = computed(()=> {
            return [...new Set(Object.keys(filteredReferences.value).map(reference => {
                return Object.keys(filteredReferences.value[reference].products).map(product => {
                    return filteredReferences.value[reference].products[product].name
                });
            }).flat())]
            .sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: false }));
        });

        watch(products, () => {
            const query = {};

            for (let key in state) {
                if (state[key] != 0) {
                    query[key] = state[key];
                }
            }

            localStorage.setItem('vsf-references', JSON.stringify(query));
        });

        return {
            totalCountries,
            globeReferencesSettings,
            products,
            countries,
            applications,
            referencesGetters,
            state,
            allReferencesModal,
            chunkedReferences,
            filteredReferences
        };
    },
});
</script>

<style lang="scss" scoped>

    ::v-deep .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;

        &.slick-active {
            display: block;
        }
    }

    ::v-deep .slick-track {
        transform: translate3d(0,0,0) !important;
    }

    .fixed-bottom {
        position: absolute;
        bottom: 75px;
        z-index: 10;
    }

    .sidebar-container {
        position: relative;
    }

    .globe {
        padding: 0;
        margin-bottom: 120px;
        background-color: #0b0b0c;
        overflow: hidden;

        .references-slider {
            display: none;
            height: 100%;
            padding-left: 28px;

            &.slick-initialized {
                display: block;
            }
        }

        &:not(.about-us) {
            height: 100vh;

            @media (max-width:991px) {
                margin-bottom: 35px;

                &.globe--inside {
                    margin-top: 70px;
                    height: calc(100vh - #{35px});
                }
            }
        }

        &.about-us {
            padding-bottom: 35px;
            
            @media (max-width:991px) {
                height: 85vh;
                margin-bottom: 35px;
            }
        }

        @media screen and (min-height: 346px) and (max-height: 695px) and (orientation: landscape) {
            &.globe--inside {
                height: 450px;
            }
        }

        .container {
            position: relative;

            &.top {
                height: 100%;
                padding: 0 0 115px;

                @media (max-width:1400px) {
                    padding: 0 0 35px;
                }
            }

            &.bottom {
                margin-top: -100px;
            }
        }

        .options {
            color: white;
            position: relative;

            @media (max-width:991px) {
                padding: 0 15px;
            }

            a {
                color: white;
                float: left;
                margin-top: 10px;

                &.see-all {
                    position: absolute;
                    right: 0;
                    top: -35px;
                    cursor: pointer;

                    @media (max-width:991px) {
                        right: 15px;
                    }
                }
            }

            form {
                margin-top: 35px;

                div {
                    position: relative;
                }
            }

            select {
                background: #0b0b0c;
                border: 1px solid #0b0b0c;
                color: white;
                width: calc(100% - 45px);
                line-height: 35px;
                height: 35px;
                margin-bottom: 10px;

                option {
                    color: white;
                    background: #0b0b0c;
                }

                @include for-desktop {
                    width: 100%;
                }
            }

            .reset {
                color: var(--c-white);
                position: absolute;
                top: 8px;
                right: 10px;
                cursor: pointer;

                @include for-desktop {
                    right: -15px;
                }
            }
        }

        h2 {
            color: #fff;
            font-weight: 400;
            font-size: 28px;
            line-height: 38px;
            margin: 65px 0 15px;

            @media (max-width:991px) {
                font-size: 22px;
                line-height: 30px;
                margin: 44px 0 28px;
            }

            @media (max-width:768px) {
                font-size: 22px;
                line-height: 30px;
                margin: 10px 0 20px;
                position: relative;
                left: 15px;
            }
        }

        ul {
            margin: 0;
            padding: 0;

            @media (max-width:991px) {
                display: none;
            }

            li {
                margin: 15px 0;

                @media (max-width:1500px) {
                    margin: 10px 0;
                }

                @media (max-width:1200px) {
                    margin: 15px 0;
                }

                a {
                    display: block;
                    color: #fff;
                    font-size: 16px;
                    position: relative;
                    text-indent: -28px;

                    &:focus, &:active {
                        outline: none;
                    }
                }
            }
        }

        .project-nav-slider-arrows {
            width: 34px;
            height: 16px;
            transition: opacity 0.2s ease-in-out;
            position: absolute;
            bottom: -35px;
            right: 25px;

            @media (max-width:991px) {
                display: none;
            }

            &.prev {
                right: 55px;

                svg {
                    transform: rotate(180deg);
                }
            }

            &.next {
                margin-left: 32px;
            }

            &:hover {
                opacity: 0.4;
            }
        }

        &__map {
            @media (max-width:991px) {
                padding: 0 0 120px;
            }

            @media (max-width:767px) {
                padding: 0 0 35px;
            }

            @media (min-width:991px) {
                width: 1250px;
                height: 1250px;
                position: absolute;
                top: 0;
                left: -170px;
                z-index: 0;
            }
        }

        .numbers {
            width: 220px;
            position: absolute;
            bottom: 0;
            z-index: 2;

            @media (min-width:991px) {
                right: 15px;
            }

            @media (max-width:991px) {
                left: 15px;
                width: calc(100% - 30px);
                bottom: 35px;
            }

            span {
                display: block;
                color: #fff;
                font-size: 60px;
                font-weight: 700;
                line-height: 68px;
                margin: 0 0 16px;

                @media (max-width:767px) {
                    font-size: 42px;
                    line-height: 42px;
                    margin: 0 0 5px;
                }
            }

            h6 {
                color: #fff;
                font-size: 16px;
                letter-spacing: 0.2px;
                margin: 0 0 5px;
            }

            p {
                color: #5a5855;
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 20px;

                @media (max-width:767px) {
                    font-size: 16px;
                    width: 90%;
                }
            }
        }

        ::v-deep .svg-image svg {
            fill: var(--c-white);
        }

        .paging {
            position: absolute;
            bottom: -25px;
            left: 28px;
            font-size: 14px;
            line-height: 20px;
            width: 45px;
            color: #5a5855;

            @media (max-width:991px) {
                display: none;
            }
        }
    }

    .all-references {
        position: fixed;
        z-index: 11;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0b0b0c;
        padding: 62px 0 0;
        visibility: hidden;
        opacity: 0;

        &.open {
            opacity: 1;
            visibility: visible;
        }

        &__content {
            max-height: calc(100vh - 120px);
            overflow: scroll;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 40px;
        }

        .close {
            position: absolute;
            top: 28px;
            right: 28px;

            @media (max-width:991px) {
                right: 15px;
            }

            button {
                width: 22px;
                height: 22px;
                border: none;
                cursor: pointer;

                &:focus {
                    outline: none;
                }

                @media (min-width:991px) {
                    &:hover {
                        opacity: 0.2;
                    }
                }
            }
        }

        h6 {
            color: #fff;
            font-size: 22px;
            line-height: 30px;
            font-weight: 400;
            margin: 0 15px 22px;
        }

        ul {
            li {
                margin: 20px 0;

                @media (max-width:991px) {
                    margin: 10px 0;
                }

                a {
                    display: block;
                    color: #fff;
                    font-size: 16px;
                    line-height: 24px;
                    padding-left: 30px;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 6px;
                        left: 0;
                    }
                }
            }
        }
    }

    ::v-deep #chartdiv {
        width: 100%;

        @media (min-width:991px) {
            height: 1250px;
        }

        @media (max-width:991px) {
            margin-top: -40px;
            height: 50vh;
        }

        @media (max-width:767px) {
            height: 45vh;
        }

        max-width: 100%;
        background: #0b0b0c;

        .ampopup-content {
            border: 1px solid #e0e0e0;
            background: #fff;
            top: 30% !important;

            .ampopup-header {
                background: #fff;

                a {
                    width: 14px;
                    height: 14px;
                    margin: 5px;
                    background-size: cover;
                }
            }

            h6 {
                color: #0b0b0c;
                font-size: 16px;
                font-weight: 700;
                margin: 0 0 15px 0;
            }

            a {
                color: #0b0b0c;
                font-size: 14px;
                line-height: 20px;
                text-decoration: underline;
                padding: 0;
                display: block;
                margin-bottom: 10px;

                @media (min-width:991px) {
                    &:hover {
                        text-decoration: none;
                    }
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    .globe {
        &.about-us {
            #chartdiv {
                @media (max-width:991px) {
                    height: 45vh;
                }
            }
        }
    }

    .js-all-references-block {
        float: left;

        a {
            cursor: pointer;
            color: white;
        }

        ::v-deep .sf-bar {
            background-color: #0b0b0c;
            color: white;
        }

        ::v-deep .sf-icon {
            fill: white;
        }

        ::v-deep .sf-modal__container {
            background-color: #0b0b0c;
            padding-top: 75px;
            padding-bottom: 200px;
            z-index: 2000;
        }

        .content {
            padding: 0 25px;

            ul {
                display: block;
            }
        }
    }
</style>