<template>
    <div class="row mt-2 mb-2" v-if="cart">
        <div class="col-6">
            <h4 class="mb-2">{{ $t("Delivery address") }}</h4>
            <UserAddressDetails
                v-for="(address, index) in cart.shipping_addresses"
                :key="index"                    
                :address="{
                    ...address,
                }"
            />
        </div>
        <div class="col-6">
            <h4 class="mb-2">{{ $t("Billing address") }}</h4>
            <UserAddressDetails
                :address="{
                    ...cart.billing_address,
                }"
            />
        </div>
    </div>
</template>

<script>
import UserAddressDetails from '~/components/UserAddressDetails.vue';

export default {
    components: {
        UserAddressDetails,
    },
    props: {
        cart: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
<style lang="scss" scoped>

</style>
