<template>
    <div>
        <SfHeading
            :level="3"
            :title="$t('Order summary')"
            class="sf-heading--left sf-heading--no-underline title"
        />

        <OrderSummary :cartOptions="cartOptions" />
        <hr class="sf-divider mt-3">
        <Addresses :cart="cart" />
        <Methods :deliveryMethod="selectedShippingMethod" :paymentTerm="selectedPaymentMethod" />

        <div class="additional-info" v-if="cartOptions['additional_info']">
            <h4 class="mb-2">{{ $t('Additional info') }}</h4>
            <p class="mt-0">{{ cartOptions['additional_info'] }}</p>
        </div>

        <table class="w-100 mt-3 table">
            <thead>
                <tr>
                    <th class="text-left">{{ $t('Product') }}</th>
                    <th class="text-left">{{ $t('Product reference') }}</th>
                    <th class="text-left">{{ $t('Requested delivery date') }}</th>
                    <th class="text-left">{{ $t('Warranty') }}</th>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Price') }}</th>
                </tr>
            </thead>
            <tbody
                v-for="(product, index) in products"
                :key="index"
                :class="getOption(product, 'row_type')"
            >
                <tr>
                    <td class="table__data table__description table__data">
                        <div class="move">
                            <div class="index">{{ (index+1) * 10 }}</div>
                        </div>
                        <div class="title-wrapper mb-2">
                            <h4 class="m-0">{{ getOption(product, 'article') }}</h4>
                            <p v-if="getOption(product, 'luminous_flux')" class="mt-0 mb-0 lumens">{{ getOption(product, 'luminous_flux') }} lm</p>
                            <p class="m-0">{{ getOption(product, 'model_name') }}</p>
                            <p v-if="getOption(product, 'dimming')" class="m-0 description">{{ $t('Dimming') }}: {{ JSON.parse(getOption(product, 'dimming')).label }}</p>
                        </div>
                    </td>
                    <td class="product-reference">
                        {{ getOption(product, 'product_reference') }}
                    </td>
                    <td class="delivery">
                        {{ getOption(product, 'requested_delivery_date') ? formatDate(getOption(product, 'requested_delivery_date')) : '' }}
                    </td>
                    <td class="warranty">
                        {{ getOption(product, 'warranty_id') && warranty ? warranty.find(el => el.id == getOption(product, 'warranty_id')).name : '' }}
                    </td>
                    <td class="quantity text-center">
                        {{ cartGetters.getItemQty(product) }}
                    </td>
                    <td class="price">
                        <SfPrice
                            class="product-price"
                            :regular="$fc(cartGetters.getItemPrice(product).regular)"
                            :special="
                              cartGetters.getItemPrice(product).special &&
                                $fc(cartGetters.getItemPrice(product).special)
                            "
                        />
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="row-description">
                        <p v-if="getOption(product, 'row_type') == 'product'" class="m-0 description">{{ getOption(product, 'description') }}</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="summary">
            <div class="summary__group">
                <SfProperty
                    name="Total price"
                    :value="$fc(totals.total)"
                    class="sf-property--full-width sf-property--large summary__property-total"
                />
                <SfCheckbox
                    v-model="terms"
                    name="terms"
                    class="summary__terms"
                >
                    <template #label>
                        <div class="sf-checkbox__label">
                            {{ $t('I agree to') }}
                            <SfLink link="#">
                                {{ $t('Terms and conditions') }}
                            </SfLink>
                        </div>
                    </template>
                </SfCheckbox>

                <div class="text-right" v-if="canApproveOrder && !loading && terms">
                    <Button
                        class="button button-primary"
                        @click="processOrder"
                    >
                        {{ $t('Approve order') }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
	SfHeading,
	SfTable,
	SfCheckbox,
	SfDivider,
	SfPrice,
	SfProperty,
	SfLink,
	SfImage,
} from '@storefront-ui/vue';
import {
	ref,
	computed,
	defineComponent,
	useRouter,
	useContext,
	onMounted,
} from '@nuxtjs/composition-api';
import { cartGetters } from '~/getters';
import { useCart, useUser, useMakeOrder, useUserOrder, usePaymentProvider } from '~/composables';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import { removeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import Button from '~/components/Common/Button.vue';
import OrderSummary from '~/components/Checkout/Summary/Order.vue';
import Addresses from '~/components/Checkout/Summary/Addresses.vue';
import Methods from '~/components/Checkout/Summary/Methods.vue';
import moment from 'moment';

export default defineComponent({
	name: 'ReviewOrder',
	components: {
		SfHeading,
		SfTable,
		SfCheckbox,
		Button,
		SfDivider,
		SfPrice,
		SfProperty,
		SfLink,
		SfImage,
		OrderSummary,
		Addresses,
		Methods,
	},
	methods: {
		formatDate(date) {
			return date.length ? moment(date).format(this.dateFormat) : '';
		},
	},
	setup() {
		const order = ref(null);
		const { user } = useUser();
		const { cart, load, setCart } = useCart();
		const { make, loading } = useMakeOrder();
		const { app, $vsf: { $magento } } = useContext();
		const { getCartOptions, setCartOptions } = useUserOrder();
		const router = useRouter();
		const terms = ref(false);
		const apiState = app.$vsf.$magento.config.state;
		const cartOptions = ref({});

		const userPermissions = app.$vsf.$magento.config.state.getPermissions();
		const canApproveOrder = computed(() => userPermissions.find(el => el.code == 'confirm order'));

		const paymentMethods = ref([]);
		const { load: loadPaymentMethods } = usePaymentProvider();
		const selectedPaymentMethod = computed(() => paymentMethods.value.length ? paymentMethods.value[0]['options'].find(e => e.status) : {});
		const warranty = computed(() => cartOptions.value ? cartOptions.value['warranty'] : []);
		const dateFormat = ref('YYYY-MM-DD');

		onMounted(async () => {
			const validStep = await isPreviousStepValid('billing');

			if (!validStep) {
				await router.push(app.localePath('/checkout/delivery'));
			}

			if (!cart.value || !cart.value.id) {
				await load();
			}

			if (cart.value) {
    			cartOptions.value = await getCartOptions(cart.value.id, false);
			}

			paymentMethods.value = await loadPaymentMethods();
		});

		const processOrder = async () => {
			if (terms.value) {
				cartOptions.value['approved_at'] = true;
				cartOptions.value['approved_by'] = user.value.email;

				await setCartOptions(cartOptions.value);

				order.value = await make();
				setCart(null);
				$magento.config.state.setCartId();
				await load();
				await removeItem('checkout');
				await router.push(
					`${app.localePath(
						'/checkout/thank-you',
					)}`,
				);
			}
		};

		const getOption = (product, type) => {
			const options = cartGetters.getConfigurationOptions(product);

			if (options) {
				const option = options.find(e => e.code == type);

				return option?.value;
			}

			return null;
		};

		const products = computed(() => cartGetters
			.getItems(cart.value)
			.filter(Boolean)
			.map((item) => ({
				...item,
				product: {
					...item.product,
					...item.configured_variant,
					...item.configuration_options,
					original_sku: item.product.sku,
					warranty: cartOptions.value['warranty'] ?? []
				},
			}))
			.sort((a, b) => a.configuration_options?.find(e => e.code == 'order_no').value.toString().localeCompare(
				b.configuration_options?.find(e => e.code == 'order_no').value.toString(),
				undefined,
				{ numeric: true }
			))
		);

		return {
			selectedShippingMethod: computed(() => cartGetters.getSelectedShippingMethod(cart.value)),
			totals: computed(() => cartGetters.getTotals(cart.value)),
			dateFormat: computed(() => apiState.getLocale() == 'us' ? 'MM-DD-YYYY' : 'DD-MM-YYYY'),
			cart,
			cartGetters,
			getShippingMethodPrice,
			loading,
			processOrder,
			products,
			terms,
			getOption,
			cartOptions,
			canApproveOrder,
			selectedPaymentMethod,
			warranty
		};
	},
});
</script>

<style lang="scss" scoped>
.title {
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;
}

.table {
    margin: 0 0 var(--spacer-base) 0;

    thead {
        th {
            border-bottom: 1px solid #dcdfe6;
            padding-bottom: 20px;
        }
    }

    tbody {
        tr {
            &:first-child {
                td {
                    padding-top: 20px;
                }
            }

            .row-description {
                padding-left: 20px;
                font-size: 14px;
            }
        }
    }
}

.title-wrapper {
    float: left;
    font-size: 14px;
    width: calc(100% - 20px);
}

.move {
    float: left;
    position: relative;
    top: 0;
    left: -5px;
    width: 20px;
    color: var(--_c-dark-secondary);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .index {
        font-size: 12px;
        line-height: 12px;
    }
}

.price {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    .sf-price__regular {
        font-size: 14px;
    }
}

.product-price {
    --price-font-size: var(--font-size--base);
}

.product-reference {
    vertical-align: baseline;
    font-size: 14px;
    width: 200px;
}

.delivery {
    vertical-align: baseline;
    font-size: 14px;
    width: 200px;
}

.quantity {
    vertical-align: baseline;
    font-size: 14px;
    width: 100px;
}

.warranty {
    vertical-align: baseline;
    font-size: 14px;
    width: 100px;
}

.summary {
    &__total {
        margin: var(--spacer-sm) 0;
        flex: 0 0 16.875rem;
    }

    &__action {
        @include for-desktop {
            display: flex;
            margin: var(--spacer-xl) 0 0 0;
        }
    }

    &__property-total {
        border-top: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
        padding: 15px 0;
        margin: 25px 0;
    }
}

.property {
    margin: 0;

    &__name {
        color: var(--c-text-muted);
    }
}

.content {
    margin: 0 0 var(--spacer-xl) 0;
    color: var(--c-text);

    &:last-child {
        margin: 0;
    }

    &__label {
        font-weight: var(--font-weight--normal);
    }
}

.additional-info {
    border-top: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;

    p {
        font-size: 14px;
    }
}

.lumens {
    font-size: 12px;
    color: var(--c-gray);
    letter-spacing: -0.4px;    
}
</style>
