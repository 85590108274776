<template>
    <div class="link-with-arrow">
        <nuxt-link :to="localePath(link)" :class="{icon: icon}">
            <svg-image v-if="icon"
                class="arrow-link"
                height="24"
                icon="arrow-link"
                width="24"
            />
            {{ text }}
        </nuxt-link>
    </div>
</template>
<script>
export default {
	name: 'Link',
	props: {
		text: {
			type: String,
			default: '',
		},
		link: {
			type: String,
			default: '',
		},
		icon: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style lang="scss" scoped>

</style>