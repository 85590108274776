<template>
    <SfInput
        ref="sfSearchBar"
        class="sf-search-bar"
        type="search"
        name="sf-search-bar"
        :value="value"
        :placeholder="placeholder"
        :icon="icon"
        v-bind="$attrs"
        v-on="listeners"
        @keyup.enter="$emit('input', value)"
        id="sf-search-bar"
    />
</template>
<script>
import SfInput from "@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue";
import { focus } from "@storefront-ui/vue/src/utilities/directives";

export default {
    name: "SfSearchBar",
    components: { 
        SfInput 
    },
    directives: {
        focus,
    },
    inheritAttrs: false,
    props: {
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: [Number, String],
            default: null,
        },
        icon: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                "keyup.esc": () => this.$emit("input", ""),
                // blur: () => this.$emit("blur"),
                "click:icon": () => this.$emit("click:icon", this.value),
            };
        },
    },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfSearchBar.scss";
</style>
