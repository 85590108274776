<template>
    <div class="center ">
        <div class="w-100 relative-pos" v-if="activeStep==='step_4'">
            <div class="text-center black-header relative-pos flex-between text-white">
                <div class="flex">
                    <div @click="resetFormData" class="reset-form" v-if="activeStep !== 'step_6'">
                        <p>{{ $t('Reset') }}</p>
                    </div>
                    <SkeletonLoader class="small-loader mr-3" v-if="loading.attributes"/>
                </div>
                <h3>{{ $t('Step') }} 4 - {{ $t('Optimization') }}</h3>
                <div class="reset-form asana-message" :class="{ 'asana-report-sent': selectedValues.general.asanaReportSent }" @click="openConfiguratorPopup = !openConfiguratorPopup">
                    <p class="text-right">{{ $t('Report issue') }}</p>
                </div>
            </div>
            <div id="warning-message">
                <div class="warning-message" v-if="reactiveGeneralValues.general.filteredLuminaireConfigs.length === 0 && (filtersLoaded || filtersLoadedOutside)">
                    <p>{{$t('No configuration found! Please try adjusting or disabling filters below.')}}</p>
                </div>
            </div>
            <div class="info-message" v-if="loadingFullConfigurations">
                <p>{{$t('Searching for matching configurations. It might take a while.')}}</p>
            </div>
            <div class="flex-center" v-if="loading.steps" :style="componentStyle">
                <div class="loader"></div>
            </div>
            <div class="container">
                <div class="skip-step flex-column">
                    <p>{{ $t('This step is optional and can be skipped') }}</p>
                    <span class="base-button" @mousedown="changeActiveStep('step_5')">{{ $t('Next step') }}</span>
                </div>
                <div class="base-attribute">
                        <div class="flex-center">
                            <div class="flex vw-10">
                                <p>{{ $t('Target configuration') }}</p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['target_configuration']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['target_configuration']"></span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-center mt-3 range-slider">
                            <div class="relative-pos">
                                <input
                                    class="vw-26"
                                    @change="filterConfigs('luminaireConfigPreference', reactiveSelectedValues.luminaireConfigPreference, true, reactiveSelectedValues.luminaireConfigPreference == 2)"
                                    type="range"
                                    v-model="reactiveSelectedValues.luminaireConfigPreference"
                                    :min=0
                                    :max=2
                                    step="1"
                                >
                            </div>
                            <div class="right-side break-text">
                                <p>{{ $t('Best price') }}</p>
                                <p class="text-align-center">{{ $t('Optimal configuration') }}</p>
                                <p>{{ $t('Highest efficacy') }}</p>
                            </div>
                        </div>
                    </div>
                <div class="base-attribute module-types align-items-start" v-if="ledModulesHasMultipleCategories()" :class="{ 'inactive': !reactiveSelectedValues.activeFilters.ledModuleType }">
                    <div class="flex-center">
                        <input
                            class="checkbox"
                            @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true)"
                            type="checkbox"
                            name="checkbox"
                            v-model="reactiveSelectedValues.activeFilters.ledModuleType"
                        >
                        <div class="flex vw-10">
                            <p>{{ $t('Module type') }}</p>
                            <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['module_type']">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="info"
                                />
                                <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['module_type']"></span>
                            </div>
                        </div>
                    </div>
                    <div class="images" v-if="reactiveSelectedValues.ledModuleTypes">
                        <div class="flex-column" v-for="(ledModule, index) in reactiveSelectedValues.ledModuleTypes"
                            :key="index"
                            :class="{ 'border-1': ledModule['selected'] }"
                        >
                            <div class="pointer">
                                <img :alt="ledModule.name" @click="setSelectedVersion(ledModule.id)" v-bind:src="apiVizuloStorageUrl + ledModule.file_path" alt="led module type">
                            </div>
                            <div class="flex-end">
                                <input
                                    type="checkbox"
                                    name="checkbox"
                                    @change="setSelectedVersion(ledModule.id)"
                                    :checked=" ledModule['selected']"
                                    :disabled="!reactiveSelectedValues.activeFilters.ledModuleType"
                                >
                                <p>{{ ledModule.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" w-100 flex-column">
                    <div v-if="reactiveProductConfigurator.driverFeatures.length > 0" class="base-attribute" :class="{ 'inactive': !reactiveSelectedValues.activeFilters.driverRank }">
                        <div class="flex-center">
                            <input
                                class="checkbox"
                                @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true)"
                                type="checkbox"
                                name="checkbox"
                                v-model="reactiveSelectedValues.activeFilters.driverRank"
                            >
                            <div class="flex vw-10">
                                <p>{{ $t('Driver features') }}</p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['driver_features']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['driver_features']"></span>
                                </div>
                            </div>
                        </div>
                        <ul class="options">
                            <li
                                class="option"
                                :class="{ active: reactiveSelectedValues.ranks.includes(driverFeature.id) && !driverFeature.preSelected, locked: driverFeature.preSelected}"
                                @click="saveRank(driverFeature.id, 'ranks', driverFeature.preSelected)"
                                :key="driverFeature.id"
                                v-for="driverFeature in reactiveProductConfigurator.driverFeatures">
                                <span>{{ driverFeature.value }}</span>
                                <div class="typical-value-locked" v-if="driverFeature.preSelected">
                                    <svg-image
                                        height="16"
                                        icon="lock"
                                        width="16"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="base-attribute align-items-start mobile-center" v-if="!reactiveGeneralValues.step_3.surgeProtection" :class="{ 'inactive': !reactiveSelectedValues.activeFilters.surgeProtection }">
                        <div class="flex-center">
                            <input
                                class="checkbox"
                                @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true)"
                                type="checkbox"
                                name="checkbox"
                                v-model="reactiveSelectedValues.activeFilters.surgeProtection"
                            >
                            <div class="flex vw-10">
                                <p>{{ $t('Min driver surge protection') }}<span class="ml-2">(kV)</span></p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['min_driver_surge_protection']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['min_driver_surge_protection']"></span>
                                </div>
                            </div>
                        </div>
                        <div class="surge-protection">
                            <div class="flex-end">
                                <p class="mr-3 normalize-text">L-N</p>
                                <div class="flex-center mt-3 range-slider">
                                    <div class="relative-pos">
                                        <input
                                            class="vw-26"
                                            @change="filterConfigs('surgeProtectionLN', reactiveSelectedValues.surgeProtectionLN, true)"
                                            type="range"
                                            v-model="reactiveSelectedValues.surgeProtectionLN"
                                            :min="reactiveProductConfigurator.filters.minSurgeProtection"
                                            :max="reactiveProductConfigurator.filters.maxSurgeProtectionLN"
                                            :disabled="!reactiveSelectedValues.activeFilters.surgeProtection"
                                            step="1"
                                        >
                                        <input
                                            type="text"
                                            class="bubble"
                                            v-model="reactiveSelectedValues.surgeProtectionLN"
                                            :disabled="!reactiveSelectedValues.activeFilters.surgeProtection"
                                            @change="filterConfigs('surgeProtectionLN', reactiveSelectedValues.surgeProtectionLN, true)"
                                            :style="{ 'left': `calc(${
                                                calculatePosition(reactiveSelectedValues.surgeProtectionLN, reactiveProductConfigurator.filters.minSurgeProtection,reactiveProductConfigurator.filters.maxSurgeProtectionLN)}% +
                                                (${ 6 - calculatePosition(reactiveSelectedValues.surgeProtectionLN, reactiveProductConfigurator.filters.minSurgeProtection,reactiveProductConfigurator.filters.maxSurgeProtectionLN) * 0.35}px))` }"
                                        >
                                    </div>
                                    <div class="right-side">
                                        <p>{{ reactiveProductConfigurator.filters.minSurgeProtection }}</p>
                                        <p>{{ reactiveProductConfigurator.filters.maxSurgeProtectionLN }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-end mt-5">
                                <p class="mr-3 bottom-8 normalize-text">L/N-PE</p>
                                <div class="range-slider">
                                    <div class="relative-pos">
                                        <input
                                            class="vw-26"
                                            @change="filterConfigs('surgeProtectionPE', reactiveSelectedValues.surgeProtectionPE, true)"
                                            type="range"
                                            v-model="reactiveSelectedValues.surgeProtectionPE"
                                            :min="reactiveProductConfigurator.filters.minSurgeProtection"
                                            :max="reactiveProductConfigurator.filters.maxSurgeProtectionLN_PE"
                                            :disabled="!reactiveSelectedValues.activeFilters.surgeProtection"
                                            step="1"
                                        >
                                        <input
                                            type="text"
                                            class="bubble"
                                            v-model="reactiveSelectedValues.surgeProtectionPE"
                                            @change="filterConfigs('surgeProtectionPE', reactiveSelectedValues.surgeProtectionPE, true)"
                                            :style="{ 'left': `calc(${
                                                calculatePosition(reactiveSelectedValues.surgeProtectionPE, reactiveProductConfigurator.filters.minSurgeProtection,reactiveProductConfigurator.filters.maxSurgeProtectionLN_PE)}% +
                                                (${ 6 - calculatePosition(reactiveSelectedValues.surgeProtectionPE, reactiveProductConfigurator.filters.minSurgeProtection,reactiveProductConfigurator.filters.maxSurgeProtectionLN_PE) * 0.35}px))` }"
                                        >
                                    </div>
                                    <div class="right-side">
                                        <p>{{ reactiveProductConfigurator.filters.minSurgeProtection }}</p>
                                        <p>{{ reactiveProductConfigurator.filters.maxSurgeProtectionLN_PE }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="base-attribute mobile-center" :class="{ 'inactive': !reactiveSelectedValues.activeFilters.driverPowerUtilization }">
                        <div class="flex-center">
                            <input
                                class="checkbox"
                                @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true)"
                                type="checkbox"
                                name="checkbox"
                                :checked="false"
                                v-model="reactiveSelectedValues.activeFilters.driverPowerUtilization"
                            >
                            <div class="flex vw-10">
                                <p>{{ $t('Min driver power utilization') }}</p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['min_driver_power_utilization']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['min_driver_power_utilization']"></span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-center mt-3 range-slider">
                            <div class="relative-pos">
                                <input
                                    class="vw-26"
                                    @change="filterConfigs('driverPowerUtilization', reactiveSelectedValues.driverPowerUtilization, true)"
                                    type="range"
                                    v-model="reactiveSelectedValues.driverPowerUtilization"
                                    :min=reactiveProductConfigurator.filters.minDriverPowerUtilization
                                    :max=reactiveProductConfigurator.filters.maxDriverPowerUtilization
                                    step="1"
                                    :disabled="!reactiveSelectedValues.activeFilters.driverPowerUtilization"
                                >
                                <input
                                    type="text"
                                    class="bubble"
                                    @change="filterConfigs('driverPowerUtilization', reactiveSelectedValues.driverPowerUtilization, true)"
                                    v-model="reactiveSelectedValues.driverPowerUtilization"
                                    :disabled="!reactiveSelectedValues.activeFilters.driverPowerUtilization"
                                    :style="{ 'left': `calc(${calculatePosition(reactiveSelectedValues.driverPowerUtilization, reactiveProductConfigurator.filters.minDriverPowerUtilization, reactiveProductConfigurator.filters.maxDriverPowerUtilization)}% +
                                    (${ 3 - calculatePosition(reactiveSelectedValues.driverPowerUtilization, reactiveProductConfigurator.filters.minDriverPowerUtilization, reactiveProductConfigurator.filters.maxDriverPowerUtilization) * 0.35}px))` }"
                                    :class="{'power-utilization': reactiveSelectedValues.activeFilters.driverPowerUtilization}"
                                >
                            </div>
                            <div class="right-side">
                                <p>{{ reactiveProductConfigurator.filters.minDriverPowerUtilization }}%</p>
                                <p>{{ reactiveProductConfigurator.filters.maxDriverPowerUtilization }}%</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="openFilters" class="base-attribute mobile-center" :class="{ 'inactive': !reactiveSelectedValues.activeFilters.minPowerFactor}">
                        <div class="flex-center" v-if="selectedValues">
                            <input
                                class="checkbox"
                                @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true, true)"
                                type="checkbox"
                                name="checkbox"
                                v-model="reactiveSelectedValues.activeFilters.minPowerFactor"
                            >
                            <div class="flex vw-10">
                                <p>{{ $t('Min power factor') }}</p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['min_power_factor']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['min_power_factor']"></span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-center mt-3 range-slider">
                            <div class="relative-pos">
                                <input
                                    class="vw-26"
                                    @change="filterConfigs('powerFactor', reactiveSelectedValues.powerFactor, true)"
                                    type="range"
                                    v-model="reactiveSelectedValues.powerFactor"
                                    :min="reactiveProductConfigurator.filters.nominalPowerFactor"
                                    :max=1
                                    step="0.01"
                                    :disabled="!reactiveSelectedValues.activeFilters.minPowerFactor"
                                >
                                <input
                                    type="text"
                                    class="bubble"
                                    @change="filterConfigs('powerFactor', reactiveSelectedValues.powerFactor, true)"
                                    v-model="reactiveSelectedValues.powerFactor"
                                    :style="{ 'left': `calc(${calculatePosition(reactiveSelectedValues.powerFactor, reactiveProductConfigurator.filters.nominalPowerFactor, 1)}% +
                                    (${ 6 - calculatePosition(reactiveSelectedValues.powerFactor, reactiveProductConfigurator.filters.nominalPowerFactor, 1) * 0.35}px))` }"
                                >
                            </div>
                            <div class="right-side">
                                <p>{{ reactiveProductConfigurator.filters.nominalPowerFactor }}</p>
                                <p>1</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="openFilters" class="base-attribute mobile-center" :class="{ 'inactive': !reactiveSelectedValues.activeFilters.minLuminousEfficacy }">
                        <div class="flex-center">
                            <input
                                class="checkbox"
                                @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true, true)"
                                type="checkbox"
                                name="checkbox"
                                v-model="reactiveSelectedValues.activeFilters.minLuminousEfficacy"
                            >
                            <div class="flex vw-10">
                                <p>{{ $t('Min luminous efficacy') }}</p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['min_luminous_efficacy']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['min_luminous_efficacy']"></span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-center mt-3 range-slider">
                            <div class="relative-pos">
                                <input
                                    class="vw-26"
                                    @change="filterConfigs('luminousEfficacy', reactiveSelectedValues.luminousEfficacy, true)"
                                    type="range"
                                    v-model="reactiveSelectedValues.luminousEfficacy"
                                    :min="reactiveSelectedValues.minLuminousEfficacy"
                                    :max="reactiveSelectedValues.maxLuminousEfficacy"
                                    step="1"
                                    :disabled="!reactiveSelectedValues.activeFilters.minLuminousEfficacy"
                                >
                                <input
                                    type="text"
                                    class="bubble"
                                    @change="filterConfigs('luminousEfficacy', reactiveSelectedValues.luminousEfficacy, true)"
                                    v-model="reactiveSelectedValues.luminousEfficacy"
                                    :style="{ 'left': `calc(${calculatePosition(reactiveSelectedValues.luminousEfficacy, reactiveSelectedValues.minLuminousEfficacy, reactiveSelectedValues.maxLuminousEfficacy)}% +
                                    (${ 6 - calculatePosition(reactiveSelectedValues.luminousEfficacy, reactiveSelectedValues.minLuminousEfficacy, reactiveSelectedValues.maxLuminousEfficacy) * 0.35}px))` }"
                                >
                            </div>
                            <div class="right-side">
                                <p>{{ reactiveSelectedValues.minLuminousEfficacy }}</p>
                                <p>{{ reactiveSelectedValues.maxLuminousEfficacy }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="openFilters" class="base-attribute mobile-center"  :class="{ 'inactive': !reactiveSelectedValues.activeFilters.current }">
                        <div class="flex-center">
                            <input
                                class="checkbox"
                                @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true, true)"
                                type="checkbox"
                                name="checkbox"
                                v-model="reactiveSelectedValues.activeFilters.current"
                            >
                            <div class="flex vw-10">
                                <p>{{ $t('Max diode current') }}<span class="ml-2">(mA)</span></p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['max_diode_current']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['max_diode_current']"></span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-center mt-3 range-slider">
                            <div class="relative-pos">
                                <input
                                    class="vw-26"
                                    @change="filterConfigs('diodeCurrent', reactiveSelectedValues.diodeCurrent, true)"
                                    type="range"
                                    v-model="reactiveSelectedValues.diodeCurrent"
                                    :min=0
                                    :max="reactiveProductConfigurator.filters.maxDiodeCurrent"
                                    :disabled="!reactiveSelectedValues.activeFilters.current"
                                >
                                <input
                                    type="text"
                                    class="bubble"
                                    @change="filterConfigs('diodeCurrent', reactiveSelectedValues.diodeCurrent, true)"
                                    v-model="reactiveSelectedValues.diodeCurrent"
                                    :style="{ 'left': `calc(${calculatePosition(reactiveSelectedValues.diodeCurrent, 0, reactiveProductConfigurator.filters.maxDiodeCurrent)}% +
                                    (${ 6 - calculatePosition(reactiveSelectedValues.diodeCurrent, 0, reactiveProductConfigurator.filters.maxDiodeCurrent) * 0.35}px))` }"
                                >
                            </div>
                            <div class="right-side">
                                <p>0</p>
                                <p>{{reactiveProductConfigurator.filters.maxDiodeCurrent}}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="openFilters" class="base-attribute align-items-start" :class="{ 'inactive': !reactiveSelectedValues.activeFilters.diodeQuantity }">
                        <div class="flex-center">
                            <input
                                class="checkbox"
                                @click="filterConfigs('activeFilters', reactiveSelectedValues.activeFilters, true)"
                                type="checkbox"
                                name="checkbox"
                                v-model="reactiveSelectedValues.activeFilters.diodeQuantity"
                            >
                            <div class="flex vw-10">
                                <p>{{ $t('Diode quantity') }}</p>
                                <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.general.attributeInfo['diode_quantity']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.general.attributeInfo['diode_quantity']"></span>
                                </div>
                            </div>
                        </div>
                        <ul class="options" v-if="reactiveSelectedValues.ledModuleTypes.length > 0">
                            <li
                                class="option"
                                :class="{ active: reactiveSelectedValues.diodeQuantity && reactiveSelectedValues.diodeQuantity.includes(diodeQuantity)}"
                                @click="saveDiodeQuantity(diodeQuantity)"
                                :key="index"
                                v-for="(diodeQuantity, index) in reactiveProductConfigurator.filters.diodeQuantity"
                            >
                                <span>{{ diodeQuantity }}</span>
                                <div class="typical-value" v-if="showTypicalValue(diodeQuantity) && reactiveSelectedValues.activeFilters.diodeQuantity">
                                    <svg-image
                                        height="13"
                                        icon="star"
                                        width="13"
                                    />
                                    <span class="typical-attribute-tooltiptext">{{ $t('Typical') }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="flex justify-content-center my-4">
                        <button v-if="openFilters" @click="openFilters = !openFilters" class="btn small">
                            {{ $t('Hide other filters') }}
                        </button>
                        <button  v-else @click="openFilters = !openFilters" class="button small">
                            {{ $t('Show other filters') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="arrow-down" @click="changeActiveStep('step_5')">
                <svg-image
                    class="icon hover-icon"
                    icon="chevron_down"
                    height="36"
                    width="36"
                />
            </div>
            <IssueReportPopup
                :visible="openConfiguratorPopup"
                :userComment="userComment"
                @close="openConfiguratorPopup = !openConfiguratorPopup"
                @sendReport="sendReport"
            />
        </div>
        <div
            v-if="activeStep !== 'step_4' && activeStep !== 'step_6'"
            class="base-attribute-button attribute-tooltip relative-pos ml-0"
            @click="changeActiveStep('step_4')"
            :class="{ 'base-attribute-button-close': isCloseToActiveStep, 'nextStep': isNextStep, 'grayed-out': selectedValues.configuratorMode === 'basic' }"
        >
            <div class="container">
                <span class="relative-pos step-4-tooltip">
                    {{ $t('Step') }} 4 - {{ $t('Optimization') }}
                </span>
                <div class="flex position-absolute" v-if="selectedValues.configuratorMode === 'basic'">
                    <svg-image
                        class="icon hover-icon"
                        icon="lock"
                    />
                </div>
            </div>
            <span class="attribute-tooltiptext steps-attribute">{{ $t('Switch to advanced mode to access this step.') }}</span>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, computed, watch} from '@nuxtjs/composition-api';
import { useProductConfigurator } from '@/modules/vizulo/productConfigurator';
import { useVizulo } from '~/composables';
import IssueReportPopup from '~/components/Common/IssueReportPopup.vue';
import dataHelpers from '@/modules/vizulo/productConfigurator/dataHelpers';

export default defineComponent({
    name: 'Step_4',
	data: () => (
		{
			openFilters: false,
		}
	),
    components: {
        IssueReportPopup,
    },
	props: {
        activeStep: String,
        isCloseToActiveStep: Boolean,
        filtersLoadedOutside: Boolean,
        storeCode: String
	},
    setup(props, {emit}) {
        const {
            productConfigurator,
            updateSelectedValues,
            filterLuminaireConfigs,
            testLuminaireConfigsAvailability,
            resetFormData,
            createBugReportInAsana,
            setLedModuleBasedOnConfig,
            selectedValues,
            loading
        } = useProductConfigurator();
        const { prioritizeConfigs, isFullModule } = dataHelpers();
        const step = 'step_4';
        const stepIndex = 4;
        const { getApiVizuloStorage } = useVizulo();
        const apiVizuloStorageUrl = getApiVizuloStorage();
        const filtersLoaded = ref(false);
        const loadingFullConfigurations = ref(false);
        const openConfiguratorPopup = ref(false);
        const userComment = ref({});

        const componentStyle = computed(() => ({
			width: '100%',
			height: 'inherit',
			borderRadius: '4px',
            animation: 'skeleton--shimmer',
            position: 'absolute',
            background: 'rgba(227, 227, 227, 0.8)',
            height: '100%',
            'z-index': "22",
		}));

        const reactiveProductConfigurator = computed(() => {
            return productConfigurator.value.step_4 ?? [];
        });

        const reactiveSelectedValues = computed(()=>{
            return selectedValues.value.step_4 ?? [];
        })

        const reactiveGeneralValues = computed(()=>{
            return selectedValues.value ?? [];
        })

        const driverFeatureIds = computed(() => {
            return reactiveProductConfigurator.value.driverFeatures?.map((feature) => {
                return feature.id;
            })
        })

        const calculatePosition = (value, min, max) => {
            let position = Number(((value - min) * 100) / (max - min));

            if (position > 100) {
                position = 100;
            }

            if (position < 0) {
                position = 0
            }

            return position;
        }

        const formDataSave = async (updatedAttribute, value = '', selectedByUser = false) => {
            filtersLoaded.value = true;
            updateSelectedValues(step, updatedAttribute, value, false, selectedByUser);
        };

        const filterConfigs = (updatedAttribute, value = '', selectedByUser = false, calculateIntensiveField = false) => {
            if (updatedAttribute === 'luminaireConfigPreference' && value != 1) {
                selectedValues.value.step_4.preferenceChanged = true;
                formDataSave(`activeFilters`, selectedValues.value.step_4.activeFilters, true);
            } else if (updatedAttribute === 'luminaireConfigPreference'){
                selectedValues.value.step_4.preferenceChanged = true;
                selectedValues.value.step_4.activeFilters.minLuminousEfficacy = false;
                formDataSave(`activeFilters`, selectedValues.value.step_4.activeFilters, true);
            }

            delay(100).then(() => {
                if (calculateIntensiveField && (selectedValues.value.general.luminaireConfigs.length > 0 && !selectedValues.value.general.luminaireConfigs[0].luminous_efficacy)) {
                    loadingFullConfigurations.value = true;
                    testLuminaireConfigsAvailability(0, false, props.storeCode).then(() => {
                        loadingFullConfigurations.value = false;

                        formDataSave(updatedAttribute, value, selectedByUser).then(() => {
                            if (selectedValues.value.general.luminaireConfigs && selectedValues.value.general.luminaireConfigs.length > 0) {
                                filterLuminaireConfigs(updatedAttribute === 'luminaireConfigPreference');
                                changeFiltersDependingOnPreference(updatedAttribute, value);
                            }
                        });
                    });
                } else {
                    formDataSave(updatedAttribute, value, selectedByUser).then(() => {
                        if (selectedValues.value.general.luminaireConfigs && selectedValues.value.general.luminaireConfigs.length > 0) {
                            filterLuminaireConfigs(updatedAttribute === 'luminaireConfigPreference');
                            if (selectedValues.value.step_4.preferenceChanged) {
                                changeFiltersDependingOnPreference(updatedAttribute, value);
                            }
                        }
                    });
                }
            });
        }

 		watch(() => reactiveGeneralValues.value.general.filteredLuminaireConfigs, () => {
            if (reactiveGeneralValues.value.general.filteredLuminaireConfigs.length === 0 && typeof window !== 'undefined') {
                scrollPageUp();
            }
		});

        const changeActiveStep = (value) => {
            if (value === 'step_5' && !selectedValues.value.general.filteredLuminaireConfigs.length) {
                scrollPageUp();
            } else if(selectedValues.value.step_3.configCount) {
                if (selectedValues.value.configuratorMode !== 'basic') {
                    emit('changeStep', value);
                } else {
                    emit('changeStep', 'step_5');
                }
            }
        }

        const scrollPageUp = () => {
            if (window.document.getElementById('warning-message') !== null) {
                window.document.getElementById('warning-message').scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }

        const delay = (time) => {
            return new Promise(resolve => setTimeout(resolve, time));
        }

        const showTypicalValue = (diodeQuantity) => {
            let isTypical = false;

            if (reactiveSelectedValues.value.ledModuleTypes) {
                if (!ledModulesHasMultipleCategories()) {
                    const selectedModule = selectedValues.value.step_4.ledModuleTypes.reduce(function(prev, current) {
                        return (prev.max_leds_per_module > current.max_leds_per_module) ? prev : current;
                    });

                    let remainder = diodeQuantity % selectedModule['max_leds_per_module'];

                    if (remainder === 0) {
                        isTypical = !reactiveSelectedValues.value.diodeQuantity.includes(diodeQuantity);
                    }
                } else {
                    reactiveSelectedValues.value.ledModuleTypes.forEach((item) => {
                        let remainder = diodeQuantity % item['max_leds_per_module'];

                        if (remainder === 0) {
                            isTypical = !reactiveSelectedValues.value.diodeQuantity.includes(diodeQuantity);
                        }
                    })
                }
            }

            return isTypical;
        }

        const setSelectedVersion = ( moduleTypeId ) => {
            if (!selectedValues.value.step_4.activeFilters.ledModuleType) {
                return;
            }

            const selectedModules = selectedValues.value.step_4.ledModuleTypes.filter(a=>a.selected===true);
            if (selectedModules.length === 1 && selectedModules[0].id == moduleTypeId) {
                return;
            }

            if (reactiveProductConfigurator.value.moduleTypes.length === 1 || selectedValues.value.step_4.ledModuleTypes.length === 1) {
                return;
            }

            selectedValues.value.step_4.ledModuleTypes.forEach((moduleType) => {
                if (moduleType.id == moduleTypeId) {
                    if (moduleType['selected']) {
                        moduleType['selected'] = false;
                    } else {
                        moduleType['selected'] = true;
                    }
                }
            })

            filterConfigs('ledModuleTypes', selectedValues.value.step_4.ledModuleTypes, true);
        };

        const saveRank = (value, attributeName, preSelected = false) => {
            const adjustedName = attributeName === 'ranks' ? 'driverRank' : attributeName;
            if (preSelected || !reactiveSelectedValues.value.activeFilters[adjustedName]) {
                return;
            }

            if (!reactiveSelectedValues[attributeName] && attributeName !== 'ranks') {
                formDataSave(`${attributeName}Edited`, true, true);
            }

            saveRankValues(value, attributeName);
        }

        const saveRankValues = (value, attributeName) => {
            if (!selectedValues.value.step_4[attributeName].includes(value)) {
                selectedValues.value.step_4[attributeName].push(value);
                filterConfigs(`${attributeName}`, selectedValues.value.step_4[attributeName], true);
            } else {
                selectedValues.value.step_4[attributeName] = selectedValues.value.step_4[attributeName].filter(( item ) => {
                    return item !== value;
                });

                if (selectedValues.value.step_4[attributeName].length !==0 || attributeName === 'ranks') {
                    filterConfigs(`${attributeName}`, selectedValues.value.step_4[attributeName], true);
                } else {
                    selectedValues.value.step_4[attributeName].push(value);
                }
            }
        }

        const saveDriverFeature = (driverFeatureId, preSelected) => {
            if (preSelected) {
                return;
            }

            selectedValues.value.step_4.driverFeatureIds.push(driverFeatureId);
        }

        const saveDiodeQuantity = (diodeQuantity) => {
            if (!reactiveSelectedValues.value.activeFilters.diodeQuantity) {
                return;
            }

            if (!reactiveSelectedValues.value.diodeQuantity.includes(diodeQuantity)) {
                reactiveSelectedValues.value.diodeQuantity.push(diodeQuantity);
                filterConfigs('diodeQuantity', reactiveSelectedValues.value.diodeQuantity, true);
            } else {
                reactiveSelectedValues.value.diodeQuantity = reactiveSelectedValues.value.diodeQuantity.filter(( item ) => {
                    return item !== diodeQuantity;
                });

                if (reactiveSelectedValues.value.diodeQuantity.length !==0 ) {
                    filterConfigs('diodeQuantity', reactiveSelectedValues.value.diodeQuantity, true);
                } else {
                    selectedValues.value.step_4.diodeQuantity.push(diodeQuantity);
                }
            }
        }

        const isNextStep = computed(()=>{
			let activeStepIndex = props.activeStep.charAt(props.activeStep.length - 1);

			if (parseInt(activeStepIndex) === stepIndex - 1 && !reactiveSelectedValues.value['isComplete']) {
				return true;
			}

			return false;
        })

        const sendReport = (sentWithSubmitButton) => {
            createBugReportInAsana(userComment.value.message ?? '', sentWithSubmitButton);
            openConfiguratorPopup.value = false;
        }

        const ledModulesHasMultipleCategories = () => {
            let categoryIds = [];

            selectedValues.value.step_4.ledModuleTypes.forEach(ledModule => {
                if (!categoryIds.includes(ledModule.category_id)) {
                    categoryIds.push(ledModule.category_id);
                }
            });

            return categoryIds.length > 1;
        }

        const changeFiltersDependingOnPreference = (updatedAttribute, value) => {
            if (updatedAttribute === 'luminaireConfigPreference' && parseInt(value) === 2) {
                const prioritizedConfigs = prioritizeConfigs(selectedValues.value, 0);
                setLedModuleBasedOnConfig();
                let priorityConfigurations = [];

                for (let i = 0; i < prioritizedConfigs.length; i++) {
                    const config = prioritizedConfigs[i];
                    if (isFullModule(config.total_diodes, selectedValues.value)) {
                        priorityConfigurations.push(config);
                        break;
                    }
                }

                if (priorityConfigurations.length > 0) {
                    selectedValues.value.step_4.activeFilters.diodeQuantity = true;
                    selectedValues.value.step_4.diodeQuantity = [];
                    selectedValues.value.step_4.diodeQuantity.push(priorityConfigurations[0].total_diodes);
                    selectedValues.value.step_4.activeFilters.minLuminousEfficacy = true;
                    selectedValues.value.step_4.luminousEfficacy = priorityConfigurations[0].luminous_efficacy - 1;
                }

                formDataSave('activeFilters', selectedValues.value.step_4.activeFilters, true);
            } else if (updatedAttribute === 'luminaireConfigPreference' && parseInt(value) === 0) {
                const prioritizedConfigs = prioritizeConfigs(selectedValues.value, 0);
                selectedValues.value.step_4.diodeQuantity = [];
                selectedValues.value.step_4.activeFilters.diodeQuantity = true;
                selectedValues.value.step_4.activeFilters.minLuminousEfficacy = false;
                selectedValues.value.step_4.diodeQuantity.push(prioritizedConfigs[0].total_diodes);
                formDataSave('activeFilters', selectedValues.value.step_4.activeFilters, true);
            } else if (updatedAttribute === 'luminaireConfigPreference') {
                selectedValues.value.step_4.diodeQuantity = [];
                selectedValues.value.step_4.activeFilters.minLuminousEfficacy = false;
                reactiveProductConfigurator.value.filters.diodeQuantity.forEach((diodeQuantity) => {
                    if (showTypicalValue(diodeQuantity)) {
                        selectedValues.value.step_4.diodeQuantity.push(diodeQuantity);
                    }
                })
            }

            if (updatedAttribute === 'luminaireConfigPreference') {
                filterLuminaireConfigs();
            }
        }

        return {
            changeActiveStep,
            formDataSave,
            setSelectedVersion,
            saveRank,
            calculatePosition,
            filterConfigs,
            filterLuminaireConfigs,
            saveDiodeQuantity,
            scrollPageUp,
            showTypicalValue,
            resetFormData,
            sendReport,
            ledModulesHasMultipleCategories,
            saveDriverFeature,
            prioritizeConfigs,
            isFullModule,
            componentStyle,
            loadingFullConfigurations,
            reactiveGeneralValues,
            isNextStep,
            loading,
            reactiveProductConfigurator,
            reactiveSelectedValues,
            apiVizuloStorageUrl,
            filtersLoaded,
            selectedValues,
            openConfiguratorPopup,
            userComment,
            driverFeatureIds,
        };
    }
});
</script>
<style lang="scss" scoped>
.options {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .option, .yes-no {
        list-style: none;
        font-size: 14px;
        line-height: 18px;
        background-color: var(--c-white);
        cursor: pointer;
        font-family: var(--font-family--primary-bold);
        padding: 9px 12px;
        border: 1px solid #979797;
        transition: all ease-in 300ms;
        display: inline-block;

        span {
            white-space:nowrap;
        }

        &:focus {
            outline: none;
        }
    }

    .option {
        position: relative;

        span {
            min-width: 16px;
            display: inline-block;
            text-align: center;
        }

        .typical-value-locked {
            position: absolute;
            top: -6px;
            right: -5px;
            padding: 3px;
            background: white;
            border-radius: 10px;
        }
    }
}

.images {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (min-width: 1000px) {
        justify-content: flex-end;
        flex-grow: 5;
    }

    .flex-column {
        border: 1px solid #c4c4c4;
        margin: 0;

        @media (min-width: 1000px) {
            margin: 12px;
            width: 35%;
        }
    }
}

.min-height-base {
  min-height: 30vh;
}

.cover {
    display: block;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 7, 7, 0.5);
    }
}

.flex-right {
    align-items: flex-end;
}

.module-types {
    flex-direction: row;
    align-items: flex-start !important;

    & > p {
        display: inline-block;
        min-width: 140px;
        flex-grow: 1;
    }
}

.bubble {
    padding: 2px 6px;
    position: absolute;
    border-radius: 5px;
    width: 48px;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid var(--_c-gray-accent) !important;
    bottom: 0;
    margin-bottom: 2rem;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}

.range-slider {
    .relative-pos {
        top: 3px;
        height: 2rem;

.power-utilization {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='21px' width='60px'><text x='27' y='16' fill='gray' font-size='14' font-family='arial'>%</text></svg>");
    background-repeat: no-repeat;
    background-position-y: center;
    width: 51px !important;
    padding-left: 7px !important;
    text-align: left !important;
}
    }
}

.relative-pos {
    position: relative;
    width: 100%;

    input {
        padding: 0 !important;
    }
}

.bottom-8 {
    position: relative;
    bottom: 8px;
}

.vw-26 {
    width: 26vw;
}

.base-attribute {
    & > div > div > p {
        font-weight: 600;
        font-size: 20px;
    }

    .checkbox {
        margin-right: 1rem;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.base-button {
    color: var(--c-white);
    background: var(--c-black);
    padding: 12px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-family: var(--font-family--primary-bold);
}

.typical-attribute-tooltiptext {
    right: 14px !important;
}

.step-4-tooltip {
    .attribute-tooltip {
        width: 30px;
        position: absolute;
        top: -3px;
        right: -2em;
    }
}

.skip-step {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--_c-gray-accent);
    padding: 15px 0;

    @media (min-width: 1200px) {
        flex-direction: row;
    }

    p{
        font-weight: 600;
        font-size: 20px;

    }
}

.warning-message, .info-message {
    display: flex;
    justify-content: center;
    background: rgb(255, 213, 136);
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.info-message {
    background: rgb(255, 255, 255);
}

.container {
    position: relative;
}

.pointer {
    img {
        width: 200px;
    }
}

.mobile-center {
    align-items: center !important;

    @media (min-width: 600px) {
        align-items: flex-start !important;
        margin-left: 1rem;
    }

    @media (min-width: 1200px) {
        margin-left: 0;
    }
}

.options {
    .locked {
        background-color: var(--c-white) !important;
        border: none !important;
        background-color: #cecece !important;
        position: relative;

        .svg-image {
            color: #535353 !important;
        }

        &::before {
            content: '';
            position: absolute;
            border: 1px solid #979797;
            background: #979797;
            border-radius: 20px;
            top: -1px;
            left: -1px;
            height: calc(100% + 2px);
            width: calc(100% + 2px);
            z-index: -4;
        }

        &::after {
            content: '';
            position: absolute;
            top: -2px;
            right: -2px;
            height: 22px;
            width: 22px;
            background: white;
            border-radius: 20px;
            z-index: -2;
        }

        &:hover {
            color: black !important;
            cursor: default !important;
        }

        .typical-value-locked {
            border-radius: 50% !important;
            top: -8px !important;
            right: -7px !important;
            background: transparent !important;
        }
    }
}

.text-align-center {
    text-align: center;
}

.break-text {
    p {
        width: 85px;

        &:last-child {
            text-align: right;
            width: 60px !important;
        }
        &:first-child {
            text-align: left;
        }
        &:nth-child(1) {
            width: 60px !important;
        }
    }
}

.position-absolute {
    position: absolute;
    top: -2px;
}

.steps-attribute {
    @media (min-width: 800px) {
        top: -4rem !important;
        left: 50% !important;
        transform: translate(-50%, 0) !important;
        margin: 0 !important;
        background: var(--c-white) !important;
        color: var(--c-black) !important;
    }
}

.grayed-out {
    background: var(--c-lighter-gray-transparent) !important;
    border-bottom: 1px solid var(--_c-gray-secondary) !important;
    color: rgba(0, 0, 0, 0.3) !important;
}

::v-deep .loader {
    border: 5px solid var(--_c-gray-secondary) !important;
    border-top: 6px solid #5a1f1f !important;
    opacity: 1 !important;
}
</style>