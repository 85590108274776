<template>
    <div id="reset-password" class="container mt-6 mb-6">
        <div class="row">
            <div class="col-12">
                <SfHeading
                    :title="$t('Reset Password')"
                    :level="2"
                    class="heading sf-heading--no-underline mb-3"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-lg-3 col-lg-6">
                <div v-if="!isPasswordChanged">
                    <ValidationObserver
                        v-slot="{ handleSubmit }"
                        key="log-in"
                    >
                        <form
                            class="form"
                            @submit.prevent="handleSubmit(setNewPassword)"
                        >
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required|email"
                            >
                                <SfInput
                                    v-model="form.email"
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    name="email"
                                    label="Your email"
                                    class="form__element"
                                />
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required|password"
                            >
                                <SfInput
                                    v-model="form.password"
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    :label="$t('Password')"
                                    name="password"
                                    type="password"
                                    class="form__element"
                                />
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                            >
                                <SfInput
                                    v-model="form.repeatPassword"
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    :label="$t('Repeat Password')"
                                    name="repeat-password"
                                    type="password"
                                    class="form__element"
                                />
                            </ValidationProvider>
                            <div v-if="passwordMatchError || forgotPasswordError.setNew">
                                {{ passwordMatchError || forgotPasswordError.setNew.message }}
                            </div>
                            <Button
                                type="submit"
                                class="button button-primary w-100"
                                :disabled="forgotPasswordLoading"
                            >

                                <div>{{ $t('Save Password') }}</div>
                            </Button>
                        </form>
                    </ValidationObserver>
                </div>
                <div class="text-center" v-else>
                    <p class="p-4">{{ $t('Password Changed') }}</p>

                    <nuxt-link class="button button-primary" :to="localePath('/login')">
                        {{ $t('Go to login page') }}
                    </nuxt-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
	SfInput,
	SfHeading,
} from '@storefront-ui/vue';
import {
	ref,
	computed,
	defineComponent,
} from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { forgotPasswordGetters } from '~/getters';
import { useForgotPassword } from '~/composables';
import { customerPasswordRegExp, invalidPasswordMsg } from '../helpers/customer/regex';
import Button from '~/components/Common/Button.vue';

extend('email', {
	...email,
	message: 'Invalid email',
});

extend('required', {
	...required,
	message: 'This field is required',
});

extend('password', {
	message: invalidPasswordMsg,
	validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
	name: 'ResetPassword',
	components: {
		Button,
		SfHeading,
		SfInput,
		ValidationProvider,
		ValidationObserver,
	},
	middleware({ redirect, route }) {
		if (!route.query.token) {
			return redirect('/');
		}
	},
	setup(props, context) {
		const {
			result,
			setNew,
			error: forgotPasswordError,
			loading: forgotPasswordLoading,
		} = useForgotPassword();

		const passwordMatchError = ref(false);
		const form = ref({});
		const isPasswordChanged = computed(() => forgotPasswordGetters.isPasswordChanged(result.value));
		const { token } = context.root.$route.query;

		const setNewPassword = async () => {
			passwordMatchError.value = false;

			if (form.value.password !== form.value.repeatPassword) {
				passwordMatchError.value = 'Passwords do not match';
				return;
			}

			await setNew({
				tokenValue: token,
				newPassword: form.value.password,
				email: form.value.email,
			});
		};

		return {
			isPasswordChanged,
			form,
			setNewPassword,
			forgotPasswordLoading,
			forgotPasswordError,
			passwordMatchError,
			token,
			result,
		};
	},
});
</script>

<style lang="scss" scoped>


    .form {
        margin-top: var(--spacer-sm);
        min-width: 350px;

        &__element {
            margin: 0 0 var(--spacer-xl) 0;
        }
    }

    .action {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
        font: var(--font-weight--light) var(--font-size--base) / 1.6 var(--font-family--secondary);

        & > * {
            margin: 0 0 0 var(--spacer-xs);
        }
    }

    .action {
        margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
    }

    .checkbox {
        margin-bottom: var(--spacer-2xl);
    }

    .bottom {
        text-align: center;
        margin-bottom: var(--spacer-lg);
        font-size: var(--h3-font-size);
        font-weight: var(--font-weight--semibold);
        font-family: var(--font-family--secondary);

        &__paragraph {
            color: var(--c-primary);
            margin: 0 0 var(--spacer-base) 0;

            @include for-desktop {
                margin: 0;
            }
        }
    }

    .button {
        font-size: 16px;
    }
</style>
