<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form
            id="shipping-details-form"
            class="form"
            @submit.prevent="handleSubmit(submitForm)"
        >
            <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="company"
                class="form__element"
            >
                <SfInput
                    v-model="form.company"
                    name="company"
                    :label="$t('Company')"
                    required
                    :valid="!errors[0]"
                    :error-message="$t(errors[0])"
                />
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="street"
                class="form__element"
            >
                <SfInput
                    v-model="form.street"
                    name="street"
                    :label="$t('Street Name')"
                    required
                    :valid="!errors[0]"
                    :error-message="$t(errors[0])"
                />
            </ValidationProvider>

            <div class="form__horizontal">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:2"
                  class="form__element"
                >
                    <SfInput
                        v-model="form.city"
                        name="city"
                        :label="$t('City')"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|min:4"
                    name="postcode"
                    class="form__element"
                >
                    <SfInput
                        v-model="form.postcode"
                        name="postcode"
                        :label="$t('Zip-code')"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                    />
                </ValidationProvider>
            </div>

            <div class="form__horizontal">
                <ValidationProvider
                    v-slot="{ errors }"
                    name="country_code"
                    rules="required|min:2"
                    slim
                >
                    <SfSelect
                        v-model="form.country_code"
                        :label="$t('Country')"
                        name="country"
                        class="form__element form__element--half form__element--half-even form__select sf-select--underlined"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="reloadCountry({ id: $event })"
                    >
                        <SfSelectOption
                            v-for="countryOption in countriesList"
                            :key="countryOption.id"
                            :value="countryOption.abbreviation"
                        >
                            {{ countryOption.label }}
                        </SfSelectOption>
                    </SfSelect>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="region"
                  :rules="!form.country_code || regionInformation.length === 0 ? null : 'required|min:2'"
                  class="form__element"
                >
                    <SfInput
                        v-if="!form.country_code || regionInformation.length === 0"
                        v-model="form.region.region"
                        :label="$t('State/Province')"
                        :disabled="!form.country_code"
                        name="state"
                        :valid="!!form.country_code"
                        :error-message="!form.country_code ? $t('Please select a country first') : ''"
                    />
                    <SfSelect
                        v-else
                        v-model="form.region.region_code"
                        :label="$t('State/Province')"
                        name="state"
                        class="form__element form__element--half form__element--half-even form__select sf-select--underlined"
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                    >
                        <SfSelectOption
                            v-for="regionOption in regionInformation"
                            :key="regionOption.id"
                            :value="regionOption.abbreviation"
                        >
                            {{ regionOption.label }}
                        </SfSelectOption>
                    </SfSelect>
                </ValidationProvider>
            </div>

            <ValidationProvider
                v-slot="{ errors }"
                rules="required|min:8"
                name="telephone"
                class="form__element"
            >
                <SfInput
                    v-model="form.telephone"
                    name="telephone"
                    :label="$t('Phone number')"
                    required
                    :valid="!errors[0]"
                    :error-message="$t(errors[0])"
                />
            </ValidationProvider>
            <SfCheckbox
                v-if="canEditBillingAddress"
                v-model="form.default_shipping"
                name="isDefaultShipping"
                :label="$t('Set as default delivery')"
                class="form__checkbox-isDefaultShipping"
            />
            <SfCheckbox
                v-if="canEditBillingAddress"
                v-model="form.default_billing"
                name="isDefaultBilling"
                :label="$t('Set as default billing')"
                class="form__checkbox-isDefaultBilling"
            />
            <Button
                class="button button-primary mt-4"
                type="submit"
            >
                {{ isNew ? $t('Add the address') : $t('Update the address') }}
            </Button>
        </form>
    </ValidationObserver>
</template>

<script type="module">
import {
	SfInput,
	SfSelect,
	SfCheckbox,
} from '@storefront-ui/vue';
import { required, min, oneOf } from 'vee-validate/dist/rules';
import {
	ValidationProvider,
	ValidationObserver,
	extend,
} from 'vee-validate';
import {
	reactive,
	computed,
	onBeforeMount,
	defineComponent,
	ref,
    useContext
} from '@nuxtjs/composition-api';
import omitDeep from 'omit-deep';
import { useCountrySearch } from '~/composables';
import { addressGetter } from '~/getters';
import Button from '~/components/Common/Button.vue';

extend('required', {
	...required,
	message: 'This field is required',
});

extend('min', {
	...min,
	message: 'The field should have at least {length} characters',
});

extend('oneOf', {
	...oneOf,
	message: 'Invalid country',
});

export default defineComponent({
	name: 'AddressForm',

	components: {
		SfInput,
		Button,
		SfSelect,
		SfCheckbox,
		ValidationProvider,
		ValidationObserver,
	},

	props: {
		address: {
			type: Object,
			default: () => ({
				id: undefined,
                company: '',
				city: '',
				country_code: '',
				firstname: '',
				lastname: '',
				postcode: '',
				region: {
					region_code: '',
					region_id: 0,
				},
				street: '',
				telephone: '',
				default_shipping: false,
				default_billing: false,
			}),
		},
		isNew: {
			type: Boolean,
			required: true,
		},
        user: {
            type: Object,
            required: true,
        },
	},

	setup(props, { emit }) {
		const {
			load: loadCountries,
			search: searchCountry,
		} = useCountrySearch();
        const { app } = useContext();

		const countries = ref([]);
		const country = ref(null);
        const userPermissions = app.$vsf.$magento.config.state.getPermissions();
        const canEditBillingAddress = computed(() => userPermissions.find(el => el.code == 'edit billing address'));

		const reloadCountry = async (params) => {
			country.value = await searchCountry(params);
		};

		const form = reactive({
            company: props.address.company ?? props.user?.company_name,
			city: props.address.city,
			country_code: props.address.country_code,
			firstname: 'Vizulo',
			lastname: 'Customer',
			postcode: props.address.postcode,
			region: {
				region_code: '',
				region_id: null,
				...props.address.region,
			},
			street: props.address.street,
			telephone: props.address.telephone,
			default_shipping: props.address.default_shipping || false,
			default_billing: props.address.default_billing || false,
			...(props.isNew ? {} : { id: props.address.id }),
		});

		// @ts-ignore
		const countriesList = computed(() => addressGetter.countriesList(countries.value));
		const regionInformation = computed(() => addressGetter.regionList(country.value));

		const submitForm = () => {
			const regionId = regionInformation.value.find((r) => r.abbreviation === form.region.region_code)?.id;
			if (regionId) {
				form.region.region_id = regionId;
			}

			emit('submit', {
				form: omitDeep(form, ['__typename']),
				// TODO: Handle Error
				onError: () => {},
			});
		};

		onBeforeMount(async () => {
			countries.value = await loadCountries();
			if (props.address.country_code) {
				country.value = await searchCountry({ id: props.address.country_code });
			}
		});

		return {
			form,
			submitForm,
			countriesList,
			regionInformation,
			reloadCountry,
            canEditBillingAddress,
		};
	},
});
</script>

<style lang='scss' scoped>

.form {
    &__element {
        display: block;
        margin-bottom: var(--spacer-base);
    }

    &__select {
        display: flex;
        align-items: center;
        --select-option-font-size: var(--font-size--lg);
        flex-wrap: wrap;

        ::v-deep .sf-select__dropdown {
            font-size: var(--font-size--lg);
            // margin: 0;
            font-family: var(--font-family--secondary);
            font-weight: var(--font-weight--normal);
        }
    }

    &__button {
        display: block;
        margin-top: var(--spacer-lg);
    }

    &__horizontal {
        @include for-desktop {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .form__element {
            @include for-desktop {
                flex: 1;
                margin-right: var(--spacer-lg);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>
