<template>
	<SfSidebar
		:button="false"
		:visible="isNavigationSidebarOpen"
		class="navigation-sidebar sf-sidebar--left"
		@close="toggleNavigationSidebar"
		:persistent="isMobile"
	>
		<nuxt-link
			:to="localePath('/')"
		>
			<svg-image
				icon="full_logo"
				:label="$t('Vizulo')"
				width="200"
				height="50"
			/>
		</nuxt-link>

		<div class="row content">
			<div class="col-12 col-md-5">
				<SfList class="navigation-sidebar__list left">
					<SfMenuItem
						v-for="(link, name) in sitemap"
						:key="name"
						:label="$t(name)"
						:link="link"
						class="mobile-menu-sidebar__item"
						@click.native="toggleNavigationSidebar"
					/>
				</SfList>
			</div>
			<div class="col-12 col-md-7">
				<h5>{{ $t('Products') }}</h5>
				<SfList class="navigation-sidebar__list right">
					<SfMenuItem
						v-for="(category, index) in categories"
						:key="index"
						:label="category.label"
						:link="localePath(getAgnosticCatLink(category))"
						class="mobile-menu-sidebar__item"
						@click.native="toggleNavigationSidebar"
					/>
					<SfMenuItem
						:label="$t('Product configurator')"
						:link="localePath('/product-configurator')"
						class="mobile-menu-sidebar__item"
						@click.native="toggleNavigationSidebar"
					/>
				</SfList>
			</div>
		</div>
		<div class="store-switcher">
			<div
				class="container__lang"
				@click="toggleNavigationStoreSwitcher"
			>
				<span>{{ storeConfig.store_code }}</span>
				<svg-image
					icon="chevron_down"
					width="12"
					height="12"
				/>
			</div>
		</div>

		<div class="row subscribe">
			<NewsLetterForm/>
		</div>
		<div class="row d-flex terms-and-policy">
			<div class="col-12">
				<CmsBlock :identifiers='new Array("terms_and_policy")'/>
			</div>
		</div>
	</SfSidebar>
</template>
<script>
import { SfList, SfMenuItem } from '@storefront-ui/vue';
import SfSidebar from '~/components/LeftNavigation/SfSidebar';
import { defineComponent, useAsync, computed, onMounted } from '@nuxtjs/composition-api';
import { categoryGetters } from '~/getters';
import { useUiHelpers, useUiState, useConfig } from '~/composables';
import CmsBlock from '~/components/ContentBlocks';
import NewsLetterForm from '~/components/Common/NewsLetterForm.vue';
import LeftNavigation from '~/components/LeftNavigation.vue';
import { useCustomerStore } from '~/stores/customer';
import isEmpty from 'lodash.isempty';
import { storeToRefs } from 'pinia';
import useCategory from '~/modules/catalog/category/components/category/useCategory';

export default defineComponent({
	name: 'LeftNavigationSidebar',
	components: {
		LeftNavigation,
		SfSidebar,
		SfList,
		SfMenuItem,
		CmsBlock,
		NewsLetterForm,
	},
	setup() {
		const { categoryTree, loadCategoryTree } = useCategory();

		const customer = useCustomerStore();
		const { user } = storeToRefs(customer);

		const {
			isNavigationSidebarOpen,
			toggleNavigationSidebar,
			isMobile,
			toggleNavigationStoreSwitcher,

		} = useUiState();

		const { getAgnosticCatLink } = useUiHelpers();
		const { config } = useConfig();

		onMounted(async () => {
			if (!categoryTree.value) {
				await loadCategoryTree();
			}
		});

		const categories = computed(() => {
			return categoryGetters
				.getCategoryTree(categoryTree.value)?.items
				.filter(c => !isEmpty(user.value) ? c.id != 74 : c.id != 68 && c.id != 74);
		});

		return {
			storeConfig: config,
			categories,
			isNavigationSidebarOpen,
			toggleNavigationSidebar,
			getAgnosticCatLink,
			isMobile,
			toggleNavigationStoreSwitcher
		};
	},

	data(app) {
		return {
			sitemap: {
				'References': app.localePath('references'),
				'About us': app.localePath('about-us'),
				'Contacts': app.localePath('contacts'),
                'News': app.localePath('news'),
				'Academy': app.localePath('academy'),
				'Members': app.localePath('members'),
                'Vacancies': app.localePath('vacancies'),
				'ESCO': app.localePath('esco'),
			},
		}
	}
});
</script>

<style lang="scss" scoped>
::v-deep .svg-image {
	color: var(--c-white);
	opacity: .2;

	&.checkbox {
		opacity: 1;
	}

	@media (max-width: 991px) {
		display: none;
	}
}

::v-deep .sf-sidebar {
	&__aside {
		width: 45vw;
		max-width: 840px;

		@media (max-width: 1400px) {
			width: 55vw;
		}


		@media (max-width: 991px) {
			width: 100vw;
			max-width: 100%;
			left: 0;
			top: 70px;
			height: unset;
		}
	}

	&__content {
		padding-left: var(--sidebar-left);
		padding-top: 55px;

		@media (max-width: 1400px) {
			padding-left: 25px;
			padding-top: 0;
		}

		h2, h5 {
			color: var(--c-white);
		}

		h5 {
			text-transform: uppercase;
			cursor: default;
			margin-top: 0;
			padding-left: 25px;

			@media (max-width: 991px) {
				padding-left: 0;
				margin-top: 25px;
			}
		}
	}
}

::v-deep .sf-menu-item:hover {
	--menu-item-label-color: var(--_c-gray-primary);
}

.store-switcher {
	cursor: pointer;
	color: white;
	text-transform: capitalize;
	font-family: var(--font-family--primary);
	text-transform: uppercase;
	margin: 8px 0;
	display: none;

	@media (max-width: 991px) {
		display: block;
	}

	.svg-image {
		display: inline-block;
		fill: var(--c-white);
		opacity: 1;
	}
}

.content {
	margin-top: 35px;

	@media (max-width: 991px) {
		margin-top: 20px;
	}
}

::v-deep .subscribe {
	display: flex;
	flex-direction: column;
	width: 480px;
	font-size: 20px;
	line-height: 28px;
	margin: 35px 0 32px;
	color: var(--c-white);
	padding-left: 0;

	@media (max-width: 1400px) {
		margin: 15px 0 15px;
		width: 100%;
	}

	.flex {
		display: block;

		h3 {
			font-size: 20px;
		}

		p {
			margin-bottom: 32px;
			font-size: 18px;

			@media (max-width: 1400px) {
				font-size: 14px;
				line-height: 20px;
				margin-top: 0;
			}
		}
	}

	.subscribe-form {
		border-bottom: 1px solid var(--c-white);

		input {
			color: var(--c-white) !important;

			&::placeholder {
				color: var(--c-white);
				opacity: 0.5;
			}

			&:focus {
				outline: none;
			}

			@media (prefers-color-scheme: dark) {
				color: var(--c-white) !important;
			}
		}

		button {
			color: var(--c-white);

			&:hover {
				cursor: pointer;
			}
		}
	}

	span {
		display: none;
	}

	.subscribe-thanks {
		display: none;
	}
}

::v-deep .terms-and-policy {
	.sf-heading {
		display: none;
	}

	a {
		color: var(--c-white);
		opacity: .5;

		@media (max-width: 991px) {
			opacity: 1;
		}
	}
}

.navigation {
	&-sidebar__list {
		&.left {
			border-right: 1px solid var(--c-white);

			@media (max-width: 991px) {
				border-bottom: 1px solid var(--c-white);
				border-right: none;
			}
		}

		&.right {
			padding-left: 25px;

			@media (max-width: 991px) {
				padding-left: 0;
			}
		}

		.mobile-menu-sidebar__item {
			font-size: 28px;
			line-height: 38px;
			margin-bottom: 18px;

			@media (max-width: 1400px) {
				font-size: 18px;
				line-height: 28px;
				margin-bottom: 14px;
			}

			@media (max-width: 991px) {
				font-size: 22px;
				line-height: 22px;
				margin-bottom: 18px;
			}

			@media (max-width: 448px) {
				font-size: 20px;
				line-height: 20px;
				margin-bottom: 15px;
			}
		}
	}
}
</style>
