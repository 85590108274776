<template>
    <div>
        <div class="about padding-top-3">
            <div class="container">
                <div>
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <div class="about_text">
                                <h1>{{ $t('Vizulo values') }}</h1>
                                <p>{{ vizuloValues }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <CataloguePdf/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="video explore">
            <Video
                ref="vimeo"
                id="home-video"
                :params="{ background: 1, allowfullscreen: 0, autoplay: 0 }"
                src="https://player.vimeo.com/video/488030994"
            />
        </div>
        <div class="about">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <div class="about_text">
                            <h3>{{ $t('About Vizulo') }}</h3>
                            <p>{{ aboutVizulo }}</p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="about_stats">
                            <div class="number">{{ totalCountries }}</div>
                            <div class="link">
                                <h6>{{ $t('Countries') }}</h6>
                                <Link
                                    :icon="true"
                                    :link="localePath('references')"
                                    :text="$t('Go to References')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="video explore">
            <Video
                ref="vimeo"
                id="home-video"
                :params="{background: 1, allowfullscreen: 0, autoplay: 0}"
                src="https://player.vimeo.com/video/519063384"
            />
        </div>
        <div class="our-management">
            <div class="container">
                <h1>{{ $t('Our management') }}</h1>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="references_item">
                            <div class="image">
                                <img src="~/static/media/images/JanisZ_aboutUs.jpg" alt="">
                            </div>
                            <div class="citations">
                                <Cite :text="$t(janisText)"/>
                            </div>
                            <div class="signature">
                                <span>—</span>
                                <strong>Jānis Zeltiņš</strong> Chief Executive Officer
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                       <div class="references_item">
                            <div class="image">
                                <img src="~/static/media/images/LindaZ_aboutUs.jpg" alt="">
                            </div>
                            <div class="citations">
                                <Cite :text="$t(lindaText)"/>
                            </div>
                            <div class="signature">
                                <span>—</span>
                                <strong>Linda Zeltiņa</strong> R&amp;D director
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="awards">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h4>{{ $t('Certificates and Awards') }}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="awards-logos">
                            <ul>
                                <li
                                    v-for="(label, index) in labels"
                                    :key="index"
                                >
                                    <img :src="label.image" :alt="label.title" :title="label.title">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="module">
import {
	ref,
	defineComponent,
	useFetch,
	computed,
} from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import Video from '~/components/Common/Video.vue';
import Cite from '~/components/Common/Cite.vue';
import { useVideos } from '~/modules/vizulo/videos'
import { storeConfigGetters } from '~/getters';
import Link from '~/components/Common/Link.vue';
import CataloguePdf from '~/components/Common/CataloguePdf.vue';
import { useLabels } from '~/modules/vizulo/labels';

export default defineComponent({
	components: {
		Cite,
		Video,
		Link,
		useVideos,
		CataloguePdf,
	},
	data() {
		return {
			lindaText: 'When we first started – as a team of 10 people in 2013 – we decided that the core values of Vizulo would be flexibility, passion to win, speed, quality, design, and innovation. Standing by these values allowed us to grow, realizing largest street lighting modernization projects in Northern Europe.',
			janisText: 'In the beginning, I thought Vizulo would be delivering lighting solutions to ensure the highest energy efficiency for our clients. Nowadays, we use different types of sensors and smart lighting management systems to distribute light where and when it\'s needed. So, in a curious way - these solutions are often more about darkness than light!',
		}
	},
	filters: {
		strippedContent: function(string) {
			return string.replace(/<\/?[^>]+>/ig, ' ');
		}
	},
    head() {
        const title = 'About us - Vizulo';
        const meta = [];

        return {
            title,
            meta,
        };
    },
	setup() {
		const { getVideos } = useVideos();
		const { config } = useConfig();
		const { getLabels, labels } = useLabels();

		useFetch(async () => {
			await getLabels({ 
                filter: {
                    type: {
                        in: ['Certification', 'Awards']
                    }
                }
            });
		});

		const vizuloValues = computed(
			() => storeConfigGetters.getVizuloValues(config.value) || '',
		);

		const aboutVizulo = computed(
			() => storeConfigGetters.getAboutVizulo(config.value) || '',
		);

		const totalCountries = computed(
			() => storeConfigGetters.getTotalCountries(config.value) || '',
		);

		return {
			vizuloValues,
			aboutVizulo,
			totalCountries,
			labels
		};
	},
});

</script>

<style lang="scss" scoped>
.awards-logos {
    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: left;
        padding: 32px 0;
        margin: 0 0 32px;

        @media (max-width: 1200px) {
            padding: 16px 0;
            margin: 0 0 16px;
        }

        li {
            text-align: center;
            vertical-align: middle;
            width: 12%;
            margin-bottom: 34px;

            &::marker{
                color: white;
            }

            @media (max-width: 1400px) {
                width: 19%;
            }

            @media (max-width: 1200px) {
                width: 33%;
                padding: 25px;
                margin-bottom: 0;
            }
        }
    }

    img {
        max-width: 80px;
        max-height: 56px;
    }
}

img {
    width: 100%;
}

a {
    text-decoration: underline;
}

.explore {
    margin-bottom: 120px;

    @media (max-width:992px) {
        margin-bottom: 72px;
    }

    & > div {
        margin-top: 120px;

        @media (max-width:992px) {
            margin-top: 72px;
        }
    }
}

.signature {
    padding-top: 12px;
    text-align: right;
}

.read-more {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 120px;

    @media (max-width:992px) {
        margin-bottom: 35px;
        font-size: 16px;
        line-height: 22px;
    }
}

.fixed-bottom {
    position: absolute;
    bottom: 75px;
    z-index: 10;
}

.about {
    margin-bottom: 120px;

    @media (max-width:992px) {
        margin-bottom: 35px;
    }

    .about_text {
        p {
            font-size: 24px;

            @media (max-width:992px) {
                font-size: 18px;
            }

        }
         h1 {
            margin-top: 0;
        }
    }

    &_image {
        position: relative;
    }

    &_text {
        @media (min-width:992px) {
            padding-left: 30px;
        }

        h5 {
            font-size: 20px;
            letter-spacing: 0.25px;
            line-height: 28px;
            margin: 0 0 28px;

            @media (max-width:992px) {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.2px;
                margin: 0 0 20px;
            }
        }

        h3 {
            font-size: 32px;
            letter-spacing: 0.25px;
            line-height: 28px;
            margin: 0 0 28px;

            @media (max-width:992px) {
                font-size: 22px;
                line-height: 24px;
                letter-spacing: 0.2px;
                margin: 0 0 20px;
            }
        }

        p {
            font-size: 20px;
            line-height: 28px;
            margin: 0 0 28px;

            @media (max-width:992px) {
                font-size: 18px;
                line-height: 25px;
            }
        }
    }

    &_stats {
        overflow: hidden;

        @media (min-width:992px) {
            padding-left: 30px;
        }

        @media (max-width:992px) {
            padding-bottom: 36px;
        }

        .number {
            float: left;
            font-size: 60px;
            font-weight: 700;
            line-height: 68px;
            margin-right: 32px;

            @media (max-width:992px) {
                font-size: 32px;
                line-height: 40px;
            }
        }

        .link {
            float: left;
            padding-top: 20px;

            @media (max-width:992px) {
                padding-top: 7px;
            }

            h6 {
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.2px;
                line-height: 18px;
                margin: 0 0 6px;

                @media (max-width:992px) {
                    font-size: 14px;
                    letter-spacing: 0.18px;
                }
            }
        }
    }
}

.padding-top-3 {
    padding-top: 84px;
}

.cite-text {
    position: relative;
    font-size: 24px;
    font-weight: 700;
    font-family: Segoe UI Bold;
    position: relative;
    padding: 16px 0 0 62px;
    line-height: 25px;
    margin-top: 16px;
    letter-spacing: 0.18px;

    @media (max-width:992px) {
        font-size: 18px;
        padding: 40px 0 0;
    }

    .citations {
        position: absolute;
        top: 0;
        left: 0;
        width: 34px;
        height: 28px;
    }
}

.references_item {
    margin-bottom: 120px;
    @media (max-width:992px) {
        margin-bottom: 72px;
    }
}
</style>
