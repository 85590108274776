<template>
    <sf-loader :loading="$fetchState.pending || loading || loadingAssortment">
        <div class="row" v-if="items && items.length">
            <a name="assortment"></a>
            <div class="col-12">
                <h3 class="assortment-title border-top pt-6">{{ $t('Best selling articles') }}</h3>
                <p>{{ $t('Other options available on request') }}</p>
                <button
                    class="reset active"
                    @click="resetFilters">
                    {{ $t('Reset') }}
                </button>
            </div>
            <div class="col-12">
                <masonry-wall
                    :items="wall"
                    :column-width="config.columnWidth"
                    :gap="config.gap"
                    :rtl="config.rtl"
                    :ssr-columns="config.ssrColumns"
                >
                    <template #default="{ item, index }">
                        <div class="configurable-option">
                            <div>{{ item.label }} <span v-if="item.abbreviation">({{ item.abbreviation }})</span>
                                <div
                                    v-if="item.description"
                                    class="table-tooltip flex"
                                    :class="{ active: item.attribute_code == selectedTooltip }"
                                    @click="showTooltip(item.attribute_code)"
                                >
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <div class="tooltiptext"><span v-html="item.description"></span></div>
                                </div>
                                <nuxt-link class="pt-2" v-if="item.attribute_code == 'lens'"
                                           :to="localePath('/products/optics')">{{ $t('Explore all optics') }}
                                </nuxt-link>
                            </div>
                            <template v-if="item.attribute_code == 'lens' || item.attribute_code == 'led_module'">
                                <div v-bar class="image-filter filter-options" :data-code="item.attribute_code">
                                    <div>
                                        <div
                                            @click="changeFilter(item, attribute)"
                                            class="option"
                                            :class="{ active: attribute.is_active }"
                                            :key="attribute.uid"
                                            v-if="attribute.url"
                                            v-for="attribute in item.values"
                                        >
                                            <span class="check"></span>
                                            <span>{{ attribute.label }}</span>
                                            <span class="description" v-if="attribute.description">{{ attribute.description }}</span>
                                            <nuxt-img
                                                v-if="attribute.url != null"
                                                v-on:click.stop
                                                loading="lazy"
                                                :src="attribute.url"
                                                quality="100"
                                                width="75"
                                                height="42"
                                                @click="openImageModal(attribute.url)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template
                                v-else-if="isRangeSlider.includes(item.attribute_code)">
                                <MultiRangeSlider
                                    :code="item"
                                    :min="item.min"
                                    :max="item.max"
                                    :step="item.step"
                                    :ruler="true"
                                    :label="true"
                                    :minValue="item.min"
                                    :maxValue="item.max"
                                    @input="updateValues"
                                />
                            </template>
                            <template v-else>
                                <ul class="filter-options" :data-code="item.attribute_code">
                                    <li
                                        class="option"
                                        @click="changeFilter(item, attribute)"
                                        :class="{ active: attribute.is_active }"
                                        :key="attribute.uid"
                                        v-if="attribute.label.length"
                                        v-for="attribute in item.values"
                                    >
                                        <span>{{ attribute.label }}</span>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </template>
                </masonry-wall>
            </div>
            <vue-good-table
                ref="sortment"
                :columns="columnsData"
                :rows="items"
                compactMode
                :pagination-options="config.paginationOptions"
                :search-options="config.searchOptions"
                @on-cell-click="onCellClick"
            >
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ props.column.label }}
                        <svg-image
                            class="arrow"
                            height="14"
                            icon="arrow-right-short"
                            width="14"
                        />
                        <strong v-if="props.column.abbreviation">{{ props.column.abbreviation }}</strong>
                    </span>
                </template>

                <template slot="table-row" slot-scope="props">
                    <span
                        v-if="props.column.html"
                        v-html="props.formattedRow[props.column.field]"
                    >
                    </span>
                    <span
                        v-else-if="props.formattedRow[props.column.field] == 'None'"
                        v-html="'<i>' + props.formattedRow[props.column.field] + '</i>'"
                    >
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>

            <DatasheetDownloadModal
                :visible="datasheetModal"
                :params="datasheetForm"
                @close="datasheetModal = !datasheetModal"
            />
            <SfModal
                :visible="imageModal"
                class="modal"
                @close="openImageModal(0)"
            >
                <div id="image-modal" class="col-12 popup-gallery__head">
                    <div class="container" v-html="imageModalImage">
                    </div>
                </div>
            </SfModal>
            <AddToCartModal
                v-if="canAddToCart"
                :key="addToCartForm.model_name"
                :visible="addToCartModal"
                :options="addToCartForm"
                @close="openAddToCartModal(0)"
            />
        </div>
    </sf-loader>
</template>

<script>
import { defineComponent, useContext, reactive, ref, useFetch } from '@nuxtjs/composition-api';
import MultiRangeSlider from '~/components/Common/MultiRangeSlider.vue';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import { useVizulo, useAssortment, useCart, useUser } from '~/composables';
import Button from '~/components/Common/Button.vue';
import { SfInput, SfModal, SfLoader } from '@storefront-ui/vue';
import { ValidationObserver } from 'vee-validate';
import HTMLContent from '~/components/HTMLContent';
import DatasheetDownloadModal from '~/components/Datasheet/DatasheetDownloadModal';
import AddToCartModal from '~/components/Common/AddToCartModal';
import { event } from 'vue-gtag';
import debounce from 'lodash.debounce';
import { search, pagination } from '~/helpers/table';

export default defineComponent({
	name: 'PriceListTable',
	components: {
		DatasheetDownloadModal,
		AddToCartModal,
		MultiRangeSlider,
		VueGoodTable,
		Button,
		SfInput,
		SfModal,
		ValidationObserver,
		HTMLContent,
		SfLoader,
	},
	props: {
		product: {
			type: Object,
			required: true,
			default: null,
		},
		loading: {
			type: Boolean,
			required: false,
			default: null,
		},
	},
	methods: {
		resetFilters() {
			this.wall.forEach((option) => {
				option.activeFilters = [];
				option.values = option.values.map(v => ({ ...v, is_active: false }));
				option.filterOptions.filterValue = '';
			});

			this.$refs.sortment.reset();
			this.activeFilter = false;
		},
		updateValues: debounce(function (e) {
			let found = this.columnsData.find(c => {
				return c.attribute_code == e.code.attribute_code;
			});

			let values = e.code.values.map(item => item.label).filter(function (value) {
				return value >= e.minValue && value <= e.maxValue
			});

			const min = Math.min(...values);
			const max = Math.max(...values);

			if (min == found.min && max == found.max) {
				found.filterOptions.filterValue = [];
			} else {
				found.filterOptions.filterValue = [min, max];
			}

			this.$refs.sortment.$refs['table-header-primary'].$refs['filter-row'].updateFilters(found, found.filterOptions.filterValue);
		}, 500),
		changeFilter(option, attribute) {
			this.wall.forEach((opt) => {
				opt.values = opt.values.map(v => ({
					...v,
					is_active: (v.uid == attribute.uid) ? !v.is_active : v.is_active
				}))
			});

			let found = this.columnsData.find(c => {
				return c.attribute_code == option.attribute_code;
			});

			found.filterOptions.activeFilters.push(attribute.label);

			if (attribute.is_active) {
				const index = found.filterOptions.activeFilters.indexOf(attribute.label);

				if (index !== -1) {
					found.filterOptions.activeFilters = found.filterOptions.activeFilters.filter(e => e != attribute.label);
				}
			}

			found.filterOptions.filterValue = found.filterOptions.activeFilters;

			this.$refs.sortment.$refs['table-header-primary'].$refs['filter-row'].updateFiltersOnKeyup(found, found.filterOptions.activeFilters);
		},
		onCellClick(params) {
			if (params.column.attribute_code == 'datasheet') {
				this.datasheetForm.article = params.row.c.replace(/\s/g, '-');
				this.datasheetForm.model_name = params.row.b;
                this.datasheetModal = true;
			}

			if (params.column.attribute_code == 'ldt_file' || params.column.attribute_code == 'ies_file') {
				this.downloadLdtFile(params.event, params.row.c, params.row.b, params.column.attribute_code.replace('_file', ''));
			}

			if (params.column.attribute_code == 'lens') {
				this.imageModalImage = params.row['0'];
				this.openImageModal(0);
			}

			if (params.column.attribute_code == 'cart') {
				this.addToCartForm.price = [{
					price: 1,
					quantity: 1
				}];

				this.addToCartForm.article = params.row.c;
				this.addToCartForm.model_name = params.row.b;

				this.openAddToCartModal();
			}
		},
		downloadLdtFile(clickEvent, article, model_number, type) {
			const data = {
				type: type,
				source: 'A',
				article: article,
				model_number: model_number,
				power_step: '1',
				offset: '0',
			};

			event('assortment_' + type + '_file', {
				event_label: 'Download ' + type + ' file',
				value: article,
			});

			axios({
				url: this.apiUrl + 'getPhotometricFile',
				method: 'POST',
				data: data,
				headers: {
					'Content-Type': 'application/json',
				},
				responseType: 'arraybuffer',
			}).then((res) => {
				if (res.status == 200 && res.data.byteLength) {
					let blob = new Blob([res.data], { type: res.headers['content-type'] });
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);

					const reader = new FileReader();

					reader.onload = (e) => {
						if (reader.result.length > 0) {
							let text = reader.result.split('\r\n');

							if (type == 'ies') {
								link.download = text[5].replace('[FILENAME] ', '') + '.' + type;
							} else {
								link.download = text[10] + '.' + type;
							}

							link.click();
						}
					};

					reader.readAsText(blob);
				} else {
					clickEvent.srcElement.title = 'Not found...';
					clickEvent.srcElement.classList.add('loading');
				}
			});
		},
	},
	setup(props, { refs }) {
		const { app } = useContext();
		const { getApiUrl } = useVizulo();
		const apiUrl = getApiUrl();
		const { getAssortment, loading: loadingAssortment } = useAssortment();
		const { canAddToCart } = useCart();
		const { isAuthenticated } = useUser();

		const activeFilter = ref(true);
		const selectedTooltip = ref(false);
		const sortment = reactive({});
		const datasheetModal = ref(false);
		const datasheetForm = ref({});
		const imageModal = ref(false);
		const imageModalImage = ref('');
		const hasLens = ref([]);
		const addToCartForm = ref({});
		const addToCartModal = ref(false);

		const items = ref([]);
		const columnsData = ref([]);
		const wall = ref([]);

		const isRangeSlider = ['power', 'luminous_flux', 'efficacy'];

		useFetch(async () => {
			const assortment = await getAssortment({
				filter: {
					abbreviation: {
						eq: props.product.abbreviation
					},
				}
			});

			items.value = assortment.data?.length ?  Object.values(assortment.data) : [];
			columnsData.value = assortment.filtered_columns?.length ? Object.values(assortment.filtered_columns) : [];

			if (canAddToCart.value) {
				columnsData.value.push({
					label: app.i18n.t('Basket'),
					abbreviation: '',
					attribute_code: 'cart',
					filterOptions: {
						activeFilters: []
					},
					field: 'zz',
					sortable: false,
					html: true,
					tdClass: 'text-center',
					values: []
				});
			}

			let collator = new Intl.Collator([], { numeric: true });

			for (let key in columnsData.value) {
				const attribute_code = columnsData.value[key].attribute_code;

				if (attribute_code == 'ldt_file' || attribute_code == 'ies_file' || attribute_code == 'datasheet' || attribute_code == 'model') {
					columnsData.value[key].sortable = false;
					columnsData.value[key].html = true;
				}

				if (attribute_code == 'ldt_file' || attribute_code == 'ies_file' || attribute_code == 'datasheet') {
					columnsData.value[key].width = '80px';
					columnsData.value[key].tdClass = 'text-center';
				}

				if (attribute_code == 'datasheet') {
					columnsData.value[key].label = app.i18n.t('Datasheet');
				}

				if (attribute_code == 'ldt_file') {
					columnsData.value[key].label = app.i18n.t('LDT file');
				}

				if (attribute_code == 'ies_file') {
					columnsData.value[key].label = app.i18n.t('IES file');
				}

				if (attribute_code == 'dimming') {
					columnsData.value[key].width = '140px';
					columnsData.value[key].tdClass = 'white-space-normal';
				}

				let dropdownItems = columnsData.value[key].values;
				dropdownItems = dropdownItems.sort((a, b) => collator.compare(a.label, b.label));

				if (attribute_code == 'led_module') {
					columnsData.value[key].hidden = true;
				}

				if (attribute_code == 'lens') {
					hasLens.value = dropdownItems;
					columnsData.value[key].field = '0';
					columnsData.value[key].html = true;
					columnsData.value[key].sortable = false;
					columnsData.value[key].width = '80px';
				}

				if (isRangeSlider.includes(attribute_code)) {
					dropdownItems = columnsData.value[key].values
						.map(item => item.label)
						.sort(function (a, b) {
							return a - b;
						});

					columnsData.value[key].type = 'number';
				}

				columnsData.value[key].filterOptions = {
					enabled: true,
					customFilter: true,
					filterValue: '',
					activeFilters: [],
					filterMultiselectDropdownItems: dropdownItems,
					filterFn: function (rowVal, filter) {
						if (isRangeSlider.includes(attribute_code)) {
							return rowVal >= filter[0] && rowVal <= filter[1];
						}

						if (Array.isArray(filter) && filter.length) {
							let ifIncludes = false;

							if (attribute_code == 'lens' || attribute_code == 'led_module') {
								filter.forEach(i => { if (rowVal.includes('"' + i + '"')) ifIncludes = true; });
							} else {
								filter.forEach(i => { if (rowVal == i) ifIncludes = true; });
							}

							return ifIncludes;
						} else if (Array.isArray(filter)) {
							return true;
						} else {
							return rowVal === filter;
						}
					}
				}
			}

			wall.value = columnsData.value.filter(column => column.values.length > 1)
				.sort((a, b) => a.field.toString().localeCompare(b.field, undefined, { numeric: false }));

			items.value.forEach((row) => {
				row['za'] = '<span class="download-ico"></span>';
				row['zb'] = '<span class="download-ico"></span>';
				row['zc'] = '<span class="download-ico"></span>';

				if (canAddToCart.value) {
				    row['zz'] = '<span class="cart-ico"></span>';
				}

				if (hasLens.value.length) {
					let found = hasLens.value.find(c => {
						return c.label == row['a'];
					});

					row['0'] = '<img alt="' + row['a'] + '" title="' + row['a'] + '" src="' + found.url + '">';
				}

				row['1'] = '<span>' + row['b'] + '</span>' + row['c'];

				return row;
			});
		});

		const openImageModal = (url) => {
			if (url) {
				imageModalImage.value = '<img alt="image" src="' + url + '">'
			}

			imageModal.value = !imageModal.value;
		}

		const showTooltip = (attribute_code) => {
			selectedTooltip.value = selectedTooltip.value == attribute_code ? false : attribute_code;
		}

		const openAddToCartModal = () => {
			addToCartModal.value = !addToCartModal.value;
		}

		const config = ref({
			columnWidth: 360,
			gap: 15,
			rtl: false,
			ssrColumns: 1,
			paginationOptions: pagination(5),
			searchOptions: {
				enabled: true,
				placeholder: app.i18n.t('Search...'),
				skipDiacritics: true,
				searchFn: search
			},
		});

		return {
			config,
			apiUrl,
			sortment,
			columnsData,
			wall,
			activeFilter,
			selectedTooltip,
			datasheetModal,
			datasheetForm,
			imageModalImage,
			imageModal,
			items,
			loadingAssortment,
			openImageModal,
			showTooltip,
			isAuthenticated,
			isRangeSlider,
			addToCartForm,
			addToCartModal,
			openAddToCartModal,
			canAddToCart
		}
	}
})
</script>

<style lang="scss" scoped>
.assortment-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    & + p {
        margin: 0 0 35px 0;
        font-size: 14px;
    }
}

#assortment {
    .sf-loader__overlay {
        margin: 0 auto;
    }
}

.reset {
    float: right;
    border: none;
    background: none;
    display: none;
    position: absolute;
    right: 0;
    top: 72px;

    &.active {
        display: block;
    }
}

::v-deep .vgt-input {
    @media (min-width: 991px) {
        width: 480px;
        max-width: 480px;
    }
}

::v-deep .vgt-wrap {
    width: 100%;
    padding: 0 15px;
}

::v-deep .vgt-responsive {
    @media (max-width: 991px) {
        padding: 0 15px;
    }
}

::v-deep table.vgt-table {
    border: none;
    width: 100% !important;
    border-collapse: separate;
    border-spacing: 0;

    thead {
        th {
            background: none;
            border: none;
            padding: 15px 15px 20px 0;
            vertical-align: top;

            span {
                float: left;
                font-size: 12px;
                line-height: 12px;
                font-family: var(--font-family--primary-bold);
                position: relative;

                .arrow {
                    margin-left: -15px;
                    padding-left: 0;
                }

                strong {
                    position: absolute;
                    left: 0;
                    bottom: -16px;
                    color: #737373;
                    font-family: var(--font-family--primary);
                    font-weight: normal;
                    padding-left: 15px;
                }
            }

            &.sortable {
                span {
                    span {
                        left: 14px;
                        top: 15px;
                        position: absolute;
                        transform: rotate(90deg);
                    }
                }

                &.sorting-desc {
                    span {
                        span {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            &:not(.sortable) {
                span {
                    span {
                        display: none;
                    }
                }
            }

            &.sortable::before,
            &.sortable::after {
                display: none;
            }

            .svg-image {
                float: none;
                margin-left: 8px;
            }
        }

        tr:nth-child(2) {
            display: none;
        }

        @media (max-width: 991px) {
            display: none;
        }
    }

    tbody {
        tr {
            &:first-child {
                td {
                    border-top: 1px solid #EAEAEA;

                    @media (max-width: 991px) {
                        border-top: none;
                    }
                }
            }

            @media (max-width: 991px) {
                border: 1px solid var(--c-black);
                padding: 10px;
            }

            td {
                @media (max-width: 991px) {
                    display: block;
                    padding: 8px 0;

                    &:before {
                        padding-left: 0;
                    }

                    &:last-child {
                        @media (max-width: 991px) {
                            border-bottom: none;
                        }
                    }
                }

                i {
                    color: var(--c-light-gray);
                }

                img {
                    cursor: pointer;

                    @media (max-width: 991px) {
                        display: block;
                        margin: 35px auto 15px auto;
                        max-width: 350px;
                    }
                }
            }
        }
    }

    td {
        border: none;
        border-bottom: 1px solid #EAEAEA;
        padding: 15px 15px 15px 0;
        font-size: 14px;

        @media (min-width: 991px) {
            white-space: nowrap;

            &.white-space-normal {
                white-space: normal;
            }
        }

        span {
            span {
                text-decoration: underline;
                display: block;

                &.download-ico {
                    display: inline-block;
                }
            }
        }
    }
}

::v-deep .vgt-global-search {
    background: none;
    border: none;
    text-align: center;

    @media (max-width: 991px) {
        padding: 0 15px;
    }
}

::v-deep .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
}

::v-deep .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out,
    margin 100ms ease-out,
    height 100ms ease-out;
    transition: background-color 100ms ease-out,
    margin 100ms ease-out,
    height 100ms ease-out;
    background-color: rgba(96, 98, 102, 0.3);
    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

::v-deep .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
}

::v-deep .vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
    margin: 0px;
    height: 100%;
}

::v-deep .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
    margin: 0px;
    height: 100%;
}

::v-deep .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
}

::v-deep .vgt-wrap__footer {
    background: none;
    border: none;
    text-align: center;

    .footer__navigation {
        float: none !important;

        .chevron {
            display: none;
        }
    }

    .footer__navigation__page-info__current-entry {
        margin: 0 4px;
    }
}

::v-deep .vgt-inner-wrap {
    border: none;
    box-shadow: none;
}

::v-deep .vgt-wrap__footer {
    .footer__row-count {
        display: none;
    }

    .footer__row-count__label {
        font-size: 14px;
    }

    .footer__row-count__select {
        font-size: 14px;
    }

    .footer__navigation__page-info {
        font-size: 14px;
        margin: 0 20px;
    }

    .footer__navigation__page-btn span {
        font-size: 14px;
    }

    .footer__navigation > button {
        margin: 0;
        padding: 10px;
    }

    .footer__navigation__page-btn .chevron.right::after {
        border-left: 6px solid var(--c-black);
    }

    .footer__navigation__page-btn .chevron.left::after {
        border-right: 6px solid var(--c-black);
    }
}

::v-deep .download-ico {
    &.loading {
        opacity: 0.3;
    }
}

::v-deep .sf-bar {
    background: white;
}

::v-deep .vgt-right-align {
    text-align: left;
}

::v-deep button.add-to-cart {
    font-size: 14px;
    line-height: 14px;
    padding: 7px 14px;
    border-radius: 14px;
}

</style>