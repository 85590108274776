var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canAddToCart && _vm.companies.length)?_c('SfModal',{staticClass:"add-to-cart-modal",attrs:{"visible":_vm.visible},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{attrs:{"id":"add-to-cart-modal"}},[_c('div',{staticClass:"container"},[_c('sf-loader',{attrs:{"loading":_vm.processing}},[_c('ValidationObserver',{key:"addtocart",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addToCart)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-4 mb-5"},[_c('div',{staticClass:"form-group"},[_c('v-select',{key:"bee_customer_id",attrs:{"clearable":false,"label":"bee_company_name","placeholder":_vm.$t('Company'),"options":_vm.companies,"reduce":function (company) { return company.bee_customer_id; }},on:{"input":function($event){return _vm.updateProjects({id: $event})}},model:{value:(_vm.form.bee_customer_id),callback:function ($$v) {_vm.$set(_vm.form, "bee_customer_id", $$v)},expression:"form.bee_customer_id"}})],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 mt-4 mb-3"},[_c('div',{staticClass:"form-group"},[(_vm.projects.length)?_c('v-select',{key:"project",attrs:{"clearable":false,"searchable":false,"label":"project","placeholder":_vm.$t('Select...'),"options":_vm.projects,"reduce":function (company) { return company.project; }},model:{value:(_vm.form.project),callback:function ($$v) {_vm.$set(_vm.form, "project", $$v)},expression:"form.project"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 mb-5"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":!_vm.form.project || _vm.form.project.length === 0 ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element text-italic",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"project","label":_vm.projects.length ? _vm.$t('Or add new project') : _vm.$t('Add new project'),"autocomplete":"off"},model:{value:(_vm.form.project_new),callback:function ($$v) {_vm.$set(_vm.form, "project_new", $$v)},expression:"form.project_new"}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"col-6 mt-4 text-left"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"quantity","label":_vm.$t('Quantity'),"autocomplete":"off"},on:{"input":function($event){return _vm.updatePrice($event)}},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"col-6 mt-4 text-left"},[_c('div',{staticClass:"form-group price"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"currency"},[_vm._v("€")]),_vm._v(" "),_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"price","label":_vm.$t('Price'),"autocomplete":"off"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_vm._v(" "),_c('div',{staticClass:"request-price button button-secondary small",class:_vm.priceNotFound ? 'disabled' : '',on:{"click":_vm.requestPrice}},[_vm._v("\n                                    "+_vm._s(_vm.priceNotFound ? _vm.$t('Not found') : _vm.$t('Request price'))+"\n                                ")])]}}],null,true)})],1)])]),_vm._v(" "),_c('Button',{staticClass:"mt-3 button button-secondary",attrs:{"type":"submit"}},[_vm._v("\n                            "+_vm._s(_vm.$t('Add to cart'))+"\n                        ")])],1)]}}],null,false,101319850)})],1)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }