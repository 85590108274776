<template>
    <div class="reference textdoc">
        <client-only>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="reference__text">
                            <country-flag class="flag-block" v-if='reference.country_id' :country='reference.country_id' size='normal'/>
                            <h1>{{ referencesGetters.getFullName(reference) }}</h1>
                            <div class="related-products">
                                <div>{{ $t('Luminaires') }}</div>
                                <template v-for="product in reference.products">
                                    <Link
                                        :icon="true"
                                        :link="productGetters.getProductLink(product)"
                                        :text="product.name"
                                    />
                                </template>                            
                            </div>
                            <div v-html="reference.content"></div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 d-none d-lg-block d-xl-block">
                        <span class="date">{{ $t('Date') }}: {{ formatDate(reference.created_at) }}</span>
                        <CataloguePdf />
                    </div>
                </div>
            </div>
        
            <ReferencesImageSlider :gallery="reference.gallery" />

            <div class="container" v-if="reference.products && reference.products.length > 0">
                <div class="row">
                    <div class="col-12">
                        <div class="related-products-slider">
                            <lazy-hydrate when-visible>
                                <ProductsCarousel
                                    :products="reference.products"
                                    :title="$t('Related Products')"
                                />
                            </lazy-hydrate>
                        </div>
                    </div>
                </div>
            </div>
        </client-only>
    </div>
</template>

<script>
import {
	defineComponent,
	useAsync,
	useRoute,
	useContext,
	computed,
	ref,
} from '@nuxtjs/composition-api';
import { useReferences } from '~/composables';
import CountryFlag from 'vue-country-flag';
import CataloguePdf from '~/components/Common/CataloguePdf.vue';
import ProductsCarousel from '~/components/ProductsCarousel.vue';
import { productGetters, referencesGetters } from '~/getters';
import ReferencesImageSlider from './ImageSlider.vue';
import Link from '~/components/Common/Link.vue';
import moment from 'moment';

export default defineComponent({
	name: 'ReferencesItem',
	components: {
		useReferences,
		CountryFlag,
		CataloguePdf,
		ProductsCarousel,
		ReferencesImageSlider,
		Link,
	},
	head() {
		if (!this.reference) {
			return null;
		}

		const title = this.reference?.name ? this.reference.name + ' - Vizulo' : 'Vizulo';
		const meta = [];

		return {
			title,
			meta,
		};
	},
	methods: { 
		formatDate(value) {
			if (value) {
				return moment(String(value)).format('ll')
			}
		},
	},
	setup() {
		const { getItem, loadingItem } = useReferences();
		const route = useRoute();
		const { params: { slug } } = route.value;
		const { app } = useContext();

		const reference = ref({});

		useAsync(async () => {
			const referenceData = await getItem({
				filter: {
					entity_id: {
						'eq': slug.split('-').pop()
					},
				}
			});

			reference.value = referenceData;
		});

		return {
			loadingItem,
			reference,
			productGetters,
			referencesGetters,
		};
	},
});
</script>

<style lang="scss" scoped>
    .reference {
        padding-top: 84px;
        margin-bottom: 120px;
        min-height: 500px;

        @media (max-width:991px) {
            margin-top: 70px;
            padding-top: 44px;
            margin-bottom: 15px;

            .mobile--margin {
                margin-bottom: 20px;
            }
        }

        &__text {
            position: relative;
            padding: 0 30px 0 60px;

            @media (max-width:991px) {
                padding: 32px 0 0 0;
            }

            h1 {
                font-size: 60px;
                font-weight: 700;
                line-height: 68px;
                margin: 0 0 11px;

                @media (max-width:991px) {
                    font-size: 26px;
                    line-height: 34px;
                }
            }

            p {
                font-size: 24px;
                margin: 0 0 28px;

                @media (max-width:991px) {
                    font-size: 20px;
                    line-height: 28px;
                    margin: 0 0 21px;
                }

                span {
                    font-size: 20px;
                    color: #0d0d0d;

                    @media (max-width:991px) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .related-products {
                margin-bottom: 70px;

                @media (max-width:991px) {
                    margin-bottom: 20px;
                }

                p {
                    margin-bottom: 10px;
                    font-size: 18px;
                    line-height: 18px;

                    &:nth-child(2n) {
                        margin-bottom: 20px;
                    }
                }

                .link-with-arrow {
                    display: inline-block;
                    position: relative;
                    padding-right: 20px;
                    margin-right: 5px;

                    &:after {
                        content: "|";
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .flag-block {
            position: absolute;
            top: 0;
            left: 15px;

            @media (max-width:991px) {
                left: 0;
                top: -10px;
            }
        }

        span.date {
            display: block;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 32px;
        }

        .related-products-slider {
            border-top: 1px solid #0B0B0C;

            .slick-track {
                margin-left: 0;
            }
        }
    }

    ::v-deep .pdf {
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;

        li {
            padding: 0;
            margin: 0 0 8px;
        }
    }


</style>
