<template>
    <SfList class="search-results" v-if="products.length > 0">
        <SfListItem
            v-for="(product, key) in products"
            :key="key"
        >
            <SfMenuItem
                :label="result.type == 'search' ? productGetters.getName(product) : result.term"
                :link="localePath(productGetters.getProductLink(product, product.categories[0]) + linkType)"
                @click.native="toggleNavigationSearch"
            />
        </SfListItem>
    </SfList>
</template>
<script>
import { SfList, SfMenuItem } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { productGetters } from '~/getters';
import { useUiState } from '~/composables';

export default defineComponent({
    name: 'SearchResults',
    components: {
        SfList,
        SfMenuItem,
    },
    props: {
        result: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props) {
        const { isNavigationSidebarOpen, toggleNavigationSearch } = useUiState();

        return {
            toggleNavigationSearch,
            productGetters,
            products: computed(() => props.result?.products || {}),
            linkType: computed(() => props.result?.type == 'article' ? '#assortment' : ''),
            term: computed(() => props.result?.term || ''),
        };
    },
});
</script>
<style lang="scss" scoped>
.search-results {
    height: auto;
    position: relative;
    background: var(--c-white);
    padding: 12px 0;
}

::v-deep .sf-list__item {
    margin: 12px 0;
    padding: 0 15px;
}

::v-deep .sf-menu-item__label {
    color: var(--c-text);

    &:hover {
        text-decoration: underline;
    }
}
</style>
