<template>
    <div>
        <Globe v-if="map.length" :references="map" />
        <div class="references">
            <div class="container content loading" v-if="loadingReferences">
                <SfLoader />
            </div>
            <div class="container content" v-else>
                <div class="row">
                    <div class="col-12">
                        <div class="references-list__intro">
                            <h4>{{ $t('Our top cases') }}</h4>
                            <p>{{ $t('We are proud to share our the most significant, largest reference projects all around the world') }}</p>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="top.length > 0">
                    <template v-for="(item, index) in top">
                        <Top :index="index" :item="item" :total="top.length" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
	defineComponent,
	onMounted,
	ref
} from '@nuxtjs/composition-api';

import { SfLoader } from '@storefront-ui/vue';
import { useReferences } from '~/composables';
import Link from '~/components/Common/Link.vue';
import { referencesGetters } from '~/getters';
import Video from '~/components/Common/Video.vue';
import Globe from './Globe.vue';
import Top from './Top.vue';

export default defineComponent({
	name: 'References',
	components: {
		useReferences,
		Link,
		Video,
		SfLoader,
		Globe,
		Top,
        Item: () => import('~/modules/vizulo/references/Item.vue'),
	},
    head() {
        const title = 'References - Vizulo';
        const meta = [];

        return {
            title,
            meta,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!from.path.includes('references')) {
                localStorage.removeItem('vsf-references');
            }
        })
    },
	setup() {
		const { getReferences, loadingReferences } = useReferences();
        const references = ref({});
        const map = ref({});
		const top = ref({});

        const referencesSearchQuery = {
            // all
            currentPage: 1,
            pageSize: 1000,
        }

		onMounted(async () => {
			const referencesData = await getReferences(
                referencesSearchQuery
            );

			references.value = referencesData.items;
            map.value = referencesData.items.filter((item) => item.map == "1");
            top.value = referencesData.items.filter((item) => item.top == "1"); 
		});

		return {
			references,
            map,
            top,
			loadingReferences,
			referencesGetters,
		};
	},
});

</script>

<style lang="scss" scoped>
    .loading {
        height: 100vh;
    }

    .references-list {
        &__intro {
            max-width: 660px;
            margin: 0 auto 120px auto;

            h4 {
                font-size: 20px;
                letter-spacing: 0.25px;
                line-height: 28px;
                margin: 0 0 28px;
            }

            p {
                font-size: 20px;
                line-height: 28px;
            }

            @media (max-width: 991px) {
                margin-bottom: 35px;

                h4 {
                    font-size: 16px;
                    letter-spacing: 0.2px;
                    line-height: 24px;
                }

                p {
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }
    }
</style>