<template>
	<div class="flex flex-row align-items-center breadcrumbs">
		<nuxt-link
			:to="localePath('/')"
			class="text-gray"
		>
			{{ $t('Home') }}
			<span>&nbsp;>&nbsp;</span>
		</nuxt-link>
		<nuxt-link
			v-for="(breadcrumb, index) in breadcrumbs"
			:key="index"
			:to="localePath(breadcrumb.link)"
			:class="index !== breadcrumbs.length - 1 ? 'text-gray' : ''"
		>
			{{ breadcrumb.text }}
			<span v-if="index !== breadcrumbs.length - 1">&nbsp;>&nbsp;</span>
		</nuxt-link>
	</div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
	name: 'Breadcrumbs',
	props: {
		breadcrumbs: {
            type: Array,
            default: () => [],
        }
	}
})
</script>

<style lang="scss" scoped>
.breadcrumbs {
	margin-top: 25px;
	margin-bottom: 23px;
}
</style>