<template>
    <div class="container" id="thank-you">
        <section class="section">
            <div class="order">
                <div class="order__contact">
                    <Link
                        :icon="false"
                        :link="localePath('/members/orders')"
                        :text="$t('Go to My orders')"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { ref, useRoute, computed } from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import Link from '~/components/Common/Link.vue';

export default {
	components: {
		SfHeading,
        Link,
	},
	setup(props, context) {
		context.emit('changeStep', 4);
        const route = useRoute();
	},
};
</script>
<style lang="scss" scoped>
#thank-you {
    margin: 75px 0 120px 0;
}

</style>
