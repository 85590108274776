<template>
  <div id="news" class="container">
        <div class="row">
            <div class="col-12">
                <h1>{{ $t('Our news') }}</h1>
            </div>
        </div>
        <div class="news-list">
            <SkeletonLoader
                v-if="loadingNews"
            />
            <div v-else>
                <masonry-wall
                    :items="news"
                    :column-width="columnWidth"
                    :gap="gap"
                    :rtl="rtl"
                    :ssr-columns="ssrColumns"
                >
                    <template #default="{ item, index }">
                        <div class="item">
                            <nuxt-link :to="localePath(newsGetters.getSlug(item))">
                                <img :src="item.image">
                                <h3>{{item.name}}</h3>
                            </nuxt-link>
                            <Link
                                :icon="true"
                                :link="newsGetters.getSlug(item)"
                                :text="$t('Explore')"
                            />
                        </div>
                    </template>
                </masonry-wall>
                <lazy-hydrate on-interaction>
                    <SfPagination
                        v-show="pagination.totalPages > 1"
                        class="pagination"
                        :current="pagination.currentPage"
                        :total="pagination.totalPages"
                        :visible="5"
                    />
                </lazy-hydrate>
            </div>
        </div>
  </div>
</template>
<script>

import {
    defineComponent, 
    useFetch,
    useAsync,
    useRoute,
    ref,
    reactive,
    computed,
    onMounted,
} from '@nuxtjs/composition-api';
import { SfPagination } from '@storefront-ui/vue';
import { useNews } from '~/modules/vizulo/news'
import { newsGetters } from '~/getters';
import Link from '~/components/Common/Link.vue';

export default defineComponent({
    name: 'News',
    components: {
        useNews,
        Link,
        SfPagination,
    },
    data() {
        return {
            columnWidth: 500,
            gap: 16,
            rtl: false,
            ssrColumns: 1,
        }
    },
    head() {
        const title = 'News - Vizulo';
        const meta = [];

        return {
            title,
            meta,
        };
    },
    transition: 'fade',
    setup() {
        const { getNews, loadingNews } = useNews();
        const route = useRoute();
        const {
            query: {
                page,
                itemsPerPage,
            },
        } = route.value;

        const news = ref([]);
        const pagination = ref({});

        onMounted(async () => {
            const newsData = await getNews({
                currentPage: Number.parseInt(page, 10) || 1,
                pageSize: Number.parseInt(itemsPerPage, 10) || 10,
            });

            news.value = newsData.items;
            pagination.value = newsGetters.getPagination(newsData);
        });

        return {
            news,
            loadingNews,
            newsGetters,
            pagination,
        };
    },
});

</script>

<style lang="scss" scoped>
    #news {
        margin: 85px auto;

        h1 {
            margin-bottom: 40px;
        }
    }

    .news-list {
        .item {
            padding: 15px;

            img {
                width: 100%;
                max-width: 500px;
            }

            &:hover {
                outline: 2px solid var(--_c-gray-primary);
                text-decoration: none;
            }
        }
    }

    ::v-deep .pagination {
        margin-top: 35px;
        text-align: center;
        justify-content: center;

        .sf-pagination__item {
            width: 32px;
            height: 32px;
            margin: 0 15px;
            font-weight: bold;
            border-radius: 50%;
            border: 1px solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;

            &:not(.current) {
                &:hover {
                    border: 1px solid #EAEAEA;
                }
            }
        }
    }
</style>