<template>
    <ValidationObserver v-slot="{ handleSubmit, dirty, reset }">
        <SfHeading
            v-e2e="'shipping-heading'"
            :level="3"
            :title="$t('Delivery')"
            class="sf-heading--left sf-heading--no-underline title"
        />
        <form @submit.prevent="handleSubmit(handleAddressSubmit(reset))">
            <SkeletonLoader v-if="loading" minHeight="300px" height="300px" />
            <UserShippingAddresses
                v-else-if="isAuthenticated && hasSavedShippingAddress"
                v-model="setAsDefault"
                :current-address-id="currentAddressId || NOT_SELECTED_ADDRESS"
                :shipping-addresses="addresses"
                @setCurrentAddress="handleSetCurrentAddress"
                :class="{ inactive: canAddNewAddress }"
            />
            <div v-if="canAddNewAddress && !loading" class="form">
                <ValidationProvider
                    v-slot="{ errors }"
                    name="company"
                    rules="required"
                    slim
                >
                    <SfInput
                        :value="shippingDetails.company"
                        :label="$t('Company')"
                        name="company"
                        class="form__element"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(company) => changeShippingDetails('company', company)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="street"
                    rules="required"
                    slim
                >
                    <SfInput
                        :value="shippingDetails.street"
                        :label="$t('Street Name')"
                        name="streetName"
                        class="form__element"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(street) => changeShippingDetails('street', street)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="city"
                    rules="required|min:2"
                    slim
                >
                    <SfInput
                        :value="shippingDetails.city"
                        :label="$t('City')"
                        name="city"
                        class="form__element form__element--half"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(city) => changeShippingDetails('city', city)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="zipCode"
                    rules="required|min:2"
                    slim
                >
                    <SfInput
                        :value="shippingDetails.postcode"
                        :label="$t('Zip-code')"
                        name="zipCode"
                        class="form__element form__element--half form__element--half-even"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(postcode) => changeShippingDetails('postcode', postcode)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="country"
                    rules="required|min:2"
                    slim
                >
                    <SfSelect
                        :value="shippingDetails.country_code"
                        :label="$t('Country')"
                        name="country"
                        class="form__element form__element--half form__select sf-select--underlined"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="changeCountry"
                      >
                            <SfSelectOption
                                v-for="countryOption in countriesList"
                                :key="countryOption.id"
                                :value="countryOption.abbreviation"
                            >
                                {{ countryOption.label }}
                            </SfSelectOption>
                    </SfSelect>
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="region"
                    :rules="
                        !shippingDetails.country_code || regionInformation.length === 0
                            ? null
                            : 'required|min:2'
                    "
                    slim
                >
                    <SfInput
                        v-if="!shippingDetails.country_code || regionInformation.length === 0"
                        :value="shippingDetails.region"
                        :label="$t('State/Province')"
                        :disabled="!shippingDetails.country_code"
                        name="state"
                        class="form__element form__element--half form__element--half-even"
                        :valid="!!shippingDetails.country_code"
                        :error-message="
                          !shippingDetails.country_code
                            ? $t('Please select a country first')
                            : ''
                        "
                        @input="(region) => changeShippingDetails('region', region)"
                    />
                    <SfSelect
                        v-else
                        :value="shippingDetails.region"
                        :label="$t('State/Province')"
                        name="state"
                        class="form__element form__element--half form__element--half-even form__select sf-select--underlined"
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(region) => changeShippingDetails('region', region)"
                    >
                        <SfSelectOption
                            v-for="regionOption in regionInformation"
                            :key="regionOption.id"
                            :value="regionOption.abbreviation"
                        >
                            {{ regionOption.label }}
                        </SfSelectOption>
                    </SfSelect>
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="phone"
                    rules="required"
                    slim
                >
                    <SfInput
                        v-model="shippingDetails.telephone"
                        :label="$t('Phone number')"
                        name="phone"
                        class="form__element form__element--half"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="
                            (telephone) => changeShippingDetails('telephone', telephone)
                        "
                    />
                </ValidationProvider>
                <SfCheckbox
                    name="new_adress"
                    :label="$t('Save to address list')"
                    v-model="form.save_in_address_list"
                    class="form__element"
                />
                <SfCheckbox
                    v-if="form.save_in_address_list && canEditBillingAddress"
                    v-model="form.default_shipping"
                    name="isDefaultShipping"
                    :label="$t('Set as default delivery')"
                    class="form__checkbox-isDefaultShipping mr-4 mb-3"
                />
                <SfCheckbox
                    v-if="form.save_in_address_list && canEditBillingAddress"
                    v-model="form.default_billing"
                    name="isDefaultBilling"
                    :label="$t('Set as default billing')"
                    class="form__checkbox-isDefaultBilling mb-3"
                />

            </div>
            <Button
                v-if="!canAddNewAddress"
                class="button button-secondary medium mb-3"
                type="submit"
                @click="handleAddNewAddressBtnClick"
            >
                {{ $t('Add new address') }}
            </Button>
            <hr class="sf-divider" v-if="canAddNewAddress || !(isShippingDetailsStepCompleted && !dirty)">
            <div class="text-right mt-3" v-if="canAddNewAddress || !(isShippingDetailsStepCompleted && !dirty)">
                <Button
                    class="button button-primary"
                    type="submit"
                >
                    {{ $t('Select delivery term') }}
                </Button>
            </div>
            <VsfShippingProvider
                v-if="isShippingDetailsStepCompleted && !dirty"
                :shipping-methods="shippingMethods"
                @submit="$router.push(`${localePath('/checkout/billing')}`)"
            />
        </form>
    </ValidationObserver>
</template>

<script>
import {
	ref,
	reactive,
	computed,
	watch,
	onMounted,
	defineComponent,
	useRouter,
	useContext,
} from '@nuxtjs/composition-api';
import { SfHeading, SfInput, SfSelect, SfCheckbox, SfLoader } from '@storefront-ui/vue';
import { required, min, digits } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { userShippingGetters, addressGetter } from '~/getters';
import { useUser, useShipping, useUserAddress, useCountrySearch, useCart, useAddresses } from '~/composables';
import { addressFromApiToForm } from '~/helpers/checkout/address';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import Button from '~/components/Common/Button.vue';

const NOT_SELECTED_ADDRESS = '';

extend('required', {
	...required,
	message: 'This field is required',
});
extend('min', {
	...min,
	message: 'The field should have at least {length} characters',
});
extend('digits', {
	...digits,
	message: 'Please provide a valid phone number',
});

export default defineComponent({
	name: 'ShippingStep',
	components: {
		SfHeading,
		SfInput,
		SfSelect,
		ValidationProvider,
		ValidationObserver,
		Button,
		SfLoader,
		SfCheckbox,
		UserShippingAddresses: () => import('~/components/Checkout/UserShippingAddresses.vue'),
		VsfShippingProvider: () => import('~/components/Checkout/VsfShippingProvider.vue'),
	},
	setup() {
		const router = useRouter();
		const { app } = useContext();
		const address = ref({});
		const userShipping = ref({});
		const { isAuthenticated } = useUser();
		const { cart, load, loading } = useCart();
		const { save: saveShipping } = useShipping();
		const { save: saveAddress } = useAddresses();
		const { addAddress, setDefaultAddress } = useUserAddress();
		const { load: loadCountries, search: searchCountry } = useCountrySearch();
		const userPermissions = app.$vsf.$magento.config.state.getPermissions();

		const countries = ref([]);
		const country = ref(null);
		const shippingDetails = ref(addressFromApiToForm(address.value) || {});
		const shippingMethods = ref([]);
		const currentAddressId = ref(NOT_SELECTED_ADDRESS);
		const setAsDefault = ref(false);
		const isFormSubmitted = ref(false);
		const canAddNewAddress = ref(true);
		const canEditBillingAddress = computed(() => userPermissions.find(el => el.code == 'edit billing address'));

		const form = reactive({
			save_in_address_list: false,
			default_shipping: false,
			default_billing: false,
		});

		const isShippingDetailsStepCompleted = ref(false);
		const addresses = ref([]);

		const canMoveForward = computed(() => shippingDetails.value && Object.keys(
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			shippingDetails.value,
		).length > 0);

		const hasSavedShippingAddress = computed(() => {
			if (!isAuthenticated.value || !userShipping.value) {
				return false;
			}

			return addresses.value.length > 0;
		});

		// @ts-ignore
		const countriesList = computed(() => addressGetter.countriesList(countries.value));
		const regionInformation = computed(() => addressGetter.regionList(country.value));

		const handleAddressSubmit = (reset) => async () => {
			const addressId = currentAddressId.value;

			const shippingDetailsData = {
				...shippingDetails.value,
				firstname: 'Vizulo',
				lastname: 'Customer',
				customerAddressId: addressId,
				save_in_address_book: false,
			};

			await mergeItem('checkout', { shipping: shippingDetailsData });
			// @TODO remove ignore when https://github.com/vuestorefront/vue-storefront/issues/5967 is applied
			// @ts-ignore
			const shippingInfo = await saveShipping({ shippingDetails: shippingDetailsData });
			shippingMethods.value = shippingInfo.available_shipping_methods;

			if (addressId !== NOT_SELECTED_ADDRESS && setAsDefault.value) {
				const [chosenAddress] = userShippingGetters.getAddresses(
					userShipping.value,
					{ id: addressId },
				);

				chosenAddress.default_shipping = setAsDefault.value;

				if (chosenAddress) {
					await setDefaultAddress({ address: chosenAddress });
					// await loadData();
				}
			}

			if (form.save_in_address_list) {
				const region = shippingDetails.value.region;
				const regionId = regionInformation.value.find((r) => r.abbreviation === region)?.id;

				const newAddress = {
					...shippingDetails.value,
					region: {
						region_code: '',
						region_id: regionId,
						region: region,
					},
					firstname: 'Vizulo',
					lastname: 'Customer',
					bee_customer_id: cart.value.bee_customer_id,
					default_shipping: form.default_shipping,
					default_billing: form.default_billing,
				};

				const addressResponse = await saveAddress({ address: newAddress });

				handleSetCurrentAddress(addressResponse);

				form.save_in_address_list = false;
				form.default_shipping = false;
				form.default_billing = false;
			}

			reset();
			isShippingDetailsStepCompleted.value = true;
			canAddNewAddress.value = false;

			await loadData();
		};

		const handleAddNewAddressBtnClick = () => {
			currentAddressId.value = NOT_SELECTED_ADDRESS;
			shippingDetails.value = {};
			canAddNewAddress.value = true;
			isShippingDetailsStepCompleted.value = false;
		};

		const handleSetCurrentAddress = (addr) => {
			shippingDetails.value = { ...addressFromApiToForm(addr) };
			currentAddressId.value = addr?.id;
			canAddNewAddress.value = false;
			isShippingDetailsStepCompleted.value = false;
		};

		const changeShippingDetails = (field, value) => {
			shippingDetails.value = {
				...shippingDetails.value,
				[field]: value,
			};

			isShippingDetailsStepCompleted.value = false;
			currentAddressId.value = NOT_SELECTED_ADDRESS;
		};

		const changeCountry = async (id) => {
			changeShippingDetails('country_code', id);
			country.value = await searchCountry({ id });
		};

		watch(address, (addr) => {
			shippingDetails.value = addressFromApiToForm(addr || {});
		});

		onMounted(async () => {
			const validStep = await isPreviousStepValid('user-account');

			if (!validStep) {
				await router.push(app.localePath('/checkout/cart'));
			}

			await loadData();

			handleSetCurrentAddress(addresses.value.find(a => {
				return a.is_selected
			}));

			const addressId = currentAddressId.value;

			if (addressId) {
				const shippingDetailsData = {
					...shippingDetails.value,
					firstname: 'Vizulo',
					lastname: 'Customer',
					customerAddressId: addressId,
					save_in_address_book: false,
				};

				await mergeItem('checkout', { shipping: shippingDetailsData });
				// @TODO remove ignore when https://github.com/vuestorefront/vue-storefront/issues/5967 is applied
				// @ts-ignore
				const shippingInfo = await saveShipping({ shippingDetails: shippingDetailsData });
				shippingMethods.value = shippingInfo.available_shipping_methods;

				isShippingDetailsStepCompleted.value = true;
			}

			await handleAddressSubmit();
		});

		const loadData = async () => {
			if (countries.value.length == 0) {
				const [loadedCountries] = await Promise.all([
					loadCountries(),
				]);

				countries.value = loadedCountries;
			}

			await load();

			userShipping.value.addresses = cart.value?.company_addresses;

			const defaultBilling = cart.value?.company_addresses.find(a => { return a.default_billing });
			const defaultShipping = cart.value?.company_addresses.find(a => { return a.default_shipping });

			// address.value = defaultShipping;

			userShipping.value.default_billing = defaultBilling ? defaultBilling.id.toString() : userShipping.value.default_billing;
			userShipping.value.default_shipping = defaultShipping ? defaultShipping.id.toString() : userShipping.value.default_shipping;

			if (shippingDetails.value?.country_code) {
				country.value = await searchCountry({ id: shippingDetails.value.country_code });
			}

			addresses.value = userShippingGetters.getAddresses(
				userShipping.value
			);

			if (!addresses.value || addresses.value.length === 0) {
				return;
			}
		}

		return {
			canAddNewAddress,
			canMoveForward,
			changeCountry,
			changeShippingDetails,
			countriesList,
			country,
			currentAddressId,
			handleAddNewAddressBtnClick,
			handleAddressSubmit,
			handleSetCurrentAddress,
			hasSavedShippingAddress,
			isAuthenticated,
			isFormSubmitted,
			isShippingDetailsStepCompleted,
			NOT_SELECTED_ADDRESS,
			regionInformation,
			searchCountry,
			setAsDefault,
			shippingDetails,
			shippingMethods,
			addresses,
			form,
			loading,
			cart,
			canEditBillingAddress
		};
	},
});
</script>

<style lang="scss" scoped>
.inactive {
    opacity: 0.4;
}

.form {
    --button-width: 100%;

    &__select {
        display: flex;
        align-items: center;
        --select-option-font-size: var(--font-size--lg);

        ::v-deep .sf-select__dropdown {
            font-size: var(--font-size--lg);
            margin: 0;
            color: var(--c-text);
            font-family: var(--font-family--secondary);
            font-weight: var(--font-weight--normal);
        }

        ::v-deep .sf-select__label {
            left: initial;
        }
    }

    @include for-desktop {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        --button-width: auto;
    }

    &__element {
        margin: 0 0 var(--spacer-xl) 0;

        @include for-desktop {
            flex: 0 0 100%;
        }

        &--half {
            @include for-desktop {
                flex: 1 1 50%;
            }

            &-even {
                @include for-desktop {
                    padding: 0 0 0 var(--spacer-xl);
                }
            }
        }
    }

    &__action {
        @include for-desktop {
            flex: 0 0 100%;
            display: flex;
        }
    }

    &__action-button {
        &--secondary {
            @include for-desktop {
                order: -1;
                text-align: left;
            }
        }

        &--add-address {
            width: 100%;
            margin: 0;

            @include for-desktop {
                margin: 0 0 var(--spacer-lg) 0;
                width: auto;
            }
        }
    }

    &__back-button {
        margin: var(--spacer-xl) 0 var(--spacer-sm);

        &:hover {
            color: var(--c-white);
        }

        @include for-desktop {
            margin: 0 var(--spacer-xl) 0 0;
        }
    }
}

.shipping {
    &__label {
        display: flex;
        justify-content: space-between;
    }

    &__description {
        --radio-description-margin: 0;
        --radio-description-font-size: var(--font-xs);
    }
}

.title {
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;
}

.sf-loader {
    &__overlay {
        margin-top: 120px;
    }

    min-height: 300px;
}
</style>
