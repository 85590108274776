<template>
    <div class="buttons">
        <div class="buttons__scroll">
            <div class="mouse"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Scroll',
    components: {
    },
};
</script>
<style lang="scss" scoped>
    .buttons {
        height: 64px;

        @media (max-width: 991px) {
            display: none;
        }

        &__scroll {
            float: left;
            width: 64px;
            height: 64px;
            border-radius: 100%;
            border: 1px solid #5b5b5b;
            position: relative;

            .mouse {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 22px;
                height: 32px;
                border: 2px solid #fff;
                border-radius: 15px;

                &::after {
                    content: "";
                    display: block;
                    margin: 0 0 0 -2px;
                    position: absolute;
                    top: 10px;
                    left: 50%;
                    width: 4px;
                    height: 4px;
                    background: #fff;
                    border-radius: 50%;
                    animation: scroll-wheel 1.8s linear 0s infinite normal none;
                }
            }

            @keyframes scroll-wheel {
                0% {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }

                35% {
                    transform: translate3d(0, 7px, 0);
                    opacity: 1;
                }

                50% {
                    transform: translate3d(0, 10px, 0);
                    opacity: 0;
                }

                50.001% {
                    transform: translate3d(0, 0, 0);
                    opacity: 0;
                }

                80% {
                    transform: translate3d(0, 0, 0);
                    opacity: 0;
                }
            }
        }
    }
</style>