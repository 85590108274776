<template>
  <div class="center">
    <div class="w-100 relative-pos" v-if="activeStep==='step_3'">
        <div class="text-center black-header relative-pos flex-between text-white">
            <div class="flex">
                <div @click="resetFormData" class="reset-form" v-if="activeStep !== 'step_6'">
                    <p>{{ $t('Reset') }}</p>
                </div>
                <SkeletonLoader class="small-loader mr-3" v-if="loading.attributes"/>
            </div>
            <h3>{{ $t('Step') }} 3 - {{ $t('Control and protection') }}</h3>
            <div class="reset-form asana-message" :class="{ 'asana-report-sent': selectedValues.general.asanaReportSent }" @click="openConfiguratorPopup = !openConfiguratorPopup">
                <p class="text-right">{{ $t('Report issue') }}</p>
            </div>
        </div>
        <div id="warning-message">
            <div class="warning-message-large" v-if="displayWarning">
                <p>{{$t('No configuration found! Please consider making changes to your selection.')}}</p>
            </div>
        </div>
        <div class="flex-center" v-if="loading.steps" :style="componentStyle">
            <div class="loader"></div>
        </div>
        <div class="container">
            <div class="base-attribute flex-column">
                <div class="flex-between w-100"  @click="openSockets = !openSockets">
                    <div class="flex vw-10">
                        <p>{{ $t('Socket') }}</p>
                        <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.attributeInfo['socket']">
                            <svg-image
                                class="icon hover-icon"
                                icon="info"
                            />
                            <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.attributeInfo['socket']"></span>
                        </div>
                    </div>
                    <div class="mr-15 pointer">
                        <svg-image
                            v-if="!openSockets"
                            height="22"
                            icon="plus"
                            width="22"
                        />
                        <svg-image
                            v-else
                            height="22"
                            icon="minus"
                            width="22"
                        />
                    </div>
                </div>
                <ul class="options flex-column w-100 mt-3 sockets"
                    v-if="openSockets || keepSocketsOpen()"
                    :class="{ 'dynamic-height': openSockets || keepSocketsOpen()}">
                    <div class="w-100 mr-15"></div>
                        <div
                            class="flex-between w-100 mt-4"
                            :key="socketLocation.id"
                            v-for="socketLocation in reactiveProductConfigurator.socketLocations"
                        >
                        <p class="normal-font" @click="isSocketsVisible">{{ socketLocation.name }} {{ $t('socket') }}</p>
                        <div>
                            <li
                                @click="manageSocketOptions({name: 'none', id: 0}, socketLocation.name)"
                                :class="{  active: reactiveSelectedValues.sockets && reactiveSelectedValues.sockets[socketLocation.name] && reactiveSelectedValues.sockets[socketLocation.name].id == 0 }"
                                class="option flex-center"
                            >
                                <span>{{ $t('None') }}</span>
                            </li>
                            <div class="inline">
                                <li
                                    class="option flex-center first-letter-cappitalized"
                                    :class="{ active: reactiveSelectedValues.sockets && reactiveSelectedValues.sockets[socketLocation.name] && (reactiveSelectedValues.sockets[socketLocation.name].id == socket.id)
                                    , hide: socket.name === 'Zhaga' && socketLocation.name == 'bottom' && reactiveSelectedValues.sockets['top'] && reactiveSelectedValues.sockets['top'].id == nemaSocketId }"
                                    @click="manageSocketOptions(socket, socketLocation.name)"
                                    :key="socket.id"
                                    v-for="socket in socketLocation.socket"
                                >
                                    <span>{{ socket.name }}</span>
                                </li>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
            <div class="base-attribute dimming">
                <div class="flex vw-10">
                    <p>{{ $t('Dimming') }}</p>
                    <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.attributeInfo['dimming']">
                        <svg-image
                            class="icon hover-icon"
                            icon="info"
                        />
                        <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.attributeInfo['dimming']"></span>
                    </div>
                </div>
                <ul class="options">
                    <li
                        class="option first-letter-cappitalized"
                        :class="{ active: reactiveSelectedValues.dimming && dimming.id == reactiveSelectedValues.dimming.id }"
                        @click="formDataSave('dimming', dimming, true, true)"
                        :key="dimming.id"
                        v-for="dimming in filteredDimmings">
                        <span>{{ dimming.name }}</span>
                    </li>
                </ul>
            </div>
            <!-- Step dimming -->
            <div class="dimming-table mb-5" v-if="reactiveSelectedValues.dimming && reactiveSelectedValues.dimming.id === 48">
                <p class="mt-4 mb-4">{{ $t('Step dimming parameters') }}</p>
                <div class="flex mb-2">
                    <div class="flex-end mr-4 levels" :class="{ 'levels-firefox': isFirefox()}">
                        <p class="mr-2">{{ $t('Switch-on level') }}</p>
                        <input
                            type="text"
                            v-model="reactiveSelectedValues.dimmingParameters.step.switchOnLevel"
                            @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true, 'switchOnLevel')"
                        >
                    </div>
                    <div class="flex-end levels mr-2" :class="{ 'levels-firefox': isFirefox()}">
                        <p class="mr-2">{{ $t('SD level') }}</p>
                        <input
                            type="text"
                            v-model="reactiveSelectedValues.dimmingParameters.step.SDLevel"
                            @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true, 'SDLevel')"
                        >
                    </div>
                </div>
                <p class="warning-message" v-if="warningMessage['target'] === 'switchOnLevel' && warningMessage['text'].length > 0">{{ warningMessage['text'] }}</p>
                <p class="warning-message" v-if="warningMessage['target'] === 'SDLevel' && warningMessage['text'].length > 0">{{ warningMessage['text'] }}</p>
            </div>
            <!-- Mains dimming -->
            <div class="dimming-table mb-5" v-if="reactiveSelectedValues.dimming && reactiveSelectedValues.dimming.id === 49">
                <p class="mt-4 mb-4">{{ $t('Mains dimming parameters') }}</p>
                <div class="flex-between mb-2">
                    <div class="flex-end mr-4 levels" :class="{ 'levels-firefox': isFirefox()}">
                        <p class="mr-2">{{ $t('Min level') }}</p>
                        <input
                            type="text"
                            v-model="reactiveSelectedValues.dimmingParameters.mains.minLevel"
                            @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true, 'minLevel')"
                        >
                    </div>
                    <div class="flex-end lifetime voltage mr-2" :class="{ 'voltage-firefox': isFirefox()}">
                        <p class="mr-2">{{ $t('Min voltage') }}</p>
                        <input
                            type="text"
                            v-model="reactiveSelectedValues.dimmingParameters.mains.minVoltage"
                            @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true, 'minVoltage')"
                        >
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="flex-end mr-4 levels" :class="{ 'levels-firefox': isFirefox()}">
                        <p class="mr-2">{{ $t('Max level') }}</p>
                        <input
                            type="text"
                            v-model="reactiveSelectedValues.dimmingParameters.mains.maxLevel"
                            @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true, 'maxLevel')"
                        >
                    </div>
                    <div class="flex-end voltage voltage mr-2" :class="{ 'voltage-firefox': isFirefox()}">
                        <p class="mr-2">{{ $t('Max voltage') }}</p>
                        <input
                            type="text"
                            v-model="reactiveSelectedValues.dimmingParameters.mains.maxVoltage"
                            @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true, 'maxVoltage')"
                        >
                    </div>
                </div>
                <p class="warning-message" v-if="warningMessage['target'] === 'maxLevel' && warningMessage['text'].length > 0">{{ warningMessage['text'] }}</p>
                <p class="warning-message" v-if="warningMessage['target'] === 'minLevel' && warningMessage['text'].length > 0">{{ warningMessage['text'] }}</p>
            </div>
            <!-- Midning dimming -->
            <div v-if="reactiveSelectedValues.dimming && (reactiveSelectedValues.dimming.id === 41 || reactiveSelectedValues.dimming.id === 69)" class="dimming-table mb-5">
                <p class="mt-4 mb-4">{{ $t('Dimming schedule') }}</p>
                <p class="warning-message" v-if="fieldIdsWithWarning.length > 0 && warningMessage['target'] === 'outputLevel'">{{ warningMessage['text'] }}</p>
                <table>
                    <tr>
                        <th><span>{{ $t('Step') }}</span></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                    </tr>
                    <tr>
                        <td><span>{{ $t('Output level') }}</span></td>
                        <td>
                            <input
                                type="text"
                                :class="{ 'warning': fieldIdsWithWarning.includes(1)}"
                                @change="updateOutputLevel(reactiveSelectedValues.dimmingParameters.midnight.steps['1']['outputLevel'], 1)"
                                v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['1']['outputLevel']"
                            >
                        </td>
                        <td>
                            <input
                                type="text"
                                :class="{ 'warning': fieldIdsWithWarning.includes(2)}"
                                @change="updateOutputLevel(reactiveSelectedValues.dimmingParameters.midnight.steps['2']['outputLevel'], 2)"
                                v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['2']['outputLevel']"
                            >
                        </td>
                        <td>
                            <input
                                type="text"
                                :class="{ 'warning': fieldIdsWithWarning.includes(3)}"
                                @change="updateOutputLevel(reactiveSelectedValues.dimmingParameters.midnight.steps['3']['outputLevel'], 3)"
                                v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['3']['outputLevel']"
                             >
                        </td>
                        <td>
                            <input
                                type="text"
                                :class="{ 'warning': fieldIdsWithWarning.includes(4)}"
                                @change="updateOutputLevel(reactiveSelectedValues.dimmingParameters.midnight.steps['4']['outputLevel'], 4)"
                                v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['4']['outputLevel']"
                            >
                        </td>
                        <td>
                            <input
                                type="text"
                                :class="{ 'warning': fieldIdsWithWarning.includes(5)}"
                                @change="updateOutputLevel(reactiveSelectedValues.dimmingParameters.midnight.steps['5']['outputLevel'], 5)"
                                v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['5']['outputLevel']"
                            >
                        </td>
                        <td>
                            <input
                                type="text"
                                :class="{ 'warning': fieldIdsWithWarning.includes(6)}"
                                @change="updateOutputLevel(reactiveSelectedValues.dimmingParameters.midnight.steps['6']['outputLevel'], 6)"
                                v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['6']['outputLevel']"
                                readonly
                            >
                        </td>
                    </tr>
                    <tr v-if="storeCode === 'us'">
                        <td><span>{{ $t('Time') }}</span></td>
                        <td>{{ $t('On') }}</td>
                        <td><vue-timepicker format="hh:mm A" @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['2']['setTime']"/></td>
                        <td><vue-timepicker format="hh:mm A" @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['3']['setTime']"/></td>
                        <td><vue-timepicker format="hh:mm A"  @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['4']['setTime']"/></td>
                        <td><vue-timepicker format="hh:mm A"  @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['5']['setTime']"/></td>
                        <td>{{ $t('Off') }}</td>
                    </tr>
                    <tr v-else>
                        <td><span>{{ $t('Time') }}</span></td>
                        <td>{{ $t('On') }}</td>
                        <td><vue-timepicker @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['2']['setTime']"/></td>
                        <td><vue-timepicker @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['3']['setTime']"/></td>
                        <td><vue-timepicker @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['4']['setTime']"/></td>
                        <td><vue-timepicker @change="formDataSave('dimmingParameters', reactiveSelectedValues.dimmingParameters, false, true)" v-model="reactiveSelectedValues.dimmingParameters.midnight.steps['5']['setTime']"/></td>
                        <td>{{ $t('Off') }}</td>
                    </tr>
                </table>
            </div>
            <div class="base-attribute border-top-light" v-if="storeCode !== 'us'">
                <div class="flex vw-10">
                    <p>{{ $t('Insulation') }}</p>
                    <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.attributeInfo['insulation']">
                        <svg-image
                            class="icon hover-icon"
                            icon="info"
                        />
                        <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.attributeInfo['insulation']"></span>
                    </div>
                </div>
                <ul class="options">
                    <li
                        class="option first-letter-cappitalized"
                        :class="{ active: reactiveSelectedValues.insulation && insulation.id == reactiveSelectedValues.insulation.id}"
                        @click="formDataSave('insulation', insulation, true, true)"
                        :key="insulation.id"
                        v-for="insulation in reactiveProductConfigurator.insulations">
                        <span>{{ insulation.name }}</span>
                    </li>
                </ul>
            </div>
            <div class="base-attribute" :class="{ 'border-top-light': storeCode === 'us' }">
                <div class="flex vw-10">
                    <p :class="{ inactive: reactiveSelectedValues.driverSize.length === 0 }">{{ $t('Surge protection device') }}</p>
                    <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.attributeInfo['surge_protection_device']">
                        <svg-image
                            class="icon hover-icon"
                            icon="info"
                        />
                        <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.attributeInfo['surge_protection_device']"></span>
                    </div>
                </div>
                <ul class="options" :class="{ inactive: reactiveSelectedValues.driverSize.length === 0 }">
                    <li
                        class="yes-no no"
                        :class="{ active: !reactiveSelectedValues.surgeProtection, ' inactive-no': reactiveSelectedValues.driverSize.length === 0 }"
                        @click="formDataSave('surgeProtection', false, true, true)"
                        >
                        <span>{{ $t('No') }}</span>
                    </li>
                    <li
                        class="yes-no yes"
                        :class="{ active: reactiveSelectedValues.surgeProtection}"
                        @click="formDataSave('surgeProtection', true, true, true)"
                        >
                        <span>{{ $t('Yes') }}</span>
                    </li>
                </ul>
            </div>
            <div class="base-attribute" v-if="selectedValues.configuratorMode === 'advanced'">
                <div class="flex vw-10">
                    <p>{{ $t('LED module thermal protection with NTC') }}</p>
                    <div class="attribute-tooltip flex" v-if="reactiveGeneralValues.attributeInfo['led_module_thermal_protection_with_ntc']">
                        <svg-image
                            class="icon hover-icon"
                            icon="info"
                        />
                        <span class="attribute-tooltiptext" v-html="reactiveGeneralValues.attributeInfo['led_module_thermal_protection_with_ntc']"></span>
                    </div>
                </div>
                <ul class="options">
                    <li
                        class="yes-no no"
                        :class="{ active: !reactiveSelectedValues.thermalProtectionNTC}"
                        @click="formDataSave('thermalProtectionNTC', false, true, true)">
                        <span>{{ $t('No') }}</span>
                    </li>
                    <li
                        class="yes-no yes"
                        :class="{ active: reactiveSelectedValues.thermalProtectionNTC}"
                        @click="formDataSave('thermalProtectionNTC', true, true, true)">
                        <span>{{ $t('Yes') }}</span>
                    </li>
                </ul>
            </div>
            <div class="warning-message flex-center" v-if="selectedValues.general.unfinishedItems && selectedValues.general.unfinishedItems.length > 0">
                <p class="mr-2">{{ $t('Required fields') }}:</p>
                <p v-if="!reactiveSelectedValues.configCount">{{$t('No configuration found! Please consider making changes to your selection.')}}</p>
                <p v-else v-html="sanatizedUnfinishedItems(selectedValues.general.unfinishedItems).join(', ')"></p>
            </div>
        </div>
        <div v-if="reactiveSelectedValues.isComplete" class="arrow-down" @click="changeActiveStep(selectedValues.configuratorMode == 'basic' ? 'step_5': 'step_4')">
            <svg-image
                class="icon hover-icon"
                icon="chevron_down"
                height="36"
                width="36"
            />
        </div>
        <IssueReportPopup
            :visible="openConfiguratorPopup"
            :userComment="userComment"
            @close="openConfiguratorPopup = !openConfiguratorPopup"
            @sendReport="sendReport"
        />
    </div>
    <div
        v-if="activeStep !== 'step_3' && activeStep !== 'step_6'"
        class="base-attribute-button"
        @click="changeActiveStep('step_3')"
        :class="{ 'base-attribute-button-close': isCloseToActiveStep, 'nextStep': isNextStep }"
    >
        <span>
            {{ $t('Step') }} 3 - {{ $t('Control and protection') }}
        </span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useProductConfigurator } from '@/modules/vizulo/productConfigurator';
import {
    ref,
    computed,
    watch,
} from '@nuxtjs/composition-api';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import IssueReportPopup from '~/components/Common/IssueReportPopup.vue';

export default defineComponent({
    name: 'Step_3',
    data: () => (
		{
            nemaSocketId: 94,
            zhagaSocketId: 92,
            mainsDimmingId: 49,
		}
	),
    components: {
        VueTimepicker,
        IssueReportPopup,
    },
	props: {
        activeStep: String,
        isCloseToActiveStep: Boolean,
        storeCode: String
	},
    setup(props, {emit}) {
        const { productConfigurator, updateSelectedValues, selectedValues, loading, testLuminaireConfigsAvailability, resetFormData, createBugReportInAsana } = useProductConfigurator();
        const step = 'step_3';
        const stepIndex = 3;
        const fluxPower = ref('flux');
        const openSockets = ref(false);
        const fieldIdsWithWarning = ref([]);
        const warningMessage = ref({
            target: '',
            text: ''
        });
        const zhagaSocketId = 92;
        const configCountRecieved = ref(false);
        const defaultWarningText = 'Please choose value between 0 and 100';
        const openConfiguratorPopup = ref(false);
        const userComment = ref({});
        const dimmingsWithSchedule = [41, 69];
        const unavailableDimmingIdsInBasicMode = [69, 48, 49, 43];
        const unavailableDimmingIdsInBasicModeUs = [69, 48, 49];

        const reactiveProductConfigurator = computed(() => {
            return productConfigurator.value.step_3 ?? [];
        });

        const reactiveSelectedValues = computed(() => {
            return selectedValues.value.step_3 ?? [];
        });

        const filteredDimmings = computed(() => {
            if (selectedValues.value.configuratorMode !== 'basic') {
                return reactiveProductConfigurator.value.dimming;
            } else {
                if (props.storeCode === 'us') {
                    return reactiveProductConfigurator.value.dimming.filter((dimming) => {
                        return !unavailableDimmingIdsInBasicModeUs.includes(Number(dimming.id));
                    });
                }

                return reactiveProductConfigurator.value.dimming.filter((dimming) => {
                    return !unavailableDimmingIdsInBasicMode.includes(Number(dimming.id));
                });
            }
        });

        const reactiveGeneralValues = computed(()=>{
            return selectedValues.value.general ?? [];
        });

        const mainContainer = ref(0);
        const elementHeight = computed(() => {
            return mainContainer.value.clientHeight;
        })

        const componentStyle = computed(() => ({
			width: '100%',
			height: 'inherit',
			borderRadius: '4px',
            animation: 'skeleton--shimmer',
            position: 'absolute',
            background: 'rgba(242, 242, 242, 0.8)',
            height: '100%',
            'z-index': "22",
		}));

        watch(() => reactiveSelectedValues.value.configCount, () => {
            if (reactiveSelectedValues.value.configCount === 0 && typeof window !== 'undefined') {
                scrollPageUp();
            }
		});

        const scrollPageUp = () => {
            if (window.document.getElementById('warning-message') !== null) {
                window.document.getElementById('warning-message').scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }

        const formDataSave = async (updatedAttribute, value = '', checkConfigAvailability = false, selectedByUser = false, dimmingAttribute = '') => {
            if (dimmingAttribute) {
                isInRange(dimmingAttribute, value);
            }

            if (updatedAttribute == 'surgeProtection' && reactiveSelectedValues.value.driverSize.length === 0) {
                return;
            }

            if (updatedAttribute === 'dimming' && props.storeCode === 'us' && dimmingsWithSchedule.includes(value.id)) {
                setUsDimmingSchedule();
            }

            updateSelectedValues(step, updatedAttribute, value, false, selectedByUser);

            if (updatedAttribute !== 'dimmingParameters') {
                if ((checkConfigAvailability && reactiveSelectedValues.value.surgeProtection
                    && (reactiveSelectedValues.value.dimming || reactiveSelectedValues.value.insulation))) {
                        testLuminaireConfigsAvailability(0, true, props.storeCode).then(() => {
                            if (reactiveSelectedValues.value.surgeProtection) {
                                configCountRecieved.value = true;
                            }
                            updateSelectedValues(step, updatedAttribute, value, false, selectedByUser);
                        });
                } else {
                    testLuminaireConfigsAvailability(0, true, props.storeCode).then(() => {
                        configCountRecieved.value = true;
                        updateSelectedValues(step, updatedAttribute, value, false, selectedByUser);
                    });
                }
            }
        };

        const isInRange = (attribute, value) => {
            const attributesTo100Mains = ['minLevel', 'maxLevel'];
            const attributesTo100Step = ['switchOnLevel' , 'SDLevel'];

            if (attributesTo100Mains.includes(attribute)) {
                warningMessage.value['text'] = '';
                warningMessage.value['target'] = attribute;

                if (parseInt(value['mains'][attribute]) > 100) {
                    value['mains'][attribute] = '100';
                    warningMessage.value['text'] = defaultWarningText;
                } else if (parseInt(value['mains'][attribute]) < 0) {
                    value['mains'][attribute] = '0';
                    warningMessage.value['text'] = defaultWarningText;
                }
            } else if (attributesTo100Step.includes(attribute)) {
                warningMessage.value['text'] = '';
                warningMessage.value['target'] = attribute;

                if (parseInt(value['step'][attribute]) > 100) {
                    value['step'][attribute] = '100';
                    warningMessage.value['text'] = defaultWarningText;
                } else if (parseInt(value['step'][attribute]) < 0) {
                    value['step'][attribute] = '0';
                    warningMessage.value['text'] = defaultWarningText;
                }
            }
        }

        const setUsDimmingSchedule = () => {
            if (!selectedValues.value.step_3.scheduleIsSet) {
                selectedValues.value.step_3.scheduleIsSet = true;

                for (let key in selectedValues.value.step_3.dimmingParameters.midnight.steps) {
                    if (selectedValues.value.step_3.dimmingParameters.midnight.steps.hasOwnProperty(key)) {
                        const value = selectedValues.value.step_3.dimmingParameters.midnight.steps[key];

                        if (key == 2) {
                            value['setTime']['hh'] = '11';
                            value['setTime']['A'] = 'PM';
                        }

                        if (key == 3) {
                            value['setTime']['hh'] = '05';
                            value['setTime']['A'] = 'AM';
                        }
                    }
                }
            }
        }

        const changeActiveStep = (value) => {
            emit('changeStep', (value));
        }

        const manageSocketOptions = (socket, socketLocationName) => {
            selectedValues.value.step_3.sockets[socketLocationName] = socket;
            formDataSave('sockets', selectedValues.value.step_3.sockets, true, true);
        }

        const isSocketsVisible = () => {
            for (const property in reactiveSelectedValues.value.sockets) {
                if (reactiveSelectedValues.value.sockets[property] !== null) {
                    openSockets.value = false;
                }
            }
        }

        const keepSocketsOpen = () => {
            for(const id in reactiveProductConfigurator.value['socketLocations']) {
                const locationName = reactiveProductConfigurator.value['socketLocations'][id].name;
                if (reactiveSelectedValues.value.sockets && reactiveSelectedValues.value.sockets[locationName] && reactiveSelectedValues.value.sockets[locationName].id !== 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        const displayWarning = computed(()=>{
            let triggerComputedProperty = configCountRecieved.value;

            if (reactiveSelectedValues.value.configCount === 0 && configCountRecieved.value) {
                return true;
            }

            return false;
        })

        const isFirefox = () => {
            if (navigator.userAgent.includes('Firefox')) {
                return true;
            } else {
                return false
            }
        }

        const isNextStep = computed(()=>{
			let activeStepIndex = props.activeStep.charAt(props.activeStep.length - 1);

			if (parseInt(activeStepIndex) === stepIndex - 1 && !reactiveSelectedValues.value['isComplete']) {
				return true;
			}

			return false;
        })

        const sendReport = (sentWithSubmitButton) => {
            createBugReportInAsana(userComment.value.message ?? '', sentWithSubmitButton);
            openConfiguratorPopup.value = false;
        }

        const sanatizedUnfinishedItems = (items) => {
            const indexToRemove = items.indexOf("ConfigCount");
            if (indexToRemove !== -1) {
                items.splice(indexToRemove, 1);
            }

            return items;
        }

        // Kapēc step 3 dimming un getDimming atšķiras pie zhaga bottom!
        const updateOutputLevel = (value, step) => {
            warningMessage.value['target'] = 'outputLevel';
            if (isNaN(parseInt(value))) {
                fieldIdsWithWarning.value.push(step);
                warningMessage.value['text']  = 'Error! Please use only numbers!';
                return;
            } else if (value > 100) {
                fieldIdsWithWarning.value.push(step);
                warningMessage.value['text'] = 'Error! Choose number between 0 - 100!';
            } else {
                fieldIdsWithWarning.value = fieldIdsWithWarning.value.filter((item) => {
                    return item !== step;
                });
            }

            formDataSave('dimmingParameters',  reactiveSelectedValues.value.dimmingParameters);
        }

        return {
            formDataSave,
            changeActiveStep,
            manageSocketOptions,
            isSocketsVisible,
            keepSocketsOpen,
            updateOutputLevel,
            testLuminaireConfigsAvailability,
            isFirefox,
            resetFormData,
            sendReport,
            sanatizedUnfinishedItems,
            componentStyle,
            elementHeight,
            reactiveGeneralValues,
            displayWarning,
            selectedValues,
            isNextStep,
            fluxPower,
            reactiveProductConfigurator,
            reactiveSelectedValues,
            loading,
            zhagaSocketId,
            openSockets,
            fieldIdsWithWarning,
            warningMessage,
            openConfiguratorPopup,
            userComment,
            filteredDimmings,
        };
    }
});
</script>

<style lang="scss" scoped>
.vw-10 {
    min-width: 8vw;
}

.base-attribute .w-100 > p{
    font-weight: 600;
    font-size: 16px;
}

.dimming {
    align-items: flex-start !important;
    border-bottom: none !important;

    .options {
        justify-content: flex-start;

        @media (min-width: 999px) {
            justify-content: flex-end;
        }
    }

    p {
        margin-top: 30px;
    }
}

.images {

  img {
    border: 1px solid black;
    width: 100px;
    margin: 12px;
  }

  .image-container {
    position: relative;

    .indicator {
        position: absolute;
        right: 1rem;
        top: 1rem;
        height: 10px;
        width: 10px;
        background: greenyellow;
        border-radius: 50%;
    }
  }

  @include for-mobile {
    min-height: 10vh
  }
}

.options {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .option, .yes-no {
        list-style: none;
        font-size: 14px;
        line-height: 18px;
        background-color: var(--c-white);
        cursor: pointer;
        font-family: var(--font-family--primary-bold);
        padding: 9px 12px;
        border: 1px solid #979797;
        transition: all ease-in 300ms;
        display: inline-block;
        margin: 6px 0;

        span {
            white-space:nowrap;
        }

        &:focus {
            outline: none;
        }
    }
}

.indicators {
    display: inline-block;
    position: relative;

    &:after {
        content: "";
        color: var(--c-black);
        position: absolute;
        bottom: -15px;
        right: 0;
        width: 2px;
        height: 24px;
        background: var(--c-black);
        transform: rotate(-30deg);
    }

    &::before {
        content: "";
        color: var(--c-black);
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 2px;
        height: 24px;
        background: var(--c-black);
        transform: rotate(30deg);
    }
}

::v-deep .vue__time-picker {
    z-index: 20;
    input {
        padding: 0.35em 0.25em;
        margin: 0;
        width: 110px;
        text-align: center;
    }
}

.dimming-table {
    width: 100%;

    &:first-letter {
        text-transform: capitalize;
    }

    input {
        padding: 0.35em 0.25em;
        margin: 0;
        width: 110px;
        text-align: center;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 1.5rem 0;
        border: 1px solid var(--_c-gray-accent);

        &:first-child {
            text-align: left;
            padding-left: 1.2rem;
            background: var(--c-white-blue);
            width: 16%;
            font-weight: bold;
        }
    }

    th {
        background: var(--c-white-blue);
    }

    td {
        text-align: center;
        width: calc(74% / 6);
    }

    p {
        text-transform: none;
        font-weight: bold;
    }

    .flex-between {
        width: 440px;
    }
}

.cover {
    display: block;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 7, 7, 0.5);
    }
}

.inactive-no {
    background-color:#02020269 !important;
    border: 1px solid rgba(157, 157, 157, 0.53) !important;

    &:hover {
        background-color: none !important;
    }
}

.sockets {
    height: 0;
}

.dynamic-height {
    height: auto;
    transition: height 0.25s ease-in;
}

.normal-font {
    margin-left: 0;
    font-weight: normal !important;

    @media (min-width: 800px) {
        margin-left: 6rem;
    }
}

.mr-15{
    margin-right: 15px;
}

.inline {
    display: inline-block;
}

.warning-message-large {
    display: flex;
    justify-content: center;
    background: rgb(255, 213, 136);
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.clo-options {
    border-bottom: 1px solid var(--_c-gray-accent);
    .warning-message {
        position: unset;
        text-align: right;
    }
}

.voltage, .levels {
    position: relative;
}

.voltage{
    input {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='80' y='20' fill='gray' font-size='16' font-family='arial'>V</text></svg>");
        background-repeat: no-repeat;
    }
}

.voltage-firefox{
    input {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='80' y='24' fill='gray' font-size='16' font-family='arial'>V</text></svg>") !important;
    }
}

.levels {
    input {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='80' y='20' fill='gray' font-size='16' font-family='arial'>%</text></svg>");
        background-repeat: no-repeat;
    }
}

.levels-firefox {
    input {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='80' y='24' fill='gray' font-size='16' font-family='arial'>%</text></svg>") !important;
    }
}

::v-deep .loader {
    border: 5px solid var(--_c-gray-secondary) !important;
    border-top: 6px solid #000 !important;
    opacity: 1 !important;
}
</style>