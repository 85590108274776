<template>
    <div class="error-page">
        <img src="/media/404.jpg" class="w-100">
    </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
	layout: 'error',
	props: {
		error: {
			type: Object,
			required: true,
		},
	},
});
</script>
<style lang="scss" scoped>

</style>
