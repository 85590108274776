var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-6 mb-6"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"text-center"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('Members'))+"\n\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[(_vm.isLogin)?_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-lg-6 mb-2"},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleLogin)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email'),"type":"email"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element mb-2",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{staticClass:"form__element float-left",attrs:{"name":"remember_me","label":_vm.$t('Remember me')},model:{value:(_vm.form.rememberMe),callback:function ($$v) {_vm.$set(_vm.form, "rememberMe", $$v)},expression:"form.rememberMe"}})]}}],null,true)}),_vm._v(" "),(_vm.error.login)?_c('p',{staticClass:"text-danger"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t(_vm.error.login))+"\n\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),_c('Button',{staticClass:"button button-primary w-100",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Login'))+"\n\t\t\t\t\t\t\t\t")])],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 text-center mt-3"},[_c('Button',{staticClass:"button button-text",on:{"click":function($event){return _vm.setIsForgottenValue(true)}}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Forgot password?'))+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('p',[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Don\'t have an account yet?'))+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('Button',{staticClass:"button button-text",on:{"click":function($event){return _vm.setIsRegisterValue(true)}}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Register'))+"\n\t\t\t\t\t\t\t")])],1)])]}}],null,false,680998453)}):(_vm.isForgotten)?_c('ValidationObserver',{key:"forgotten-password",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-9 col-lg-6"},[_c('p',{staticClass:"mb-4"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Forgot Password'))+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleForgotten)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Forgot Password Modal Email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),(_vm.forgotPasswordError.request)?_c('p',{staticClass:"text-danger"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('It was not possible to request a new password, please check the entered email address.'))+"\n\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),_c('div',[_c('Button',{staticClass:"button button-primary w-100",attrs:{"type":"submit","disabled":_vm.forgotPasswordLoading}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Reset Password'))+"\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"text-center mt-2"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('or'))+"\n\t\t\t\t\t\t\t\t\t\t"),_c('Button',{staticClass:"button button-text",on:{"click":function($event){return _vm.setIsLoginValue(true)}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('go to login page'))+"\n\t\t\t\t\t\t\t\t\t\t")])],1)],1)],1)])])]}}])}):(_vm.isThankYouAfterForgotten)?_c('div',{staticClass:"thank-you"},[_c('i18n',{staticClass:"thank-you__paragraph text-center",attrs:{"tag":"p","path":"forgotPasswordConfirmation"}},[_c('span',{staticClass:"thank-you__paragraph--bold"},[_vm._v(_vm._s(_vm.userEmail))])]),_vm._v(" "),_c('p',{staticClass:"thank-you__paragraph text-center"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('Thank You Inbox'))+"\n\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"text-center mt-2"},[_c('Button',{staticClass:"button button-text",on:{"click":function($event){return _vm.setIsLoginValue(true)}}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Go to login page'))+"\n\t\t\t\t\t\t")])],1)],1):(_vm.isRegister)?_c('ValidationObserver',{key:"sign-up",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Fill the form and become a member of vizulo.com')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('To become a member you must fill this form. Our team will review it and give positive or negative answer to your request. All fields are required.')))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-6"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleRegister)}}},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Your email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first_name","label":_vm.$t('First Name')},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"last_name","label":_vm.$t('Last Name')},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"company_name","label":_vm.$t('Company name')},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"taxvat","label":_vm.$t('Company VAT number')},model:{value:(_vm.form.taxvat),callback:function ($$v) {_vm.$set(_vm.form, "taxvat", $$v)},expression:"form.taxvat"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"company_address","label":_vm.$t('Company address')},model:{value:(_vm.form.company_address),callback:function ($$v) {_vm.$set(_vm.form, "company_address", $$v)},expression:"form.company_address"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"company_position","label":_vm.$t('Your position in company')},model:{value:(_vm.form.company_position),callback:function ($$v) {_vm.$set(_vm.form, "company_position", $$v)},expression:"form.company_position"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"phone-number","label":_vm.$t('Phone number')},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)})],1),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__element",attrs:{"label":_vm.$t('Sign Up for Newsletter'),"name":"signupNewsletter"},model:{value:(_vm.isSubscribed),callback:function ($$v) {_vm.isSubscribed=$$v},expression:"isSubscribed"}}),_vm._v(" "),(_vm.error.register)?_c('p',{staticClass:"text-danger"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t(_vm.error.register))+"\n\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),_c('Button',{staticClass:"button button-primary w-100",attrs:{"type":"submit","disabled":_vm.loading || invalid}},[_c('div',[_vm._v(_vm._s(_vm.$t('Request access')))])])],1),_vm._v(" "),_c('div',{staticClass:"text-center mt-2"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('or'))+"\n\t\t\t\t\t\t\t\t"),_c('Button',{staticClass:"button button-text",on:{"click":function($event){return _vm.setIsLoginValue(true)}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('go to login page'))+"\n\t\t\t\t\t\t\t\t")])],1)])])]}}])}):(_vm.isThankYouAfterRegister)?_c('div',{staticClass:"thank-you text-center"},[_c('p',{staticClass:"thank-you__paragraph"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('Thank you for registering! However, our team will review your request and give positive or negative answer to it via email.'))+"\n\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"text-center mt-2"},[_c('Button',{staticClass:"button button-text",on:{"click":function($event){return _vm.setIsLoginValue(true)}}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t('Go to login page'))+"\n\t\t\t\t\t\t")])],1)]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }