<template>
  <div class="subscribe container">
    <h3>{{ $t('Join our newsletter') }}</h3>
    <div class="flex">
        <p>{{ $t("You'll be first to know about exciting new products and events. We promise not to spam.") }}</p>
        <form action="https://vizulo.com/en/newsletter/register" method="get" class="subscribe-form">
            <input class="email" type="email" id="newsletter_email" name="email" value="" placeholder="Email">
            <button>{{ $t('Send') }}</button>
        </form>
    </div>
    <span>{{ $t('Valid email is required') }}</span>
    <div class="subscribe-thanks">{{ $t('Thank you for signing up!') }}</div>
  </div>
</template>

<script>

import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'NewsLetterForm',
    components: {},
    data() {
        return {

        }
    },
    setup() {},
});
</script>

<style lang="scss" scoped>
.subscribe {
    color: var(--_c-dark-primary);
    line-height: 28px;
    margin-right: 0;

    @media (min-width: 600px) {
        margin-right: 30%;
    }

    @media (min-width: 999px) {
        margin-right: 0;
        padding-right: 0;
    }

    @media (max-width: 991px) {
        padding: 0;
    }

    h3 {
        margin: 0;
        font-size: 16px;
        
        @media (min-width: 992px) {
            font-size: 20px;
        }
    }

    p {
        margin-bottom: 32px;
    }

    .subscribe-form {
        position: relative;
        border-bottom: 1px solid var(--_c-dark-primary);
        display: flex;
        justify-content: space-between;
        width: 100%;

        .email {
            width: 100%;

            @media (min-width: 1200px) {
                width: 80%;
            }
        }

        input, button {
            border: none;
            background: none;
        }

        input:focus {
            outline: none;
        }

        button:hover {
            cursor: pointer;
        }

        button {
            padding-right: 0;
        }
    }

    span {
        display: none;
    }

    .subscribe-thanks {
        display: none;
    }

    .flex {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @media (min-width: 1200px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        p {
            flex-basis: none;

            @media (min-width: 1200px) {
                flex-basis: 42%;
            }
        }

        form {
            position: relative;
            bottom: 16px;
            flex-basis: none;

            @media (min-width: 1200px) {
                flex-basis: 52%;
            }
        }
    }
}
</style>