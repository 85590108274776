var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-6 mb-6",attrs:{"id":"reset-password"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('SfHeading',{staticClass:"heading sf-heading--no-underline mb-3",attrs:{"title":_vm.$t('Reset Password'),"level":2}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 offset-lg-3 col-lg-6"},[(!_vm.isPasswordChanged)?_c('div',[_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.setNewPassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":"Your email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"label":_vm.$t('Password'),"name":"password","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"label":_vm.$t('Repeat Password'),"name":"repeat-password","type":"password"},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}})]}}],null,true)}),_vm._v(" "),(_vm.passwordMatchError || _vm.forgotPasswordError.setNew)?_c('div',[_vm._v("\n                            "+_vm._s(_vm.passwordMatchError || _vm.forgotPasswordError.setNew.message)+"\n                        ")]):_vm._e(),_vm._v(" "),_c('Button',{staticClass:"button button-primary w-100",attrs:{"type":"submit","disabled":_vm.forgotPasswordLoading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Save Password')))])])],1)]}}],null,false,733869508)})],1):_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"p-4"},[_vm._v(_vm._s(_vm.$t('Password Changed')))]),_vm._v(" "),_c('nuxt-link',{staticClass:"button button-primary",attrs:{"to":_vm.localePath('/login')}},[_vm._v("\n                    "+_vm._s(_vm.$t('Go to login page'))+"\n                ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }