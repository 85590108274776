<template>
    <div class="map-popup">
        <div id="map-container" style="width: 100%; height: 300px;">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                map: null,
            };
        },
        mounted() {
            // Load Here.com API
            const scriptCore = document.createElement('script');
            scriptCore.src = 'https://js.api.here.com/v3/3.1/mapsjs-core.js';
            scriptCore.type = 'text/javascript';
            scriptCore.charset = 'utf-8';
            scriptCore.onload = this.loadMapService;


            document.head.appendChild(scriptCore);

        },
        methods: {
            loadMapService() {
                // Load Here.com Map Service
                const scriptService = document.createElement('script');
                scriptService.src = 'https://js.api.here.com/v3/3.1/mapsjs-service.js';
                scriptService.type = 'text/javascript';
                document.head.appendChild(scriptService);

                scriptService.onload = this.loadMapEvents;
            },
            loadMapEvents() {
                // Load Here.com Map Events
                const scriptEvents = document.createElement('script');
                scriptEvents.src = 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js';
                scriptEvents.type = 'text/javascript';
                document.head.appendChild(scriptEvents);

                scriptEvents.onload = this.initializeMap;
            },
            initializeMap() {
                // Initialize the map
                const platform = new H.service.Platform({
                apikey: '9CRiXTIlilwObJvzDGMDzrGbkaVnNvVQ8psCpIioIp4',
                });

                if ("geolocation" in navigator) {
                    // Prompt user for permission to access their location
                    navigator.geolocation.getCurrentPosition(
                        // Success callback function
                        (position) => {
                        // Get the user's latitude and longitude coordinates
                        const lat = position.coords.latitude;
                        const lng = position.coords.longitude;
                        this.map.setCenter({ lat, lng });

                        // Do something with the location data, e.g. display on a map
                        },
                        // Error callback function
                        (error) => {
                        // Handle errors, e.g. user denied location sharing permissions
                            console.error("Error getting user location:", error);
                        }
                    );
                } else {
                    // Geolocation is not supported by the browser
                    console.error("Geolocation is not supported by this browser.");
                }

                const defaultLayers = platform.createDefaultLayers();
                this.map = new H.Map(
                  document.getElementById('map-container'),
                  defaultLayers.vector.normal.map,
                  {
                    center: { lat: 56.963003, lng: 24.112621 }, // Set initial center coordinates
                    zoom: 11, // Set initial zoom level
                  }
                );

                // Enable behavior for pan and zoom
                const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
            },
        },
    };
</script>

<style scoped>
  /* Add styles as needed */
  .map-popup {
    width: 100%;
    height: 40%;
    /* position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%); */
    z-index: 100;
    background: gray;
  }

  #map-container {
    width: 100%;
    height: 400px; /* Set an initial height or adjust as needed */
    overflow: auto; /* Add overflow to make it scrollable if needed */
  }

  #map-container canvas {
    width: 100% !important; /* Force the map canvas to take 100% of the container's width */
    height: auto !important; /* Allow the map canvas to adjust its height automatically */
  }
</style>
