<template>
    <transition name="sf-fade">
        <div
            :class="[{ 'hidden': !visible }, colorClass]"
            class="sf-notification"
        >
            <div class="notification-container" @click="closeHandler">
                <div>
                    <slot name="icon" v-bind="{ icon }">
                        <SfIcon
                            :class="{ 'hidden': !icon }"
                            class="sf-notification__icon"
                            :icon="icon"
                            size="lg"
                            color="white"
                        />
                    </slot>
                    <slot name="title" v-bind="{ title }">
                        <div
                            :class="{ 'hidden': !title }"
                            class="sf-notification__title smartphone-only"
                        >
                            {{ title }}
                        </div>
                    </slot>
                    <slot name="message" v-bind="{ message }">
                        <span
                            :class="{ 'hidden': !message }"
                            class="sf-notification__message"
                            >{{ message }}</span
                        >
                    </slot>
                    <slot name="action" v-bind="{ action, actionHandler }">
                        <Button
                            :class="{ 'hidden': !action }"
                            class="sf-button--pure sf-notification__action"
                            @click="actionHandler"
                        >
                            {{ action }}
                        </Button>
                    </slot>
                </div>
                <slot name="close" v-bind="{ closeHandler }">
                    <Button
                        aria-label="Close notification"
                        class="sf-button--pure sf-notification__close"
                        @click="closeHandler"
                    >
                        <SfIcon icon="cross" color="white" />
                    </Button>
                </slot>
            </div>
        </div>
    </transition>
</template>
<script>
import { SfIcon } from '@storefront-ui/vue';
import Button from '~/components/Common/Button.vue';

export default {
    name: "SfNotification",
    components: {
        SfIcon,
        Button,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        message: {
            type: String,
            default: "",
        },
        action: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "secondary",
            validator: function (value) {
                return ["secondary", "info", "success", "warning", "danger"].includes(
                    value
                );
            },
        },
    },
    computed: {
        icon() {
            switch (this.type) {
                case "success":
                    return "added_to_cart";
                case "danger":
                    return "info_shield";
                default:
                    return "info_circle";
            }
        },
        colorClass() {
            switch (this.type) {
                case "secondary":
                    return "color-secondary";
                case "info":
                    return "color-secondary";
                case "success":
                    return "color-secondary";
                case "warning":
                    return "color-secondary";
                case "danger":
                    return "color-secondary";
                default:
                  return "color-secondary";
            }
        },
    },
    methods: {
        actionHandler() {
            this.$emit("click:action");
        },
        closeHandler() {
            this.$emit("click:close");
        },
    },
};
</script>
<style lang="scss">
    @import "~@storefront-ui/shared/styles/components/molecules/SfNotification.scss";

    .notification-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: -120px;
        padding: 4px 15px 4px 120px;
        position: relative;
     
        @media (max-width: 991px) {
            font-size: 14px;
        }

        .svg-image {
            position: relative;

            svg {
                position: absolute;
                left: -25px;
                top: -20px;
            }
        }
    }

    .sf-notification {
        border-radius: 0;
        max-width: 100%;

         &__message {
            display: inline;
         }
    }
</style>
