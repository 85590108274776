import { render, staticRenderFns } from "./LoginAsCustomer.vue?vue&type=template&id=52d23dfc&scoped=true&"
import script from "./LoginAsCustomer.vue?vue&type=script&lang=ts&"
export * from "./LoginAsCustomer.vue?vue&type=script&lang=ts&"
import style0 from "./LoginAsCustomer.vue?vue&type=style&index=0&id=52d23dfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d23dfc",
  null
  
)

export default component.exports