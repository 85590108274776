<template>
    <div>
        <div>
            <div class="mt-3" v-if="!showFeatureInMobileView">
                <div v-if="currentFeature" class="media" :class="currentFeature && currentFeature.video ? 'video' : 'image'">
                    <div id="image-flicker">
                        <video
							:key="currentFeatureIndex"
							v-if="currentFeature.video"
							:src="currentFeature.video"
							loop autoplay muted
						/>
                        <img :key="currentFeatureIndex + 1" v-else v-bind:src="currentFeature.image" alt="category features">
                    </div>
                    <div class="previous" @click="previousFeature()">
                        <svg-image
                            icon="arrow-right-short"
                            width="20"
                            height="20"
                        />
                    </div>
                    <div class="next" @click="nextFeature()">
                        <svg-image
                            icon="arrow-right-short"
                            width="20"
                            height="20"
                        />
                    </div>
                </div>
                <div v-if="currentFeature">
                    <div class="navigation">
                        <div v-if="categoryFeatures.items" class="page">
                            <p>
                                {{ currentFeatureIndex + 1 > 9 ? currentFeatureIndex: `0${currentFeatureIndex+1}` }} /
                                {{ categoryFeatures.items.length > 9 ? categoryFeatures.items.length: `0${categoryFeatures.items.length}` }}
                            </p>
                        </div>
                    </div>
                    <h3>{{ currentFeature.name }}</h3>

                    <p class="description" v-if="currentFeature.content">
                        <ReadMore :maxChars="400" :text="currentFeature.content" />
                    </p>
                    <img v-if="currentFeature.gallery" v-bind:src="currentFeature.gallery" alt="category additional features">
                </div>
                <div class="media" v-else>
                </div>
            </div>
            <div v-else>
                <div class="feature-scroll" v-for="item of categoryFeatures.items" :key="item.id" >
                    <video v-if="item.video" loop autoplay muted>
                        <source v-bind:src="item.video" type="video/mp4">
                    </video>
                    <img v-else v-bind:src="item.image" alt="category features">
                    <h3>{{ item.name }}</h3>
                    <p v-if="currentFeature && currentFeature.content" class="description">
                        <ReadMore :maxChars="400" :text="item.content" />
                    </p>
                    <img v-if="item.gallery" v-bind:src="item.gallery" alt="category additional features">
                </div>
            </div>
            <div v-if="updatedProp"></div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from '@nuxtjs/composition-api';
import ReadMore from '~/components/Common/ReadMore.vue';

export default {
	components: {
		ReadMore,
	},
	props: {
		featureCategoryId: {
			type: Number,
			required: true,
		},
		categoryFeatures: {
			type: [Array, Object],
			required: true
		},
		menuSelectedFeatureIndex: {
			type: Number
		},
		showFeatureInMobileView: {
			type: Boolean,
			default: false,
		},
		productSku: {
			type: String,
		},
		showLast: {
			type: Boolean,
			default: false,
		}
	},
	setup(props ,{ emit }) {
		const currentFeature = ref(null);
		const currentFeatureIndex = ref(0);
		const updatedProp = computed(() => getCategoryFeatures());

		const getCategoryFeatures = () => {
			if (props.showLast) {
				currentFeature.value = props.categoryFeatures.items[props.categoryFeatures.items.length - 1];
				currentFeatureIndex.value = props.categoryFeatures.items.length - 1;
			} else {
				currentFeature.value = props.categoryFeatures.items[currentFeatureIndex.value];
			}

			return currentFeature;
		};

		watch(() => props.menuSelectedFeatureIndex, (newMenuSelectedFeatureIndex) => {
			if (newMenuSelectedFeatureIndex !== null) {
				currentFeatureIndex.value = newMenuSelectedFeatureIndex;
			}
		});

		const setFadeInClassForImageFlicker = () => {
			const imageFlicker = document.getElementById('image-flicker');

			if (imageFlicker) {
				imageFlicker.classList.add('fade-in');

				setTimeout(removeFadeClass, 500);

				function removeFadeClass() {
					imageFlicker.classList.remove('fade-in');
				}
			}
		}

		const nextFeature = () => {
			currentFeatureIndex.value++;
			if (props.categoryFeatures.items[currentFeatureIndex.value]) {
				currentFeature.value = props.categoryFeatures.items[currentFeatureIndex.value];
			} else {
				currentFeatureIndex.value = 0;
				currentFeature.value = props.categoryFeatures.items[currentFeatureIndex.value];
				emit('changeCategory',(1));
			}

			emit('setMenuSelectedFeature', currentFeatureIndex.value);
			setFadeInClassForImageFlicker();
		}

		const previousFeature = () => {
			if (props.categoryFeatures.items[currentFeatureIndex.value -1]) {
				currentFeatureIndex.value--;
				currentFeature.value = props.categoryFeatures.items[currentFeatureIndex.value];
			} else {
				currentFeatureIndex.value = props.categoryFeatures.items.length - 1;
				currentFeature.value = props.categoryFeatures.items[currentFeatureIndex.value];
				emit('changeCategory',(-1));
			}

			emit('setMenuSelectedFeature', currentFeatureIndex.value);
			setFadeInClassForImageFlicker();
		}

		return {
			updatedProp,
			currentFeature,
			currentFeatureIndex,
			nextFeature,
			previousFeature
		};
	}
};
</script>

<style lang="scss" scoped>
.container {
    .navigation {
        position: relative;

        .page {
            position: absolute;
            top: 0;
            right: 0;

            p {
                margin: 0;
            }
        }
    }

    .arrows {
        cursor: pointer;
        display: flex;

        .previous {
            margin-right: 30px;
        }
    }
}

.loader {
    min-height: 400px;
}

.media {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 992px) {
        height: 550px;
    }

    @media (min-width: 1400px) {
        height: 650px;
    }

    .previous,
    .next {
        display: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        padding: 10px;
        background: var(--c-white);
        cursor: pointer;

        @media (min-width: 992px) {
            display: block;
        }
    }

    .previous {
        left: 10%;

        .svg-image {
            position: relative;
            transform: rotate(180deg);
        }
    }

    .next {
        left: 90%;
    }

    img  {
        width: 100%;
    }

    & + div {
        min-height: 200px;
    }
}

video {
    width: 100%;
}

@keyframes fadeOut {
   0% {opacity: 1;}
   100% {opacity: 0;}
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}

.feature-scroll {
    margin-bottom: 52px;
}

.fade-in {
    animation: fadeIn  0.6s;
}

.fade-out {
    animation: fadeOut 0.6s;
}
</style>