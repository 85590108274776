<template>
    <div class="container contacts">
        <div class="row">
            <GetInTouch />
            <div class="contacts-image col-12 col-lg-8">
                <img src="~/static/media/images/contact-image-vizulo.jpg" alt="Vizulo">
            </div>
        </div>
    </div>
</template>
<script type="module">

import { defineComponent } from '@nuxtjs/composition-api';
import GetInTouch from '~/components/Common/GetInTouch.vue';

export default defineComponent({
	name: 'Contacts',
	components: {
		GetInTouch,
	},
	head() {
		const title = 'Contacts - Vizulo';
		const meta = [];

		return {
			title,
			meta,
		};
	},
	setup() {
	},
});

</script>

<style lang="scss" scoped>
.contacts {
    padding-top: 128px;
    margin-bottom: 120px;

    @media (max-width:991px) {
        padding-top: 35px;
        margin-bottom: 35px;
    }

    &-image {
        img {
            width: 100%;
        }

        @media (max-width: 992px) {
            margin-top: 35px;
        }
    }

    h1 {
        font-size: 28px;
        line-height: 38px;
        font-weight: 400;
        padding: 0 15px;
    }
}
</style>
