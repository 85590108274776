<template>
    <client-only>
        <div>
            <sf-loader class="references-loader" :loading="!references.length">
                <div class="project">
                    <div class="project-wr">
                        <MobileImageSlider
                            v-if="isMobile && mobileReference"
                            :reference="mobileReference"
                            :key="mobileReference.entity_id"
                        />
                        <div 
                            v-if="!isMobile"
                            class="project-main-slider-wr"
                        >
                            <vue-slick-carousel                            
                                ref="bigImagesCarousel"
                                v-bind="bigImagesCarouselSettings"
                                class="project-main-slider"
                            >
                                <div
                                    v-for="main in references"
                                    :key="main.entity_id"
                                    class="slide"
                                >
                                    <div
                                        class="slide-image js-open-popup-gallery"
                                        :data-index="main.entity_id"
                                        :style="backgroundStyle(main.image.wide)"
                                        @click="openReferenceModal(main)"
                                    >
                                    </div>
                                </div>
                            </vue-slick-carousel>
                        </div>

                        <div class="right-side">
                            <div class="project-info-slider-wr">
                                <vue-slick-carousel
                                    ref="infoCarousel"
                                    v-bind="infoCarouselSettings"
                                    :as-nav-for="bigImagesCarousel"
                                    class="project-info-slider"
                                    @init="onInit"
                                    @beforeChange="onSlideBeforeChange"
                                >
                                    <div
                                        v-for="info in references"
                                        :key="info.entity_id"
                                        class="slide pointer"
                                    >

                                        <div class="title">{{ $t('Reference') }} #{{ page }}</div>
                                        <country-flag :country='info.country_id' size='normal'/>
                                        <h6>{{ referencesGetters.getFullName(info) }}</h6>

                                        <div class="related-products slider-products">
                                            <div>{{ $t('Luminaires') }}</div>
                                            <div
                                                v-for="product in info.products"
                                                :key="product.sku"
                                            >
                                                <Link
                                                    :icon="true"
                                                    :link="productGetters.getProductLink(product)"
                                                    :text="product.name"
                                                />
                                            </div>
                                        </div>
                                        <p>
                                            <Link
                                                :icon="true"
                                                :link="referencesGetters.getSlug(info)"
                                                :text="$t('About project')"
                                            />
                                            <Link
                                                :icon="true"
                                                :link="localePath('references')"
                                                :text="$t('See all references')"
                                            />
                                        </p>
                                    </div>
                                </vue-slick-carousel>
                            </div>
                            <div class="project-nav-slider-wr">
                                <vue-slick-carousel
                                    v-bind="smallImagesCarouselSettings"
                                    :as-nav-for="infoCarousel"
                                    class="project-nav-slider"
                                >
                                    <div
                                        v-for="nav in references"
                                        :key="nav.entity_id"
                                        class="slide pointer"
                                    >
                                        <div class="slide-image">
                                            <img class="arrow-btn-next" :src="nav.image.horizontal" alt="">
                                        </div>
                                    </div>
                                    <template #prevArrow>
                                        <div class="project-nav-slider-arrows prev">
                                            <svg-image
                                                width="24"
                                                height="24"
                                                class="icon pointer"
                                                icon="arrow_left"
                                            />
                                        </div>
                                    </template>
                                    <template #nextArrow>
                                        <div class="project-nav-slider-arrows next">
                                            <svg-image
                                                class="icon pointer arrow"
                                                icon="arrow_right"
                                                width="24"
                                                height="24"
                                            />
                                        </div>
                                    </template>
                                </vue-slick-carousel>
                                <div class="row project-nav">
                                    <div class="col-4">
                                        <div class="project-nav-slider-counter">
                                            <span class="js-slide-current">{{ page }}</span> / <span class="js-slides-total">{{ references.length }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </sf-loader>

            <SfModal
                :visible="galleryModal"
                class="modal fullscreen"
                @close="openModal()"
            >
                <div class="col-11 popup-gallery__head product">
                    <div class="container top">
                        <h4>{{ referencesGetters.getFullName(galleryModalObject) }}</h4>
                        <template v-for="product in galleryModalObject.products">
                            <Link
                                :icon="true"
                                :link="productGetters.getProductLink(product)"
                                :text="product.name"
                            />
                        </template>                            
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 product-gallery-modal-images">
                            <vue-slick-carousel
                                ref="modalImagesCarousel"
                                v-bind="modalImagesCarouselSettings"
                            >
                                <template v-for="(image, index) in galleryModalObject.gallery">
                                    <img
                                        :key="index"
                                        :src="image.original"
                                        alt="image"
                                    >
                                </template>
                                <template #prevArrow>
                                    <div class="product-gallery-modal-arrows prev">
                                        <svg-image
                                            width="36"
                                            height="36"
                                            class="icon pointer"
                                            icon="arrow_left"
                                        />
                                    </div>
                                </template>
                                <template #nextArrow>
                                    <div class="product-gallery-modal-arrows next">
                                        <svg-image
                                            class="icon pointer arrow"
                                            icon="arrow_right"
                                            width="36"
                                            height="36"
                                        />
                                    </div>
                                </template>
                            </vue-slick-carousel>
                        </div>
                    </div>
                </div>
            </SfModal>
        </div>
    </client-only>
</template>

<script>
import {
    defineComponent, 
    useAsync,
    useRoute,
    useContext,
    ref,
    onMounted,
    reactive,
} from '@nuxtjs/composition-api';
import { SfGallery, SfLoader, SfModal } from '@storefront-ui/vue';
import CountryFlag from 'vue-country-flag';
import { productGetters, referencesGetters } from '~/getters';
import Link from '~/components/Common/Link.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import { useUiState } from '~/composables';
import MobileImageSlider from './MobileImageSlider.vue';

export default defineComponent({
    name: 'ReferencesSlider',
    components: {
        VueSlickCarousel,
        SfGallery,
        SfLoader,
        SfModal,
        CountryFlag,
        Link,
        MobileImageSlider,
    },
    data: () => (
        {
            page: 1,
        }
    ),
    methods: {
        onInit: function() {
            this.mobileReference = this.references[0];
        },
        onSlideBeforeChange: function(currentIndex, nextIndex) {
            this.page = nextIndex + 1;

            if (this.isMobile) {
                this.mobileReference = this.references[nextIndex];
            }
        },
    },
    props: {
        loading: Boolean,
        references: {
            type: [Array],
            default: () => [],
        }
    },
    setup(props) {
        const { app } = useContext();
        const { isMobile } = useUiState();

        const bigImagesCarousel = ref(undefined);
        const infoCarousel = ref(undefined);
        const galleryModal = ref(false);
        const galleryModalObject = ref({});
        const mobileReference = ref({});

		const carouselCommonSettings = {
			dots: false,
			focusOnSelect: true,
			lazyLoad: 'progressive',
		};

		const bigImagesCarouselSettings = reactive({
			...carouselCommonSettings,
			arrows: false,
			slidesToShow: 1,
			draggable: false,
			touchMove: false,
		});

		const smallImagesCarouselSettings = reactive({
			...carouselCommonSettings,
			arrows: true,
			draggable: false,
			slidesToShow: 2,
			slidesToScroll: 1,
		})

		const infoCarouselSettings = reactive({
			...carouselCommonSettings,
			arrows: !isMobile,
			draggable: false,
			slidesToShow: 1,
			slidesToScroll: 1,
            fade: true,
		});

        const modalImagesCarouselSettings = reactive({
            ...carouselCommonSettings,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        });

        const backgroundStyle = (image) => {
            return {
                'background-image': `url(${image})`,
            }
        };

        const openModal = () => {
            galleryModal.value = !galleryModal.value;
        };

        const openReferenceModal = (reference) => {
            if (!isMobile.value) {
                galleryModalObject.value = reference;
                openModal();
            }
        };

        return {
            isMobile,
            bigImagesCarousel,
            infoCarousel,
            bigImagesCarouselSettings,
            smallImagesCarouselSettings,
            infoCarouselSettings,
            modalImagesCarouselSettings,
            referencesGetters,
            productGetters,
            galleryModal,
            galleryModalObject,
            backgroundStyle,
            openModal,
            openReferenceModal,
            mobileReference,
        };        
    },
});
</script>

<style lang="scss" scoped>
::v-deep .sf-modal {
    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        border: none;
        max-height: 100%;
        transform: translate3d(0, 0, 0);
        z-index: 10000;
        background: var(--c-black);

        &::-webkit-scrollbar {
            width: 0;
        }

        .top {
            color: var(--c-white);

            h4 {
                margin-bottom: 0;
            }

            div {

                display: inline-block;
            }

            a {
                font-size: 14px;
                letter-spacing: 0.25px;
                line-height: 18px;
                margin: 0;
                padding: 0 15px 0 0;

                span {
                    display: none;
                }
            }
        }
    }

    .sf-icon {
        width: 35px;
        height: 35px;
        fill: var(--c-black);
    }
}

::v-deep .sf-modal__content {
    padding: 0;
}

::v-deep .sf-bar {
    background: white;
}

.product-gallery {
    &-modal-images {
        ::v-deep .slick-list {
            overflow-x: hidden;
            height: calc(100vh - 100px);
            display: flex;
            align-items: center;

            .slick-track {
                display: flex;
                align-items: center;
            }
        }
    }

    &-modal-images {
        ::v-deep .slick-list {
            .slick-slide {
                div {
                    padding: 25px;
                    text-align: center;

                    img {
                        max-height: calc(100vh - 250px);
                        width: auto !important;
                    }
                }
            }
        }

        .slick-arrow {
            position: absolute;
            z-index: 2;
            top: 100%;
            left: 15px;
            color: var(--c-white);

            &.slick-next {
                left: auto;
                right: 15px;
            }

            @media (min-width: 992px) {
                left: -35px;
                top: 50%;

                &.slick-next {
                    right: -35px;
                }
            }
        }
    }
}

::v-deep .slick-initialized .slick-slide {
    display: block;

    &.slick-active {
        z-index: 1000;
    }
}

::v-deep .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.sf-loader__overlay {
    display: none;
}

::v-deep .project {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 120px 0;
    min-height: 500px;

    @media (max-width:991px) {
        padding-left: 0;
        padding-right: 0;
        margin: 35px 0;
    }

    .project-wr {
        @media (min-width:991px) {
            display: flex;
            overflow: hidden;
            position: relative;
        }

        .project-main-slider-wr {
            width: 100%;

            @media (max-width:991px) {
                height: 55vw;
                margin-bottom: 44px;
            }

            @media (min-width:991px) {
                width: calc(100% - 350px);
            }

            @media (min-width:1400px) {
                width: calc(100% - 600px);
            }

            @media (min-width:1700px) {
                min-height: auto;
            }
        }
    }

    .right-side {
        position: relative;
        width: 100%;
        z-index: 1;

        &.align-center {
            display: flex;
            align-items: center;
        }

        @media (max-width:991px) {
            padding: 0 15px;
        }

        @media (min-width:991px) {
            width: 350px;
            min-height: 604px;
        }

        @media (min-width:1400px) {
            width: 590px;
        }

        @media (min-width:1700px) {
            width: 600px;
            min-height: 800px;
        }

        @media (max-width:991px) {
            width: 100%;
            min-height: 1px;
        }

        .project-info-slider-wr {
            overflow: hidden;

            @media (min-width:1700px) {
                padding-right: 145px;
            }

            @media (max-width:991px) {
                position: relative;
                z-index: 1;
                padding-bottom: 30px;
            }
        }
    }
}

::v-deep .project-nav-slider-wr {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 100%;
    z-index: 1111;

    @media (min-width:991px) {
        padding-bottom: 100px;
        width: 330px;
    }

    @media (min-width:1400px) {
        padding-bottom: 100px;
        width: 570px;
    }

    @media (max-width:991px) {
        padding-bottom: 0;
        height: 30px;
        bottom: 0px;
    }

    .project-nav-slider {
        overflow: hidden;

        .slide-image {
            padding: 0 16px 0 0;

            @media (min-width:1700px) {
                padding: 0 32px 0 0;
            }

            @media (max-width:991px) {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }

    .project-nav-slider-counter {
        display: flex;
        justify-content: center;
        margin-top: 28px;
        position: absolute;

        span {
            margin: 0 3px;
        }

        @media (max-width:1200px) {
            display: none;
        }
    }

    .project-nav-slider-arrows {
        position: absolute;
        top: auto;
        bottom: 35px;
        right: 100px;
        z-index: 1;

        @media (max-width:991px) {
            position: absolute;
            right: 35px;
            top: 0;
        }

        &.prev {
            right: 150px;

            @media (max-width:991px) {
                right: 90px;
            }
        }
    }
}

::v-deep .project-main-slider {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: none;

    &.slick-initialized {
        display: block;
    }

    .slick-list, .slick-slide > div, .slick-track {
        height: 100%;
    }

    .slide {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        .slide-image {
            width: 100%;
            height: 100%;
            display: block;
            background-position: 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transform: translate3d(100%, 0, 0);
    }
}

::v-deep .project-info-slider {
    padding: 0 60px 44px;
    display: none;

    &.slick-initialized {
        padding-bottom: 410px;
        display: block;

        @media (max-width:1500px) {
            padding-bottom: 290px;
        }

        @media (max-width:1400px) {
            padding-bottom: 350px;
            padding: 0 45px 45px;
        }

        @media (max-width:1200px) {
            padding-bottom: 256px;
        }

        @media (max-width:991px) {
            padding: 0;
        }
    }

    @media (max-width:991px) {
        padding-left: 0;
        padding-right: 0;
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.25px;
        line-height: 24px;

        @media (max-width:991px) {
            font-size: 14px;
            letter-spacing: 0.2px;
        }
    }

    .flag {
        margin-bottom: 0;

        @media (min-width:992px) {
            margin-bottom: 5px;
        }
    }

    h6 {
        font-size: 18px;
        letter-spacing: 0.25px;
        margin: 0 0 16px;

        @media (max-width:991px) {
            margin: 0 0 10px;
        }

        @media (max-width:1200px) {
            font-size: 16px;
            letter-spacing: 0.2px;
        }
    }

    p {
        font-size: 18px;
        margin: 0;

        @media (max-width:1200px) {
            font-size: 16px;
            margin-bottom: 0;
        }
    }

    .related-products {
        padding-bottom: 31px;

        @media (max-width:991px) {
            padding-bottom: 15px;
        }

        a:not(.icon) {
            font-size: 18px;
            letter-spacing: 0.25px;

            @media (max-width:1200px) {
                font-size: 16px;
                letter-spacing: 0.2px;
            }
        }

    }
}
</style>
