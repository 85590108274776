<template>
	<div class="embed-responsive" :class="[css]" v-if="valid">
		<iframe
			ref="videoRef"
			loading="lazy"
			sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
			class="embed-responsive-item"
			allowfullscreen
			:src="url"
		/>
		<div
			v-if="!this.autoPlay"
			class="trigger-play"
			v-bind:class="{ active: this.isPlaying }"
		>
			<button v-bind:class="{ active: this.isPlaying }" @click="toggle">
				<svg-image
					v-if="!this.isPlaying"
					class="play-button"
					height="20"
					width="20"
					icon="play-button"
				/>
				<svg-image
					v-if="this.isPlaying"
					class="pause-button"
					height="20"
					width="20"
					icon="pause-button"
				/>
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Video',
	props: {
		css: {
			type: String,
			default: 'embed-responsive-16by9',
		},
		src: {
			type: String,
		},
		params: {
			type: Object,
		},
		title: {
			type: String,
		},
		description: {
			type: String,
		},
	},
	data() {
		return {
			autoPlay: false,
			isPlaying: false,
			valid: false,
			url: '',
			videos: [
				{
					reg: /^.*vimeo.com\/(video)\/(\d+)($|\/|\b)/i,
					url: 'https://player.vimeo.com/video/$2',
					params: {
						background: 1,
						title: 0,
						api: 1,
						autoplay: 0,
						byline: 0,
						portrait: 0,
					},
				},
			],
		};
	},
	watch: {
		src(val) {
			this.parse();
		},
	},
	methods: {
		toggle() {
			if (this.isPlaying) {
				this.$refs.videoRef.contentWindow.postMessage(
					JSON.stringify({ method: 'pause' }),
					'*'
				);
				this.isPlaying = false;
			} else {
				this.$refs.videoRef.contentWindow.postMessage(
					JSON.stringify({ method: 'play' }),
					'*'
				);
				this.isPlaying = true;
			}
		},
		parse() {
			if (this.src) {
				for (let i = 0; i < this.videos.length; i++) {
					const v = this.videos[i];
					var m = v.reg.exec(this.src);
					if (m) {
						var params = Object.assign({}, v.params, this.params);

						if (params.autoplay) {
							this.autoPlay = true;
						}

						var query = Object.keys(params)
							.map((key) => key + '=' + params[key])
							.join('&');
						var and = v.url.indexOf('?') >= 0 ? '&' : '?';
						this.url = this.src.replace(v.reg, v.url) + and + query;
						this.valid = true;
						return;
					}
				}
			}
			this.valid = false;
		},
	},
	mounted() {
		this.parse();
	},
};
</script>
<style lang="scss" scoped>
.embed-responsive {
	position: relative;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	background-color: var(--c-black);

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}

	&.embed-responsive-16by9 {
		padding-bottom: 56.25%;

		@media screen and (min-aspect-ratio: 16/10) {
			width: 112vw;
		}

		@media screen and (max-aspect-ratio: 4/3) {
			width: 135vw;
		}
	}

	.trigger-play {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&.active {
			background-color: rgba(0, 0, 0, 0);
		}

		button {
			width: 62px;
			height: 62px;
			border: none;
			border-radius: 100%;
			background-color: var(--c-white);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
			transition: all 0.2s ease-in-out;

			&:focus {
				outline: none;
			}

			&.active {
				opacity: 0;
			}
		}
	}

	&:hover {
		.trigger-play {
			background-color: rgba(0, 0, 0, 0.3);
			transition: all 0.2s ease-in-out;

			&.active {
				background-color: rgba(0, 0, 0, 0);
			}

			button {
				background-color: var(--c-text-muted);
				transition: all 0.2s ease-in-out;

				&.active {
					opacity: 0.5;
				}
			}
		}
	}
}
</style>
