<template>
    <div class="row mt-3">
        <div class="col-12 col-sm-6">
            <p class="m-0" v-if="cartOptions['category_id'] && cartOptions['categories']">
                <strong>{{ $t('Category') }}:</strong> {{ cartOptions['categories'].find(el => el.id == cartOptions['category_id']).name }}
            </p>
            <p class="m-0" v-if="cartOptions['customer_order_number']">
                <strong>{{ $t('Customer order number') }}:</strong> {{ cartOptions['customer_order_number'] }}
            </p>
            <p class="m-0" v-if="cartOptions['requested_delivery_date']">
                <strong>{{ $t('Requested delivery date') }}:</strong> {{ formatDate(cartOptions['requested_delivery_date']) }}
            </p>
            <p class="m-0" v-if="cartOptions['seller_id'] && cartOptions['sellers']">
                <strong>{{ $t('Seller') }}:</strong> {{ cartOptions['sellers'].find(el => el.id == cartOptions['seller_id']).name }}
            </p>
            <p class="m-0" v-if="cartOptions['our_reference_id'] && cartOptions['our_references']">
                <strong>{{ $t('Our reference') }}:</strong> {{ cartOptions['our_references'].find(el => el.id == cartOptions['our_reference_id']).name }}
            </p>
        </div>
        <div class="col-12 col-sm-6">
            <p class="m-0" v-if="cartOptions['customer_reference_id'] && cartOptions['customer_references']">
                <strong>{{ $t('Customer reference') }}:</strong> {{ cartOptions['customer_references'].find(el => el.id == cartOptions['customer_reference_id']).name }}
            </p>
            <p class="m-0" v-if="cartOptions['country_id'] && cartOptions['countries']">
                <strong>{{ $t('Country') }}:</strong> {{ cartOptions['countries'].find(el => el.id == cartOptions['country_id']).full_name_locale }}
            </p>
            <p class="m-0" v-if="cartOptions['location']">
                <strong>{{ $t('Location') }}:</strong> {{ cartOptions['location'] }}
            </p>
            <p class="m-0" v-if="cartOptions['is_corrosive_environment']">
                <strong>{{ $t('Corrosive environment') }}:</strong> {{ cartOptions['is_corrosive_environment'] ? $t('Yes') : $t('No') }}
            </p>
            <p class="m-0" v-if="cartOptions['warranty_id'] && cartOptions['warranty']">
                <strong>{{ $t('Warranty') }}:</strong> {{ cartOptions['warranty'].find(el => el.id == cartOptions['warranty_id']).name }}
            </p>
            <p class="m-0" v-if="cartOptions['dimming']">
                <strong>{{ $t('Dimming') }}:</strong> {{ JSON.parse(cartOptions['dimming']).label }}
            </p>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, useContext, ref } from '@nuxtjs/composition-api';
import moment from 'moment';

export default defineComponent({
	props: {
		cartOptions: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		formatDate(date) {
			return date.length ? moment(date).format(this.dateFormat) : '';
		},
	},
	setup() {
		const { app } = useContext();
		const apiState = app.$vsf.$magento.config.state;
		const dateFormat = ref('YYYY-MM-DD');

		return {
			dateFormat: computed(() => apiState.getLocale() == 'us' ? 'MM-DD-YYYY' : 'DD-MM-YYYY'),
		}
	}
});
</script>

<style lang="scss" scoped>

</style>
