<template>
    <div ref="amchart_map"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldMoroccoLow from "@amcharts/amcharts4-geodata/worldMoroccoLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { referencesGetters } from '~/getters';

import {
    defineComponent, useContext, ref, reactive
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: "Map",
    props: {
        references: Array,
    },
    methods: {
        loadChart() {
            if (this.chart) {
                this.chart.dispose();
            }

            let countries = {};
            let map_details = [];

            for (const reference of this.references) {
                let cId = reference.country_id;
                let str = '<a href="' + this.localePath(referencesGetters.getSlug(reference)) + '">' + reference.name + '</a>';

                map_details[cId] = map_details[reference.country_id] 
                    ? map_details[cId] + str 
                    : map_details[cId] = '<h6>Explore ' + reference.country + '</h6>' + str;

                countries[cId] = countries[cId] ? countries[cId] + 1 : 1;
            }

            // Themes
            am4core.useTheme(am4themes_animated);

            let chart = am4core.create(this.$refs.amchart_map, am4maps.MapChart);

            // Set map definition
            chart.geodata = am4geodata_worldMoroccoLow;

            // Set projection
            chart.projection = new am4maps.projections.Orthographic();
            chart.panBehavior = "rotateLongLat";
            chart.deltaLongitude = 5;
            chart.deltaLatitude = -37;
            chart.chartContainer.wheelable = false;
            chart.padding(20, 20, 20, 20);

            // limits vertical rotation
            chart.adapter.add("deltaLatitude", function(delatLatitude) {
                return am4core.math.fitToRange(delatLatitude, -90, 90);
            })

            // Create map polygon series
            const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

            // Make map load polygon (like country names) data from GeoJSON
            polygonSeries.useGeodata = true;

            polygonSeries.calculateVisualCenter = false;
            polygonSeries.tooltip.background.fillOpacity = 1;
            polygonSeries.tooltip.background.cornerRadius = 20;

            // Configure series
            const polygonTemplate = polygonSeries.mapPolygons.template;
            polygonTemplate.nonScalingStroke = true;
            polygonTemplate.fill = am4core.color("#141415");
            polygonTemplate.stroke = am4core.color("#282829");
            polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

            polygonTemplate.propertyFields.id = "id";
            polygonTemplate.tooltipPosition = "fixed";
            polygonTemplate.fillOpacity = 1;

            polygonTemplate.events.on("over", function(event) {
                if (event.target.dummyData) {
                    event.target.dummyData.isHover = true;
                }
            })
            polygonTemplate.events.on("out", function(event) {
                if (event.target.dummyData) {
                    event.target.dummyData.isHover = false;
                }
            })

            polygonTemplate.events.on("hit", function(ev) {
                if (map_details[ev.target.dataItem.dataContext.id]) {
                    chart.closeAllPopups();
                    chart.openPopup(map_details[ev.target.dataItem.dataContext.id]);
                }
            });

            const graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
            graticuleSeries.mapLines.template.line.stroke = am4core.color("#000000");
            graticuleSeries.mapLines.template.line.strokeOpacity = 0;
            graticuleSeries.fitExtent = false;

            // Country hover tooltip
            const measelsSeries = chart.series.push(new am4maps.MapPolygonSeries())
            measelsSeries.tooltip.background.fillOpacity = 0;
            measelsSeries.tooltip.background.cornerRadius = 20;
            measelsSeries.tooltip.autoTextColor = false;
            measelsSeries.tooltip.label.fill = am4core.color("#fff");
            measelsSeries.tooltip.dy = -5;

            const measelTemplate = measelsSeries.mapPolygons.template;
            measelTemplate.fill = am4core.color("#8d8d8d");
            measelTemplate.strokeOpacity = 0;
            measelTemplate.fillOpacity = 1;
            measelTemplate.tooltipPosition = "fixed";
            measelTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

            const hoverState = polygonSeries.mapPolygons.template.states.create("hover");
            hoverState.properties.fill = am4core.color("#3c3c3c");
            hoverState.properties.stroke = am4core.color("#3c3c3c");

            polygonSeries.mapPolygons.template.events.on("over", function(event) {
              event.target.zIndex = Number.MAX_VALUE;
              event.target.toFront();
            });

            polygonSeries.events.on("inited", function() {
                polygonSeries.mapPolygons.each(function(mapPolygon) {
                    const count = countries[mapPolygon.id];

                    if (count > 0) {
                        const polygon = measelsSeries.mapPolygons.create();
                        polygon.multiPolygon = am4maps.getCircle(mapPolygon.visualLongitude, mapPolygon.visualLatitude, Math.max(0.2, Math.log(count) * Math.LN10 / 10));
                        polygon.tooltipText = mapPolygon.dataItem.dataContext.name;
                        mapPolygon.dummyData = polygon;
                        polygon.events.on("over", function() {
                            mapPolygon.isHover = true;
                        })
                        polygon.events.on("out", function() {
                            mapPolygon.isHover = false;
                        })
                        polygon.events.on("hit", function(ev) {
                            if (map_details[mapPolygon.dataItem.dataContext.id]) {
                                chart.closeAllPopups();
                                chart.openPopup(map_details[mapPolygon.dataItem.dataContext.id]);
                            }
                        });
                    } else {
                        //mapPolygon.tooltipText = mapPolygon.dataItem.dataContext.name;
                    }
                })
            })

            // Water
            chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#0b0b0c");
            chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0;

            chart.legend = new am4maps.Legend();

            this.chart = chart;
            this.chart.invalidateRawData();
        },
    },
    mounted() {
        this.loadChart();
    },
    watch: {
        references: {
            deep: true,
            handler: 'loadChart',
        },
        chartData: {
            handler: 'loadChart',
            deep: true
        }
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
    setup(props) {
        const { app } = useContext();
    }
})
</script>