<template>
    <div class="vacancies">
        <div class="top">
            <h1>{{ $t('Vacancies in Vizulo') }}</h1>
            <Video
                ref="vimeo"
                id="vacancies-video"
                :params="{background: 1, allowfullscreen: 0, autoplay: 1}"
                src="https://player.vimeo.com/video/519063384"
            />
        </div>
        <div class="container content">
            <div class="row">
                <div class="col-12">
                    <h1>{{ $t('Join our team') }}</h1>
                </div>
            </div>
        </div>
        <div class="container content loading" v-if="loadingVacancies">
            <SfLoader />
        </div>
        <div v-else>
            <div class="container content">
                <div class="row" v-if="riga.length > 0">
                    <div class="col-12">
                        <h2>{{ $t('Riga') }}</h2>
                    </div>
                    <div class="col-12">
                        <div 
                            class="vacancies__items" 
                            v-for="item in riga"
                            v-bind:key="item.vacancy_id"
                            >
                            <div class="item">
                                <nuxt-link :to="localePath(vacanciesGetters.getSlug(item))">
                                    <div class="data">
                                        <h5>{{ item.name }}</h5>
                                        <svg-image
                                          class="arrow-link"
                                          height="24"
                                          icon="arrow-link"
                                          width="24"
                                        />
                                    </div>
                                </nuxt-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="iecava.length > 0">
                    <div class="col-12">
                        <h2>{{ $t('Iecava') }}</h2>
                    </div>
                    <div class="col-12">
                        <div 
                            class="vacancies__items" 
                            v-for="item in iecava" 
                            v-bind:key="item.vacancy_id"
                            >
                            <div class="item">
                                <nuxt-link :to="localePath(vacanciesGetters.getSlug(item))">
                                    <div class="data">
                                        <h5>{{ item.name }}</h5>
                                        <svg-image
                                          class="arrow-link"
                                          height="24"
                                          icon="arrow-link"
                                          width="24"
                                        />
                                    </div>
                                </nuxt-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container content">
                <div class="row">
                    <div class="col-12 text-center">
                        <h3>{{ $t('Ja vēlies būt daļa no VIZULO izaugsmes stāsta, atstāj mums savu CV. Mēs to rūpīgi izskatīsim un sazināsimies ar Tevi tiklīdz mums būs vajadzīgs tieši tāds darbinieks kā Tu.') }}</h3>
                    </div>
                    <div class="col-12">
                        <Form />
                    </div>
                </div>                
            </div>
            <div class="container content">
                <div class="row" v-if="archive.length > 0">
                    <div class="col-12">
                        <h1>{{ $t('Archive') }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div 
                            class="vacancies__items" 
                            v-for="item in archive" 
                            v-bind:key="item.vacancy_id"
                            >
                            <div class="item">
                                <nuxt-link :to="localePath(vacanciesGetters.getSlug(item))">
                                    <div class="data">
                                        <h5>{{ item.name }}</h5>
                                        <svg-image
                                          class="arrow-link"
                                          height="24"
                                          icon="arrow-link"
                                          width="24"
                                        />
                                    </div>
                                </nuxt-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SfLoader } from '@storefront-ui/vue';
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { useVacancies } from '~/modules/vizulo/vacancies'
import { vacanciesGetters } from '~/getters';
import Link from '~/components/Common/Link.vue';
import Form from './Form.vue';
import Video from '~/components/Common/Video.vue';

export default defineComponent({
    name: 'Vacancies',
    components: {
        useVacancies,
        Link,
        Video,
        SfLoader,
        Form,
    },
    head() {
        const title = 'Vacancies - Vizulo';
        const meta = [];

        return {
            title,
            meta,
        };
    },
    setup() {
        const { getVacancies, loadingVacancies } = useVacancies();
        const riga = ref({});
        const iecava = ref({});
        const archive = ref({});

        onMounted(async () => {
            const vacanciesData = await getVacancies({
                currentPage: 1,
                pageSize: 1000,
            });

            riga.value = vacanciesData.items.filter(item => item.enabled == 1 && item.location == 'riga');
            iecava.value = vacanciesData.items.filter(item => item.enabled == 1 && item.location == 'iecava');
            archive.value = vacanciesData.items.filter(item => item.enabled == 2);
        });

        return {
            loadingVacancies,
            riga,
            iecava,
            archive,
            vacanciesGetters,
        };
    },
});

</script>

<style lang="scss" scoped>
    .loading {
        height: 500px;
    }

    .vacancies {
        .top {
            position: relative;
            padding-bottom: 42.25%;
            height: 0;

            h1 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 32px;
                color: white;
                font-weight: bold;
                z-index: 7;
                padding: 0;
                margin: 0;

                @media (max-width:991px) {
                    text-align: center;
                }
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 6;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                top: 0;
            }
        }

        .content {
            h1 {
                margin-top: 120px;

                @media (max-width:991px) {
                    margin-top: 35px;
                }
            }

            h2 {
                margin-top: 35px;

                @media (max-width:991px) {
                    margin-top: 25px;
                }
            }
        }


        &__items {
            .item {
                border-bottom: 1px dotted #0d0d0d;
                border-left: 1px dotted #0d0d0d;
                display: flex;
                flex-flow: column;
                margin-bottom: 35px;
                padding-left: 15px;

                a {
                    padding: 15px 0;

                    @media (max-width:991px) {
                        padding: 8px 0;
                    }
                }

                h5 {
                    float: left;
                    font-size: 18px;
                    padding: 0;
                    margin: 0;

                    @media (max-width:991px) {
                        font-size: 14px;
                    }
                }

                p {
                    float: left;
                    clear: both;
                    margin-top: 0;
                }

                .link-with-arrow {
                    float: right;
                }
            }
        }
    }

    ::v-deep iframe#vacancies-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    ::v-deep .embed-responsive#vacancies-video {
        padding-bottom: 42.25%;
    }

    ::v-deep .svg-image {
        float: right;
    }

</style>