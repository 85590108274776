<template>
    <client-only>
        <sf-loader :loading="!gallery.length">
            <lazy-hydrate>
                <div class="project js-project-slider">
                    <div class="project-wr">
                        <div class="project-main-slider-wr">
                            <div @click="openMobileModal()">
                                <svg-image
                                    width="36"
                                    height="36"
                                    class="icon pointer full-screen"
                                    icon="full-screen"
                                />
                            </div>
                            <vue-slick-carousel
                                ref="bigImagesCarousel"
                                v-bind="bigImagesCarouselSettings"
                                class="project-main-slider"
                            >
                                <div
                                    v-for="(image, index) in gallery"
                                    :key="index"
                                    class="slide"
                                >
                                    <div
                                        class="slide-image js-open-popup-gallery"
                                        :data-index="index"
                                        :style="backgroundStyle(image.wide)"
                                        @click="openModal(index)"
                                    />
                                </div>
                            </vue-slick-carousel>
                        </div>
                        <div class="right-side">
                            <div class="project-nav-slider-wr">
                                <vue-slick-carousel
                                    v-bind="smallImagesCarouselSettings"
                                    :as-nav-for="bigImagesCarousel"
                                    @beforeChange="onSlideBeforeChange"
                                    class="project-nav-slider"
                                >
                                    <div
                                        v-for="(image, index) in gallery"
                                        :key="index"
                                        class="slide pointer"
                                    >
                                        <div class="slide-image">
                                            <img class="arrow-btn-next" :src="image.horizontal" alt="">
                                        </div>
                                    </div>
                                    <template #prevArrow>
                                        <div class="project-nav-slider-arrows prev">
                                            <svg-image
                                                width="24"
                                                height="24"
                                                class="icon pointer"
                                                icon="arrow_left"
                                            />
                                        </div>
                                    </template>
                                    <template #nextArrow>
                                        <div class="project-nav-slider-arrows next">
                                            <svg-image
                                                class="icon pointer arrow"
                                                icon="arrow_right"
                                                width="24"
                                                height="24"
                                            />
                                        </div>
                                    </template>
                                </vue-slick-carousel>
                                <div class="row project-nav">
                                    <div class="col-4">
                                        <div class="project-nav-slider-counter">
                                            <span class="js-slide-current">{{ page }}</span> / <span class="js-slides-total">{{ gallery.length }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SfModal
                        :visible="gallery_modal"
                        class="modal fullscreen"
                        @close="openMobileModal(0)"
                    >
                    <div class="col-11 popup-gallery__head product">
                        <div class="container top">
                         
                        </div>
                    </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-12 product-gallery-modal-images">
                                    <vue-slick-carousel
                                        ref="modalImagesCarousel"
                                        v-bind="modalImagesCarouselSettings"
                                    >
                                        <template v-for="(image, index) in gallery">
                                            <img
                                                :key="index"
                                                :src="image.original"
                                            >
                                        </template>
                                        <template #prevArrow>
                                            <div class="product-gallery-modal-arrows prev">
                                                <svg-image
                                                    width="36"
                                                    height="36"
                                                    class="icon pointer"
                                                    icon="arrow_left"
                                                />
                                            </div>
                                        </template>
                                        <template #nextArrow>
                                            <div class="product-gallery-modal-arrows next">
                                                <svg-image
                                                    class="icon pointer arrow"
                                                    icon="arrow_right"
                                                    width="36"
                                                    height="36"
                                                />
                                            </div>
                                        </template>
                                    </vue-slick-carousel>
                                </div>
                            </div>
                        </div>
                    </SfModal>
                </div>
            </lazy-hydrate>
        </sf-loader>
    </client-only>
</template>

<script>
import {
    defineComponent, 
    useAsync,
    useRoute,
    ref,
    reactive,
} from '@nuxtjs/composition-api';
import { SfGallery, SfLoader, SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import VueSlickCarousel from 'vue-slick-carousel';

export default defineComponent({
    name: 'ReferencesImageSlider',
    components: {
        VueSlickCarousel,
        SfGallery,
        SfLoader,
        SfModal,
    },
    data: () => (
        {
            page: 1,
        }
    ),
    methods: {
        backgroundStyle(image) {
            return {
                'background-image': `url(${image})`,
            }
        },
        onSlideBeforeChange: function(currentIndex, nextIndex) {
            this.page = nextIndex + 1;
        },
        openModal(index) {
            if (!this.isMobile) {
                this.gallery_modal = !this.gallery_modal;
            }
        },
        openMobileModal(index) {
            this.gallery_modal = !this.gallery_modal;
        },
    },
    props: {
        gallery: {
            type: [Array, Object],
            default() {
                return {}
            }
        },
    },
    setup(props) {
        const { isMobile } = useUiState();

        const bigImagesCarousel = ref(undefined);
        const gallery_modal = ref(false);

        const carouselCommonSettings = {
            dots: false,
            focusOnSelect: true,
            draggable: isMobile,
            touchMove: isMobile
        };

        const bigImagesCarouselSettings = reactive({
            ...carouselCommonSettings,
            arrows: false,
            slidesToShow: 1,
            dots: isMobile,
        });

        const smallImagesCarouselSettings = reactive({
            ...carouselCommonSettings,
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 1,
        });

        const modalImagesCarouselSettings = reactive({
            ...carouselCommonSettings,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        });

        return {
            isMobile,
            bigImagesCarousel,
            bigImagesCarouselSettings,
            smallImagesCarouselSettings,
            modalImagesCarouselSettings,
            gallery_modal,
        };        
    },
});
</script>

<style lang="scss" scoped>
::v-deep .sf-modal {
    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        border: none;
        max-height: 100%;
        transform: translate3d(0, 0, 0);
        z-index: 10000;
        background: var(--c-black);

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .sf-icon {
        width: 35px;
        height: 35px;
        fill: var(--c-black);
    }
}

::v-deep .sf-modal__content {
    padding: 0;
    overflow: inherit;
}

::v-deep .sf-bar {
    background: var(--c-black);
    padding: 0 15px;

    .sf-icon {
        fill: var(--c-white);
    }
}

.full-screen {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 1;
    fill: var(--c-black);
}

.product-gallery {
    &-modal-images {
        ::v-deep .slick-list {
            overflow-x: hidden;
            height: calc(100vh - 100px);
            display: flex;
            align-items: center;

            .slick-track {
                display: flex;
                align-items: center;
            }
        }
    }

    &-modal-images {
        ::v-deep .slick-list {
            .slick-slide {
                div {
                    padding: 2vh 50px;
                    text-align: center;

                    @media (max-width: 991px) {
                        padding: 2vh 10px;
                    }

                    img {
                        max-height: calc(100vh - 200px);
                        width: auto !important;
                    }
                }
            }
        }

        .slick-arrow {
            position: absolute;
            z-index: 2;
            top: 100%;
            left: 15px;
            color: var(--c-white);

            &.slick-next {
                left: auto;
                right: 15px;
            }

            @media (min-width: 992px) {
                left: -5px;
                top: 50%;

                &.slick-next {
                    right: -5px;
                }
            }
        }
    }
}

::v-deep .slick-initialized .slick-slide {
    display: block;
}

::v-deep .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

::v-deep .project {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 120px 0;

    @media (max-width:991px) {
        padding-left: 0;
        padding-right: 0;
        margin: 15px 0;
    }

    .project-wr {
        overflow: hidden;

        @media (min-width:991px) {
            display: flex;
        }

        .project-main-slider-wr {
            width: 100%;

            @media (min-width:992px) {
                width: calc(100% - 350px);
            }

            @media (min-width:1400px) {
                width: calc(100% - 600px);
            }

            @media (min-width:1700px) {
                min-height: auto;
            }

            @media (max-width:991px) {
                width: 100%;
                height: calc(55vw);
                margin-bottom: 44px;
            }

            .project-main-slider {
                overflow: hidden;
                width: 100%;
                height: 100%;
                display: none;

                &.slick-initialized {
                    display: block;
                }

                .slick-list, .slick-slide > div, .slick-track {
                    height: 100%;
                }

                .slide {
                    overflow: hidden;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .slide-image {
                        width: 100%;
                        height: 100%;
                        display: block;
                        background-position: 50%;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    transform: translate3d(100%, 0, 0);
                }
            }
        }
    }

    .right-side {
        position: relative;
        width: 430px;
        min-height: 604px;
        z-index: 1;

        &.align-center {
            display: flex;
            align-items: center;
        }

        @media (min-width:991px) {
            width: 350px;
        }

        @media (min-width:1400px) {
            width: 590px;
        }

        @media (min-width:1700px) {
            width: 600px;
            min-height: 800px;
        }

        @media (max-width:991px) {
            width: 100%;
            min-height: 1px;
        }

        .project-info-slider-wr {
            @media (min-width:1700px) {
                padding-right: 145px;
            }

            @media (max-width:991px) {
                position: relative;
                z-index: 1;
            }

            .project-info-slider {
                padding: 0 60px 44px;
                display: none;

                &.slick-initialized {
                    padding-bottom: 410px;
                    display: block;

                    @media (max-width:1500px) {
                        padding-bottom: 290px;
                    }

                    @media (max-width:1400px) {
                        padding-bottom: 350px;
                        padding: 0 45px 45px;
                    }

                    @media (max-width:1200px) {
                        padding-bottom: 256px;
                    }

                    @media (max-width:991px) {
                        padding: 0;
                    }
                }

                @media (max-width:991px) {
                    padding-left: 0;
                    padding-right: 0;
                }

                .title {
                    font-size: 20px;
                    font-weight: 700;
                    letter-spacing: 0.25px;
                    line-height: 28px;
                    margin-bottom: 28px;

                    @media (max-width:991px) {
                        font-size: 16px;
                        letter-spacing: 0.2px;
                        line-height: 24px;
                        margin-bottom: 20px;
                    }
                }

                .flag {
                    margin-bottom: 5px;
                    height: 20px;
                    width: auto;

                    img {
                        height: 20px;
                        box-shadow: 1px 1px 3px #cecece;
                    }
                }

                h6 {
                    font-size: 20px;
                    letter-spacing: 0.25px;
                    line-height: 28px;
                    margin: 0 0 16px;

                    @media (max-width:1200px) {
                        font-size: 16px;
                        letter-spacing: 0.2px;
                        line-height: 24px;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0;

                    @media (max-width:1200px) {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 0;
                    }

                    &:nth-child(2n) {
                        margin-bottom: 31px;

                        @media (max-width:1200px) {
                            margin-bottom: 26px;
                        }
                    }
                }

                .related-products {
                    a {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 16px;
                        padding-left: 20px;

                        &:before {
                            top: 0px;
                            height: 14px;
                            width: 14px;
                        }
                    }
                }
            }
        }

        .project-nav-slider-wr {
            position: absolute;
            bottom: 0;
            right: 0px;
            width: 330px;

            @media (min-width:991px) {
                padding-bottom: 100px;
            }

            @media (min-width:1400px) {
                padding-bottom: 100px;
                width: 570px;
            }

            .project-nav-slider {
                overflow: hidden;

                .slide-image {
                    padding: 0 16px 0 0;

                    @media (min-width:1700px) {
                        padding: 0 32px 0 0;
                    }

                    @media (max-width:991px) {
                        display: none;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            .project-nav-slider-counter {
                display: flex;
                justify-content: center;
                margin-top: 28px;
                position: absolute;

                span {
                    margin: 0 3px;
                }

                @media (max-width:1200px) {
                    display: none;
                }
            }

            .project-nav-slider-arrows {
                position: absolute;
                top: auto;
                bottom: 35px;
                right: 100px;

                @media (max-width:991px) {
                    display: none;
                }

                &.prev {
                    right: 150px;
                }
            }
        }
    }
}

::v-deep .slick-dots {
    text-align: center;
    padding: 0 5px;
    position: absolute;
    margin: 0;
    width: 100%;

    li {
        display: inline-block;
        padding: 0;

        button {
            width: 8px;
            height: 8px;
            font-size: 0;
            border-radius: 50%;
            padding: 1px;
            cursor: pointer;
            margin-right: 10px;
            transition: 0.5s;
            border: 1px solid var(--_c-dark-primary);
            background: transparent;
        }

        &.slick-active {
            button {
                background: var(--_c-dark-primary);
            }
        }
    }
}
</style>
