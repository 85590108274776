<template>
    <client-only>
        <SkeletonLoader
            v-if="loading"
            :height="(height + 20) + 'px'"
            :minHeight="(height + 20) + 'px'"
        />
        <div v-else :style="{ minHeight: (height + 35) + 'px' }">
            <div v-if="reference && reference.gallery.length > 1">

                <div @click="openMobileModal()">
                    <svg-image
                        width="36"
                        height="36"
                        class="icon pointer full-screen"
                        icon="full-screen"
                    />
                </div>

                <vue-slick-carousel
                    v-bind="options"
                    class="slider"
                    @init="onInit"
                    ref="slider"
                >
                    <div
                        v-for="(image, index) in reference.gallery"
                        :key="index"
                        class="slide"
                    >
                        <div class="slide-image" :data-index="index">
                            <img :key="index" :src="image.wide">
                        </div>
                    </div>
                </vue-slick-carousel>
            </div>
            <div v-else-if="reference && reference.gallery.length == 1">
                <img class="single-slide" :src="reference.gallery[0].wide">
            </div>

            <SfModal
                :visible="gallery_modal"
                class="modal fullscreen"
                @close="openMobileModal(0)"
            >
            <div class="col-11 popup-gallery__head product">
                <div class="container top">
                 
                </div>
            </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 product-gallery-modal-images">
                            <vue-slick-carousel
                                ref="modalImagesCarousel"
                                v-bind="modalImagesCarouselSettings"
                            >
                                <template v-for="(image, index) in reference.gallery">
                                    <img
                                        :key="index"
                                        :src="image.original"
                                    >
                                </template>
                                <template #prevArrow>
                                    <div class="product-gallery-modal-arrows prev">
                                        <svg-image
                                            width="36"
                                            height="36"
                                            class="icon pointer"
                                            icon="arrow_left"
                                        />
                                    </div>
                                </template>
                                <template #nextArrow>
                                    <div class="product-gallery-modal-arrows next">
                                        <svg-image
                                            class="icon pointer arrow"
                                            icon="arrow_right"
                                            width="36"
                                            height="36"
                                        />
                                    </div>
                                </template>
                            </vue-slick-carousel>
                        </div>
                    </div>
                </div>
            </SfModal>
        </div>
    </client-only>
</template>

<script>
import {
    defineComponent, 
    reactive,
    ref
} from '@nuxtjs/composition-api';
import VueSlickCarousel from 'vue-slick-carousel';
import { SfModal } from '@storefront-ui/vue';

export default defineComponent({
    name: 'MobileImageSlider',
    components: {
        VueSlickCarousel,
        SfModal,
    },
    data: () => (
        {
            page: 1,
            options: {
                slidesToShow: 1,
                draggable: true,
                touchMove: true,
                arrows: false,
                dots: true,
            }
        }
    ),
    methods: {
        onSlideBeforeChange: function(currentIndex, nextIndex) {
            this.page = nextIndex + 1;
        },
        onInit: function() {
            this.page = 1;
            
        },
        openMobileModal(index) {
            this.gallery_modal = !this.gallery_modal;
        },
    },
    props: {
        reference: {
            type: Object,
            default: () => {},
        }
    },
    setup(props) {
        const gallery_modal = ref(false);
        const slider = ref(undefined);
        const loading = ref(true);
        // 16/9
        const height = ref(1/(1072/603) * window.innerWidth);

        setTimeout(() => {
            loading.value = false;
        }, 1000);

        const backgroundStyle = (image) => {
            return {
                'background-image': `url(${image})`,
            }
        };

        const carouselCommonSettings = {
            dots: false,
            focusOnSelect: true,
            draggable: true,
            touchMove: true
        };

        const modalImagesCarouselSettings = reactive({
            ...carouselCommonSettings,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
        });

        return {
            gallery_modal,
            height,
            loading,
            backgroundStyle,
            slider,
            modalImagesCarouselSettings
        };        
    },
});
</script>

<style lang="scss" scoped>
::v-deep .sf-modal {
    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        border: none;
        max-height: 100%;
        transform: translate3d(0, 0, 0);
        z-index: 10000;
        background: var(--c-black);

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .sf-bar {
        background: var(--c-black);
        padding: 0 15px;

        .sf-icon {
            fill: var(--c-white);
        }
    }

    .sf-icon {
        width: 35px;
        height: 35px;
        fill: var(--c-black);
    }
}

::v-deep .sf-modal__content {
    padding: 0;
    overflow: inherit;
}

.product-gallery {
    &-modal-images {
        ::v-deep .slick-list {
            overflow-x: hidden;
            height: calc(100vh - 100px);
            display: flex;
            align-items: center;

            .slick-track {
                display: flex;
                align-items: center;
            }
        }
    }

    &-modal-images {
        ::v-deep .slick-list {
            .slick-slide {
                div {
                    padding: 2vh 50px;
                    text-align: center;

                    @media (max-width: 991px) {
                        padding: 2vh 10px;
                    }

                    img {
                        max-height: calc(100vh - 200px);
                        width: auto !important;
                    }
                }
            }
        }

        .slick-arrow {
            position: absolute;
            z-index: 2;
            top: 100%;
            left: 15px;
            color: var(--c-white);

            &.slick-next {
                left: auto;
                right: 15px;
            }

            @media (min-width: 992px) {
                left: -5px;
                top: 50%;

                &.slick-next {
                    right: -5px;
                }
            }
        }
    }
}

.loader {
    animation: none;
    border: none;
}

::v-deep .slider {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: none;

    &.slick-initialized {
        display: block;
    }

    .slick-list, .slick-slide > div, .slick-track {
        height: 100%;
    }

    .slick-slide {
        display: none;

        &.slick-current {
            display: block;
        }
    }

    .slick-track {
        overflow: hidden;
    }


    .slide {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        .slide-image {
            width: 100%;
            height: 100%;
            display: block;
            background-position: 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transform: translate3d(100%, 0, 0);
    }
}
::v-deep .slick-dots {
    text-align: center;
    padding: 0 15px;
    margin: 0;

    li {
        display: inline-block;
        margin-right: 5px;

        button {
            width: 8px;
            height: 8px;
            font-size: 0;
            border-radius: 50%;
            padding: 1px;
            cursor: pointer;
            transition: 0.5s;
            border: 1px solid var(--_c-dark-primary);
            background: transparent;
        }

        &.slick-active {
            button {
                background: var(--_c-dark-primary);
            }
        }
    }
}

.single-slide {
    margin-bottom: 30px;
}

.full-screen {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 1;
    fill: var(--c-black);
}
</style>