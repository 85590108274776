<template>
    <div class="container">
        <div class="container checkout">
            <div class="checkout__main">
                <SfSteps
                    v-if="!isThankYou"
                    :active="currentStepIndex"
                    :class="{ checkout__steps: true }"
                    :companies="companies"
                    :carts="carts"
                    :selectedCompany="selectedCompany"
                    :selectedProject="selectedProject"
                    @change="handleStepClick"
                    @selectCompany="selectCompany"
                    @selectProject="selectProject"
                    @removeProject="removeProject"
                    @addProject="addProject"
                >
                    <SfStep
                        v-for="(step, key) in STEPS"
                        :key="key"
                        :name="$t(step.title)"
                        :active="1"
                        can-go-back
                    >
                        <nuxt-child />
                    </SfStep>
                </SfSteps>
                <nuxt-child v-else />
            </div>
            <NewProject
                v-if="newProjectModal"
                :visible="newProjectModal"
                :bee_customer_id="selectedCompany"
                :companies="companies"
                @close="newProjectModal = 0"
                @handleStepClick="handleStepClick"
            />
            <RemoveProject
                v-if="removeProjectModal"
                :visible="removeProjectModal"
                :cart="cart"
                @close="removeProjectModal = 0"
                @handleStepClick="handleStepClick"
            />
        </div>
    </div>
</template>
<script>
import NewProject from '~/components/Checkout/NewProject.vue';
import RemoveProject from '~/components/Checkout/RemoveProject.vue';
import SfSteps from '~/components/SfSteps/SfSteps.vue';
import { SfSelect } from '@storefront-ui/vue';
import { cartGetters } from '~/getters';
import { useUser, useMember, useCart, useUiState } from '~/composables';
import {
	computed,
	defineComponent,
	ref,
	useRoute,
	useRouter,
	useContext,
	onMounted,
	useFetch,
	watch,
	reactive,
} from '@nuxtjs/composition-api';

export default defineComponent({
	name: 'CheckoutPage',
	components: {
		SfSteps,
		SfSelect,
		NewProject,
		RemoveProject,
	},
	head() {
		const title = 'Checkout page - Vizulo';
		const meta = [];

		return {
			title,
			meta,
		};
	},
	middleware: [
		'is-member',
		'is-authenticated',
		'has-access-to-checkout'
	],
	setup() {
		const STEPS = ref([
			{ title: 'Cart', url: 'cart' },
			{ title: 'Delivery', url: 'delivery' },
			{ title: 'Billing', url: 'billing' },
			{ title: 'Order summary', url: 'summary' },
		]);

		const route = useRoute();
		const { app } = useContext();
		const { path } = route.value;
		const router = useRouter();
		const { load: loadUser, user } = useUser();
		const { cart, load, loading, getCarts, setNewCart, removeCart } = useCart();
		const products = computed(() => cartGetters.getItems(cart.value));
		const currentStep = computed(() => path.split('/').pop());
		const { loading: permissionsLoading, getPermissions } = useMember();
		const apiState = app.$vsf.$magento.config.state;
		const { isRefreshCart } = useUiState();

		const companies = ref([]);
		const cartCompanies = ref([]);
		const carts = ref([]);

		const selectedCart = ref(null);
		const selectedCompany = ref(null);
		const selectedProject = ref(null);
		const newProjectModal = ref(null);
		const removeProjectModal = ref(null);

		const currentStepIndex = computed(() => STEPS.value.findIndex((step) => step.url === currentStep.value));

		const handleStepClick = async (stepIndex) => {
			if (stepIndex <= currentStepIndex.value) {
				const { url } = STEPS.value[stepIndex];
				await router.push(`${app.localePath(`/checkout/${url}`)}`);
			}
		};

		onMounted(async () => {
			await refreshCart();
		});

		watch(() => isRefreshCart.value, async (newState, oldState) => {
			if (newState) {
				await refreshCart();
			}
		});

		const refreshCart = async () => {
			if (!user.value.id) {
				await loadUser();
			}

			const cartsResponse = await getCarts(user.value.bee_customer_id);
			carts.value = cartsResponse.items ?? [];

			selectedCart.value = carts.value.find(c => { return c.is_active });

			if (selectedCart.value) {
				selectedCompany.value = selectedCart.value.bee_customer_id;
				selectedProject.value = selectedCart.value.project;

				apiState.setSelectedCompany(selectedCompany.value);

				await load(selectedCompany.value);
			}

			if (!loading && products.value.length === 0 && currentStep.value !== 'thank-you') {
				await router.push(app.localePath('/'));
			}

			const resultPermissions = await getPermissions();

			companies.value = resultPermissions.companies;
			cartCompanies.value = resultPermissions.companies.filter(c => carts.value.map(a => a.bee_customer_id).includes(c.id));
		}

		const selectCompany = async (option) => {
			selectedCompany.value = option.toString();

			if (apiState.getSelectedCompany() != option) {
				const newCart = carts.value.find(c => { 
					return c.bee_customer_id && option.toString() == c.bee_customer_id.toString();
				});

				if (newCart) {
					apiState.setSelectedCompany(option.toString());
                    
					selectedProject.value = newCart.project;

					await setNewCart(newCart.id, newCart.project);
					await router.push(`${app.localePath('/checkout/cart')}`);
				}
			}
		};

		const selectProject = async (option) => {
			selectedProject.value = option;

			const newCart = carts.value.find(c => {
				return c.bee_customer_id == selectedCompany.value && option == c.project;
			});

			if (newCart) {
				await setNewCart(newCart.id, option);
				await router.push(`${app.localePath('/checkout/cart')}`);
			}
		};

		const addProject = async (project) => {
			newProjectModal.value = true;
		};

		const removeProject = async () => {
			removeProjectModal.value = true;
		};

		return {
			cart,
			loading,
			companies,
			cartCompanies,
			selectedCompany,
			selectedProject,
			handleStepClick,
			STEPS,
			currentStepIndex,
			isThankYou: computed(() => currentStep.value === 'thank-you'),
			currentStep,
			selectCompany,
			selectProject,
			carts,
			addProject,
			removeProject,
			isRefreshCart,
			newProjectModal,
			removeProjectModal
		};
	},
});
</script>

<style lang="scss" scoped>
.checkout {
    @include for-desktop {
        min-height: calc(100vh - 650px);
        display: flex;
        padding: 0 0 120px 0;
    }

    &__main {
        @include for-desktop {
            flex: 1;
        }
    }

    &__steps {
        --steps-content-padding: 0 var(--spacer-base);
        @include for-desktop {
            --steps-content-padding: 0;
        }

        &-auth::v-deep .sf-steps__step:first-child {
            --steps-step-color: #e8e4e4;
        }
    }
}

.empty-cart {
    --heading-description-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-color: var(--c-primary);
    --heading-title-font-weight: var(--font-weight--semibold);
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 120px 0;

    &__heading {
        padding: 0 var(--spacer-base);
    }

    @include for-desktop {
        --heading-title-font-size: var(--font-size--xl);
        --heading-title-margin: 0 0 var(--spacer-sm) 0;
    }
}
</style>
