import { render, staticRenderFns } from "./VsfShippingProvider.vue?vue&type=template&id=43e3dd42&scoped=true&"
import script from "./VsfShippingProvider.vue?vue&type=script&lang=js&"
export * from "./VsfShippingProvider.vue?vue&type=script&lang=js&"
import style0 from "./VsfShippingProvider.vue?vue&type=style&index=0&id=43e3dd42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e3dd42",
  null
  
)

export default component.exports