<template>
    <ValidationObserver v-slot="{ handleSubmit, reset }">
        <SfHeading
            :level="3"
            :title="$t('Billing address')"
            class="sf-heading--left sf-heading--no-underline title"
        />
        <form @submit.prevent="handleSubmit(handleAddressSubmit(reset))">
            <div v-if="currentAddressId" class="copy__shipping__addresses">
                <div class="copy__shipping__address">
                    <div class="sf-address">
                        <UserAddressDetails
                            :address="{
                                ...billingDetails,
                                region: { region_code: billingDetails.region },
                            }"
                        />
                    </div>
                </div>
            </div>

            <div v-if="!currentAddressId && canEditBillingAddress" class="form">
                <ValidationProvider
                    v-slot="{ errors }"
                    name="company"
                    rules="required"
                    slim
                >
                    <SfInput
                        :value="billingDetails.company"
                        :label="$t('Company')"
                        name="company"
                        class="form__element"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(company) => changeBillingDetails('company', company)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="street"
                    rules="required"
                    slim
                >
                    <SfInput
                        :value="billingDetails.street"
                        :label="$t('Street Name')"
                        name="streetName"
                        class="form__element"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(street) => changeBillingDetails('street', street)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="city"
                    rules="required|min:2"
                    slim
                >
                    <SfInput
                        :value="billingDetails.city"
                        :label="$t('City')"
                        name="city"
                        class="form__element form__element--half"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(city) => changeBillingDetails('city', city)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="zipCode"
                    rules="required|min:2"
                    slim
                >
                    <SfInput
                        :value="billingDetails.postcode"
                        :label="$t('Zip-code')"
                        name="zipCode"
                        class="form__element form__element--half form__element--half-even"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(postcode) => changeBillingDetails('postcode', postcode)"
                    />
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="country"
                    rules="required|min:2"
                    slim
                >
                    <SfSelect
                        :value="billingDetails.country_code"
                        :label="$t('Country')"
                        name="country"
                        class="form__element form__element--half form__select sf-select--underlined"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="changeCountry"
                      >
                            <SfSelectOption
                                v-for="countryOption in countriesList"
                                :key="countryOption.id"
                                :value="countryOption.abbreviation"
                            >
                                {{ countryOption.label }}
                            </SfSelectOption>
                    </SfSelect>
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="region"
                    :rules="
                        !billingDetails.country_code || regionInformation.length === 0
                            ? null
                            : 'required|min:2'
                    "
                    slim
                >
                    <SfInput
                        v-if="!billingDetails.country_code || regionInformation.length === 0"
                        :value="billingDetails.region"
                        :label="$t('State/Province')"
                        :disabled="!billingDetails.country_code"
                        name="state"
                        class="form__element form__element--half form__element--half-even"
                        :valid="!!billingDetails.country_code"
                        :error-message="
                          !billingDetails.country_code
                            ? $t('Please select a country first')
                            : ''
                        "
                        @input="(region) => changeBillingDetails('region', region)"
                    />
                    <SfSelect
                        v-else
                        :value="billingDetails.region"
                        :label="$t('State/Province')"
                        name="state"
                        class="form__element form__element--half form__element--half-even form__select sf-select--underlined"
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="(region) => changeBillingDetails('region', region)"
                    >
                        <SfSelectOption
                            v-for="regionOption in regionInformation"
                            :key="regionOption.id"
                            :value="regionOption.abbreviation"
                        >
                            {{ regionOption.label }}
                        </SfSelectOption>
                    </SfSelect>
                </ValidationProvider>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="phone"
                    rules="required"
                    slim
                >
                    <SfInput
                        v-model="billingDetails.telephone"
                        :label="$t('Phone number')"
                        name="phone"
                        class="form__element form__element--half"
                        required
                        :valid="!errors[0]"
                        :error-message="$t(errors[0])"
                        @input="
                            (telephone) => changeBillingDetails('telephone', telephone)
                        "
                    />
                </ValidationProvider>
            </div>
            <Button
                v-if="!currentAddressId && canEditBillingAddress"
                class="button button-secondary medium mb-3"
            >
                {{ $t('Save') }}
            </Button>

            <VsfPaymentProvider v-if="currentAddressId" @status="setPaymentReady" />
            <div class="text-right">
                <Button
                    v-if="isPaymentReady"
                    class="button button-primary"
                    type="submit"
                    :disabled="!canMoveForward"
                >
                    {{ $t('Continue to summary') }}
                </Button>
                <nuxt-link
                    to="localePath('/checkout/delivery')"
                    class="button button-secondary smartphone-only"
                >
                    {{ $t('Go back') }}
                </nuxt-link>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import {
	ref,
	computed,
	onMounted,
	watch,
	useRouter,
	defineComponent,
	useContext,
} from '@nuxtjs/composition-api';

import { SfHeading, SfInput, SfSelect, SfCheckbox, SfLoader } from '@storefront-ui/vue';
import { required, min, digits } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { userBillingGetters, addressGetter } from '~/getters';
import { useUser, useCart, useBilling, useUserAddress, useAddresses, useUiNotification, useCountrySearch } from '~/composables';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import { addressFromApiToForm, formatAddressReturnToData } from '~/helpers/checkout/address';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import Button from '~/components/Common/Button.vue';
import Link from '~/components/Common/Link.vue';

const NOT_SELECTED_ADDRESS = '';

extend('required', {
	...required,
	message: 'This field is required',
});
extend('min', {
	...min,
	message: 'The field should have at least {length} characters',
});
extend('digits', {
	...digits,
	message: 'Please provide a valid phone number',
});

export default defineComponent({
	name: 'BillingStep',
	components: {
		SfHeading,
		SfInput,
		SfSelect,
		SfCheckbox,
		Button,
		Link,
		SfLoader,
		ValidationProvider,
		ValidationObserver,
		UserBillingAddresses: () => import('~/components/Checkout/UserBillingAddresses.vue'),
		VsfPaymentProvider: () => import('~/components/Checkout/VsfPaymentProvider.vue'),
		UserAddressDetails,
	},
	setup() {
		const router = useRouter();
		const { app } = useContext();
		const billingAddress = ref({});
		const userBilling = ref({});
		const apiState = app.$vsf.$magento.config.state;
		const { cart, load, loading: loadingCart } = useCart();
		const { send: sendNotification } = useUiNotification();
		const { save, load: loadBilling, loading } = useBilling();
		const { load: loadUserBilling, setDefaultAddress } = useUserAddress();
		const { load: loadCountries, search: searchCountry } = useCountrySearch();
		const countries = ref([]);
		const country = ref(null);

		// custom
		const {
			save: saveAddress,
			loading: loadingCustomerAddresses,
			getCustomerAddresses
		} = useAddresses();

		const { isAuthenticated } = useUser();
		const billingDetails = ref(addressFromApiToForm(billingAddress.value));
		const currentAddressId = ref(NOT_SELECTED_ADDRESS);
		const setAsDefault = ref(false);
		const isPaymentReady = ref(false);

		const userPermissions = apiState.getPermissions();
		const canEditBillingAddress = computed(() => userPermissions.find(el => el.code == 'edit billing address'));

		const isBillingDetailsStepCompleted = ref(false);
		const addresses = computed(() => userBillingGetters.getAddresses(userBilling.value) ?? []);

		const canMoveForward = computed(() => !loading.value && billingDetails.value && Object.keys(
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			billingDetails.value,
		).length > 0);

		const hasSavedBillingAddress = computed(() => {
			if (!isAuthenticated.value || !userBilling.value) {
				return false;
			}

			return addresses.value.length > 0;
		});

		// @ts-ignore
		const countriesList = computed(() => addressGetter.countriesList(countries.value));
		const regionInformation = computed(() => addressGetter.regionList(country.value));


		const handleAddressSubmit = (reset) => async () => {
			let cont = true;

			if (!currentAddressId.value) {
				cont = false;
				const region = billingDetails.value.region;
				const regionId = regionInformation.value.find((r) => r.abbreviation === region)?.id;

				const addressResponse = await saveAddress({ address: {
					...billingDetails.value,
					firstname: 'Vizulo',
					lastname: 'Customer',
					bee_customer_id: cart.value.bee_customer_id,
					default_billing: true,
					region: {
						region_code: '',
						region_id: regionId,
						region: region,
					},
				} });

				currentAddressId.value = addressResponse.id;
				billingDetails.value = addressResponse;
			}

			const billingDetailsData = {
				billingDetails: {
					...billingDetails.value,
					customerAddressId: currentAddressId.value,
					sameAsShipping: false,
					save_in_address_book: false
				},
			};

			await save(billingDetailsData);

			if (currentAddressId.value !== NOT_SELECTED_ADDRESS && setAsDefault.value) {
				const [chosenAddress] = userBillingGetters.getAddresses(
					userBilling.value,
					{ id: currentAddressId.value },
				);

				chosenAddress.default_billing = setAsDefault.value;

				if (chosenAddress) {
					await setDefaultAddress({ address: chosenAddress });
					userBilling.value = loadUserBilling(true);
				}
			}

			if (cont) {
    			reset();
    			await mergeItem('checkout', { billing: billingDetailsData });
    			await router.push(`${app.localePath('/checkout/summary')}`);
    			isBillingDetailsStepCompleted.value = true;
			}
		};

		const handleSetCurrentAddress = (addr) => {
			billingDetails.value = { ...addressFromApiToForm(addr) };
			currentAddressId.value = addr?.id;
			isBillingDetailsStepCompleted.value = false;
		};

		const selectDefaultAddress = () => {
			const defaultAddress = userBillingGetters.getAddresses(
				userBilling.value,
				{ default_billing: true },
			);

			if (defaultAddress && defaultAddress.length > 0) {
				handleSetCurrentAddress(defaultAddress[0]);
			}
		};

		const changeBillingDetails = (field, value) => {
			billingDetails.value = {
				...billingDetails.value,
				[field]: value,
			};
		};

		const changeCountry = async (id) => {
			changeBillingDetails('country_code', id);
			country.value = await searchCountry({ id });
		};

		const setPaymentReady = () => {
			isPaymentReady.value = true;
		};

		watch(billingAddress, (addr) => {
			billingDetails.value = addressFromApiToForm(addr || {});
		});

		onMounted(async () => {
			const validStep = await isPreviousStepValid('shipping');

			if (!validStep) {
				await router.push(app.localePath('/checkout/cart'));
			}

			if (countries.value.length == 0) {
				const [loadedCountries] = await Promise.all([
					loadCountries(),
				]);

				countries.value = loadedCountries;
			}

			if (!cart.value.id) {
				await load();
			}

			userBilling.value.addresses = cart.value?.company_addresses;

			const defaultBilling = cart.value?.company_addresses.find(a => { return a.default_billing });

			billingAddress.value = defaultBilling;

			const billingAddresses = userBillingGetters.getAddresses(
				userBilling.value,
			);

			if (billingDetails.value?.country_code) {
				country.value = await searchCountry({ id: billingDetails.value.country_code });
			}

			if (!canEditBillingAddress.value && (!defaultBilling || !billingAddresses || billingAddresses.length === 0)) {
				sendNotification({
					id: Symbol('address_missing'),
					type: 'danger',
					icon: 'cross',
					persist: false,
					message: 'Missing billing address!',
				});

				await router.push(app.localePath('/checkout/delivery'));
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			const hasEmptyBillingDetails = !billingDetails.value || Object.keys(billingDetails.value).length === 0;
			if (hasEmptyBillingDetails) {
				selectDefaultAddress();
				return;
			}
		});

		return {
			canMoveForward,
			currentAddressId,
			handleAddressSubmit,
			handleSetCurrentAddress,
			hasSavedBillingAddress,
			isAuthenticated,
			isBillingDetailsStepCompleted,
			loading,
			NOT_SELECTED_ADDRESS,
			setAsDefault,
			billingDetails,
			addresses,
			setPaymentReady,
			isPaymentReady,
			canEditBillingAddress,
			changeBillingDetails,
			countriesList,
			country,
			regionInformation,
			changeCountry,
		};
	},
});
</script>
<style lang="scss" scoped>
.title {
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;
    --heading-title-font-weight: var(--font-weight--bold);
}

.copy__shipping {
    &__address {
        margin-bottom: var(--spacer-xs);

        @include for-desktop {
            margin-right: var(--spacer-sm);
            display: flex;
            width: 100%;
            flex-direction: column;
        }

        .sf-address {
            padding: var(--spacer-xs);
        }
    }

    &__addresses {
        margin-bottom: var(--spacer-xl);

        @include for-desktop {
            display: flex;
        }
    }
}

.form {
    --button-width: 100%;

    &__select {
        display: flex;
        align-items: center;
        --select-option-font-size: var(--font-size--lg);

        ::v-deep .sf-select__dropdown {
            font-size: var(--font-size--lg);
            margin: 0;
            color: var(--c-text);
            font-family: var(--font-family--secondary);
            font-weight: var(--font-weight--normal);
        }

        ::v-deep .sf-select__label {
            left: initial;
        }
    }

    @include for-desktop {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        --button-width: auto;
    }

    &__element {
        margin: 0 0 var(--spacer-xl) 0;

        @include for-desktop {
            flex: 0 0 100%;
        }

        &--half {
            @include for-desktop {
                flex: 1 1 50%;
            }

            &-even {
                @include for-desktop {
                    padding: 0 0 0 var(--spacer-xl);
                }
            }
        }
    }

    &__action {
        @include for-desktop {
            flex: 0 0 100%;
            display: flex;
        }
    }

    &__action-button {
        &--secondary {
            @include for-desktop {
                order: -1;
                text-align: left;
            }
        }

        &--add-address {
            width: 100%;
            margin: 0;

            @include for-desktop {
                margin: 0 0 var(--spacer-lg) 0;
                width: auto;
            }
        }
    }

    &__back-button {
        margin: var(--spacer-xl) 0 var(--spacer-sm);

        &:hover {
            color: var(--c-white);
        }

        @include for-desktop {
            margin: 0 var(--spacer-xl) 0 0;
        }
    }
}

.billing {
    &__label {
        display: flex;
        justify-content: space-between;
    }

    &__description {
        --radio-description-margin: 0;
        --radio-description-font-size: var(--font-xs);
    }
}

.sf-loader {
    &__overlay {
        margin-top: 120px;
    }

    min-height: 300px;
}
</style>
