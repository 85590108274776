<template>
    <SfLoader :loading="$fetchState.pending || loading">
        <div class="textdoc" :class="{notFound: page.identifier == 'no-route' }">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <SfHeading
                            v-if="page && page.content_heading"
                            :title="page.content_heading"
                            :level="1"
                            class="text-center"
                        />
                    </div>
                    <div class="col-12">
                        <HTMLContent
                            v-if="page && page.content"
                            :content="page.content"
                        />
                    </div>
                </div>
            </div>
        </div>
    </SfLoader>
</template>
<script>
import {
	SfLoader,
	SfHeading,
} from '@storefront-ui/vue';
import {
	defineComponent, ref, useContext, useFetch, useRoute,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { useContent } from '~/composables';
import HTMLContent from '~/components/HTMLContent';

export default defineComponent({
	name: 'CmsPage',
	components: {
		HTMLContent,
		SfLoader,
		SfHeading,
	},
	props: {
		identifier: {
			type: [String],
			default: '',
		},
	},
    head() {
        if (!this.page) {
            return null;
        }

        const title = this.page?.meta_title ? this.page.meta_title : this.page.title;
        const meta = [];

        if (this.page.meta_description) {
            meta.push({
                hid: 'description',
                name: 'description',
                content: this.page.meta_description,
            });
        }

        return {
            title,
            meta,
        };
    },
	setup(props) {
		const { addTags } = useCache();
		const {
			loadPage,
			error,
			loading,
		} = useContent('cmsPage');

		const route = useRoute();
		const { error: nuxtError, app } = useContext();
		const { params } = route.value;
		const page = ref({});

		useFetch(async () => {
			page.value = await loadPage({ identifier: params.slug || props.identifier });

			if (error?.value?.page || !page.value) {
				return nuxtError({ statusCode: 404, message: app.i18n.t('Page not found') });
			}

			addTags([{ prefix: CacheTagPrefix.View, value: page.value.identifier }]);
		});

		return {
			page,
			loading,
		};
	}
});
</script>
<style lang="scss" scoped>
.notFound {
    padding: 0;
    margin: 0;

    .container {
        width: calc(100vw - var(--sidebar-width));
        max-width: calc(100vw - var(--sidebar-width));
        padding: 0;

        .col-12 {
            padding: 0;
            margin: 0;
        }

        .sf-heading {
            display: none;
        }

        img {
            width: 100%;
        }
    }   
}
</style>
