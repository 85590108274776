<template>
	<div>
        <script>0</script>
        <client-only>
            <Notification />
        </client-only>

		<IconSprite />
		<div id="layout">
			<nuxt :key="route.fullPath"/>
		</div>
		<LeftNavigation />
		<SfOverlay
			v-if="isSidebarOpen || isCountrySelectorModalOpen"
			:visible="isSidebarOpen || isCountrySelectorModalOpen"
			:class="{ 'country-modal-open': isCountrySelectorModalOpen }"
		/>
        <client-only>
    		<div class="container" v-if="!route.path.includes('product-configurator')">
    			<div class="row">
    				<div class="col-12">
    					<AppFooter />
    				</div>
    			</div>
    		</div>
        </client-only>
		<CountrySelectorModal
			v-if="isCountrySelectorModalOpen"
		/>
		<client-only>
			<cookie-law theme="vizulo">
				<div slot-scope="props">
					<button class="skew" @click="props.accept"><span>{{ $t('Got it') }}</span></button>
					<p>
						We use cookies to understand how you use our site and to improve your experience. By continuing
						to use our site, you accept the use of cookies described in our
						<nuxt-link
							:to="localePath('/privacy-policy-cookie-restriction-mode')"
							class="text-gray"
						>
							{{ $t('privacy policy') }}
						</nuxt-link>
					</p>
				</div>
			</cookie-law>
		</client-only>
	</div>
</template>
<script>
import LeftNavigation from '~/components/LeftNavigation.vue';
import AppFooter from '~/components/AppFooter.vue';
import { SfOverlay } from '@storefront-ui/vue';
import CountrySelectorModal from '@/components/CountrySelector/CountrySelectorModal';

import {
	useRoute, defineComponent, useAsync, onMounted, onUnmounted, computed, useContext, onBeforeMount,
} from '@nuxtjs/composition-api';

import { useUiState, useUser } from '~/composables';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';
import IconSprite from '~/components/General/IconSprite.vue';

export default defineComponent({
	name: 'DefaultLayout',
	components: {
		LeftNavigation,
		AppFooter,
		IconSprite,
		SfOverlay,
		CountrySelectorModal,
		Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification'),
	},
	middleware: 'is-staging',
	setup() {
		const route = useRoute();
		const { load: loadUser } = useUser();
		const { loadConfiguration } = useMagentoConfiguration();
		const { app } = useContext();

		const {
			toggleMobileState,
			isNavigationSidebarOpen,
			isNavigationSearchOpen,
			isNavigationStoreSwitcherOpen,
			isCountrySelectorModalOpen,
			toggleCountrySelectorModal
		} = useUiState();

		const openCountrySelector = () => {
			if (!app.$vsf.$magento.config.state.getIsCountrySelected()) {
                // show only for /en/
				if (route.value.path.split('/')[1] == 'en') {
				    toggleCountrySelectorModal();
				} else {
					const expiryDate = new Date();
					expiryDate.setMonth(expiryDate.getMonth() + 1);

					app.$vsf.$magento.config.state.setIsCountrySelected(true, { expires: expiryDate, path: '/' });
				}
			}
		}

		useAsync(async () => {
			await Promise.all([loadConfiguration(), loadUser()]);
		});

		const isSidebarOpen = computed(() => {
			return isNavigationSidebarOpen.value || isNavigationSearchOpen.value || isNavigationStoreSwitcherOpen.value;
		});

		onBeforeMount(() => {
			window.addEventListener('load', toggleMobileState);
			window.addEventListener('resize', toggleMobileState);
		});

		onMounted(() => {
			openCountrySelector();
		});

		onUnmounted(() => {
			window.removeEventListener('load', toggleMobileState);
			window.removeEventListener('resize', toggleMobileState);
		});

		return {
			route,
			isSidebarOpen,
			isCountrySelectorModalOpen
		};
	},
});
</script>

<style lang="scss">
@import "assets/styles";

.sf-overlay {
	background: linear-gradient(90deg, transparent 0, transparent 0, rgba(0, 0, 0, .5) 0);
	z-index: 100;

	&.country-modal-open {
		z-index: 111;
	}

	@media (min-width: 992px) {
		background: linear-gradient(90deg, transparent 0, transparent 120px, rgba(0, 0, 0, .5) 120px);
	}
}

body, html {
	font-family: var(--font-family--primary);
}

body {
	padding-left: var(--sidebar-left);

	@media(max-width: 991px) {
		padding-left: var(--sidebar-left-mobile);

		#layout {
			margin-top: 70px;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: var(--font-family--secondary);
	letter-spacing: 0.2px;
}

.Cookie--vizulo {
	background: var(--c-black);
	color: var(--c-white);
	padding: 15px;

	button {
		background: var(--c-white);
	}
}

.Cookie--bottom {
	@media (min-width: 992px) {
		left: 120px !important;
	}
}

</style>
