<template>
    <div class="container footer">
        <div class="upper-footer row">
            <div class="col-12 col-lg-4 flex pt-2 pb-1">
                <div>
                    <div class="sf-heading">
                        <h3 class="sf-heading__title h3" v-html="$t('Contacts')" />
                    </div>
                    <content-block
                        v-if="footer_contacts"
                        :title="footer_contacts.title"
                        :content="footer_contacts.content"
                        :showTitle="false"
                    />
                </div>
                <div class="footer_sitemap">
                    <div class="sf-heading">
                        <h3 class="sf-heading__title h3" v-html="$t('Sitemap')" />
                    </div>
                    <SfList class="left footer-link">
                        <SfListItem
                            v-for="(link, name) in sitemap"
                            :key="link"
                        >
                            <SfMenuItem
                                :label="$t(name)"
                                :link="link"
                            />
                        </SfListItem>
                    </SfList>
                </div>
            </div>
            <div class="col-12 col-lg-8">
                <div class="row pl-2 contact-us">
                    <ContactUs />
                </div>
                <div class="row border-top pt-3 pl-2">
                    <NewsLetterForm />
                </div>
            </div>
        </div>
        <div class="terms-privacy row border-top border-bot">
            <content-block
                v-if="terms_and_policy"
                :title="terms_and_policy.title"
                :content="terms_and_policy.content"
                :showTitle="false"
            />
            <content-block
                v-if="social_media"
                :title="social_media.title"
                :content="social_media.content"
                :showTitle="false"
            />
        </div>
    </div>
</template>

<script>
import { addBasePath } from '~/helpers/addBasePath';
import NewsLetterForm from '~/components/Common/NewsLetterForm.vue';
import ContactUs from './Common/ContactUs.vue';
import { SfList, SfMenuItem } from '@storefront-ui/vue';
import { useContent } from '~/composables';
import { ref, useFetch, computed } from '@nuxtjs/composition-api';
import ContentBlock from './ContentBlock';

export default {
    components: {
        NewsLetterForm,
        ContactUs,
        ContactUs,
        SfList,
        SfMenuItem,
        ContentBlock
    },
    setup() {
        const {
            loadBlocks,
        } = useContent();

        const blocks = ref([]);

        useFetch(async () => {
            blocks.value = await loadBlocks({
                showTitle: false,
                identifiers: [
                    'footer_contacts',
                    'terms_and_policy',
                    'social_media'
                ]
            });
        });

        const footer_contacts = computed(() => blocks.value.find(e => e.identifier == 'footer_contacts'));
        const terms_and_policy = computed(() => blocks.value.find(e => e.identifier == 'terms_and_policy'));
        const social_media = computed(() => blocks.value.find(e => e.identifier == 'social_media'));

        return {
            footer_contacts,
            terms_and_policy,
            social_media,
            addBasePath,
        };
    },

    data(app) {
        return {
            sitemap: {
                'References': app.localePath('references'),
                'About us': app.localePath('about-us'),
                'Contacts': app.localePath('contacts'),
                'News': app.localePath('news'),
                'Members': app.localePath('members'),
                'Vacancies': app.localePath('vacancies')
            },
        }
    }
};
</script>

<style lang="scss" scoped>
h6 {
    margin: 0 0 12px;
}

::v-deep h4 {
    font-size: 20px;
    letter-spacing: .25px;
    line-height: 24px;
    margin-bottom: 0;
}

h3 {
    margin-top: 4px;
    font-size: 16px;

    @media (min-width: 992px) {
        font-size: var(--heading-title-font-size, var(--h3-font-size));
    }
}

::v-deep p {
    font-size: 18px;
}

.contact-widget__link {
    position: absolute;
    top: 32px;
    right: 60px;
    font-weight: 700;
    letter-spacing: .25px;
}

.contact-us {
    display: none;

    @media (min-width: 1200px) {
        display: flex;
    }
}

.footer_sitemap {
    margin-right: 12px;
    margin-top: 12px;

    @media (min-width: 992px) {
        margin-top: 24px;
    }

    @media (min-width: 1200px) {
        margin-top: 0;
    }

    .footer-link {
        .sf-menu-item__label {
             color: var(--c-black) !important;
        }
    }

    ul {
        padding-top: 3px;
    }
}

img {
    display: inline;
    height: 28px;
    width: 28px;
    margin-right: 12px;
    margin-bottom: -8px;
}

a {
    text-decoration: none;
}

.footer {
    font-size: 16px;
    text-align: left;
    margin-bottom: 30px;
    border-top: 1px solid var(--c-black);

    @media (min-width: 992px) {
        font-size: 20px;
        margin-bottom: 65px;
    }

    @media (min-width: 1200px) {
        border-top: none;
    }

    .sf-heading {
        text-align: left;
        margin-bottom: 20px;
    }
    .terms-privacy {
        padding-top: 24px;
        padding-bottom: 24px;

        & > div {
            padding: 0;
        }
    }

    .upper-footer {
        flex-direction: column;

        @media (max-width: 991px) {
            & > div:first-child {
                padding-left: 0;
                padding-right: 0;
            }                
        }

        @media (min-width: 1200px) {
            flex-direction: row;
            border-top: 1px solid var(--c-black);

            & > div:first-child {
                padding-left: 0;
            }
        }
    }
}

.flex {
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 20px;
        border-right: 1px solid var(--c-black);
    }
}

.border-top {
    border-top: none;

    @media (min-width: 1200px) {
        border-top: 1px solid var(--c-black);
    }
}

.border-bot {
    border-bottom: none;

    @media (min-width: 1200px) {
        border-bottom: 1px solid var(--c-black);
    }
}

.p-1 {
	padding: 12px;

	@media (min-width: 992px) {
		padding: 24px
	}
}

.pt-2 {
    padding-top: 24px !important;
}

.pt-3 {
    padding-top: 12px !important;

    @media (min-width: 992px) {
        padding-top: 36px !important
    }
}

.pb-3 {
    padding-bottom: 0 !important;

    @media (min-width: 1200px) {
        padding-bottom: 36px !important;
    }
}

.pl-2 {
    padding-left: 0 !important;

    @media (min-width: 1200px) {
        padding-left: 24px !important;
    }
}

::v-deep .sf-heading {
    text-align: left;
    margin-bottom: 20px;
    font-size: 16px;

    @media (min-width: 992px) {
        font-size: 20px;
    }
}

::v-deep .sf-menu-item__label {
    font-family: var(--font-family--primary);
    margin-bottom: 12px;
    color:var(--c-black) !important;
    font-size: 16px;

    @media (min-width: 992px) {
        font-size: 20px;
    }
}

::v-deep .sf-menu-item__mobile-nav-icon {
    display: none;
}
</style>