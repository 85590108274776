<template>
    <div class="container">
        <div class="contact-widget">
            <h4>{{ $t('Contact us') }}</h4>
            <div class="flex">
                <div class="fb-1">
                    <p>{{ $t('Reach us by e-mail, phone, or social networks to become our client or get more information.') }}</p>
                </div>

                <div class="fb-2" v-if="apiState.getStore() == 'pl'">
                    <p>
                        Piotr Kapkowski<br>
                        <a href="mailto:piotr.kapkowski@vizulo.com">piotr.kapkowski@vizulo.com</a><br>
                        {{ $t('Phone') }}: +48 518 927 069
                    </p>                    
                </div>
                <div class="fb-2" v-else>
                    <p>
                        {{ $t('Office') }}: <a href="mailto:office@vizulo.com">office@vizulo.com</a><br>
                        {{ $t('Phone') }}: +371 67 383 023
                    </p>
                </div>
            </div>
            <div class="contact-widget__link">
                <a :href="linkedIn[apiState.getStore()] ? linkedIn[apiState.getStore()] : linkedIn['en']" target="_blank">
                    <img src="~/static/media/images/logo-linkedin.svg" alt="">
                    Linkedin
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'ContactUs',
    components: {},
    data() {
        return {
            linkedIn: {
                en: 'https://www.linkedin.com/company/vizulo-solution/',
                pl: 'https://www.linkedin.com/company/vizulo-polska/'
            }
        }
    },
    setup() {
        const { app } = useContext();
        const apiState = app.$vsf.$magento.config.state;

        return {
            apiState
        }
    },
});
</script>

<style lang="scss" scoped>
    .contact-widget {
        position: relative;
        font-size: 20px;
        line-height: 28px;
        margin: 0;

        @media (max-width:992px) {
            font-size: 16px;
            letter-spacing: .2px;
            line-height: 24px;
        }

        h6 {
            margin: 0 0 12px;
        }

        h4 {
            font-size: 20px;
            letter-spacing: .25px;
            line-height: 28px;
        }

        .contact-widget__link {
            position: absolute;
            top: 0;
            right: -12px;
            font-weight: 700;
            letter-spacing: .25px;
        }

        img {
            display: inline;
            height: 28px;
            width: 28px;
            margin-right: 12px;
            margin-bottom: -8px;
        }

        a {
            text-decoration: none;
        }

        .flex {
            display: flex;
            justify-content: space-between;
        }

        .fb-1 {
            flex-basis: none;

            @media (min-width: 992px) {
                flex-basis: 40%;
            }
        }
    }
    ::v-deep .sf-heading {
        text-align: left;
        margin-bottom: 20px;
        font-size: 16px;
        @media (min-width: 992px) {
            font-size: 20px;
        }
    }
    ::v-deep li {
        margin-bottom: 12px;
    }
</style>