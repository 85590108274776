<template>
    <div id="cart">
        <div class="add-article mb-5 ml-auto">
            <CartArticle :bee_customer_id="selectedOptions.bee_customer_id" @refreshAddedArticles="updateArticlesWithFiveYearWarranty"/>
        </div>
        <ValidationObserver ref="form" tag="div" key="cart-options" v-if="selectedOptions.project">
            <div class="row">
                <div class="col-12 col-md-3" v-if="cartOptions.hasOwnProperty('categories') && cartOptions.categories.length">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfSelect
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.category_id"
                                :placeholder="$t('Select...')"
                                :label="$t('Category')"
                                name="member"
                                class="form__element form__select sf-select--underlined"
                                @input="updateCartOption('category_id', $event)"
                            >
                                <SfSelectOption
                                    v-for="category in cartOptions.categories"
                                    :key="category.id"
                                    :value="category.id"
                                    v-html="category.name"
                                />
                            </SfSelect>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfInput
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.customer_order_number"
                                name="customer_order_number"
                                :label="$t('Customer order number')"
                                class="form__element"
                                @input="updateCartOption('customer_order_number', $event, true)"
                            />
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <vue-datepicker
                                :format="dateFormat"
                                :required="true"
                                class="form__element"
                                :label="$t('Requested delivery date')"
                                v-model="selectedOptions.requested_delivery_date"
                                @input="updateCartOption('requested_delivery_date', $event, true)"
                            />
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-3" v-if="cartOptions.hasOwnProperty('sellers') && cartOptions.sellers.length">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfSelect
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.seller_id"
                                :placeholder="$t('Select...')"
                                :label="$t('Seller')"
                                name="seller"
                                class="form__element form__select sf-select--underlined"
                                @input="updateCartOption('seller_id', $event)"
                            >
                                <SfSelectOption
                                    v-for="seller in cartOptions.sellers"
                                    :key="seller.id"
                                    :value="seller.id"
                                    v-html="seller.name"
                                />
                            </SfSelect>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 col-md-3" v-if="cartOptions.hasOwnProperty('our_references') && cartOptions.our_references.length">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfSelect
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.our_reference_id"
                                :placeholder="$t('Select...')"
                                :label="$t('Our reference')"
                                name="our_reference"
                                class="form__element form__select sf-select--underlined"
                                @input="updateCartOption('our_reference_id', $event)"
                            >
                                <SfSelectOption
                                    v-for="reference in cartOptions.our_references"
                                    :key="reference.id"
                                    :value="reference.id"
                                    v-html="reference.name"
                                />
                            </SfSelect>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 col-md-3" v-if="cartOptions.hasOwnProperty('customer_references') && cartOptions.customer_references.length">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfSelect
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.customer_reference_id"
                                :placeholder="$t('Select...')"
                                :label="$t('Customer reference')"
                                name="customer_reference"
                                class="form__element form__select sf-select--underlined"
                                @input="updateCartOption('customer_reference_id', $event)"
                            >
                                <SfSelectOption
                                    v-for="reference in cartOptions.customer_references"
                                    :key="reference.id"
                                    :value="reference.id"
                                    v-html="reference.name"
                                />
                            </SfSelect>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-3" v-if="cartOptions.hasOwnProperty('countries') && cartOptions.countries.length">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfSelect
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.country_id"
                                :placeholder="$t('Select...')"
                                :label="$t('Country')"
                                name="country"
                                class="form__element form__select sf-select--underlined"
                                @input="updateCartOption('country_id', $event)"
                            >
                                <SfSelectOption
                                    v-for="country in cartOptions.countries"
                                    :key="country.id"
                                    :value="country.id"
                                    v-html="country.full_name_locale"
                                />
                            </SfSelect>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules=""
                        >
                            <SfInput
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.location"
                                name="location"
                                :label="$t('Location')"
                                class="form__element"
                                @input="updateCartOption('location', $event, true)"
                            />
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfCheckbox
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                name="is_corrosive_environment"
                                :label="$t('Yes')"
                                :infoMessage="$t('Corrosive environment')"
                                v-model="selectedOptions.is_corrosive_environment"
                                class="form__element"
                                @input="updateCartOption('is_corrosive_environment', $event)"
                            />
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 col-md-3" v-if="cartOptions.hasOwnProperty('warranty') && warrantyList.length">
                    <div class="form-group">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <SfSelect
                                :valid="!errors[0]"
                                :error-message="$t(errors[0])"
                                v-model="selectedOptions.warranty_id"
                                :placeholder="$t('Select...')"
                                :label="$t('Warranty')"
                                name="warranty"
                                class="form__element form__select sf-select--underlined"
                                @input="updateCartOption('warranty_id', $event)"
                            >
                                <SfSelectOption
                                    v-for="warranty in warrantyList"
                                    :class="shortTermArticles.length > 0 ? 'hidden': ''"
                                    :key="warranty.id"
                                    :value="warranty.id"
                                    v-html="warranty.name"
                                />
                            </SfSelect>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <span
                        v-if="selectedOptions.dimming"
                        class="button button-secondary medium"
                        @click="openDimmingModal(null, selectedOptions.dimming)"
                    >
                        {{ $t('Configure dimming') }}
                    </span>
                </div>
            </div>
        </ValidationObserver>
        <div v-if="deliveryDateMissing" class="error-message">{{ $t('Requested delivery date missing') }}</div>
        <div v-if="orderWarrantyChanged" class="error-message" :class="{ 'flash-message': orderWarrantyChanged }">{{ $t('Order warranty has been changed to five years!') }}</div>
        <div
            v-if="articlesWithFiveYearWarranty && showFlashMessage && articlesWithFiveYearWarranty.length > 0"
            :class="{ 'flash-message': showFlashMessage }"
            class="error-message"
        >
            {{ $t('Warranty for products') }}
            ( {{ articlesWithFiveYearWarranty.join(', ') }} )
            {{ $t('can\'t be higher than 5 years. Contact sales support to request increased warranty or lower warranty requirements.') }}
        </div>
        <hr class="sf-divider mt-3 mb-3" v-if="totalItems">
        <div
            v-if="totalItems"
            key="my-cart"
            class="my-cart"
        >
            <div class="collected-product-list">
                <table class="w-100">
                    <CartProducts
                        v-for="(product, index) in products"
                        :key="product.product.original_sku + index"
                        class="collected-product"
                        :product="product"
                        :title="cartGetters.getItemName(product)"
                        :price="cartGetters.getItemPrice(product).regular"
                        :qty="cartGetters.getItemQty(product)"
                        :configuration_options="cartGetters.getConfigurationOptions(product)"
                        @click:remove="sendToRemove({ product })"
                        :cart_options="cartOptions"
                        :selected_options="selectedOptions"
                        @updateItemOption="updateItemOption({ product, options: $event })"
                        @openCableModal="openCableModal(...arguments)"
                        @openDimmingModal="openDimmingModal(...arguments)"
                        @downloadDatasheet="downloadDatasheet(...arguments)"
                        :totalItems="totalItems"
                        :items="products"
                        :index="index"
                        :last="ifLastProduct(products, index)"
                        :warrantyList="warrantyList"
                        :articlesWithFiveYearWarranty="articlesWithFiveYearWarranty"
                    />
                </table>
            </div>
        </div>
        <hr class="sf-divider mt-3" v-if="totalItems">
        <div class="totals" v-if="totalItems">
            <div class="editable mt-5" v-if="hasPermissionToAddInfo">
                <SfTextarea
                    :label="$t('Additional info')"
                    name="additional_info"
                    :rows="2"
                    :cols="100"
                    wrap="soft"
                    :value="selectedOptions.additional_info"
                    @input="updateCartOption('additional_info', $event, true)"
                />
            </div>

            <div class="text-right">
                <h4>{{$t('Total') }}: {{ $fc(totals.total) }}</h4>
            </div>
            <hr class="sf-divider mb-5" v-if="totalItems">
            <div class="next-step">
                <Button
                    type="button"
                    class="button button-primary"
                    @click="checkValidation().then(submitForm(goToShipping))"
                >
                    {{ $t('Go to delivery') }}
                </Button>
            </div>
        </div>
        <CartProductCables
            v-if="cableModal"
            :article="cableArticle"
            :visible="cableModal"
            :order_no="orderNo"
            :parent_order_no="parentOrderNo"
            @close="cableModal = !cableModal"
        />
        <CartDimming
            v-if="dimmingModal"
            :visible="dimmingModal"
            :options="dimmingOptions"
            @close="dimmingModal = !dimmingModal"
            @update="updateDimming"
        />
        <DatasheetDownloadModal
            :visible="datasheetModal"
            :params="datasheetForm"
            @close="datasheetModal = !datasheetModal"
        />
        <SfModal
            v-if="!loading"
            :visible="visible"
            class="modal"
            @close="openRemoveItem(false)"
        >
            <div id="remove-modal" class="col-12 popup-gallery__head">
                <div class="container">
                    <div class="col-12" v-html="$t('Are you sure you would like to remove this item from the shopping cart?')" />
                    <div class="mt-3 button-wrap text-right">
                        <Button type="button" class="button button-primary medium" @click="actionRemoveItem(tempProduct)">
                            {{ $t('Yes') }}
                        </Button>
                    </div>
                </div>
            </div>
        </SfModal>
    </div>
</template>
<script>
import {
	SfInput,
	SfCheckbox,
	SfSelect,
	SfTextarea,
	SfModal,
	SfLoader,
	SfHeading,
} from '@storefront-ui/vue';
import {
	computed,
	defineComponent,
	useRouter,
	useContext,
	onMounted,
	reactive,
	watch,
	ref,
} from '@nuxtjs/composition-api';
import {
	useUserOrder,
	useCart,
	useUser,
	useUiNotification,
	useExternalCheckout,
} from '~/composables';
import CartProductCables from '~/components/Checkout/CartProductCables.vue';
import DatasheetDownloadModal from '~/components/Datasheet/DatasheetDownloadModal.vue';
import CartDimming from '~/components/Checkout/CartDimming.vue';
import CartProducts from '~/components/Checkout/CartProducts.vue';
import CartArticle from '~/components/Checkout/CartArticle.vue';
import Button from '~/components/Common/Button.vue';
import { useUiState } from '~/composables';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { cartGetters } from '~/getters';
import _debounce from 'lodash.debounce';
import moment from 'moment';
import VueDatepicker from '~/components/Common/Calendar/VueDatepicker.vue';

extend('required', {
	...required,
	message: 'This field is required',
});

export default defineComponent({
	name: 'Cart',
	components: {
		SfInput,
		SfCheckbox,
		SfSelect,
		SfTextarea,
		SfModal,
		SfLoader,
		SfHeading,
		Button,
		CartProducts,
		CartArticle,
		ValidationProvider,
		ValidationObserver,
		CartProductCables,
		CartDimming,
		DatasheetDownloadModal,
		VueDatepicker
	},
	methods: {
		checkValidation() {
			return this.$refs.form.validate();
		},
		submitForm(form) {
            if (!this.selectedOptions.requested_delivery_date) {
                // Display a warning or error message
                this.deliveryDateMissing = true;
                return; // Stop the form from submitting
            }

			return this.$refs.form.handleSubmit(form);
		},
		getTomorrow() {
			return moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
		},
	},
	setup(props, { emit }) {
		const { send: sendNotification, notifications } = useUiNotification();
		const { initializeCheckout } = useExternalCheckout();
		const { load: loadUser, user, hasPermission } = useUser();
		const { isRefreshCart } = useUiState();
		const { app } = useContext();
		const { cart, removeItem, updateItemOptions, loading, clear: clearCart } = useCart();
		const { getCartOptions, setCartOptions, getArticlesWithFiveYearWarranty, shortTermArticles } = useUserOrder();
		const router = useRouter();
		const apiState = app.$vsf.$magento.config.state;
		const userPermissions = apiState.getPermissions();
		const canAddBeeArticles = computed(() => userPermissions.find(el => el.code == 'add bee articles'));

		const visible = ref(false);
		const tempProduct = ref();
		const cartOptions = ref({});
		const cableModal = ref(false);
		const dimmingModal = ref(false);
		const dimmingOptions = ref({});
		const parentOrderNo = ref(10);
		const orderNo = ref(10.1);
		const cableArticle = ref(null);
        const deliveryDateMissing = ref(false);
		const datasheetForm = ref({});
		const datasheetModal = ref(false);
        const articlesWithFiveYearWarranty = computed(() => shortTermArticles.value);
        const showFlashMessage = ref(false);
        const fiveYearWarrantyId = 3;
        const orderWarrantyChanged = ref(false);

		const selectedOptions = reactive({
			cart_id: cart.value?.id,
			project: null,
			bee_customer_id: null,
			seller_id: null,
			category_id: null,
			warranty_id: null,
			customer_order_number: null,
			country_id: null,
			location: null,
			is_corrosive_environment: false,
			requested_delivery_date: null,
			our_reference_id: null,
			customer_reference_id: null,
			approved_at: null,
			approved_by: null,
			additional_info: null,
			dimming: null,
		});

		onMounted(async () => {
			await loadCartOptions();
            getArticlesWithFiveYearWarranty(productArticles.value);
		});

		watch(() => cart.value, async (newCart, oldCart) => {
			if (newCart && oldCart.id && oldCart.id != newCart.id) {
				await loadCartOptions();
			}
		});

		watch(() => isRefreshCart.value, async (newState, oldState) => {
			// fix for refresing after new article added
			if (!newState) {
				await loadCartOptions();
			}
		});

        watch(() => articlesWithFiveYearWarranty.value, async (newValue, oldValue) => {
            if (newValue.length > 0 && newValue.length > oldValue.length) {
                if (!oldValue.length && newValue.length - oldValue.length === 1) {
                    if (selectedOptions.warranty_id) {
                        // selectedOptions.warranty_id = fiveYearWarrantyId.toString();// Sets warranty id to 5 year warranty
                        updateCartOption('warranty_id', fiveYearWarrantyId.toString())
                        orderWarrantyChanged.value = true;
                        setTimeout(() => {
                            orderWarrantyChanged.value = false;
                        }, 9000);
                    }

                    showFlashMessage.value = true;
                    // Automatically hide the message after 7 seconds
                    setTimeout(() => {
                        showFlashMessage.value = false;
                    }, 9000);
                }
            }
		});

        const warrantyList = computed(() => {
            if (articlesWithFiveYearWarranty.value.length > 0) {
                return cartOptions.value.warranty.filter((warranty) => warranty.id == fiveYearWarrantyId);
            } else {
                return cartOptions.value.warranty;
            }
        })

        const productArticles = computed(() => {
			return products.value
                .filter(product =>
                    product.configuration_options.some(opt => opt.code === 'article')
                )
                .map(product =>
                    product.configuration_options.find(opt => opt.code === 'article').value
                );
        })

        const updateArticlesWithFiveYearWarranty = (productArticle) => {
            let allArticles = [];
            if (!productArticles.value.includes(productArticle)) {
                allArticles = [...productArticles.value, productArticle];
            } else {
                allArticles = productArticles.value;
            }

            getArticlesWithFiveYearWarranty(allArticles);
        };

		const loadCartOptions = async () => {
			const resultCartOptions = await getCartOptions(cart.value.id, true);
			cartOptions.value = resultCartOptions;

			if (cartOptions.value.hasOwnProperty('countries')) {
				cartOptions.value.countries.sort((a, b) =>
					a.full_name_locale.toString().localeCompare(b.full_name_locale, undefined, { numeric: false })
				);
			}

			if (cartOptions.value.hasOwnProperty('sellers')) {
				cartOptions.value.sellers.sort((a, b) =>
					a.name.toString().localeCompare(b.name, undefined, { numeric: false })
				);
			}

			if (cartOptions.value) {
				Object.entries(cartOptions.value).forEach(entry => {
					const [key, val] = entry;
					selectedOptions[key] = val;
				});
			}

			apiState.setSelectedCompany(selectedOptions['bee_customer_id']);
		};

		const products = computed(() => cartGetters
			.getItems(cart.value)
			.filter(Boolean)
			.map((item) => ({
				...item,
				product: {
					...item.product,
					...item.configured_variant,
					...item.configuration_options,
					original_sku: item.product.sku,
				},
			}))
			.sort(
				(a, b) => a.configuration_options.find(e => e.code == 'order_no').value.toString().localeCompare(
					b.configuration_options.find(e => e.code == 'order_no').value.toString(),
					undefined,
					{ numeric: true }
				)
			)
		);

		const delayedSetCartOptions = _debounce(
			(params) => setCartOptions(params),
			1000,
		);

		const openRemoveItem = ({ status }) => { visible.value = status; };

		const goToShipping = async () => {
			await loadUser();

			setCartOptions(selectedOptions);

			const form = ref({
				firstname: user.value.firstname,
				lastname: user.value.lastname,
				email: user.value.email,
			});

			await mergeItem('checkout', { 'user-account': form.value });

			const redirectUrl = await initializeCheckout({
				baseUrl: '/checkout/delivery',
			});

			await router.push(`${app.localePath(redirectUrl)}`);
		};

		const updateCartOption = (key, value, delay = false) => {
			let opts = selectedOptions;
			opts[key] = value;

			opts['approved_at'] = null;
			opts['approved_by'] = null;
			selectedOptions['approved_by'] = null;

			if (delay) {
				delayedSetCartOptions(opts);
			} else {
				setCartOptions(opts);
			}

            if (key === 'requested_delivery_date') {
                deliveryDateMissing.value = false;
            }
		};

		const sendToRemove = ({ product }) => {
			if (notifications.value.length > 0) {
				notifications.value[0].dismiss();
			}

			visible.value = true;
			tempProduct.value = product;
		};

		const actionRemoveItem = async (product) => {
			visible.value = false;
			await removeItem({ product }).then(() => {
                getArticlesWithFiveYearWarranty(productArticles.value);
            });
		};

		const updateItemOptionDelayed = _debounce(
			(params) => updateItemOptions(params),
			1000,
		);

		const updateItemOption = (params) => {
			const delay = params['options']['delay'];
			delete params['options']['delay'];

			if (delay) {
				updateItemOptionDelayed(params);
			} else {
				updateItemOptions(params);
			}
		};

		const openCableModal = (num, article) => {
			const cables = products.value.filter(
				product => product.configuration_options.filter(opt => opt.code == 'parent_order_no' && opt.value == num.toString()).length
			);

			let count = 0.1;

			if (cables.length) {
				count *= (cables.length + 1);
			}

			orderNo.value = parseInt(num) + count;
			parentOrderNo.value = num;
			cableModal.value = !cableModal.value;
			cableArticle.value = article;
		};

		const openDimmingModal = (product, dimming) => {
			dimmingOptions.value = '';

			if (dimming.length) {
				dimming = JSON.parse(dimming);

				dimmingOptions.value = dimming;
			}

			tempProduct.value = product;
			dimmingModal.value = !dimmingModal.value;
		};

		const ifLastProduct = (items, index) => {
			if (items[index + 1] === undefined) {
				return true;
			}

			const remainingProducts = items.slice(index + 1).filter(
				product => product.configuration_options.filter(opt => opt.code == 'row_type' && opt.value == 'product').length
			);

			return remainingProducts.length ? false : true;
		};

		const stopSubmit = () => {
			return false;
		};

		const updateDimming = (data, delay = false) => {
			let params = {};

			if (tempProduct.value) {
				params['options'] = { dimming: data ? JSON.stringify(data) : '' };
				params['product'] = tempProduct.value;

				if (delay) {
					updateItemOptionDelayed(params);
				} else {
					updateItemOptions(params);
				}
			} else {
				updateCartOption('dimming', data ? JSON.stringify(data) : '', delay);
			}
		};

		const downloadDatasheet = (article, model_name, product_reference) => {
			datasheetForm.value = {
				article: article,
				model_name: model_name,
				product_reference: product_reference,
				project: selectedOptions.project
			}

			datasheetModal.value = !datasheetModal.value;
		}

		return {
			totals: computed(() => cartGetters.getTotals(cart.value)),
			totalItems: computed(() => cartGetters.getTotalItems(cart.value)),
			hasPermissionToAddInfo: computed(() => { return hasPermission('add additional info') }),
			dateFormat: computed(() => apiState.getLocale() == 'us' ? 'MM-DD-YYYY' : 'DD-MM-YYYY'),
			openRemoveItem,
			sendToRemove,
			actionRemoveItem,
			loading,
			products,
			removeItem,
			visible,
			tempProduct,
			goToShipping,
			cartGetters,
			clearCart,
			cartOptions,
			selectedOptions,
			updateItemOption,
			canAddBeeArticles,
			cableModal,
			dimmingModal,
			parentOrderNo,
			openCableModal,
			openDimmingModal,
			ifLastProduct,
			cableArticle,
			updateCartOption,
			stopSubmit,
			orderNo,
			dimmingOptions,
			updateDimming,
			downloadDatasheet,
			datasheetForm,
			datasheetModal,
            deliveryDateMissing,
            articlesWithFiveYearWarranty,
            productArticles,
            getArticlesWithFiveYearWarranty,
            updateArticlesWithFiveYearWarranty,
            warrantyList,
            shortTermArticles,
            showFlashMessage,
            orderWarrantyChanged
		};
	},
});
</script>

<style lang="scss" scoped>
#cart {
    --sidebar-z-index: 3;
    --overlay-z-index: 3;
    min-height: 50vh;
    display: flex;
    flex-direction: column;

    & > form {
        margin: 50px 0;
    }
}

.cart-summary {
    margin-top: var(--spacer-xl);
}

.my-cart {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__total-items {
        margin: 0;
    }

    &__total-price {
        --price-font-size: var(--font-size--xl);
        --price-font-weight: var(--font-weight--medium);
        margin: 0 0 var(--spacer-base) 0;
    }
}

.empty-cart {
    --heading-description-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-color: var(--c-primary);
    --heading-title-font-weight: var(--font-weight--semibold);
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    height: 100%;

    &__heading {
        padding: 0 var(--spacer-base);
    }

    &__image {
        --image-width: 16rem;
        margin: 0 0 var(--spacer-2xl) 7.5rem;
    }

    @include for-desktop {
        --heading-title-font-size: var(--font-size--xl);
        --heading-title-margin: 0 0 var(--spacer-sm) 0;
    }
}

.collected-product-list {
    flex: 1;

    table {
        border-collapse: collapse;
    }
}

.totals {
    justify-content: end;

    .sf-textarea {
        textarea {
            max-width: 1080px;
        }
    }
}

.next-step {
    text-align: right;
}

.sf-loader {
    min-height: 250px;
}

.editable {
    display: flex;
    align-items: flex-start;

    .svg-image {
        margin-left: 5px;
        cursor: pointer;
    }

    span {
        margin-bottom: 15px;
        width: 75%;
    }
}

.sf-textarea {
    width: 100%;

    textarea {
        font-size: 14px;
        width: 100%;
        max-width: 850px;

        &:read-only {
            border: 1px solid #dcdfe6;
        }
    }
}
.error-message {
    color: var(--_c-red-primary);
}

.flash-message {
    animation: flash 0.5s ease-in-out;
}

@keyframes flash {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

</style>
