<template>
    <div>
        <h2 class="ml-3">{{ title }}</h2>
        <ul>
            <li v-for="file in files" :key="file.name">
            <p class="cursor-pointer" @click="loadFilesForDownload(file.name)">
                {{  file.name.replace('_', ' ') }}
            </p>
            </li>
        </ul>
    </div>
</template>

<script>
    import { useVizulo } from '@/composables';
    import axios from 'axios';

    export default {
    name: 'DownloadFilesModal',
    props: {
        title: {
            type: String,
            default: 'Download datasheets and LDT files'
        },
        files: {
            type: Array,
            default: () => []
        }
    },
    setup() {
        const { getApiUrl } = useVizulo();
        const apiUrl = getApiUrl();

        const loadFilesForDownload = async (fileName) => {
            axios({
				url: apiUrl + 'downloadSpecFile/' + fileName,
				method: 'GET',
                responseType: 'blob', // Important for handling file download
            }).then(response => {
                // Create a blob from the response data
                const blob = new Blob([response.data]);

                // Use the FileReader API to read the blob as a data URL
                const reader = new FileReader();
                reader.onloadend = function() {
                    const link = document.createElement('a');
                    link.style.display = 'none'; // Hide the link
                    link.href = reader.result;
                    link.setAttribute('download', fileName);

                    // Append the link to the body
                    document.body.appendChild(link);

                    // Programmatically click the link to trigger the download
                    link.click();

                    // Remove the link from the document
                    document.body.removeChild(link);
                };

                reader.readAsDataURL(blob); // Read the blob as a data URL
            }).catch(error => {
                console.error('Error downloading the file:', error);
            });
        }

        return {
            loadFilesForDownload,
            apiUrl
        };
    },
    };
</script>

<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }
</style>
