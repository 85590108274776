<template>
    <div class="checkout-steps container">
        <div class="checkout-steps__header">
            <div class="checkout-steps__step company">
                <div class="d-flex">
                    <v-select
                        :value="companies.length ? selectedCompany : ''"
                        :clearable="false"
                        :searchable="false"
                        key="id"
                        label="name"
                        :placeholder="$t('Select company')"
                        :options="companies"
                        :reduce="company => company.id"
                        @input="selectCompany({id: $event})"
                        class="mr-2"
                        :disabled="companies.length == 1 && carts.filter(c => c.bee_customer_id == selectedCompany).length == 1"
                    />
                    <v-select
                        v-if="selectedCompany && projects.length"
                        :value="projects.length ? selectedProject: ''"
                        :clearable="false"
                        :searchable="false"
                        key="project"
                        label="project"
                        :placeholder="$t('Project')"
                        :options="projects"
                        :reduce="company => company.project"
                        @input="selectProject({project: $event})"
                    />
                    <svg-image
                        v-if="selectedCompany && selectedProject && selectedProject.length"
                        class="trash pointer ml-2"
                        height="18"
                        icon="trash"
                        width="18"
                        @click.native="removeProject"
                        :title="$t('Remove')"
                    />
                    <svg-image
                        v-if="selectedCompany"
                        class="plus pointer ml-2"
                        height="19"
                        icon="plus"
                        width="19"
                        @click.native="addProject"
                        :title="$t('Add new project')"
                    />
                </div>
            </div>
            <slot
                v-for="step in parsedSteps"
                name="steps"
                :step-click="stepClick"
                v-bind="{ step }"
            >
                <SfButton
                    :key="step.index"
                    class="sf-button--pure"
                    :class="{
                        'checkout-steps__step': true,
                        'is-done': step.done,
                        current: step.current,
                        'is-disabled': step.disabled,
                    }"
                    data-testid="steps-button"
                    @click="stepClick(step)"
                >
                    <span class="checkout-steps__title">{{ step.step }}</span>
                </SfButton>
            </slot>
        </div>
        <div class="checkout-steps__content">
            <slot />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import SfStep from './SfStep.vue';
import { SfButton, SfSelect, SfInput } from '@storefront-ui/vue';

Vue.component('SfStep', SfStep);

export default {
	name: 'SfSteps',
	components: {
		SfButton,
        SfSelect,
        SfInput,
	},
	model: {
		prop: 'active',
		event: 'change',
	},
	provide() {
		const stepsData = {};
		Object.defineProperty(stepsData, 'index', {
			enumerable: false,
			get: () => this.active,
		});
		Object.defineProperty(stepsData, 'name', {
			enumerable: false,
			get: () => this.steps[this.active],
		});
		Object.defineProperty(stepsData, 'updateSteps', {
			enumerable: false,
			value: this.updateSteps,
		});
		return {
			stepsData,
		};
	},
	props: {
        companies: {
            type: Array,
            default: () => [],
        },
        carts: {
            type: Array,
            default: () => [],
        },
        selectedCompany: {
            type: String,
            default: null
        },
        selectedProject: {
            type: String,
            default: null
        },
		active: {
			type: Number,
			default: 0,
		},
		canGoBack: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			steps: [],
		};
	},
	computed: {
		parsedSteps() {
			if (this.steps) {
				return this.steps.map((step, index) => ({
					index,
					step,
					done: index < this.active,
					disabled: !this.canGoBack && index < this.active,
					current: index === this.active,
				}));
			}
			return [];
		},
        projects() {
            return this.carts.filter(c => c.bee_customer_id == this.selectedCompany);
        }
	},
	methods: {
		updateSteps(step) {
			if (this.steps.includes(step)) return;
			this.steps.push(step);
		},
		stepClick({ index, disabled }) {
			if (!disabled) {
				this.$emit('change', index);
			}
		},
        selectCompany({ id }) {
            this.$emit('selectCompany', id);

            if (this.active > 0) {
                this.$emit('change', 0);
            }
        },
        selectProject({ project }) {
            this.$emit('selectProject', project);

            if (this.active > 0) {
                this.$emit('change', 0);
            }
        },
        removeProject() {
            this.$emit('removeProject');
        },
        addProject() {
            this.$emit('addProject');
        }
	},
};
</script>
<style lang="scss" scoped>
.checkout-steps {
    &__header {
        position: sticky;
        top: 0;
        z-index: 10;
        overflow-x: scroll;

        @media (min-width: 576px) {
            overflow-x: unset;
        }

        @media (min-width: 992px) {
            top: 0;
            height: 63px;
        }

        width: 100%;
        background-color: var(--c-white);
        border-bottom: var(--steps-border, var(--steps-border-style, solid) var(--steps-border-color, var(--c-light)));
        display: flex;
        justify-content: flex-end;

        .v-select {
            margin-top: 15px;
            width: 290px;
            border: none;
            font-size: 14px;
            height: 26px;
        }

        .sf-select {
            height: var(--select-height, 3rem);
        }

        ::v-deep .vs--disabled .vs__clear,
        ::v-deep .vs--disabled .vs__dropdown-toggle,
        ::v-deep .vs--disabled .vs__open-indicator,
        ::v-deep .vs--disabled .vs__search,
        ::v-deep .vs--disabled .vs__selected {
            background: transparent;

            .vs__actions {
                display: none;
            }
        }

        .sf-button {
            margin: 5px 0 5px 15px;
            color: var(--c-light);
            font-size: 14px;
            line-height: 14px;
            border-radius: 18px;
            padding: 0 16px;
            height: 50px;

            &.current {
                color: var(--c-dark);
            }
        }

        .svg-image {
            margin-top: 20px;
        }
    }

    .company {
        margin-right: auto;
    }
}
</style>
