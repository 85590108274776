<template>
    <div v-if="items && items.length">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <strong v-if="bee_customer_id == '77'" class="description">This is an internal VIZULO price list! No discount has been applied to prices listed below.</strong>
                    <HTMLContent
                        class="description"
                        :class="{ more: showMoreAccessories == false }"
                        :content="description"
                    />
                    <button
                        v-if="showMoreAccessories"
                        class="show-more button button-secondary"
                        @click="showMore">
                            {{ $t('Show more') }}
                    </button>
                </div>
            </div>
            <div class="col-12">
                <h3 class="border-top pt-6">{{ $t('Search for typical options') }}</h3>
                <button
                    class="reset active"
                    @click="resetFilters"
                    v-html="$t('Reset')"
                />
            </div>
            <div class="col-12">
                <masonry-wall
                    :items="configurableOptions"
                    :column-width="config.columnWidth"
                    :gap="config.gap"
                    :rtl="config.rtl"
                    :ssr-columns="config.ssrColumns"
                >
                    <template #default="{ item, index }">
                        <div class="configurable-option">
                            <div>{{ item.label }} <span v-if="item.abbreviation">({{ item.abbreviation }})</span>
                                <div
                                    v-if="item.description"
                                    class="table-tooltip flex"
                                    :class="{ active: item.attribute_code == selectedTooltip }"
                                    @click="showTooltip(item.attribute_code)"
                                >
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <div class="tooltiptext"><span v-html="item.description"></span></div>
                                </div>
                                <nuxt-link class="pt-2" v-if="item.label == 'Optic'" :to="localePath('/products/optics')">{{ $t('Explore all optics') }}</nuxt-link>
                            </div>
                            <template v-if="item.label == 'Optic' || item.label == 'Module type'">
                                <div v-bar class="image-filter filter-options" :data-code="item.attribute_code">
                                    <div>
                                        <div
                                            @click="changeFilter(item, attribute)"
                                            class="option"
                                            :class="{ active: attribute.isActive }"
                                            :key="attribute.uid"
                                            v-if="attribute.url"
                                            v-for="attribute in item.values"
                                        >
                                            <span class="check"></span>
                                            <span>{{ attribute.label }}</span>
                                            <span class="description" v-if="attribute.description">{{ attribute.description }}</span>
                                            <nuxt-img
                                                v-if="attribute.url != null"
                                                v-on:click.stop
                                                loading="lazy"
                                                :src="attribute.url"
                                                quality="100"
                                                width="75"
                                                height="42"
                                                @click="openImageModal(attribute.url)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="item.attribute_code.includes('Price') || isRangeSlider.includes(item.attribute_code)">
                                <MultiRangeSlider
                                    v-if="item.min && item.max"
                                    :code="item"
                                    :min="item.min"
                                    :max="item.max"
                                    :step="item.step"
                                    :ruler="true"
                                    :label="true"
                                    :minValue="item.min"
                                    :maxValue="item.max"
                                    @input="updateValues"
                                />
                            </template>
                            <template v-else-if="item.attribute_code == 'Driver'">
                                <SfSelect
                                    name="driver"
                                    :placeholder="$t('Select...')"
                                    class="form__element form__element--half form__element--half-even form__select sf-select--underlined"
                                    @input="changeDriver({ driver: $event })"
                                >
                                    <SfSelectOption
                                        :key="0"
                                        :value="0"
                                        v-html="$t('All')"
                                    />
                                    <SfSelectOption
                                        v-for="attribute in item.values"
                                        :key="attribute.uid"
                                        :value="attribute.label"
                                        v-html="attribute.label"
                                    />
                                </SfSelect>
                            </template>
                            <template v-else>
                                <ul class="filter-options" :data-code="item.attribute_code">
                                    <li
                                    class="option"
                                    @click="changeFilter(item, attribute)"
                                    :class="{ active: attribute.isActive }"
                                    :key="attribute.uid"
                                    v-if="attribute.label.length"
                                    v-for="attribute in item.values">
                                        <span>{{ attribute.label }}</span>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </template>
                </masonry-wall>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 table-container">
                    <vue-good-table
                        ref="priceList"
                        :columns="columnsData"
                        :rows="items"
                        compactMode
                        :pagination-options="config.paginationOptions"
                        :search-options="config.searchOptions"
                        :sort-options="config.sortOptions"
                        @on-cell-click="onCellClick"
                    >
                        <template slot="table-column" slot-scope="props">
                            <span>
                                <svg-image
                                    class="arrow"
                                    height="14"
                                    icon="arrow-right-short"
                                    width="14"
                                />
                                <p class="label">{{ props.column.label }}</p>
                                <strong v-if="props.column.abbreviation">{{ props.column.abbreviation }}</strong>
                            </span>
                        </template>

                        <template slot="table-row" slot-scope="props">
                            <span
                                v-if="props.column.html"
                                v-html="props.formattedRow[props.column.field]"
                            >
                            </span>
                            <span
                                v-else-if="props.formattedRow[props.column.field] == 'None'"
                                v-html="'<i>' + props.formattedRow[props.column.field] + '</i>'"
                            >
                            </span>
                            <span v-else v-html="props.formattedRow[props.column.field]" />
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 info-container">
                    <ul>
                        <li v-if="option.description" v-for="option in configurableOptions">
                            <p>{{ option.label }}</p>
                            <HTMLContent :content="option.description" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <DatasheetDownloadModal
            :visible="datasheetModal"
            :params="datasheetForm"
            @close="datasheetModal = !datasheetModal"
        />
        <AddToCartModal
            :key="addToCartForm.model_name"
            :visible="addToCartModal"
            :options="addToCartForm"
            @close="openAddToCartModal(0)"
        />
        <SfModal
            :visible="imageModal"
            class="modal"
            @close="openImageModal(0)"
        >
            <div id="image-modal" class="col-12 popup-gallery__head">
                <div class="container" v-html="imageModalImage" />
            </div>
        </SfModal>
    </div>
</template>

<script>
import { defineComponent, useContext, reactive, ref, useAsync, computed } from '@nuxtjs/composition-api';
import MultiRangeSlider from '~/components/Common/MultiRangeSlider.vue';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import { useVizulo, useCart } from '~/composables';
import Button from '~/components/Common/Button.vue';
import { SfInput, SfModal, SfSelect } from '@storefront-ui/vue';
import { ValidationObserver } from 'vee-validate';
import HTMLContent from '~/components/HTMLContent';
import DatasheetDownloadModal from '~/components/Datasheet/DatasheetDownloadModal';
import AddToCartModal from '~/components/Common/AddToCartModal';
import debounce from 'lodash.debounce';

export default defineComponent({
	name: 'PriceListTable',
	components: {
		MultiRangeSlider,
		VueGoodTable,
		Button,
		SfInput,
		SfSelect,
		SfModal,
		ValidationObserver,
		HTMLContent,
		DatasheetDownloadModal,
		AddToCartModal
	},
	props: {
		options: Object,
		description: {
			type: String,
			default: ''
		},
		columns: {
            type: Array,
            default: () => [],
        },
		items: {
            type: Array,
            default: () => [],
        },
		price_list_file_id: Number,
		bee_customer_id: String
	},
	methods: {
		resetFilters() {
			this.columnsData.forEach((option) => {
				option.activeFilters = [];
				option.values = option.values.map(v => ({ ...v, isActive: false }));
				option.filterOptions.filterValue = '';
			});

			this.$refs.priceList.reset();
			this.activeFilter = false;
		},
		updateValues: debounce(function (e) {
			let found = this.columnsData.find(c => {
				return c.attribute_code == e.code.attribute_code;
			});

			let values = e.code.values.map(item => item.label).filter(function (value) {
				return value >= e.minValue && value <= e.maxValue
			});

			const min = Math.min(...values);
			const max = Math.max(...values);

			if (min == found.min && max == found.max) {
				found.filterOptions.filterValue = [];
			} else {
				found.filterOptions.filterValue = [min, max];
			}

			this.$refs.priceList.$refs['table-header-primary'].$refs['filter-row'].updateFilters(found, found.filterOptions.filterValue);
		}, 500),
		changeDriver(obj) {
			let found = this.columnsData.find(c => {
				return c.attribute_code == 'Driver';
			});

			found.filterOptions.filterValue = obj.driver == '0' ? '' : obj.driver;

			this.$refs.priceList.$refs['table-header-primary'].$refs['filter-row'].updateFilters(found, found.filterOptions.filterValue);
		},
		changeFilter(option, attribute) {
			this.columnsData.forEach((opt) => {
				opt.values = opt.values.map(v => ({
					...v,
					isActive: (v.uid == attribute.uid) ? !v.isActive : v.isActive
				}))
			});

			let found = this.columnsData.find(c => {
				return c.attribute_code == option.attribute_code;
			});

			found.filterOptions.activeFilters.push(attribute.label);

			if (attribute.isActive) {
				const index = found.filterOptions.activeFilters.indexOf(attribute.label);

				if (index !== -1) {
					found.filterOptions.activeFilters = found.filterOptions.activeFilters.filter(e => e != attribute.label);
				}
			}

			found.filterOptions.filterValue = found.filterOptions.activeFilters;

			this.$refs.priceList.$refs['table-header-primary'].$refs['filter-row'].updateFiltersOnKeyup(found, found.filterOptions.activeFilters);
		},
		onCellClick(params) {
			if (params.column.field == '0') {
				this.reservePriceList(params);
			}

			if (params.column.field == '1') {
				const clone = Object.assign({}, params.row);
				delete clone[0];
				delete clone[1];
				delete clone[2];
				delete clone['a'];
				delete clone['originalIndex'];
				delete clone['vgt_id'];

				if (this.canAddToCart) {
					delete clone['zzz'];
				}

				let str = '';
				for (const [p, val] of Object.entries(clone)) {
					let found = this.columnsData.find(c => {
						return c.field == p;
					});

					if (typeof found !== 'undefined' && val) {
						str += `${found.label}: ${val}; `;
					}
				}

				this.copyText(str);
				params.row[1] = '<span class="btn copy">copied</span>';
			}

			if (params.column.attribute_code == 'datasheet') {
				let article = params.row.c.replace('-', '');
				article = article.replace('None', '');

				this.datasheetForm.price_list_file_id = this.price_list_file_id;
				this.datasheetForm.price_list_id = params.row.a;
				this.datasheetForm.article = article;
				this.datasheetForm.model_name = params.row.b;
				this.datasheetModal = true;
			}

			if (params.column.attribute_code == 'ldt_file' || params.column.attribute_code == 'ies_file') {
			    this.downloadLdtFile(params, params.column.attribute_code.replace('_file', ''));
			}

			if (params.column.field == 'Optic') {
				this.imageModalImage = params.row.optic;
				this.openImageModal(0);
			}

			if (params.column.attribute_code == 'cart') {
				const priceColumns = this.columnsData.filter(c => {
					return c.attribute_code.includes('Price');
				});

				this.addToCartForm.price = [];

				if (priceColumns.length) {
					priceColumns.forEach((priceColumn) => {
						this.addToCartForm.price.push({
							price: params.row[priceColumn.field],
							quantity: priceColumn.attribute_code.split('Price')[1],
						});
					});
				}

				this.addToCartForm.article = params.row.c.replace('-', '');
				this.addToCartForm.model_name = params.row.b;

				this.openAddToCartModal();
			}
		},
		reservePriceList(params) {
			const form = {
				customer_id: this.options.customer.bee_customer_id,
				price_list_file_id: this.options.file.alternative_price_list_file_id ?? this.options.file.price_list_file_id,
				price_list_id: params.row.a,
				price_list_name: this.options.file.alternative_price_list_file_name ?? this.options.file.name,
				price: params.row.d
			};

			const apiUrl = this.apiUrl;

			axios({
				url: apiUrl + 'priceList/reservePriceList',
				method: 'POST',
				data: form,
				headers: {
					'Content-Type': 'application/json',
				},
				responseType: 'text',
			}).then((res) => {
				params.event.srcElement.title = 'reserved';
				params.event.srcElement.textContent = 'reserved';
				params.event.srcElement.classList = 'btn reserved';
			});
		},
		downloadLdtFile(params, type) {
			const data = {
				type: type,
				source: 'P',
				price_list_file_id: this.price_list_file_id,
				price_list_id: params.row.a,
				model_name: params.row.b,
				article: params.row.c.replace('-', ''),
				power_step: '1',
				offset: '0',
			};

			axios({
				url: this.apiUrl + 'getPhotometricFile',
				method: 'POST',
				data: data,
				headers: {
					'Content-Type': 'application/json',
				},
				responseType: 'arraybuffer',
			}).then((res) => {
				if (res.status == 200 && res.data.byteLength) {
					let blob = new Blob([res.data], { type: res.headers['content-type'] });
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);

					const reader = new FileReader();

					reader.onload = (e) => {
						if (reader.result.length > 0) {
							let text = reader.result.split('\r\n');

							if (type == 'ies') {
								link.download = text[5].replace('[FILENAME] ', '') + '.' + type;
							} else {
								link.download = text[10] + '.' + type;
							}

							link.click();
						}
					};

					reader.readAsText(blob);
				} else {
					params.event.srcElement.title = 'Not found...';
					params.event.srcElement.classList.add('loading');
				}
			});
		},
	},
	setup(props, { refs }) {
		const { app } = useContext();
		const { getApiUrl } = useVizulo();
		const { loading: loadingCart, addItem, canAddToCart } = useCart();

		const activeFilter = ref(true);
		const selectedTooltip = ref(false);
		const priceList = reactive({});
		const datasheetModal = ref(false);
		const datasheetForm = ref({});
		const addToCartModal = ref(false);
		const imageModal = ref(false);
		const addToCartForm = ref({});
		const apiUrl = getApiUrl();
		const imageModalImage = ref('');
		const configurableOptions = ref([]);
		const showMoreAccessories = ref(false);

		const isRangeSlider = ['Power', 'Flux', 'Efficacy'];

		if (props.description.includes('table')) {
			showMoreAccessories.value = props.description.match(/tr/g).length > 12 ? true : false;
		}

		const columnsData = ref([{
			label: '',
			abbreviation: '',
			attribute_code: 'reserved',
			filterOptions: {
				activeFilters: []
			},
			field: '0',
			sortable: false,
			html: true,
			values: []
		},
		{
			label: '',
			abbreviation: '',
			attribute_code: 'copy',
			filterOptions: {
				activeFilters: []
			},
			field: '1',
			sortable: false,
			html: true,
			values: []
		},
		{
			label: app.i18n.t('Datasheet'),
			abbreviation: '',
			attribute_code: 'datasheet',
			filterOptions: {
				activeFilters: []
			},
			field: '2',
			sortable: false,
			html: true,
			tdClass: 'text-center',
			values: []
		},
		{
			label: app.i18n.t('LDT file'),
			abbreviation: '',
			attribute_code: 'ldt_file',
			filterOptions: {
				activeFilters: []
			},
			field: '3',
			sortable: false,
			html: true,
			tdClass: 'text-center',
			values: []
		},
		{
		    label: app.i18n.t('IES file'),
		    abbreviation: '',
		    attribute_code: 'ies_file',
		    filterOptions: {
		        activeFilters: []
		    },
		    field: '4',
		    sortable: false,
		    html: true,
		    tdClass: 'text-center',
		    values: []
		}
		]);

		if (canAddToCart.value) {
			columnsData.value.push({
				label: app.i18n.t('Basket'),
				abbreviation: '',
				attribute_code: 'cart',
				filterOptions: {
					activeFilters: []
				},
				field: 'zzz',
				sortable: false,
				html: true,
				tdClass: 'text-center',
				values: []
			});
		}

		let collator = new Intl.Collator([], { numeric: true });

		for (let key in props.columns) {
			if (props.columns[key]) {
				let tmpCol = {
					...props.columns[key]
				};

				const attribute_code = tmpCol.attribute_code;
				let dropdownItems = tmpCol.values;
				dropdownItems = dropdownItems.sort((a, b) => collator.compare(a.label, b.label));

				if (isRangeSlider.includes(attribute_code)) {
					dropdownItems = tmpCol.values
						.map(item => item.label)
						.sort(function (a, b) {
							return a - b;
						});

					tmpCol.type = 'number';
				}

				tmpCol.filterOptions = {
					enabled: true,
					customFilter: true,
					filterValue: '',
					activeFilters: [],
					filterMultiselectDropdownItems: dropdownItems,
					filterFn: function (rowVal, filter) {
						if (attribute_code.includes('Price') || isRangeSlider.includes(attribute_code)) {
							return rowVal >= filter[0] && rowVal <= filter[1];
						}

						if (Array.isArray(filter) && filter.length) {
							let ifIncludes = false;

							filter.forEach(i => {
								if (rowVal == i) ifIncludes = true;
							});

							return ifIncludes;
						} else if (Array.isArray(filter)) {
							return true;
						} else {
							return rowVal === filter;
						}
					},
				}

				columnsData.value.push(tmpCol);

				if (tmpCol.values.length > 1) {
					configurableOptions.value.push(tmpCol);
				}
			}
		}

		const showMore = () => {
			showMoreAccessories.value = false;
		}

		const showTooltip = (attribute_code) => {
			selectedTooltip.value = selectedTooltip.value == attribute_code ? false : attribute_code;
		}

		const openAddToCartModal = () => {
			addToCartModal.value = !addToCartModal.value;
		}

		const openImageModal = (url) => {
			if (url) {
				imageModalImage.value = '<img alt="image" src="' + url + '">';
			}

			imageModal.value = !imageModal.value;
		}

		const copyText = async (textToCopy) => {
			try {
				await navigator.clipboard.writeText(textToCopy);
			} catch($e) {
				console.error($e);
			}
		}

		const config = ref({
			columnWidth: 350,
			gap: 15,
			rtl: false,
			ssrColumns: 1,
			paginationOptions: {
				enabled: true,
				mode: 'pages',
				perPage: 20,
				position: 'bottom',
				perPageDropdown: [20, 40, 60],
				dropdownAllowAll: false,
				firstLabel: app.i18n.t('First Page'),
				lastLabel: app.i18n.t('Last Page'),
				nextLabel: app.i18n.t('next'),
				prevLabel: app.i18n.t('prev'),
				rowsPerPageLabel: app.i18n.t('Rows per page'),
				ofLabel: app.i18n.t('of'),
				pageLabel: app.i18n.t('page'),
				allLabel: app.i18n.t('All'),
			},
			searchOptions: {
				enabled: true,
				placeholder: app.i18n.t('Search...'),
				skipDiacritics: true,
				searchFn: (row, col, cellValue, search) => {
					if (cellValue && cellValue.length > 2) {
						if (search.substring(0, 1) === '>') {
							let el = search.split('>');

							if (cellValue !== ' ') {
								return Number(cellValue) > el[1];
							}
						}

						if (search.substring(0, 1) === '<') {
							let el = search.split('<');

							if (cellValue !== ' ') {
								return Number(cellValue) < el[1];
							}
						}

						if (search.substring(0, 1) === '=') {
							let el = search.split('=');

							return cellValue.toString().toUpperCase() === el[1].toString().toUpperCase();
						}
                        
						let split = search.toString().toUpperCase().split(' ').filter(Boolean);

						if (split.length > 1) {
							let el = Object.values(row).filter(Boolean);

							return split.every(
								i => el.some(str => str.toString().toUpperCase().includes(i))
							);
						} else {
							return cellValue.toString().toUpperCase().includes(search.toString().toUpperCase().trim());
						}
					}

					return false;
				}
			},
			sortOptions: {
				enabled: true,
				multipleColumns: false,
			}
		});

		return {
			config,
			canAddToCart,
			showTooltip,
			openAddToCartModal,
			openImageModal,
			apiUrl,
			priceList,
			columnsData,
			configurableOptions,
			activeFilter,
			selectedTooltip,
			datasheetModal,
			addToCartModal,
			datasheetForm,
			addToCartForm,
			imageModalImage,
			imageModal,
			showMoreAccessories,
			showMore,
			copyText,
			isRangeSlider
		}
	}
})
</script>

<style lang="scss" scoped>
.reset {
    float: right;
    border: none;
    background: none;
    display: none;
    position: absolute;
    right: 0;
    top: 72px;

    &.active {
        display: block;
    }
}

::v-deep .vgt-input {
    @media (min-width:991px) {
        width: 480px;
        max-width: 480px;
    }
}

::v-deep .vgt-wrap {
    width: 100%;
    padding: 0 15px;
}

::v-deep .vgt-responsive {
    @media (max-width:991px) {
        padding: 0 15px;
    }
}

.description {
    white-space: pre-line;
    padding: 0 15px;
    font-size: 14px;

    ::v-deep table {
        border: none;
        width: 100% !important;
        border-collapse: separate;
        border-spacing: 0;

        thead {
            tr {
                th {
                    background: none;
                    border: none;
                    padding: 8px;
                    vertical-align: top;
                    text-align: left;
                }
            }
        }

        tbody {
            tr {
                &:first-child {
                    td {
                        border-top: 1px solid #EAEAEA;
                    }
                }

                td {
                    padding: 8px;
                    border-bottom: 1px solid #EAEAEA;
                }

                &:nth-child(4) {
                    position: relative;
                    &:after {
                        position: absolute;
                        content: "";
                        left: 0px;
                        top: 0px;
                        height: 100%;
                        width: 100%;
                        background: linear-gradient(transparent, white);
                        pointer-events: none;
                    }
                }

                &:nth-child(n + 5) {
                    display: none;
                }
            }
        }
    }

    &.more {
        ::v-deep table {
            tbody {
                tr {
                    display: table-row;

                    &:nth-child(4) {
                        &:after {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}

::v-deep table.vgt-table {
    border: none;
    width: 100% !important;
    border-collapse: separate;
    border-spacing: 0;

    thead {
        th {
            background: none;
            border: none;
            padding: 15px 15px 20px 0;
            vertical-align: top;

            span {
                float: left;
                font-size: 12px;
                line-height: 12px;
                font-family: var(--font-family--primary-bold);
                position: relative;

                p {
                    height: 24px;
                }

                .arrow {
                    margin-left: -15px;
                    padding-left: 0;
                }

                strong {
                    position: absolute;
                    left: 0;
                    bottom: -16px;
                    color: #737373;
                    font-family: var(--font-family--primary);
                    font-weight: normal;
                    padding-left: 15px;
                }
            }

            &.sortable {
                span {
                    span {
                        left: 14px;
                        bottom: -15px;
                        position: absolute;
                        transform: rotate(90deg);
                    }
                }

                &.sorting-desc {
                    span {
                        span {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            &:not(.sortable) {
                span {
                    span {
                        display: none;
                    }
                }
            }

            &.sortable::before,
            &.sortable::after {
                display: none;
            }

            .svg-image {
                float: none;
                fill: #e1e1e1;
            }
        }

        tr:nth-child(2) {
            display: none;
        }

        @media (max-width:991px) {
            display: none;
        }
    }

    tbody {
        tr {
            &:first-child {
                td {
                    border-top: 1px solid #EAEAEA;

                    @media (max-width:991px) {
                        border-top: none;
                    }
                }
            }

            @media (max-width:991px) {
                border: 1px solid var(--c-black);
                padding: 8px;
            }

            td {
                @media (max-width:991px) {
                    display: block;
                    padding: 8px 0;

                    &:before {
                        padding-left: 0;
                    }

                    &:last-child {
                        @media (max-width:991px) {
                            border-bottom: none;
                        }
                    }
                }

                img {
                    cursor: pointer;

                    @media (max-width:991px) {
                        display: block;
                        margin: 35px auto 15px auto;
                        max-width: 350px;
                    }
                }

                i {
                    color: var(--c-light-gray);
                }
            }
        }
    }

    td {
        border:none;
        border-bottom: 1px solid #EAEAEA;
        padding: 6px 8px 6px 0;
        font-size: 14px;

        @media (min-width:991px) {
            white-space: nowrap;

            &.white-space-normal {
                white-space: normal;
            }
        }

        span {
            span {
                text-decoration: underline;
                display: block;

                &.download-ico {
                    display: inline-block;
                }
            }
        }
    }
}

::v-deep .vgt-global-search {
    background: none;
    border: none;
    text-align: center;

    @media (max-width:991px) {
        padding: 0 15px;
    }
}

::v-deep .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
}

::v-deep .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0,0,0,0);
    transform: rotate3d(0,0,0,0);
    -webkit-transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    background-color: rgba(96, 98, 102, 0.3);
    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

::v-deep .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
}

::v-deep .vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
    margin: 0px;
    height: 100%;
}

::v-deep .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
    margin: 0px;
    height: 100%;
}

::v-deep .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(96, 98, 102, 0.3);
}

::v-deep .vgt-wrap__footer {
    background: none;
    border: none;
    text-align: center;

    .footer__navigation {
        float: none !important;

        .chevron {
            display: none;
        }
    }

    .footer__navigation__page-info__current-entry {
        margin: 0 4px;
    }
}

::v-deep .vgt-inner-wrap {
    border: none;
    box-shadow: none;
}

::v-deep .vgt-wrap__footer {
    .footer__row-count {
        display: none;
    }

    .footer__row-count__label {
        font-size: 14px;
    }

    .footer__row-count__select {
        font-size: 14px;
    }

    .footer__navigation__page-info {
        font-size: 14px;
        margin: 0 20px;
    }

    .footer__navigation__page-btn span {
        font-size: 14px;
    }

    .footer__navigation > button {
        margin: 0;
        padding: 10px;
    }

    .footer__navigation__page-btn .chevron.right::after {
        border-left: 6px solid var(--c-black);
    }

    .footer__navigation__page-btn .chevron.left::after {
        border-right: 6px solid var(--c-black);
    }
}

::v-deep .download-ico {
    &.loading {
        opacity: 0.3;
    }
}

::v-deep .p-0 {
    padding: 10px 0 10px 0 !important;
}

::v-deep .sf-bar {
    background: white;
}

::v-deep .btn.copy {
    line-height: 20px;
    padding: 0px 8px 3px 8px;
    float: left;
    text-decoration: none;
    border-radius: 20px;
    transition: all ease-in-out 300ms;
    cursor: pointer;
    width: 55px;
    text-align: center;

    &:hover {
        background: var(--c-black);
        color: #fff;
    }
}

::v-deep .btn.reserve {
    line-height: 20px;
    padding: 0px 8px 3px 8px;
    float: left;
    text-decoration: none;
    border-radius: 20px;
    transition: all ease-in-out 300ms;
    cursor: pointer;
    width: 55px;
    text-align: center;

    &:hover {
        background: var(--c-black);
        color: #fff;
    }
}

::v-deep .btn.reserved {
    float: left;
    text-align: center;
    width: 55px;
    cursor: pointer;
}

.info-container {
    ul {
        margin: 35px 0;
        padding: 0;
        list-style: none;
        font-size: 12px;

        li {
            margin: 0 0 10px 0;
            padding: 0;
            white-space: pre-line;

            p {
                font-family: var(--font-family--primary-bold);
                margin: 0;
            }
        }
    }
}

.show-more.button {
    margin-left: 15px;
}

::v-deep .sf-select {
    padding: 0;
}

::v-deep .sf-select__dropdown {
    border-color: var(--_c-dark-primary);
    border-bottom-width: 2px;
}

::v-deep .vgt-right-align {
    text-align: left;
}

</style>
