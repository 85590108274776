var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var validate = ref.validate;
return [_c('SfHeading',{staticClass:"sf-heading--left sf-heading--no-underline title",attrs:{"level":3,"title":_vm.$t('User Account')}}),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();validate().then(handleSubmit(_vm.handleFormSubmit(reset)))}}},[_c('div',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":_vm.loginUserAccount ? '' : 'required|min:2',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element form__element--half",attrs:{"label":"First name","name":"firstName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"lastname","rules":_vm.loginUserAccount ? '' : 'required|min:2',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element form__element--half form__element--half-even",attrs:{"label":"Last name","name":"lastName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"email|required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element form__element--half",attrs:{"label":"E-mail","name":"email","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('Button',{staticClass:"button button-primary",attrs:{"type":"submit","disabled":!_vm.canMoveForward}},[_vm._v("\n                "+_vm._s(_vm.$t('Continue to delivery'))+"\n            ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }