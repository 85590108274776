<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>{{ $t('My account') }}</h1>
                <div id="my-account">
                    <SfContentPages
                      :active="activePage"
                      class="my-account"
                      @click:change="changeActivePage"
                    >
                        <SfContentCategory>
                            <SfContentPage :title="$t('My profile')">
                                <MyProfile />
                            </SfContentPage>
                            <SfContentPage :title="$t('Member approval')" v-if="canApproveMember">
                                <MemberApproval />
                            </SfContentPage>
                            <SfContentPage :title="$t('Addresses details')" v-if="canEditBillingAddress">
                                <AddressesDetails />
                            </SfContentPage>
                            <SfContentPage :title="$t('My newsletter')">
                                <MyNewsletter />
                            </SfContentPage>
                        </SfContentCategory>
                	</SfContentPages>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
	computed,
	defineComponent,
	useContext,
	useRoute,
	useRouter,
} from '@nuxtjs/composition-api';
import { SfContentPages } from '@storefront-ui/vue';
import { useUser, useCart } from '@/composables';
import MyProfile from './MyAccount/MyProfile.vue';
import MemberApproval from './MyAccount/MemberApproval.vue';
import AddressesDetails from './MyAccount/AddressesDetails.vue';
import MyNewsletter from './MyAccount/MyNewsletter.vue';

export default defineComponent({
	name: 'MyAccount',
	components: {
		AddressesDetails,
		MyNewsletter,
		MyProfile,
        MemberApproval,
		SfContentPages,
	},
	middleware: 'is-accepted-terms',
	setup() {
		const route = useRoute();
		const router = useRouter();
		const { logout, canApproveMember } = useUser();
		const { clear, canAddToCart } = useCart();
		const { localePath, app } = useContext();
        const userPermissions = app.$vsf.$magento.config.state.getPermissions();

        const canEditBillingAddress = computed(() => userPermissions.find(el => el.code == 'edit billing address'));

		const activePage = computed(() => {
			let { pageName } = route.value.params;
			if (!pageName) {
				pageName = app.i18n.t('My profile');
			}

			return (pageName.charAt(0).toUpperCase() + pageName.slice(1)).replace('-', ' ');
		});

		const changeActivePage = async (title) => {
			if (title === app.i18n.t('Log out')) {
				localStorage.removeItem('vsf-checkout');
				await Promise.all([logout(), clear()]);
				await router.push(`${localePath({ name: 'home' })}`);

				return;
			}

			const slugifiedTitle = (title || '').toLowerCase().replace(' ', '-');
			const transformedPath = `/members/my-account/${slugifiedTitle}`;

			await router.push(`${localePath(transformedPath)}`);
		};

		return {
			activePage,
			changeActivePage,
            canAddToCart,
            canApproveMember,
            canEditBillingAddress,
		};
	},
});
</script>

<style lang="scss" scoped>
#my-account {
    box-sizing: border-box;

    @include for-desktop {
        max-width: 1240px;
        margin: 0 auto;
    }

    ::v-deep .sf-content-pages__sidebar {
        flex: 0 0 25%;
        background: transparent;
    }

    ::v-deep .sf-menu-item__label {
        color: var(--c-black); 
    }
}

.my-account {
    @include for-mobile {
        --content-pages-sidebar-category-title-font-weight: var(--font-weight--normal);
        --content-pages-sidebar-category-title-margin: var(--spacer-sm)
        var(--spacer-sm) var(--spacer-sm) var(--spacer-sm);
    }

    @include for-desktop {
        --content-pages-sidebar-category-title-margin: var(--spacer-xl) 0 0 0;
    }
}

.sf-content-pages {
    height: auto;
}

::v-deep .sf-content-pages__content {
    height: auto;
}

.sf-content-pages__content {
    height: auto;
}
</style>
