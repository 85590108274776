<template>
    <div class="empty-result">
        <slot>
            <svg-image
                icon="logo"
                :label="$t('Vizulo')"
                width="50"
                height="50"
            />
            <p>{{ $t("We can't find products matching the selection.") }}</p>
        </slot>
    </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
	name: 'CategoryEmptyResults',
});
</script>
<style lang="scss" scoped>
.empty-result {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    @include for-mobile {
        min-height: 10vh
    }
}
</style>
