<template>
	<SfSidebar
		@click="toggleSearch"
		:button="false"
		:visible="isNavigationSearchOpen"
		class="navigation-sidebar sf-sidebar--left"
		@close="toggleNavigationSearch"
	>
        <div
            class="menu smartphone-only"
            :class="{ open: isNavigationSearchOpen }"
            @click="toggleNavigationSearch"
        >
            <div>
                <div><span /><span /><span /><span /></div>
            </div>
            <div></div>
        </div>

		<nuxt-link
			:to="localePath('/')"
            class="desktop-only"
		>
			<svg-image
				icon="full_logo"
				:label="$t('Vizulo')"
				width="200"
				height="50"
			/>
		</nuxt-link>

		<SearchBar
			@SearchBar:result="result = $event"
		/>
		<SearchResults
			v-if="isNavigationSearchOpen"
			:visible="isNavigationSearchOpen"
            :result="result"
		/>
	</SfSidebar>
</template>
<script>
import SfSidebar from '~/components/LeftNavigation/SfSidebar';
import {
	defineComponent,
	ref
} from '@nuxtjs/composition-api';

import {
	useUiState
} from '~/composables';

export default defineComponent({
	name: 'LeftNavigationSearch',
	components: {
		SfSidebar,
		SearchBar: () => import('~/components/LeftNavigation/SearchBar/SearchBar.vue'),
		SearchResults: () => import(
			/* webpackPrefetch: true */ '~/components/LeftNavigation/SearchBar/SearchResults.vue'
		),
	},
	setup() {
		const { isNavigationSearchOpen, toggleNavigationSearch } = useUiState();
        const result = ref(null);

		const toggleSearch = () => {
			if (!isNavigationSearchOpen.value) {
				emit('SearchBar:result', {});
			}
		};

		return {
			toggleSearch,
			result,
			isNavigationSearchOpen,
			toggleNavigationSearch,
		};
	},
});
</script>

<style lang="scss" scoped>

::v-deep .svg-image {
	color: var(--c-white);
	opacity: .2;

	&.checkbox {
		opacity: 1;
	}
}

::v-deep .sf-search-bar {
	margin-top: 85px;
	align-items: inherit;
	border-bottom: 1px solid var(--c-white);
	min-height: 40px;
	height: 52px;
	padding-bottom: 12px;

    @media (max-width: 991px) {
        margin-top: 50px;
    }
}

::v-deep .sf-sidebar {
	&__aside {
		width: 45vw;
		max-width: 840px;

		@media (max-width: 991px) {
			width: 100vw;
			max-width: 100%;
			left: 0;
		}
	}

	&__content {
		padding-left: var(--sidebar-left);
		padding-top: 55px;
		display: block;

        @media (max-width: 991px) {
            padding-left: 15px;
            padding-top: 9px;
        }

		h2 {
			color: var(--c-white);
		}
	}
}
</style>
