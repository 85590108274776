<template>
    <div class="row">
        <nuxt-link
            v-for="(product, index) in productGroups"
            :key="getSlug(product)"
            class="product p-0 mb-2"
            :class="getClass(index, 'main', productGroups.length)"
            :to="localePath(
                isProductConfigurator
                    ? getProductConfigurationLink(product, getCategory(product, $route.path, true))
                    : getProductLink(product, getCategory(product, $route.path))
            )"
        >
            <div class="hover">
                <svg-image
                    icon="arrow-right-thin"
                    width="60"
                    height="60"
                />
            </div>
            <div class="product__image m-2">
                <nuxt-img
                    placeholder="/media/catalog-placeholder.png"
                    :loading="'eager'"
                    :src="getMagentoImage(getCoverImage(product))"
                    quality="100"
                    :alt="product.name"
                />

                <img
                    v-if="getProductUlLabel(product)"
                    class="product__image-is-ul"
                    src="/icons/ul.png"
                    alt="UL"
                >
                <h3
                    v-if="getProductLabel(product)"
                    class="product__image-new-label"
                >
                    {{ getProductLabel(product) }}
                </h3>
            </div>
            <div class="content">
                <h5 v-if="!isProductConfigurator">{{ product.name }}</h5>
                <h5 v-else>{{ product.bee_product_name ? product.bee_product_name.toUpperCase() : product.name }}</h5>
                <span v-html="getProductInfo(product)"></span>
            </div>
        </nuxt-link>
    </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { productGetters } from '~/getters';
import { useUiState, useImage } from '~/composables';

export default defineComponent({
	name: 'ProductListNew',
	props: {
		parent: Object,
		productGroups: Array,
		isProductConfigurator: Boolean
	},
	setup(props) {
		const { getMagentoImage } = useImage();
		const { isMobile } = useUiState();

		const getClass = (index, type, length) => {
			if (isMobile.value) {
				if (length % 7 == 0) {
					if (length - 2 <= index) {
						if (type == 'main') {
							return 'col-6';
						} else {
							return 'rectangle';
						}
					}
				}

				if (length % 9 == 0) {
					if (length - 1 <= index) {
						if (type == 'main') {
							return 'col-12';
						} else {
							return 'square';
						}
					}
				}

				if (type == 'main') {
					return index % 5 == 0 ? 'col-12' : 'col-6';
				} else {
					return index % 5 == 0 ? 'square' : 'rectangle';
				}
			} else {
				if (length % 6 == 0 || length % 11 == 0) {
					if (length - 3 <= index) {
						if (type == 'main') {
							return 'col-md-4';
						} else {
							return 'rectangle';
						}
					}
				}

				if (length < 3 || length % 7 == 0) {
					if (length - 3 <= index) {
						if (type == 'main') {
							return 'col-md-6';
						} else {
							return 'square';
						}
					}
				}

				if (length % 9 == 0) {
					if (length - 4 <= index) {
						if (type == 'main') {
							return 'col-md-6';
						} else {
							return 'square';
						}
					}
				}

				if (type == 'main') {
					return index % 5 == 3 || index % 5 == 4 ? 'col-md-6' : 'col-md-4';
				} else {
					return index % 5 == 3 || index % 5 == 4 ? 'square' : 'rectangle';
				}
			}
		};

		return {
			...productGetters,
			getMagentoImage,
			getClass
		}
	}
})
</script>

<style lang="scss" scoped>
.product {
    text-decoration: none;
    color: inherit;

    .content {
        position: absolute;
        top: 1rem;
        left: 2rem;
        padding-right: 10px;

        @media (max-width: 767px) {
            top: 0.5rem;
            left: 1rem;
        }
    }

    .hover {
        background: rgba(0, 0, 0, 0.18);
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        bottom: 0.5rem;
        right: 0.5rem;
        opacity: 0;
        transition: all 0.6s ease-in-out 0s;
        z-index: 1;

        ::v-deep .svg-image {
            position: absolute;
            right: 25px;
            top: 25px;
            color: var(--c-white);
            transition: all 0.8s ease-in-out 0s;
        }
    }

    &:hover {
        .hover {
            opacity: 1;
        }
    }

    &.col-md-4 {
        .product__image {
            aspect-ratio: 0.75;
        }
    }

    &.col-6,
    &.col-md-6 {
        .product__image {
            aspect-ratio: 1;
        }
    }

    h5 {
        margin: 15px 0 4px 0;
        font-size: 14px;
        line-height: 14px;

        @media(max-width: 991px) {
            margin-bottom: 0px;
        }
    }

    span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.4px;
    }

    &__image {
        position: relative;
        background: #DBDBDB;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
}
</style>
